import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { Layout } from 'src/api/testrunner/models/layout';
import { TestItemModel } from 'src/app/models/test-item-model';

export const pageAdapter: EntityAdapter<TestItemModel> = createEntityAdapter<TestItemModel>({
  selectId: (item) => item.id,
  sortComparer: false,
});

export interface PageState extends EntityState<TestItemModel> {
  currentPage: Layout | null;
  isTaskAnswerSaved: boolean;
  isAnswersValid: boolean;
  isPageChanging: boolean;
  pageShowStartTime: string | null;
}
