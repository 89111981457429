<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

  <form [formGroup]="formAnswer">
    <ng-container [ngSwitch]="freeForm?.answerFormat">
      <ng-container *ngSwitchCase="formats.OneRow">
        <input
          class="w-full"
          type="text"
          pInputText
          formControlName="answer"
          [class.ng-invalid]="!isValidLength"
          [class.ng-dirty]="!isValidLength"
          maxlength="{{ maxLength }}"
        />

        <p class="length-hint">{{ lengthHint$ | async }}</p>
        <ng-template *ngIf="freeForm.answerLengthLimitsEnabled && !isValidLength" [ngTemplateOutlet]="answerLengthInvalid"></ng-template>
      </ng-container>
      <!--      <ng-container *ngSwitchCase="formats.RegExp">-->
      <!--        <input class="w-full" type="text" pInputText placeholder="{{t('testrunner.freeform.regExp')}}"/>-->
      <!--      </ng-container>-->
      <ng-container *ngSwitchCase="formats.Email">
        <input
          class="w-full"
          type="text"
          pInputText
          placeholder="your@email.domain"
          formControlName="answer"
          [class.ng-invalid]="!isValidEmail"
          [class.ng-dirty]="!isValidEmail"
        />
      </ng-container>
      <ng-container *ngSwitchCase="formats.PhoneNumber">
        <app-phone-number-input formControlName="answer"></app-phone-number-input>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <textarea
          pInputTextarea
          class="w-full"
          formControlName="answer"
          [rows]="3"
          [class.ng-invalid]="!isValidLength"
          [class.ng-dirty]="!isValidLength"
          [maxlength]="maxLength"
        ></textarea>

        <p class="length-hint">{{ lengthHint$ | async }}</p>
        <ng-template *ngIf="freeForm.answerLengthLimitsEnabled && !isValidLength" [ngTemplateOutlet]="answerLengthInvalid"></ng-template>
      </ng-container>
    </ng-container>
    <p-checkbox
      *ngIf="freeForm.isSkipButtonEnabled"
      inputId="isSkipButtonEnabled"
      name="isSkipButtonEnabled"
      [binary]="true"
      formControlName="isAnswerSkipped"
      [label]="freeForm.skipButtonText || t('testrunner.freeform.noAnswer')"
    ></p-checkbox>
  </form>

  <ng-template #answerLengthInvalid>
    <small class="p-error block">
      <span>
        {{
          t('testrunner.freeform.answerLengthInvalid', {
            answerMinLength: freeForm.answerMinLength,
            answerMaxLength: freeForm.answerMaxLength,
          })
        }}
      </span>
    </small>
  </ng-template>
</ng-container>
