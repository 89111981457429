import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TestItemCardGroupExtended } from '../../models/test-item-card-group-extended';

@Component({
  selector: 'app-card-group',
  styleUrls: ['./card-group.component.scss'],
  templateUrl: './card-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardGroupComponent implements OnInit {
  @Input() public cardsCount = 0;
  @Input() public readonly cardGroup!: TestItemCardGroupExtended;
  @Input() public isCardsGroupsLimitEnabled = false;
  @Input() public isCardsGroupNameRequired = false;
  @Output() public readonly cardGroupChange: EventEmitter<TestItemCardGroupExtended> = new EventEmitter<TestItemCardGroupExtended>();
  @Output() public readonly removeGroup: EventEmitter<TestItemCardGroupExtended> = new EventEmitter<TestItemCardGroupExtended>();
  groupName = '';
  isGroupNameEditing = false;
  isGroupNameEmpty = false;

  constructor() {}

  public getTooltipActivationEvent(): 'focus' | 'hover' {
    return 'ontouchstart' in document.documentElement ? 'focus' : 'hover';
  }

  public ngOnInit(): void {
    this.groupName = this.cardGroup.name ?? '';
    // Если обязательное заполнение имени группы и пустое имя, то при инициализации надо перейти в режим редактирования названия
    this.isGroupNameEditing = this.groupName === '' && this.isCardsGroupNameRequired;
  }

  saveGroupName(): void {
    this.isGroupNameEmpty = !this.groupName.length;
    if (!this.isGroupNameEmpty || !this.isCardsGroupNameRequired) {
      this.isGroupNameEditing = false;
      this.cardGroupChange.emit({ ...this.cardGroup, name: this.groupName });
    }
  }
}
