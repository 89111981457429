import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import {
  DistributionTypeEnum,
  LayoutGroup,
  Page,
  RandomizeTypeEnum,
  TaskGroup,
  TestItemResultFieldEnum,
  TestItemStatistics,
  TestItemStatisticsKey,
  TestItemTypeEnum,
} from 'src/api/testrunner/models';
import { TestItemStatisticsService } from 'src/api/testrunner/services/test-item-statistics.service';
import { EMPTY_GUID } from '../../constants/constants';
import { LogicService } from '../../services/logic.service';
import { changePageAnswerSuccessAction } from '../actions/answers.actions';
import { getNextPageAction, getNextPageSuccessAction } from '../actions/page.actions';
import {
  getBalancingStatisticsSuccessAction,
  getLayoutStatisticsSuccessAction,
  getPageItemsStatisticsSuccessAction,
} from '../actions/test-item-statistics.actions';
import { backendErrorsAction } from '../actions/test-runner.actions';
import { selectAllTestItemModelsOnPage } from '../selectors/page.selectors';
import { selectLastStatisticsUpdate } from '../selectors/test-item-statistics.selectors';
import { selectAllTestItems } from '../selectors/test-items.selectors';

const REQUEST_FIELDS = [TestItemResultFieldEnum.LogicResultTrueCount, TestItemResultFieldEnum.LogicResultFalseCount];

@Injectable()
export class TestItemStatisticsEffects {
  /**
   * Эффект для получения статистики рандосизации для элементов типа Task
   */
  getBalancingStatistics = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNextPageSuccessAction),
      concatLatestFrom(() => [this.store.select(selectAllTestItemModelsOnPage), this.store.select(selectLastStatisticsUpdate)]),
      exhaustMap(([{ page }, testItemModels, lastUpdate]) => {
        // Договорились отправлять запросы на серверную статистику не чаще чем раз в 5 секунд
        if (Date.now() - lastUpdate > 5000) {
          const testItemStatisticsKeys: Array<TestItemStatisticsKey> = [
            ...(page ? [page] : []),
            ...testItemModels.map((testItemModel) => testItemModel.data),
          ]
            .filter(
              (testItem) =>
                [TestItemTypeEnum.TaskGroup, TestItemTypeEnum.Page].includes(testItem?.testItemType) &&
                (testItem as Page | TaskGroup).randomizeType === RandomizeTypeEnum.SpecifiedCount &&
                (testItem as Page | TaskGroup).distributionType === DistributionTypeEnum.Balancing,
            )
            .map((groupItem) =>
              [
                ...(groupItem.groupItems || []).filter((testItem) => testItem.testItemType !== TestItemTypeEnum.Else),
                ...((groupItem.groupItems || []).find((testItem) => testItem.testItemType === TestItemTypeEnum.Else)?.groupItems || []),
              ].map((testItem) => ({ testItemId: testItem.id, iterationId: EMPTY_GUID }) as TestItemStatisticsKey),
            )
            .reduce((result, quotaId) => result.concat(quotaId), []);

          return testItemStatisticsKeys.length > 0
            ? this.testItemStatisticsService.testItemStatisticsGetTestItemStatisticsPostAsync({ body: { testItemStatisticsKeys } }).pipe(
                map((testItemStatisticsResponse) =>
                  //Запрос по айди тест айтемов статистик
                  getBalancingStatisticsSuccessAction({
                    page,
                    testItemStatistics: testItemStatisticsResponse?.testItemStatistics ?? new Array<TestItemStatistics>(),
                  }),
                ),

                catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
              )
            : of(getBalancingStatisticsSuccessAction({ page, testItemStatistics: new Array<TestItemStatistics>() }));
        }

        return of(getBalancingStatisticsSuccessAction({ page, testItemStatistics: new Array<TestItemStatistics>() }));
      }),
    );
  });

  /**
   * Эффект для получения статистики для кондишнов лэйаут элементов
   */
  getLayoutItemsStatistics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNextPageAction),
      concatLatestFrom(() => [this.store.select(selectAllTestItems), this.store.select(selectLastStatisticsUpdate)]),
      exhaustMap(([, testItems, lastUpdate]) => {
        // Договорились отправлять запросы на серверную статистику не чаще чем раз в 5 секунд
        if (Date.now() - lastUpdate > 5000) {
          const testItemStatisticsKeys: Array<TestItemStatisticsKey> = [
            //Логика
            ...(testItems || [])
              // Поиск всех лэйаут элементов
              .filter((testItem) =>
                [
                  TestItemTypeEnum.LayoutGroup,
                  TestItemTypeEnum.Mobile,
                  TestItemTypeEnum.Page,
                  TestItemTypeEnum.WebSite,
                  TestItemTypeEnum.TestEndByCondition,
                  TestItemTypeEnum.TestEndByQuota,
                ].includes(testItem.testItemType),
              )
              // Поиск guid айтемов в кондишнах на квоты или другие данные с сервера. Типы таких кондишнов указаны в массиве REQUEST_FIELDS
              .map((testItem) => this.logicService.getTestItemStatisticsIds(testItem?.rootConditionGroup ?? null, REQUEST_FIELDS))
              .reduce((result, quotaId) => result.concat(quotaId), [])
              // Distinct
              .filter((value, index, self) => self.indexOf(value) === index),
            //Рандомизация
            ...(testItems || [])
              .filter(
                (testItem) =>
                  testItem.testItemType === TestItemTypeEnum.LayoutGroup &&
                  (testItem as LayoutGroup).randomizeType === RandomizeTypeEnum.SpecifiedCount &&
                  (testItem as LayoutGroup).distributionType === DistributionTypeEnum.Balancing,
              )
              .map((layoutGroup) =>
                [
                  ...(layoutGroup.groupItems || []).filter((testItem) => testItem.testItemType !== TestItemTypeEnum.Else),
                  ...((layoutGroup.groupItems || []).find((testItem) => testItem.testItemType === TestItemTypeEnum.Else)?.groupItems || []),
                ].map((testItem) => ({ testItemId: testItem.id, iterationId: EMPTY_GUID }) as TestItemStatisticsKey),
              )
              .reduce((result, quotaId) => result.concat(quotaId), []),
            //Квоты
            ...(testItems || [])
              .filter((testItem) => testItem.testItemType === TestItemTypeEnum.TestEndByQuota)
              .map((testItem) => ({ testItemId: testItem.id, iterationId: EMPTY_GUID }) as TestItemStatisticsKey),
          ];

          return testItemStatisticsKeys.length > 0
            ? this.testItemStatisticsService.testItemStatisticsGetTestItemStatisticsPostAsync({ body: { testItemStatisticsKeys } }).pipe(
                map((testItemStatisticsResponse) =>
                  //Запрос по айди тест айтемов статистик
                  getLayoutStatisticsSuccessAction({
                    testItemStatistics: testItemStatisticsResponse?.testItemStatistics ?? new Array<TestItemStatistics>(),
                  }),
                ),

                catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
              )
            : of(getLayoutStatisticsSuccessAction({ testItemStatistics: new Array<TestItemStatistics>() }));
        }

        return of(getLayoutStatisticsSuccessAction({ testItemStatistics: new Array<TestItemStatistics>() }));
      }),
    );
  });

  /**
   * Эффект для получения статистики для элементов типа Task
   */
  getPageItemsStatistics$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePageAnswerSuccessAction),
      concatLatestFrom(() => [this.store.select(selectAllTestItemModelsOnPage), this.store.select(selectLastStatisticsUpdate)]),
      exhaustMap(([, testItemModels, lastUpdate]) => {
        // Договорились отправлять запросы на серверную статистику не чаще чем раз в 5 секунд
        if (Date.now() - lastUpdate > 5000) {
          const testItemStatisticsKeys: Array<TestItemStatisticsKey> = testItemModels
            // Поиск всех тасков кроме Else
            .filter((testItemModel) => testItemModel.data.testItemType !== TestItemTypeEnum.Else)
            // Поиск guid айтемов в кондишнах на квоты или другие данные с сервера. Типы таких кондишнов указаны в массиве REQUEST_FIELDS
            .map((testItemModel) =>
              this.logicService.getTestItemStatisticsIds(testItemModel.data?.rootConditionGroup ?? null, REQUEST_FIELDS),
            )
            .reduce((result, quotaId) => result.concat(quotaId), [])
            // Distinct
            .filter((value, index, self) => self.indexOf(value) === index);

          return testItemStatisticsKeys.length > 0
            ? this.testItemStatisticsService.testItemStatisticsGetTestItemStatisticsPostAsync({ body: { testItemStatisticsKeys } }).pipe(
                map((testItemStatisticsResponse) =>
                  //Запрос по айди тест айтемов статистик
                  getPageItemsStatisticsSuccessAction({
                    testItemStatistics: testItemStatisticsResponse?.testItemStatistics ?? new Array<TestItemStatistics>(),
                  }),
                ),

                catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse }))),
              )
            : of(getPageItemsStatisticsSuccessAction({ testItemStatistics: new Array<TestItemStatistics>() }));
        }

        return of(getPageItemsStatisticsSuccessAction({ testItemStatistics: new Array<TestItemStatistics>() }));
      }),
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly logicService: LogicService,
    private readonly store: Store,
    private readonly testItemStatisticsService: TestItemStatisticsService,
  ) {}
}
