import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DeviceInfo } from 'ngx-device-detector/lib/device-detector.service';
import { Observable, Subscription, of } from 'rxjs';
import { MobileTaskTypeEnum } from '../../../api/testrunner/models';
import { Mobile } from '../../../api/testrunner/models/mobile';
import { environment } from '../../../environments/environment';
import { MobileAppActionEnum } from '../../models/mobile-app-action-enum';
import { MobileAppStatusEnum } from '../../models/mobile-app-status-enum';
import { TestInfo } from '../../models/test-info';
import { MacrosPipe } from '../../services/macros.pipe';
import { MobileService } from '../../services/mobile.service';
import { PersistenceService } from '../../services/persistence.service';
import { setMobileAppStatusSuccessAction } from '../../store/actions/message.actions';
import { selectIsMobileAppInstalled } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.scss'],
})
export class MobileComponent implements OnInit, OnDestroy {
  @Input() canStartTask = true;
  @Input() mobile?: Mobile;
  @Input() testInfo?: TestInfo;

  // @todo: вынести это все в environment файлы
  readonly appUri;
  activeLang: string;
  appInstallInstructionAndroid?: SafeHtml;
  appInstallInstructionIos?: SafeHtml;
  defaultCompleteButtonText;
  defaultGoToTaskButtonText;
  defaultReturnButtonText;
  defaultSkipButtonText;
  description?: SafeHtml;
  deviceInfo?: DeviceInfo;
  isMobileAppInstalled$: Observable<boolean> = of(false);
  mobileTaskTypeEnum = MobileTaskTypeEnum;
  taskInProgress = false;
  readonly testUrl;

  //@TODO private macrosData?: MacrosParams;
  private startUrl?: string;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    public deviceDetectorService: DeviceDetectorService,
    private macrosPipe: MacrosPipe,
    private mobileService: MobileService,
    private persistenceService: PersistenceService,
    private sanitizer: DomSanitizer,
    private store: Store,
    private translocoService: TranslocoService,
    route: ActivatedRoute,
    router: Router,
  ) {
    this.activeLang = translocoService.getActiveLang();
    this.defaultCompleteButtonText = translocoService.translate('testrunner.mobile.completeAssignment');
    this.defaultGoToTaskButtonText = translocoService.translate('testrunner.mobile.goToAssignment');
    this.defaultReturnButtonText = translocoService.translate('testrunner.mobile.returnToAssignment');
    this.defaultSkipButtonText = translocoService.translate('testrunner.mobile.skipAssignment');

    const urlTree = router.createUrlTree([], {
      relativeTo: route,
      queryParams: {
        anonymousId: this.persistenceService.get('anonymousId'),
        token: persistenceService.get('testRunnerToken'),
      },
      queryParamsHandling: 'merge',
    });

    this.appUri = `${environment.mobileAppScheme}://${document.location.host}${urlTree.toString()}`;
    this.testUrl = document.location.origin + urlTree.toString();
  }

  get appInstallPageUrl(): string {
    switch (this.deviceInfo?.os) {
      case 'iOS':
        return environment.iosAppPageUrl;
      case 'Android':
        return `${environment.androidAppPageUrl}&referrer=${encodeURIComponent(this.testUrl)}`;
      default:
        return '';
    }
  }

  get completeButtonText(): string {
    return (this.mobile as Mobile).finishButtonText ?? this.defaultCompleteButtonText;
  }

  get goToTaskButtonText(): string {
    return (this.mobile as Mobile).startButtonText ?? this.defaultGoToTaskButtonText;
  }

  get returnButtonText(): string {
    return (this.mobile as Mobile).returnButtonText ?? this.defaultReturnButtonText;
  }

  get skipButtonText(): string {
    return (this.mobile as Mobile).skipButtonText ?? this.defaultSkipButtonText;
  }

  backToTask(): void {
    this.mobileService.postMessage(
      MobileAppActionEnum.BackToTask,
      {
        ...this.mobile,
        androidUrl: this.macrosPipe.transform(this.mobile?.androidUrl ?? '', this.mobile),
        iosUrl: this.macrosPipe.transform(this.mobile?.iosUrl ?? '', this.mobile),
        startUrl: this.macrosPipe.transform(this.mobile?.startUrl ?? '', this.mobile),
      } as Mobile,
      //@TODO this.macrosData;
    );
    this.store.dispatch(setMobileAppStatusSuccessAction({ status: null }));
    this.taskInProgress = false;
  }

  skipTask(): void {
    // this.mobileService.postMessage(MobileAppActionEnum.FinishTask, this.mobile);
    this.store.dispatch(setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.TaskSkipped }));
    this.taskInProgress = false;
  }

  completeTask(): void {
    this.store.dispatch(setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.TaskCompleted }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    console.log('deviceInfo', this.deviceInfo);
    console.log('isDesktop', this.deviceDetectorService.isDesktop());

    const defaultInstruction = this.translocoService.translate('testrunner.mobile.instruction');

    this.appInstallInstructionAndroid = this.sanitizer.bypassSecurityTrustHtml(
      this.mobile?.appInstallInstructionAndroid ? this.macrosPipe.transform(this.mobile?.appInstallInstructionAndroid) : defaultInstruction,
    );

    this.appInstallInstructionIos = this.sanitizer.bypassSecurityTrustHtml(
      this.mobile?.appInstallInstructionIos ? this.macrosPipe.transform(this.mobile?.appInstallInstructionIos) : defaultInstruction,
    );

    // Подстановка макросов
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.macrosPipe.transform(this.mobile?.description ?? '', this.mobile));
    //@TODO this.macrosData = this.macrosService.getMacrosDictionary(this.mobile);
    this.startUrl = this.macrosPipe.transform(this.mobile?.startUrl ?? '', this.mobile);

    this.isMobileAppInstalled$ = this.store.select(selectIsMobileAppInstalled);
    this.subscription.add(
      this.isMobileAppInstalled$.subscribe((isMobileAppInstalled) => isMobileAppInstalled && this.startMobileAppTask()),
    );
  }

  startMobileAppTask(): void {
    this.mobileService.postMessage(
      MobileAppActionEnum.StartTask,
      {
        ...this.mobile,
        androidUrl: this.macrosPipe.transform(this.mobile?.androidUrl ?? '', this.mobile),
        iosUrl: this.macrosPipe.transform(this.mobile?.iosUrl ?? '', this.mobile),
        startUrl: this.macrosPipe.transform(this.mobile?.startUrl ?? '', this.mobile),
      } as Mobile,
      //@TODO this.macrosData
    );
    this.store.dispatch(setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.TaskInProgress }));
    this.taskInProgress = true;
  }
}
