import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { backendErrorsAction } from '../actions/test-runner.actions';
import { HttpErrorResponse } from '@angular/common/http';
import { saveVideoAction, saveVideoSuccessAction } from '../actions/telemetry.actions';
import { VideoService } from '../../../api/telemetry/services/video.service';

@Injectable()
export class TelemetryEffects {
  saveVideo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveVideoAction),
      concatMap(({ video }) =>
        this.videoService.videoSaveVideo(video).pipe(
          map(() => saveVideoSuccessAction()),

          catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private videoService: VideoService) {}
}
