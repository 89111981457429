<ng-container *transloco="let t">
  <div *ngIf="answerQuantity === answerQuantityEnum.Multiple; then multiple; else single"></div>

  <ng-template #multiple>
    <div class="grid mx-0 option-group">
      <p-autoComplete
        (completeMethod)="searchInDictionary($event.query)"
        dataKey="entityId"
        [dropdown]="true"
        [field]="dictionaryLanguage"
        [group]="dictionaryDepthLevel > 0"
        [multiple]="true"
        [optionGroupLabel]="dictionaryLanguage"
        optionGroupChildren="fabDictionaryItems"
        [(ngModel)]="selectedItems"
        (ngModelChange)="updateValue()"
        [placeholder]="dictionaryName"
        [suggestions]="searchResults"
        [forceSelection]="true"
      >
        <ng-template let-entity pTemplate="selectedItem">
          {{ getDictionaryItemCaption(entity) }}
        </ng-template>
      </p-autoComplete>
    </div>
  </ng-template>

  <ng-template #single let-formControl="selectedValue">
    <div class="grid mx-0 option-group">
      <p-autoComplete
        (completeMethod)="searchInDictionary($event.query)"
        dataKey="entityId"
        [dropdown]="true"
        [field]="dictionaryLanguage"
        [group]="dictionaryDepthLevel > 0"
        [optionGroupLabel]="dictionaryLanguage"
        optionGroupChildren="fabDictionaryItems"
        [(ngModel)]="selectedItem"
        (ngModelChange)="updateValue()"
        [placeholder]="dictionaryName"
        [suggestions]="searchResults"
        [forceSelection]="true"
      >
        <ng-template let-entity pTemplate="selectedItem">
          {{ getDictionaryItemCaption(entity) }}
        </ng-template>
      </p-autoComplete>
    </div>
  </ng-template>
</ng-container>
