<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

  <form [formGroup]="formAnswer">
    <ng-container formArrayName="formRows">
      <ng-container
        *ngIf="columns?.length < maxColumns && rows?.length < maxRows && (isMobileDevice$ | async) !== true; else accordionMatrix"
      >
        <p-table [columns]="columns" [value]="rows" [scrollable]="true">
          <ng-template pTemplate="header" let-columns frozen="true">
            <tr #head>
              <th class="border-none" pFrozenColumn></th>
              <th
                *ngFor="let col of columns"
                class="border-none bg-transparent text-center font-normal white-space-nowrap col-title justify-content-center"
                [style.minWidth.px]="columnWidth$ | async"
              >
                <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: col }"></ng-template>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-row let-columns="columns" let-rowIndex="rowIndex">
            <tr [formGroupName]="rowIndex">
              <td class="border-none pl-0" pFrozenColumn>
                <ng-template
                  [ngTemplateOutlet]="answerTemplateRef"
                  [ngTemplateOutletContext]="{ col: row, isFirstCol: true }"
                ></ng-template>
              </td>

              <td *ngFor="let col of columns; let colIndex = index" class="border-none text-center">
                <p-radioButton *ngIf="!isMultipleAnswers" [value]="col.entityId" [formControlName]="row?.entityId"></p-radioButton>
                <ng-container *ngIf="isMultipleAnswers" [formArrayName]="row?.entityId">
                  <p-checkbox
                    [value]="col.entityId"
                    [formControlName]="colIndex"
                    (onChange)="checkAnswerMaxSelected(row?.entityId)"
                  ></p-checkbox>
                </ng-container>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </ng-container>

      <!-- аккордеон -->
      <ng-template #accordionMatrix>
        <p-accordion
          class="accordion-matrix"
          expandIcon="fa-solid fa-caret-right"
          collapseIcon="fa-solid fa-caret-down"
          (activeIndexChange)="setAccordionActiveIndex($event)"
          [activeIndex]="accordionActiveIndex$ | async"
        >
          <p-accordionTab *ngFor="let row of rows; let i = index; let lastTab = last" [formGroupName]="i" #accordionTab>
            <ng-template pTemplate="header">
              <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row, isFirstCol: true }"></ng-template>
            </ng-template>

            <ng-template pTemplate="content">
              <ng-container *ngIf="!isMultipleAnswers">
                <ng-container *ngFor="let col of columns">
                  <label
                    [for]="matrix?.id + row?.entityId + col?.entityId"
                    class="border-none flex justify-content-start accordion-row"
                    (click)="accordionTabCompleted(i, lastTab)"
                  >
                    <p-radioButton
                      [value]="col.entityId"
                      [formControlName]="row?.entityId"
                      [inputId]="matrix?.id + row?.entityId + col?.entityId"
                    ></p-radioButton>
                    <span class="p-column-title">
                      <ng-template
                        [ngTemplateOutlet]="answerTemplateRef"
                        [ngTemplateOutletContext]="{ col: col, isAccordion: true }"
                      ></ng-template>
                    </span>
                  </label>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="isMultipleAnswers">
                <label
                  [for]="matrix.id + row?.entityId + col?.entityId"
                  *ngFor="let col of columns; index as i"
                  formArrayName="{{ row?.entityId }}"
                  class="border-none flex justify-content-start accordion-row"
                >
                  <p-checkbox
                    [value]="col.entityId"
                    [formControlName]="i"
                    (onChange)="checkAnswerMaxSelected(row?.entityId)"
                    [inputId]="matrix.id + row?.entityId + col?.entityId"
                  ></p-checkbox>
                  <span class="p-column-title">
                    <ng-template
                      [ngTemplateOutlet]="answerTemplateRef"
                      [ngTemplateOutletContext]="{ col: col, isAccordion: true }"
                    ></ng-template>
                  </span>
                </label>
              </ng-container>
            </ng-template>
          </p-accordionTab>
        </p-accordion>
      </ng-template>
    </ng-container>

    <p-checkbox
      *ngIf="matrix.isSkipButtonEnabled"
      inputId="isSkipButtonEnabled"
      name="isSkipButtonEnabled"
      [binary]="true"
      formControlName="isAnswerSkipped"
      [label]="matrix.skipButtonText || t('testrunner.freeform.noAnswer')"
    ></p-checkbox>
  </form>
</ng-container>

<ng-template #answerTemplateRef let-col="col" let-isFirstCol="isFirstCol" let-isAccordion="isAccordion">
  <div class="flex align-items-center answer" [class.justify-content-center]="!isFirstCol && !isAccordion">
    <ng-container *ngIf="col?.imageUrl?.length">
      <span class="mr-2">
        <app-image [url]="col.imageUrl" [dialog]="true" [title]="col?.captionRu ?? ''"></app-image>
      </span>
    </ng-container>
    <span [innerHTML]="col?.captionRu ?? '' | safeHtml"></span>
  </div>
</ng-template>
