import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmationService } from 'primeng/api';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { FirstGlanceResult, Task, TestItemResult, TestItemResultStatusEnum, TestItemResultTypeEnum } from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';

@Component({
  selector: 'app-first-glance',
  templateUrl: './first-glance.component.html',
  styleUrls: ['./first-glance.component.scss'],
  providers: [ConfirmationService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstGlanceComponent implements OnChanges, OnInit {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  description: SafeHtml | undefined;
  displayTimeMS?: number;
  imageUrl?: string | null;
  info?: SafeHtml | undefined;
  isInfoDialogVisible = false;
  isImageHidden = true;
  isImageLoading = true;
  isImageLoaded = true;
  isShown = false;
  firstGlance?: Task;
  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly pipe: MacrosPipe,
    private readonly sanitizer: DomSanitizer,
    private readonly translocoService: TranslocoService,
  ) {}

  closeInfoDialog(): void {
    this.isInfoDialogVisible = false;
  }

  public ngOnInit(): void {
    this.firstGlance = this.testItemModel?.data;

    this.imageUrl = this.firstGlance?.imageUrl;
    this.isImageLoading = !!this.firstGlance?.imageUrl;
    this.displayTimeMS = this.firstGlance?.displayTimeMS;
    if (this.firstGlance?.id) {
      this.isShown = !!this.persistenceService.get('answer_' + this.firstGlance?.id);
    }

    this.info = this.translocoService.translate('testrunner.firstGlance.info', { displayTime: (this.displayTimeMS ?? 0) / 1000 });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev!==curr because of lastUpdateTime
    ) {
      this.descriptionString = this.testItemModel.data.description;

      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  onImageError(): void {
    this.isImageLoading = false;
    this.isImageLoaded = false;
  }

  onImageLoad(): void {
    this.isImageLoading = false;
    this.isImageLoaded = true;
  }

  openInfoDialog(): void {
    this.isInfoDialogVisible = true;
  }

  showImage(): void {
    this.closeInfoDialog();

    const readingTimeSpentMs = DateTimeService.getDuration(this.testItemModel?.showStartTime);
    this.isImageHidden = false;
    interval(this.displayTimeMS)
      .pipe(take(1))
      .subscribe(() => {
        this.answerChanges.emit({
          answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
          clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
          iterationId: this.testItemModel?.iterationId,
          readingTimeSpentMs,
          testId: this.testItemModel?.data.testId,
          testItemId: this.testItemModel?.data.id,
          type: TestItemResultTypeEnum.FirstGlance,
          status: TestItemResultStatusEnum.Intermediate,
        } as FirstGlanceResult);

        this.isImageHidden = true;
        this.isShown = true;
      });
  }
}
