<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

  <ng-container *ngIf="!isImageLoading">
    <button
      *ngIf="isImageHidden && !hasAnswer; else msg"
      type="button"
      pButton
      pRipple
      class="p-button-outlined p-button-secondary"
      [label]="t('testrunner.firstClick.showImage')"
      (click)="showImage()"
    ></button>
    <ng-template #msg>
      <span class="border-1 border-primary border-round px-3 py-2 text-primary">
        <i class="fa-solid fa-check"></i>
        <span class="ml-2 font-medium">{{t('testrunner.firstClick.clickSaved')}}</span>
      </span>
    </ng-template>
  </ng-container>

  <div *ngIf="isImageLoading" class="flex justify-content-center align-items-center h-full">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <p-sidebar
    [visible]="!isImageHidden"
    [fullScreen]="true"
    [showCloseIcon]="false"
    (onHide)="isImageHidden = true"
    [blockScroll]="true"
    [modal]="false"
  >
    <div *ngIf="isImageLoaded">
      <!--div-- class="flex align-items-center justify-content-end"
           style="height: 3rem; margin: auto"
           [style.width]="imageElementWidth">
        <p-tag
          [value]="t('testrunner.firstClick.closeButton')"
          icon="fa-regular fa-xmark"
          [rounded]="true"
          class="mr-3"
          styleClass="cursor-pointer bg-gray-900 text-white font-medium"
          (click)="isImageHidden = true"
        ></p-tag>
      </div-->

      <div
           class="help-text-wrapper"
      >
        <div [innerHTML]="helpText"></div>
      </div>

      <div
        class="image-container"
        [class.align-items-center]="isImageFitToScreenEnabled"
        [class.flex]="isImageFitToScreenEnabled"
        [class.justify-content-center]="isImageFitToScreenEnabled"
      >
        <img
          #imageElement
          *ngIf="isImageLoaded"
          class="image"
          [src]="imageUrl"
          (load)="onImageLoad()"
          (error)="onImageError()"
          [hidden]="isImageHidden"
          (click)="onImageClick($event)"
          [class.fit-to-screen]="isImageFitToScreenEnabled"
          [ngStyle]="{'width': (isHighDpi) ? realImageWidth/devicePixelRatio +'px' : realImageWidth + 'px'}"
        >
        <i *ngIf="!isImageLoaded" class="fa-regular fa-image-slash fa-10x" (click)="onImageClick($event)"></i>
      </div>
    </div>
  </p-sidebar>

  <p-dialog
    [visible]="!isHelperHidden" (visibleChange)="isHelperHidden = $event"
    (onHide)="isHelperHidden = true"
    appendTo="body"
    [blockScroll]="true"
    position="top-right"
    [modal]="true"
    [closable]="false"
    [dismissableMask]="true"
    [draggable]="false"
    [showHeader]="false"
  >
    <div [innerHTML]="helpText"></div>

    <ng-template pTemplate="footer">
      <button
        pButton
        type="button"
        [label]="t('testrunner.firstClick.closeButton')"
        class="p-button-secondary p-button-outlined mr-0"
        (click)="isHelperHidden = true"
      ></button>
    </ng-template>
  </p-dialog>
</ng-container>
