<div
  [@fadeInLeft]="fadeInLeftAnimationTrigger"
  (@fadeInLeft.done)="fadeInLeftAnimationDone($event)"
  [@fadeInRight]="fadeInRightAnimationTrigger"
  (@fadeInRight.done)="fadeInRightAnimationDone($event)"
  [@fadeOutLeft]="fadeOutLeftAnimationTrigger"
  (@fadeOutLeft.done)="fadeOutLeftAnimationDone($event)"
  [@fadeOutRight]="fadeOutRightAnimationTrigger"
  (@fadeOutRight.done)="fadeOutRightAnimationDone($event)"
  *transloco="let t"
  class="h-full"
>
  <ng-container *ngIf="!(isPageChanging$ | async) && !(isVideoLoading$ | async)">
    <app-page
      *ngIf="currentPage$ | async"
      class="flex flex-column w-full h-full"
      [page]="currentPage$ | async"
      [isLastPage]="isLastPage$ | async"
      [testScripts]="testScripts$ | async"
      (toNextPage)="onNextPage($event)"
      (toPrevPage)="onPrevPage()"
    >
    </app-page>
  </ng-container>

  <div class="flex flex-column justify-content-center align-items-center w-full h-full" *ngIf="isVideoLoading$ | async">
    <p-progressSpinner></p-progressSpinner>
    <p class="mt-2 font-normal text-lg">{{ t('testrunner.webSite.videoIsUploading') }}...</p>
  </div>

  <ng-container *ngIf="(extensionStatus$ | async) === null && (isTestFinished$ | async) === true && (isVideoLoading$ | async) !== true">
    <ng-container *ngIf="(isAnswersQueueEmpty$ | async) === true; else answerSavingProgress">
      <app-test-end
        [completeTestInfo]="completeTestInfo$ | async"
        [redirectUrl]="redirectUrl$ | async"
        [test]="test$ | async"
      ></app-test-end>
    </ng-container>

    <ng-template #answerSavingProgress>
      <div class="flex flex-column justify-content-center align-items-center w-full h-full">
        <p-progressSpinner></p-progressSpinner>
        <p class="mt-2 font-normal text-lg">{{ t('testrunner.waitingAnswersSaving') }}...</p>
      </div>
    </ng-template>
  </ng-container>
</div>
