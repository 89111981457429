import { Injectable } from '@angular/core';
import { FilterService } from 'primeng/api';
import { FabDictionary } from '../../api/testrunner/models/fab-dictionary';
import { FabDictionaryItem } from 'src/api/testrunner/models/fab-dictionary-item';
import { GroupedDictionary } from '../models/grouped-dictionary';
import { TranslocoService } from '@ngneat/transloco';
import { TranslationsService } from './translations.service';

@Injectable({
  providedIn: 'root',
})
export class DictionaryToolsService {
  simpleDictionaryDeep = 3;
  bigDictionary: Array<FabDictionaryItem> = [];

  constructor(
    private filterService: FilterService,
    private translocoService: TranslocoService,
    private translationService: TranslationsService
  ) {}

  /* Конвертация словаря в сгруппированный список */
  convertDictionaryToGroupedList(fabDictionary: FabDictionary): GroupedDictionary[] {
    const groupedList: GroupedDictionary[] = [];

    fabDictionary.fabDictionaryItems?.forEach((_fabDictionaryItem) => {
      const group = {
        label: _fabDictionaryItem[this.translationService.getDictionaryLanguages()],
        value: _fabDictionaryItem.entityId,
        items: _fabDictionaryItem.fabDictionaryItems?.map((fabDictionaryItemEntry) => ({
          captionRu: fabDictionaryItemEntry.captionRu,
          captionEn: fabDictionaryItemEntry.captionEn,
          entityId: fabDictionaryItemEntry.entityId,
        })),
      };
      groupedList.push(group as GroupedDictionary);
    });

    return groupedList;
  }

  convertDictionaryToSimpleList(fabDictionary: FabDictionary): FabDictionaryItem[] {
    return this.simplifyLargeDictionary(fabDictionary?.fabDictionaryItems ?? []);
  }

  convertDictionaryToTwoLevelList(dictionaryItems: Array<FabDictionaryItem>, maxDepthLevel: number, level = 0): Array<FabDictionaryItem> {
    const resultDepthLevel = 2;
    return (dictionaryItems || []).reduce((twoLevelList, dictionaryItem) => {
      if (maxDepthLevel - level < resultDepthLevel) {
        twoLevelList.push(dictionaryItem);
      }
      if (dictionaryItem?.fabDictionaryItems?.length && maxDepthLevel - level >= resultDepthLevel) {
        twoLevelList = [
          ...twoLevelList,
          ...this.convertDictionaryToTwoLevelList(dictionaryItem.fabDictionaryItems, maxDepthLevel, level + 1),
        ];
      }
      return twoLevelList;
    }, new Array<FabDictionaryItem>());
  }

  /* Является ли словарь более чем 2-уровневой вложенности */
  isDeepDictionary(fabDictionary: FabDictionary | null): boolean {
    return this.getDictionaryDepth(fabDictionary?.fabDictionaryItems ?? []) >= this.simpleDictionaryDeep;
  }

  getDictionaryDepth(dictionaryItems: Array<FabDictionaryItem>, level = 0): number {
    return (dictionaryItems || []).reduce(
      (depth: number, dictionaryItem) =>
        Math.max(
          depth,
          dictionaryItem?.fabDictionaryItems?.length ? this.getDictionaryDepth(dictionaryItem.fabDictionaryItems, level + 1) : depth
        ),
      level
    );
  }

  getFlattenDictionaryItems(fabDictionaryItems: Array<FabDictionaryItem>): Array<FabDictionaryItem> {
    return (fabDictionaryItems || []).reduce(
      (flatDictionary, fabDictionaryItem) => [
        ...flatDictionary,
        { ...fabDictionaryItem },
        ...this.getFlattenDictionaryItems(fabDictionaryItem?.fabDictionaryItems ?? []),
      ],
      new Array<FabDictionaryItem>()
    );
  }

  getDictionaryItemCaption(entity: FabDictionaryItem | string, simpleList: FabDictionaryItem[]): string {
    if (typeof entity === 'object' && (entity.captionRu || entity.captionEn)) {
      return entity[this.translationService.getDictionaryLanguages()] ?? '';
    } else {
      const fabDictionaryItem = simpleList?.filter((_fabDictionaryItem) => _fabDictionaryItem.entityId === entity);
      return fabDictionaryItem ? fabDictionaryItem[0][this.translationService.getDictionaryLanguages()] ?? '' : '';
    }
  }

  filterRecursive(array: FabDictionaryItem[], callback: (fabDictionaryItem: FabDictionaryItem) => boolean): FabDictionaryItem[] {
    return array.reduce((accumulator: FabDictionaryItem[], item: FabDictionaryItem) => {
      if (item?.fabDictionaryItems?.length) {
        const fabDictionaryItems = this.filterRecursive(item.fabDictionaryItems || [], callback);
        if (fabDictionaryItems?.length) {
          accumulator.push({ ...item, fabDictionaryItems });
        }
      } else if (callback(item)) {
        accumulator.push(item);
      }
      return accumulator;
    }, []);
  }

  /* searchInGroupedDictionary(event: { query: string }, dictionaryValues: FabDictionaryItem[]): any {
     const searchResults = [];

     for (const optgroup of dictionaryValues as GroupedDictionary[]) {
       const filteredSubOptions = this.filterService.filter(
         optgroup.items,
         [this.translationService.getDictionaryLanguages()],
         event.query,
         'contains'
       );

       if (filteredSubOptions && filteredSubOptions.length) {
         searchResults?.push({
           label: optgroup.label,
           value: optgroup.value,
           items: filteredSubOptions,
         });
       }
     }
     return searchResults;
   }
 */

  searchInSimpleDictionary(event: { query: string }, dictionaryValues: FabDictionaryItem[]): FabDictionaryItem[] {
    return (dictionaryValues as FabDictionaryItem[]).filter(
      (item) => item[this.translationService.getDictionaryLanguages()]?.toLowerCase().indexOf(event.query.toLowerCase()) !== -1
    );
  }

  simplifyLargeDictionary(fabDictionaryItems: Array<FabDictionaryItem>): Array<FabDictionaryItem> {
    return (fabDictionaryItems || []).reduce((flatDictionary, fabDictionaryItem) => {
      flatDictionary.push(
        {
          captionRu: fabDictionaryItem.captionRu,
          captionEn: fabDictionaryItem.captionEn,
          entityId: fabDictionaryItem.entityId,
        },
        ...this.simplifyLargeDictionary(fabDictionaryItem?.fabDictionaryItems ?? [])
      );
      return flatDictionary;
    }, new Array<FabDictionaryItem>());
  }
}
