import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent {
  @Input() url = '';
  @Input() title = '';
  @Input() width = 100;
  @Input() height = 100;
  @Input() selected = false;
  @Input() disabled = false;
  @Input() dialog = false;

  public display: boolean = false;

  public get plainTitle(): string {
    return this.title.replace(/<[^>]*>?/gm, '');
  }

  showDialog(): void {
    this.display = true;
  }
}
