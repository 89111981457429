<div class="p-2 flex justify-content-between align-items-center header">
  <div class="h-full">
    <img height="100%" *ngIf="(test$ | async)?.logo; else defaultLogo" src="{{ (test$ | async)?.logo }}" alt="" />
  </div>
  <div class="mr-1">
    <app-switch-theme-button></app-switch-theme-button>
  </div>
</div>

<ng-template #defaultLogo>
  <img height="100%" src="{{ logo }}" alt="" />
</ng-template>
