import { from, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { map, switchMap, tap } from 'rxjs/operators';
import * as RecordRTC from 'recordrtc';

import { DateTimeService } from './date-time.service';
import { Video } from '../models/video';
import { VideoMetadataTypeEnum } from '../../api/telemetry/models';
import { TestInfo } from '../models/test-info';

@Injectable({
  providedIn: 'root',
})
export class VideoRecorderService {
  isCameraStarted = false;
  stream?: MediaStream;
  video: Video | null = null;

  private isMessagesEnabled = true;
  private recorder?: RecordRTCPromisesHandler;

  constructor() {}

  disableMessages(): void {
    this.isMessagesEnabled = false;
  }

  enableMessages(): void {
    this.isMessagesEnabled = true;
  }

  getRecorder(): RecordRTCPromisesHandler | undefined {
    return this.recorder;
  }

  startRecording(
    constraints: MediaStreamConstraints = {
      video: true,
      audio: true,
    },
    options: RecordRTC.Options = {
      type: 'video',
      mimeType: 'video/webm;codecs=vp9',
    }
  ): void {
    if (!this.isCameraStarted) {
      this.isCameraStarted = true;
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.stream = stream;
          this.recorder = new RecordRTCPromisesHandler(stream, options);
          this.recorder.startRecording().then();
        })
        .catch((error: Error) => {
          throw error;
        });
    }
  }

  pauseRecording(): void {
    this.recorder?.pauseRecording();
  }

  resumeRecording(): void {
    this.recorder?.resumeRecording();
  }

  getVideo$(testInfo: TestInfo | null, testItemId?: string, iterationId?: string): Observable<Video | null> {
    const recorder = this.recorder;
    if (!recorder) {
      return of(null);
    }
    return from(recorder.stopRecording()).pipe(
      tap(() => this.stopStreamTracks()),
      switchMap(() => from(recorder.getBlob())),
      map((blob) => {
        this.recorder?.reset();
        if (blob) {
          return {
            testId: testInfo?.testId ?? undefined,
            final: true,
            preview: testInfo?.preview,
            body: {
              /* eslint-disable @typescript-eslint/naming-convention */
              TestItemId: testItemId,
              IterationId: iterationId,
              Type: VideoMetadataTypeEnum.Camera,
              MimeType: 'video/webm',
              RecordStartedClientTimeUtc: DateTimeService.currentDateTimeUTC,
              ClientTimeZoneMinutes: 180,
              videoFile: blob,
            },
          } as Video;
        } else {
          return null;
        }
      })
    );
  }

  /*  getVideo(testInfo: TestInfo | null, testItemId?: string): void {
      this.recorder?.stopRecording(() => {
        this.stopStreamTracks();
        const blob = this.recorder?.getBlob();
        if (blob) {
          this.video = {
            /!* eslint-disable @typescript-eslint/naming-convention *!/
            testId: testInfo?.testId ?? undefined,
            final: true,
            preview: testInfo?.preview,
            body: {
              TestItemId: testItemId,
              Type: VideoMetadataTypeEnum.Camera,
              MimeType: 'video/webm',
              RecordStartedClientTimeUtc: DateTimeService.currentDateTimeUTC,
              ClientTimeZoneMinutes: 180,
              videoFile: blob,
            },
          } as Video;
          if (this.video?.body?.videoFile) {
            const link = document.createElement('a');
            link.download = 'test.webm';
            link.href = URL.createObjectURL(this.video.body.videoFile);
            link.click();
          }
        }
        this.recorder?.reset();
      });
    }*/

  private stopStreamTracks(): void {
    this.stream?.getTracks().forEach((track) => {
      track.stop();
    });
    this.isCameraStarted = false;
  }
}
