import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DEFAULT_THEME_FILENAME } from '../../constants/default-theme-filename';
import { ThemeFilename } from '../../models/theme-filename';
import { ThemesService } from '../../services/themes.service';

const DARK_THEME: ThemeFilename = 'arya-green';

@Component({
  selector: 'app-switch-theme-button',
  templateUrl: './switch-theme-button.component.html',
  styleUrls: ['./switch-theme-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SwitchThemeButtonComponent {
  public isDarkTheme = false;

  constructor(private themesService: ThemesService) {
    this.isDarkTheme = this.themesService.selectedTheme === DARK_THEME;
  }

  public toggleTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;
    this.themesService.changeTheme(this.isDarkTheme ? DARK_THEME : DEFAULT_THEME_FILENAME);
  }
}
