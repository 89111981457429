import { Action, createReducer, on } from '@ngrx/store';

import { changePageAnswerAction } from '../actions/answers.actions';
import { getNextPageAction, getNextPageSuccessAction } from '../actions/page.actions';
import {
  getBalancingStatisticsSuccessAction,
  getLayoutStatisticsSuccessAction,
  getPageItemsStatisticsSuccessAction,
} from '../actions/test-item-statistics.actions';
import { TestItemsStatisticsState, testItemStatisticsAdapter } from '../states/test-item-statistics.state';

export const initialState: TestItemsStatisticsState = testItemStatisticsAdapter.getInitialState({
  lastUpdate: 0,
  isUpdating: false,
});

export const reducer = createReducer(
  initialState,
  on(changePageAnswerAction, (state): TestItemsStatisticsState => ({ ...state, isUpdating: true })),
  on(getNextPageAction, (state): TestItemsStatisticsState => ({ ...state, isUpdating: true })),
  on(getNextPageSuccessAction, (state): TestItemsStatisticsState => ({ ...state, isUpdating: true })),
  on(
    getBalancingStatisticsSuccessAction,
    (state, { testItemStatistics, lastUpdate }): TestItemsStatisticsState =>
      testItemStatisticsAdapter.upsertMany(testItemStatistics, { ...state, lastUpdate: lastUpdate ?? state.lastUpdate, isUpdating: false })
  ),
  on(
    getLayoutStatisticsSuccessAction,
    (state, { testItemStatistics, lastUpdate }): TestItemsStatisticsState =>
      testItemStatisticsAdapter.upsertMany(testItemStatistics, { ...state, lastUpdate: lastUpdate ?? state.lastUpdate, isUpdating: false })
  ),
  on(
    getPageItemsStatisticsSuccessAction,
    (state, { testItemStatistics, lastUpdate }): TestItemsStatisticsState =>
      testItemStatisticsAdapter.upsertMany(testItemStatistics, { ...state, lastUpdate: lastUpdate ?? state.lastUpdate, isUpdating: false })
  )
);

export const statisticsReducers = (state: TestItemsStatisticsState, action: Action): TestItemsStatisticsState => reducer(state, action);
