import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { delay } from 'rxjs/operators';
import { RowAnswerQuantityEnum } from '../../../api/testrunner/models/row-answer-quantity-enum';
import { TestItemModel } from '../../models/test-item-model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-select-custom-answers',
  templateUrl: './select-custom-answers.component.html',
  styleUrls: ['./select-custom-answers.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCustomAnswersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCustomAnswersComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() public answerQuantity?: RowAnswerQuantityEnum;
  @Input() public customAnswersLabel!: string;
  @Input() public customAnswersMaxCount!: number;
  @Input() public model?: TestItemModel;

  @ViewChildren('answer') public readonly answerInput?: QueryList<ElementRef>;

  public readonly answersFormArray: FormArray<FormControl<string>> = new FormArray<FormControl<string>>([]);
  public answerQuantityEnum: typeof RowAnswerQuantityEnum = RowAnswerQuantityEnum;
  public isCustomAnswerSelected: boolean = false;
  public isDisabled: boolean = false;

  private readonly subscription: Subscription = new Subscription();

  public addAnswer(): void {
    this.answersFormArray.push(new FormControl<string>('', { nonNullable: true }));
    const timer = setTimeout(() => {
      this.answerInput?.last?.nativeElement.focus();
      clearTimeout(timer);
    }, 0);
  }

  public ngOnInit(): void {
    this.subscription.add(this.processAnswersFormArrayValueChanges());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public registerOnChange(fn: (elementStrings: string[]) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public removeAnswer(index: number): void {
    this.answersFormArray.removeAt(index);
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public writeValue(elementStrings: string[]): void {
    this.answersFormArray.controls = elementStrings.map((answer: string) => new FormControl<string>(answer, { nonNullable: true }));
    this.isCustomAnswerSelected = elementStrings.length > 0;
  }

  public showCustomAnswers(): void {
    if (this.isCustomAnswerSelected) {
      if (!this.answersFormArray.controls.length) {
        this.addAnswer();
      }
    } else {
      this.answersFormArray.clear();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: string[]): void => {};
  private onTouched = (): void => {};

  private processAnswersFormArrayValueChanges(): Subscription {
    return this.answersFormArray.valueChanges.pipe(delay(0)).subscribe((answer: string[]) => {
      this.isCustomAnswerSelected = answer.length > 0;
      this.onTouched();
      this.onChange(answer);
    });
  }
}
