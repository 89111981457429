import { Action, createReducer, on } from '@ngrx/store';

import { testItemAdapter, TestItemsState } from '../states/test-items.state';
import { reorderLayoutItems, startSessionSuccessAction } from '../actions/test-runner.actions';
import { TestItem } from 'src/api/testrunner/models/test-item';
import { TestItemTypeEnum } from 'src/api/testrunner/models/test-item-type-enum';

export const initialState: TestItemsState = testItemAdapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(
    startSessionSuccessAction,
    (state, { testRunnerStartData }): TestItemsState => {
      const flattenTestItems = new Array<TestItem>();
      const stack = [...(testRunnerStartData?.test?.rootGroup?.groupItems || new Array<TestItem>())].sort((a, b) => a.order - b.order);

      while (stack.length > 0) {
        const item = stack.shift();
        if (item) {
          flattenTestItems.push(item);
          stack.unshift(
            ...[...(item?.groupItems || [])].sort((a, b) => (a.testItemType !== TestItemTypeEnum.Else ? a.order - b.order : 1))
          );
        }
      }

      return testItemAdapter.upsertMany(flattenTestItems, state);
    }
  ),
  on(reorderLayoutItems, (state, { reorderedItems }): TestItemsState => testItemAdapter.upsertMany(reorderedItems, state))
);

export const testItemsReducers = (state: TestItemsState, action: Action): TestItemsState => reducer(state, action);
