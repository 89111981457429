import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { isNil } from 'lodash';
import { AccordionTab } from 'primeng/accordion';
import { BehaviorSubject, Observable, ReplaySubject, Subscription, animationFrameScheduler, combineLatest, of, timer } from 'rxjs';
import { debounce, debounceTime, distinctUntilKeyChanged, filter, map, observeOn, shareReplay, take } from 'rxjs/operators';
import {
  FabDictionaryItem,
  MatrixResult,
  MatrixResultItem,
  RowAnswerQuantityEnum,
  Task,
  TestItemResult,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
} from 'src/api/testrunner/models';
import { UtilsService } from 'src/app/services/utils.service';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { lockButtonNext } from '../../store/actions/page.actions';
import { selectDictionaryItemsById, selectIsMobileDevice } from '../../store/selectors/test-runner.selectors';

interface MatrixFormValue {
  formRows: { [id: string]: string | string[] }[];
  isAnswerSkipped: boolean;
}

const MAX_ROWS: number = 200;
const MAX_COLUMNS: number = 10;

@Component({
  selector: 'app-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatrixComponent implements OnInit, OnChanges, AfterViewChecked, OnDestroy {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  @ViewChild('head') public head?: ElementRef;
  @ViewChildren(AccordionTab) public readonly accordionTabComponents?: QueryList<AccordionTab>;

  private readonly headRefState$: ReplaySubject<HTMLElement> = new ReplaySubject<HTMLElement>(1);
  public readonly columnWidth$: Observable<number> = this.headRefState$.pipe(
    observeOn(animationFrameScheduler),
    distinctUntilKeyChanged('clientWidth'),
    map((headRefElement: HTMLElement) => {
      const headerCells: HTMLTableCellElement[] = Array.from(headRefElement.getElementsByTagName('th'));
      let colWidth: number = 0;
      headerCells.splice(1).forEach((cell) => {
        const cellWidth: number | undefined = cell.getElementsByTagName('span')[0]?.clientWidth;
        colWidth = cellWidth > colWidth ? cellWidth : colWidth;
      });
      return colWidth;
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  public columns: FabDictionaryItem[] = [];
  public rows: FabDictionaryItem[] = [];

  public description: SafeHtml | undefined;
  public formAnswer!: UntypedFormGroup;
  public readonly isMobileDevice$: Observable<boolean> = this.store.select(selectIsMobileDevice);
  public matrix?: Task;

  private defaultFormValue?: { [id: string]: string | string[] }[];

  public readonly maxRows: number = MAX_ROWS;
  public readonly maxColumns: number = MAX_COLUMNS;

  private rows$: Observable<FabDictionaryItem[]> = of([]);
  private columns$: Observable<FabDictionaryItem[]> = of([]);

  public readonly accordionActiveIndex$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly fb: UntypedFormBuilder,
    private readonly sanitizer: DomSanitizer,
    private readonly macrosPipe: MacrosPipe,
    private readonly store: Store,
  ) {}

  public get isMultipleAnswers(): boolean {
    return RowAnswerQuantityEnum.Multiple === this.testItemModel?.data?.answerQuantity;
  }

  public ngOnInit(): void {
    this.matrix = this.testItemModel?.data;

    this.rows$ = this.store.select(selectDictionaryItemsById(this.matrix?.rowFabDictionaryId ?? '')).pipe(
      distinctUntilKeyChanged('length'),
      map((dictionaryItems: FabDictionaryItem[]) => {
        if (this.matrix?.shuffleRows) {
          UtilsService.shuffleItems(dictionaryItems, this.matrix?.lockOnShuffleDictionaryItems);
        }
        return dictionaryItems;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.columns$ = this.store.select(selectDictionaryItemsById(this.matrix?.columnFabDictionaryId ?? '')).pipe(
      distinctUntilKeyChanged('length'),
      map((dictionaryItems: FabDictionaryItem[]) => {
        if (this.matrix?.shuffleColumns) {
          UtilsService.shuffleItems(dictionaryItems, this.matrix?.lockOnShuffleDictionaryItems);
        }
        return dictionaryItems;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.initializeForm();

    this.subscription.add(this.processFormAnswerValueChanges());
  }

  public ngAfterViewChecked(): void {
    if (isNil(this.head) || this.head.nativeElement.clientWidth === 0) {
      return;
    }

    this.headRefState$.next(this.head.nativeElement);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.sanitizer.bypassSecurityTrustHtml(
        this.macrosPipe.transform(this.testItemModel.data?.description, pipingItem),
      );
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public setAccordionActiveIndex(activeIndex: number): void {
    this.accordionActiveIndex$.next(activeIndex);
  }

  public accordionTabCompleted(currentIndex: number, isLastTab: boolean): void {
    if (isLastTab) {
      return;
    }
    this.accordionActiveIndex$.next(currentIndex + 1);
    const focusedAccordionTab: AccordionTab | undefined = this.accordionTabComponents?.toArray()[currentIndex + 1];
    focusedAccordionTab?.selectedChange
      .pipe(
        debounceTime(300),
        take(1),
        filter((isSelected: boolean) => isSelected),
      )
      .subscribe(() => focusedAccordionTab?.el.nativeElement?.scrollIntoView({ behavior: 'smooth', block: 'start' }));
  }

  public checkAnswerMaxSelected(rowId: string): void {
    const answerMaxSelectedItems = this.matrix?.answerMaxSelectedItems;
    const answerRequired = this.matrix?.answerRequired;

    if (answerMaxSelectedItems && answerRequired && this.matrix?.answerMaxSelectedItemsEnabled) {
      const rows = (this.formAnswer.controls.formRows as UntypedFormArray).controls;

      rows.forEach((item) => {
        const row = item as UntypedFormGroup;

        if (Object.keys(row.controls)[0] === rowId) {
          const ctrl = Object.values(row.controls)[0] as UntypedFormArray;

          const filled = ctrl.controls.filter((elem) => elem.value && elem.value.length);

          ctrl.controls.forEach((elem) => {
            if ((!elem.value || !elem?.value.length) && answerMaxSelectedItems && filled.length >= answerMaxSelectedItems) {
              elem.disable();
            } else {
              elem.enable();
            }
          });
        }
      });
    }
  }

  private save(result: MatrixResultItem[], isAnswerSkipped: boolean): void {
    const matrix = this.testItemModel?.data as Task;
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      columnDictionaryId: matrix.columnFabDictionaryId,
      iterationId: this.testItemModel?.iterationId,
      elementItems: result,
      rowDictionaryId: matrix.rowFabDictionaryId,
      testId: matrix.testId,
      testItemId: matrix.id,
      type: TestItemResultTypeEnum.Matrix,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as MatrixResult);
  }

  private initializeForm(): void {
    const answerResult = this.persistenceService.get('answer_' + this.matrix?.id ?? '') || null;

    const isSkipped: boolean = Boolean(answerResult?.isAnswerSkipped && this.matrix?.isSkipButtonEnabled);

    this.formAnswer = this.fb.group({
      formRows: this.fb.array([]),
      isAnswerSkipped: isSkipped,
    });

    if (isSkipped) {
      this.formAnswer.controls.formRows.disable();
    }

    const controls = this.formAnswer.controls.formRows as UntypedFormArray;

    combineLatest([this.rows$, this.columns$])
      .pipe(
        filter(([rows, columns]) => rows !== undefined && columns !== undefined),
        take(1),
      )
      .subscribe(([rows, columns]) => {
        this.rows = rows;
        this.columns = columns;

        if (this.isMultipleAnswers) {
          for (const row of rows || []) {
            const checkboxArray = new UntypedFormArray([]);
            for (const col of columns || []) {
              const savedRow = (answerResult?.elementItems || []).find(
                (item: any) => row.entityId === item.rowElementItemId && col.entityId === item.columnElementItemId,
              );
              const control = new UntypedFormControl(savedRow?.columnElementItemId ? [savedRow.columnElementItemId] : []);
              checkboxArray.push(control);
            }
            controls.push(
              this.fb.group({
                [row.entityId as string]: checkboxArray,
              }),
            );

            this.checkAnswerMaxSelected(row.entityId as string);
          }
        } else {
          for (const row of rows || []) {
            const savedRow = (answerResult?.elementItems || []).find((item: any) => row.entityId === item.rowElementItemId);
            controls.push(
              this.fb.group({
                [row.entityId as string]: [savedRow?.columnElementItemId ?? ''],
              }),
            );
          }
        }
        this.defaultFormValue = this.formAnswer.controls.formRows.getRawValue();
      });
  }

  private processFormAnswerValueChanges(): Subscription {
    return this.formAnswer.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(0);
        }),
        map(() => this.formAnswer.getRawValue()),
      )
      .subscribe((data: MatrixFormValue) => {
        if (data.isAnswerSkipped) {
          this.formAnswer.controls.formRows.setValue(this.defaultFormValue, { emitEvent: false });
          this.formAnswer.controls.formRows.disable({ emitEvent: false });
          this.save(this.defaultFormValue ?? [], data.isAnswerSkipped);
          return;
        } else {
          this.formAnswer.controls.formRows.enable({ emitEvent: false });
        }

        const answer: MatrixResultItem[] = data.formRows
          .map(
            (row) =>
              Object.entries<string | string[]>(row).map(([_row, _col]) => {
                const columnResultArray: string[] = _col instanceof Array ? ([] as string[]).concat(..._col) : _col !== '' ? [_col] : [];

                return columnResultArray.map((col) => ({ columnElementItemId: col, rowElementItemId: _row }) as MatrixResultItem);
              })[0],
          )
          .reduce((acc, val) => acc.concat(val), []);

        this.save(answer, data.isAnswerSkipped);
      });
  }
}
