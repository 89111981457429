import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { PersistenceService } from '../../services/persistence.service';
import { PromoCodeService } from '../../../api/testrunner/services/promo-code.service';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map } from 'rxjs/operators';
import { getPromoCodeAction, getPromoCodeErrorAction, getPromoCodeSuccessAction } from '../actions/test-runner.actions';
import { selectTestInfo } from '../selectors/test-runner.selectors';
import { of } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class PromoCodeEffects {
  getPromoCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getPromoCodeAction),
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      concatMap(([{ testItemId }, testInfo]) => {
        const currentPromoCode = this.persistenceService.get('answer_' + testItemId)?.promoCode;
        if (currentPromoCode) {
          return of(getPromoCodeSuccessAction({ promoCode: currentPromoCode, testItemId }));
        }

        return this.promoCodeService
          .promoCodeConsumePromoCode({
            testItemId,
            preview: testInfo?.preview,
          })
          .pipe(
            map((promoCodeResponse) => getPromoCodeSuccessAction({ promoCode: promoCodeResponse.code ?? null, testItemId })),

            catchError((errorResponse: unknown) => of(getPromoCodeErrorAction({ error: errorResponse as HttpErrorResponse, testItemId })))
          );
      })
    );
  });
  constructor(
    private actions$: Actions,
    private persistenceService: PersistenceService,
    private promoCodeService: PromoCodeService,
    private store: Store
  ) {}
}
