import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { fadeInDownAnimation, fadeInDownOnEnterAnimation } from 'angular-animations';
import { FilterService, PrimeNGConfig } from 'primeng/api';
import { Observable, Subscription, combineLatest, of, throwError } from 'rxjs';
import { catchError, filter, map, shareReplay, take } from 'rxjs/operators';
import { CompleteTestInfo } from 'src/app/models/complete-test-info';
import { UrlParams } from 'src/app/models/url-params';
import { PersistenceService } from 'src/app/services/persistence.service';
import { TranslationsService } from 'src/app/services/translations.service';
import { StartSessionStatusEnum, Test, TestStatusEnum } from '../../../api/testrunner/models';
import { getTokenAction } from '../../store/actions/test-runner.actions';
import { selectSendAnswerAttempt } from '../../store/selectors/answers.selectors';
import {
  selectBackendErrors,
  selectCompleteTestInfo,
  selectIsLoading,
  selectIsWrongBrowser,
  selectIsWrongDesktopDevice,
  selectIsWrongMobileDevice,
  selectStartSessionStatus$,
  selectTestStartData,
} from '../../store/selectors/test-runner.selectors';

const MAX_ATTEMPT_COUNT: number = 5;

const TEST_STARTED_STATUSES: Set<StartSessionStatusEnum> = new Set<StartSessionStatusEnum>([
  StartSessionStatusEnum.Success,
  StartSessionStatusEnum.AlreadyStarted,
  StartSessionStatusEnum.PreviewMode,
  StartSessionStatusEnum.Finished,
]);

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FilterService, PrimeNGConfig],
  animations: [fadeInDownAnimation(), fadeInDownOnEnterAnimation()],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestComponent implements OnInit, OnDestroy {
  @HostBinding('@.disabled') public animationsDisabled = false;

  public readonly completeTestInfo$: Observable<CompleteTestInfo | null> = this.store.select(selectCompleteTestInfo);
  public readonly error$: Observable<HttpErrorResponse | null> = this.store.select(selectBackendErrors);
  public readonly isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  public readonly isWrongDesktopDevice$: Observable<boolean> = this.store.select(selectIsWrongDesktopDevice);
  public readonly isWrongMobileDevice$: Observable<boolean> = this.store.select(selectIsWrongMobileDevice);

  public readonly isFlowVisible$: Observable<boolean> = combineLatest([
    this.store.select(selectIsWrongBrowser),
    this.isWrongDesktopDevice$,
    this.isWrongMobileDevice$,
  ]).pipe(
    map(([isWrongBrowser, isWrongDesktopDevice, isWrongMobileDevice]) => !(isWrongBrowser || isWrongDesktopDevice || isWrongMobileDevice)),
    shareReplay(1),
  );

  isGuid = true;
  isGuidValid = true;
  public isTestStarted$: Observable<boolean> = of(false);
  private isTestCompleted$: Observable<boolean> = of(false);
  readonly maxAttemptCount = MAX_ATTEMPT_COUNT;
  redirectUrl$: Observable<string | null> = of(null);
  public readonly sendAnswerAttempt$: Observable<number> = this.store.select(selectSendAnswerAttempt);
  public readonly startSessionStatus$: Observable<StartSessionStatusEnum | null> = this.store.select(selectStartSessionStatus$);
  startSessionStatusEnum = StartSessionStatusEnum;
  public readonly test$: Observable<Test | null> = this.store.select(selectTestStartData);
  urlParams: UrlParams | null = null;
  title = 'testrunner';

  private readonly subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly persistenceService: PersistenceService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly translationsService: TranslationsService,
  ) {}

  get isTokenLoad(): boolean {
    return this.persistenceService.get('testRunnerToken');
  }

  get isTestFound(): boolean {
    return this.isGuid && this.isGuidValid;
  }

  public ngOnInit(): void {
    this.isTestStarted$ = combineLatest([this.isLoading$, this.test$, this.isTestCompleted$, this.startSessionStatus$]).pipe(
      map(
        ([isLoading, test, isTestCompleted, startSessionStatus]) =>
          !isLoading &&
          this.isGuid &&
          this.isGuidValid &&
          !!this.persistenceService.get('testRunnerToken') &&
          test !== null &&
          !isTestCompleted &&
          startSessionStatus !== null &&
          TEST_STARTED_STATUSES.has(startSessionStatus),
      ),
    );
    this.isTestCompleted$ = this.test$.pipe(map((test) => test?.status === TestStatusEnum.Complete && !this.urlParams?.preview));

    this.subscription.add(
      this.getRouteParams$().subscribe((urlParams) => {
        if (!urlParams) {
          return;
        }

        this.urlParams = urlParams;
        this.store.dispatch(getTokenAction({ urlParams }));
      }),
    );

    this.subscription.add(
      this.test$.pipe(filter((test) => test !== null)).subscribe((test) => {
        this.translationsService.setHtmlLangAttr(test?.language);
        this.persistenceService.set('selectedTranslation', test?.language);
        this.renderCustomStyles(test?.customCss ?? '');
        this.translationsService.setDefaultTranslation();
        /* this.metadataService.setMetadata({
          description: this.translocoService.translate('metadata.description'),
          imageUrl: `/assets/images/og/senso_${test?.type ?? TestTypeEnum.Survey}.png`,
          title: this.translocoService.translate('metadata.title'),
        });*/
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private renderCustomStyles(customCss: string): void {
    const styles = this.document.createElement('STYLE') as HTMLStyleElement;
    styles.id = 'customCss';
    styles.innerHTML = customCss;
    this.renderer.appendChild(this.document.body, styles);
  }

  private getRouteParams$(): Observable<UrlParams | null> {
    return combineLatest([this.route.params, this.route.queryParams]).pipe(
      take(1),
      map(([routeParamMap, queryParamMap]) => {
        const params = {
          anonymousId: queryParamMap?.anonymousId ?? '',
          fabUniqueId: queryParamMap?.fabUniqueId ?? '',
          logicOn: !(queryParamMap?.logicOn === 'false'),
          preview: queryParamMap?.preview === 'true',
          queryString: new URL(window.document.location.href).searchParams.toString(),
          sapOutboundId: queryParamMap?.['sap-outbound-id'] ?? '',
          taskPreviewId: queryParamMap?.taskId,
          testId: routeParamMap?.id ?? '',
          token: queryParamMap?.token ?? '',
        } as UrlParams;

        if (params.testId) {
          const testRecord = this.persistenceService.get('test_' + params.testId);
          if (((testRecord && testRecord.preview !== params.preview) || params.preview) && queryParamMap?.saveStorage !== 'true') {
            //this.persistenceService.clearByTestId(params.testId);
            this.persistenceService.clear();
          }

          if (queryParamMap?.anon === 'true') {
            this.persistenceService.clear();
            this.router
              .navigate([], {
                queryParams: {
                  ...queryParamMap,
                  anon: null,
                },
                queryParamsHandling: 'merge',
              })
              .then();
          }

          if (queryParamMap?.anonymousId || queryParamMap?.token) {
            this.router
              .navigate([], {
                queryParams: {
                  ...queryParamMap,
                  anonymousId: null,
                  token: null,
                },
                queryParamsHandling: 'merge',
              })
              .then();
          }
          //sap-outbound-id так генерятся пользовательские ссылки, поэтому без камелкейса
          if (queryParamMap?.['sap-outbound-id']) {
            if (this.persistenceService.get('sapOutboundId') !== queryParamMap['sap-outbound-id']) {
              this.persistenceService.clear();
            }
            this.persistenceService.set('sapOutboundId', queryParamMap['sap-outbound-id']);
          }

          if (this.parseGuid(params.testId)) {
            return params;
          } else {
            this.isGuidValid = false;
          }
        } else {
          this.isGuid = false;
        }

        return null;
      }),

      catchError((err: unknown) => throwError((err as Error).message)),
    );
  }

  private parseGuid(guid: string): boolean {
    const array = guid.split('-');
    return array.length === 5;
  }
}
