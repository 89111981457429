import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-desktop-only',
  templateUrl: './desktop-only.component.html',
  styleUrls: ['./desktop-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopOnlyComponent {
  private readonly document: Document = inject(DOCUMENT);
  public readonly url: string = this.document.location.origin + this.router.url;

  constructor(private readonly router: Router) {}

  public share(): void {
    if (this.document.defaultView?.navigator?.share) {
      this.document.defaultView?.navigator
        .share({
          title: 'Share',
          text: 'Url',
          url: this.url,
        })
        .then(() => console.log('Successful share'))
        .catch((error: unknown) => console.log('Error sharing', error));
    } else {
      alert('share not supported');
    }
  }
}
