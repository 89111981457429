<p-card
  *transloco="let t"
  [class.invalid]="isGroupNameEmpty && isCardsGroupNameRequired"
  styleClass="bg-gray-50 text-center border-round-6px mb-3 card-group flex flex-column"
>
  <ng-template pTemplate="header">
    <div class="w-full flex align-items-top mt-2">
      <p-button
        *ngIf="cardGroup.isCustomGroup"
        icon="fa-regular fa-trash-can"
        [pTooltip]="t('testrunner.cardSorting.removeGroupTooltip')"
        styleClass="p-button-danger p-button-text border-round-6px mx-1"
        (click)="removeGroup.emit(cardGroup)"
      ></p-button>
      <p-inplace
        [active]="isGroupNameEditing"
        class="flex-grow-1 flex justify-content-center align-items-center"
        [class.ml-6]="cardGroup.description"
        [closable]="false"
        [preventClick]="true"
        styleClass="group-name"
      >
        <ng-template pTemplate="display">
          <span class="w-full p-2 text-lg font-medium text-center inline-block" [class.text-gray-300]="!cardGroup.name">{{
            cardGroup.name ? cardGroup.name : t('testrunner.cardSorting.noname')
          }}</span>
        </ng-template>
        <ng-template pTemplate="content">
          <input
            pAutoFocus
            [autofocus]="true"
            [(ngModel)]="groupName"
            class="w-full outline-input bg-gray-50 p-2 text-lg font-medium text-center"
            (blur)="isGroupNameEmpty = !cardGroup.name"
            (keyup.enter)="saveGroupName()"
          />
          <span *ngIf="isGroupNameEmpty && isCardsGroupNameRequired" class="text-danger">{{
            t('testrunner.cardSorting.requiredGroupName')
          }}</span>
        </ng-template>
      </p-inplace>
      <p-button
        *ngIf="!isGroupNameEditing && cardGroup.isCustomGroup"
        icon="fa-regular fa-pen"
        [pTooltip]="t('testrunner.cardSorting.renameGroupTooltip')"
        styleClass="p-button-secondary p-button-text border-round-6px hover:bg-gray-100 mx-1"
        (click)="isGroupNameEditing = true"
      ></p-button>
      <p-button
        *ngIf="isGroupNameEditing"
        icon="fa-regular fa-check"
        styleClass="p-button-success p-button-text border-round-6px hover:bg-gray-100 mx-1"
        (click)="saveGroupName()"
      ></p-button>
      <p-button
        *ngIf="cardGroup.description && !cardGroup.isCustomGroup"
        [escape]="false"
        icon="fa-regular fa-circle-info"
        styleClass="p-button-secondary p-button-text border-round-6px hover:bg-gray-100 mx-1"
        [pTooltip]="tooltipContent"
        showDelay="500"
        [tabindex]="0"
        tooltipPosition="left"
        [tooltipEvent]="getTooltipActivationEvent()"
      ></p-button>

      <ng-template #tooltipContent>
        <span class="p-text-light white-space-normal">
          <div class="font-light mt-2">{{ cardGroup.description }}</div>
        </span>
      </ng-template>
    </div>
  </ng-template>

  <ng-content></ng-content>

  <ng-template pTemplate="footer">
    <span class="flex justify-content-center align-items-center text-sm text-gray-300">
      <span>
        {{ t('testrunner.cardSorting.groupCardsCount', { count: cardsCount }) }}
      </span>
      <span *ngIf="isCardsGroupsLimitEnabled && cardGroup.maxCardsCount">
        &nbsp;{{ t('testrunner.cardSorting.groupCardsLimit', { total: cardGroup.maxCardsCount }) }}
      </span>
    </span>
  </ng-template>
</p-card>
