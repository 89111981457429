import { HttpClient } from '@angular/common/http';
import { Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, translocoConfig, TranslocoLoader, TranslocoModule } from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../../environments/environment';
// import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  // eslint-disable-next-line rxjs/finnish
  /*getTranslation(lang: string): Observable<Translation> {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }*/

  // Статья про решение проблемы с кэшированием:
  // https://netbasal.com/strategies-for-cache-busting-translation-files-in-angular-86143ee14c3c
  getTranslation(lang: string): Promise<Translation> {
    return import(`../../assets/i18n/${lang}.json`).then(res => res.default);
  }
}

export const translocoConf = {
  availableLangs: ['En', 'Es', 'Kz', 'Ru'],
  defaultLang: 'En',
  // Remove this option if your application doesn't support changing language in runtime.
  reRenderOnLangChange: true,
  prodMode: environment.production,
};

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig(translocoConf),
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
