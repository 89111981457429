import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root',
})
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(private persistenceService: PersistenceService) {}

  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.persistenceService.get('testRunnerToken');
    const tokenReq = req.clone({
      headers: req.headers.set('Authorization', token ? 'Bearer ' + token : ''),
    });

    return next.handle(tokenReq);
  }
}
