<div class="default-scale-container">
  <div *ngIf="rows[0] as row" class="default-scale-item">
    <span class="default-scale-item__left-text">
      <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ $implicit: row }"></ng-template>
    </span>

    <div class="scale-line">
      <div *ngFor="let digit of [].constructor(11); let index = index" class="scale-line__value">
        <label [for]="'radio' + row.entityId + index" class="inline-flex justify-content-center px-1 md:px-2 pt-2 pb-1">
          {{ scaleData.minValue + scaleData.changeStep * index }}
        </label>
        <span class="inline-flex justify-content-center px-1 md:px-2 pt-1 pb-2">
          <p-radioButton
            [inputId]="'radio' + row.entityId + index"
            [name]="'radio' + rowIndex + row.entityId"
            [value]="scaleData.minValue + scaleData.changeStep * index"
            [formControl]="formAnswer.controls.formRows.controls[rowIndex].controls[row.entityId]"
          ></p-radioButton>
        </span>
      </div>
    </div>

    <span class="default-scale-item__right-text" *ngIf="columns[rowIndex]">
      <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ $implicit: columns[rowIndex] }"></ng-template>
    </span>
  </div>
</div>

<ng-template #answerTemplateRef let-data>
  <span class="mr-2" *ngIf="data.imageUrl && data.imageUrl.length">
    <app-image [url]="data.imageUrl" [dialog]="true"></app-image>
  </span>
  <span [innerHTML]="data.captionRu ?? '&nbsp;' | safeHtml"></span>
</ng-template>
