import { Action, createReducer, on } from '@ngrx/store';
import { cloneDeep, findIndex } from 'lodash';

import { backendErrorsAction } from '../actions/test-runner.actions';
import {
  addSessionChatMessageSuccess,
  addSessionNote,
  addSessionNoteSuccess,
  deleteSessionNote,
  deleteSessionNoteSuccess,
  getModeratorDataAction,
  getModeratorDataSuccessAction,
  getRoomDataAction,
  getRoomDataSuccessAction,
  getSessionAction,
  getSessionSuccessAction,
  getTestAction,
  getTestAndRoomDataAndSessionAction,
  getTestSuccessAction,
  getTokenInfoAction,
  getTokenInfoSuccessAction,
  initTestAction,
  setSessionStatusAction,
  setSessionStatusSuccessAction,
  startSessionAction,
  startSessionSuccessAction,
  updateSessionChatMessage,
  updateSessionChatMessageSuccess,
  updateSessionNote,
  updateSessionNoteSuccess
} from '../actions/moderated.actions';
import { ModeratedState } from '../states/moderated.state';

export const initialState: ModeratedState = {
  isLoading: false,
  isTestLoading: true,
  isUpdating: false,
  chatMessagesIds: {},
  notes: [],
  roomData: null,
  session: null,
  test: null,
  tokenInfo: null,
};

export const moderatedReducers = (state: ModeratedState, action: Action): ModeratedState => moderatedReducer(state, action);

export const moderatedReducer = createReducer(
  initialState,
  on(
    backendErrorsAction,
    (state): ModeratedState => ({
      ...state,
      isLoading: false,
      isTestLoading: false,
      isUpdating: false
    })
  ),
  on(
    addSessionChatMessageSuccess,
    (state, { messageId, sessionChatMessage }): ModeratedState => {
      const chatMessagesIds = cloneDeep(state.chatMessagesIds);
      chatMessagesIds[messageId] = sessionChatMessage.id as string;

      return {
        ...state,
        chatMessagesIds
      };
    }
  ),
  on(
    addSessionNote,
    (state): ModeratedState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    addSessionNoteSuccess,
    (state, { sessionNote }): ModeratedState => ({
      ...state,
      notes: state.notes.concat(sessionNote),
      isUpdating: false
    })
  ),
  on(
    deleteSessionNote,
    (state): ModeratedState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    deleteSessionNoteSuccess,
    (state, { noteId }): ModeratedState => ({
      ...state,
      notes: state.notes.filter((note) => note?.id !== noteId),
      isUpdating: false
    })
  ),
  on(
    getModeratorDataAction,
    (state): ModeratedState => ({
      ...state,
      roomData: initialState.roomData,
      isLoading: true
    })
  ),
  on(
    getModeratorDataSuccessAction,
    (state, { roomData }): ModeratedState => ({
      ...state,
      roomData,
      isLoading: false
    })
  ),
  on(
    getRoomDataAction,
    (state): ModeratedState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    getRoomDataSuccessAction,
    (state, { roomData }): ModeratedState => ({
      ...state,
      roomData,
      isLoading: false
    })
  ),
  on(
    getTestAndRoomDataAndSessionAction,
    (state, { tokenInfo }): ModeratedState => ({
      ...state,
      tokenInfo,
      isLoading: false
    })
  ),
  on(
    getSessionAction,
    (state): ModeratedState => ({
      ...state,
      // session: initialState.session,
      isLoading: true
    })
  ),
  on(
    getSessionSuccessAction,
    (state, { session }): ModeratedState => ({
      ...state,
      session,
      isLoading: false
    })
  ),
  on(
    getTestAction,
    (state, { tokenInfo }): ModeratedState => ({
      ...state,
      tokenInfo: tokenInfo ?? state.tokenInfo,
      isTestLoading: true
    })
  ),
  on(
    getTestSuccessAction,
    (state, { test }): ModeratedState => ({
      ...state,
      isTestLoading: false,
      test
    })
  ),
  on(
    getTokenInfoAction,
    (state): ModeratedState => ({
      ...state,
      tokenInfo: initialState.tokenInfo,
      isLoading: true
    })
  ),
  on(
    getTokenInfoSuccessAction,
    (state, { tokenInfo }): ModeratedState => ({
      ...state,
      tokenInfo,
      isLoading: false
    })
  ),
  on(
    initTestAction,
    (state): ModeratedState => ({
      ...state,
      isTestLoading: true
    })
  ),
  on(
    setSessionStatusAction,
    (state): ModeratedState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    setSessionStatusSuccessAction,
    (state, { session }): ModeratedState => ({
      ...state,
      session,
      isUpdating: false
    })
  ),
  on(
    startSessionAction,
    (state): ModeratedState => ({
      ...state,
      isLoading: true
    })
  ),
  on(
    startSessionSuccessAction,
    (state, { roomData, session }): ModeratedState => ({
      ...state,
      roomData,
      session,
      isLoading: false
    })
  ),
  on(
    updateSessionChatMessage,
    (state): ModeratedState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    updateSessionChatMessageSuccess,
    (state): ModeratedState => ({
      ...state,
      isUpdating: false
    })
  ),
  on(
    updateSessionNote,
    (state): ModeratedState => ({
      ...state,
      isUpdating: true
    })
  ),
  on(
    updateSessionNoteSuccess,
    (state, { sessionNote }): ModeratedState => {
      const notes = cloneDeep(state.notes);
      const index = findIndex(state.notes, { id: sessionNote.id });
      notes[index] = sessionNote;

      return {
        ...state,
        notes,
        isUpdating: false
      };
    }
  ),
);
