import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-camera-settings',
  templateUrl: './camera-settings.component.html',
  styleUrls: ['./camera-settings.component.scss'],
})
export class CameraSettingsComponent implements OnInit, OnDestroy {
  @Input() isNeedRequest!: boolean;
  @Output() selectCameraId: EventEmitter<string | null> = new EventEmitter<string | null>();

  @ViewChild('video') videoElementRef?: ElementRef;
  cameraDevices: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  selectedCameraDeviceId: string | null = null;
  isCameraRequested = false;

  private videoStream: MediaStream | null = null;

  constructor() {}

  ngOnInit(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices
        .getUserMedia({ video: {
               width: { ideal: 720 },
          } })
        .then((stream) => {
          this.setVideoStream(stream);
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              console.log(devices);
              this.cameraDevices = devices.filter((device) => device.kind === 'videoinput');
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  }

  ngOnDestroy(): void {
    this.stopPreviousStream();
  }

  requestPermissions(): void {
    this.selectCameraId.emit(this.selectedCameraDeviceId);
    this.isCameraRequested = true;
    /*this.stopPreviousStream();
    this.store.dispatch(setMediaDevices({ cameraDeviceId: this.selectedCameraDeviceId }));*/
  }

  selectCamera(deviceId: string): void {
    navigator.mediaDevices
      .getUserMedia({ video: { deviceId: deviceId, width: { ideal: 720 } } })
      .then((stream) => {
        this.setVideoStream(stream);
      })
      .catch((error) => console.error(error));
  }

  private setVideoStream(stream: MediaStream): void {
    if (this.videoElementRef) {
      this.videoElementRef.nativeElement.pause();
      this.stopPreviousStream();
      this.videoStream = stream;
      this.videoElementRef.nativeElement.srcObject = stream;
      this.videoElementRef.nativeElement.play();
    }
  }

  private stopPreviousStream(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach((track) => track.stop());
    }
  }
}
