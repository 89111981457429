<img
  class="pic"
  [src]="url"
  [ngClass]="{ selected: selected, disabled: disabled }"
  (click)="dialog ? showDialog() : null"
  loading="lazy"
/>
<p-dialog [header]="plainTitle" *transloco="let t" [(visible)]="display" [modal]="true" [dismissableMask]="true" appendTo="body">
  <img class="view" [src]="url" [alt]="title" [ngStyle]="{ maxWidth: '80vw', maxHeight: '70vh' }" />
  <ng-template pTemplate="footer">
    <!--    <p-button icon="pi pi-check" (click)="display=false" label="{{ t('cabinet.editor.flow.singleAnswer') }}" styleClass="p-button-text"></p-button>-->
  </ng-template>
</p-dialog>
