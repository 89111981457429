/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { FabDictionary } from '../models/fab-dictionary';
import { FabDictionaryView } from '../models/fab-dictionary-view';

@Injectable({
  providedIn: 'root',
})
export class DictionariesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDictionaryAsync
   */
  static readonly GetDictionaryAsyncPath = '/api/v1/dic/{dic_id}/{view}';

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDictionaryAsync()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDictionaryAsync$Response(params: {
    dic_id: string;
    view: FabDictionaryView;
  }): Observable<StrictHttpResponse<FabDictionary>> {

    const rb = new RequestBuilder(this.rootUrl, DictionariesService.GetDictionaryAsyncPath, 'get');
    if (params) {
      rb.path('dic_id', params.dic_id, {});
      rb.path('view', params.view, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FabDictionary>;
      })
    );
  }

  /**
   * Получение словаря.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getDictionaryAsync$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDictionaryAsync(params: {
    dic_id: string;
    view: FabDictionaryView;
  }): Observable<FabDictionary> {

    return this.getDictionaryAsync$Response(params).pipe(
      map((r: StrictHttpResponse<FabDictionary>) => r.body as FabDictionary)
    );
  }

}
