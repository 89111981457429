import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { buffer, catchError, concatMap, debounceTime, filter, map, timeout } from 'rxjs/operators';
import { TestItem, TestItemResult, TestItemResultStatusEnum, TestItemResultTypeEnum, TestItemTypeEnum } from 'src/api/testrunner/models';
import { ResultService } from 'src/api/testrunner/services/result.service';
import { AnswerResult } from 'src/app/models/answer-result';
import { PipingItem } from '../../models/piping-item';
import { DateTimeService } from '../../services/date-time.service';
import { PersistenceService } from '../../services/persistence.service';
import {
  changePageAnswerAction,
  changePageAnswerSuccessAction,
  saveAnswersAction,
  saveAnswersErrorAction,
  saveAnswersSuccessAction,
  saveAnswersToQueueAction,
  saveQueueAction,
} from '../actions/answers.actions';
import {
  getNextPageAction,
  getNextPageSuccessAction,
  getTestItemModelsVisibilitySuccessAction,
  initPageSuccessAction,
} from '../actions/page.actions';
import { finishTestAction, screenOutAction } from '../actions/test-runner.actions';
import { selectAnswersQueue } from '../selectors/answers.selectors';
import { selectAllTestItemModelsOnPage, selectCurrentPage, selectPageShowStartTime } from '../selectors/page.selectors';
import { selectTestInfo } from '../selectors/test-runner.selectors';

const SAVE_QUEUE_TIMEOUT: number = 20_000;

@Injectable()
export class AnswerResultsEffects {
  bufferAnswers$ = createEffect(() => {
    const savingAction$ = this.actions$.pipe(
      ofType(changePageAnswerAction),
      map(({ answers }) => answers),
    );
    return savingAction$.pipe(
      buffer(savingAction$.pipe(debounceTime(100))),
      map((answers) => changePageAnswerSuccessAction({ answers: answers.reduce((acc, val) => acc.concat(val), []) })),
    );
  });

  saveAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        changePageAnswerSuccessAction,
        initPageSuccessAction,
        finishTestAction,
        getTestItemModelsVisibilitySuccessAction,
        getNextPageSuccessAction,
        saveAnswersAction,
        screenOutAction,
      ),
      map((val) => {
        (val.answers || []).forEach((answerResult) => this.persistenceService.set('answer_' + answerResult.testItemId, answerResult));

        this.persistenceService.set('answersQueue', [...(this.persistenceService.get('answersQueue') ?? []), ...(val.answers ?? [])]);

        return saveAnswersToQueueAction({ answers: val.answers ?? new Array<TestItemResult>() });
      }),
    );
  });

  savePageAnswers$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getNextPageAction),
      concatLatestFrom(() => [
        this.store.select(selectCurrentPage),
        this.store.select(selectAllTestItemModelsOnPage),
        this.store.select(selectPageShowStartTime),
      ]),
      map(([{ answerStatus }, page, testItemModels, pageShowStartTime]) => {
        let pageAnswers: Array<AnswerResult> = new Array<AnswerResult>();

        if (page && page.testItemType === TestItemTypeEnum.Kano) {
          const kanoResult = this.persistenceService.get(
            'answer_' + page?.id + ((page as PipingItem)?.iterationId ? '&' + (page as PipingItem)?.iterationId : ''),
          );
          pageAnswers.push({
            ...kanoResult,
            answerTimeSpentMs: pageShowStartTime ? DateTimeService.getDuration(pageShowStartTime) : 0,
            clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
            clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
            clientStartTimeUtc: pageShowStartTime ?? DateTimeService.currentDateTimeUTC,
            clientTimeZoneMinutes: DateTimeService.timezone,
            iterationId: (page as PipingItem).iterationId ?? null,
            logicResult: true,
            status:
              answerStatus && [TestItemResultStatusEnum.IgnoredByUser, TestItemResultStatusEnum.TimeOut].includes(answerStatus)
                ? answerStatus
                : TestItemResultStatusEnum.SubmittedByUser,
          });
        } else if (page) {
          pageAnswers.push({
            answerTimeSpentMs: pageShowStartTime ? DateTimeService.getDuration(pageShowStartTime) : 0,
            clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
            clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
            clientStartTimeUtc: pageShowStartTime ?? DateTimeService.currentDateTimeUTC,
            clientTimeZoneMinutes: DateTimeService.timezone,
            iterationId: (page as PipingItem).iterationId ?? null,
            logicResult: true,
            status:
              answerStatus && [TestItemResultStatusEnum.IgnoredByUser, TestItemResultStatusEnum.TimeOut].includes(answerStatus)
                ? answerStatus
                : TestItemResultStatusEnum.SubmittedByUser,
            testId: page?.testId,
            testItemId: page?.id,
            type: page?.testItemType as string as TestItemResultTypeEnum,
          });
        }

        testItemModels.forEach((testItemModel) => {
          const testItem: TestItem = testItemModel.data;
          const answerResult = this.persistenceService.get('answer_' + testItemModel.id);
          let answer: TestItemResult;

          if (answerResult && answerResult.isAnswerSkipped) {
            answer = {
              ...answerResult,
              answerTimeSpentMs: answerResult.answerTimeSpentMs ?? DateTimeService.getDuration(testItemModel.showStartTime),
              clientEndTimeUtc: answerResult.clientEndTimeUtc ?? DateTimeService.currentDateTimeUTC,
              clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
              status: TestItemResultStatusEnum.Failed,
            } as TestItemResult;
            pageAnswers.push(answer);
            return;
          }

          if (
            answerResult &&
            testItemModel.isShown &&
            (answerResult.status === TestItemResultStatusEnum.Intermediate ||
              answerResult.status === TestItemResultStatusEnum.SubmittedByUser)
          ) {
            answer = {
              ...answerResult,
              answerTimeSpentMs: answerResult.answerTimeSpentMs ?? DateTimeService.getDuration(testItemModel.showStartTime),
              clientEndTimeUtc: answerResult.clientEndTimeUtc ?? DateTimeService.currentDateTimeUTC,
              clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
              status:
                answerResult.type === TestItemResultTypeEnum.TaskGroup
                  ? TestItemResultStatusEnum.SubmittedByLogic
                  : TestItemResultStatusEnum.SubmittedByUser,
            } as TestItemResult;
          } else if (answerStatus === TestItemResultStatusEnum.TimeOut) {
            answer = {
              answerTimeSpentMs: DateTimeService.getDuration(testItemModel.showStartTime),
              clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
              clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
              iterationId: testItemModel.iterationId,
              testItemId: testItem.id,
              testId: testItem.testId,
              status: testItem.testItemType === TestItemTypeEnum.Info ? TestItemResultStatusEnum.SubmittedByUser : answerStatus,
              type: (testItem.testItemType.toString() as TestItemResultTypeEnum) ?? null,
            } as TestItemResult;
          } else {
            answer = {
              answerTimeSpentMs: 0,
              clientStartTimeUtc: DateTimeService.currentDateTimeUTC,
              clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
              clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
              clientTimeZoneMinutes: DateTimeService.timezone,
              iterationId: testItemModel.iterationId,
              testItemId: testItem.id,
              testId: testItem.testId,
              status:
                (answerResult && answerResult.status !== TestItemResultStatusEnum.SkippedByLogic) || testItemModel.isShown
                  ? TestItemResultStatusEnum.IgnoredByUser
                  : TestItemResultStatusEnum.SkippedByLogic,
              type: (testItem.testItemType.toString() as TestItemResultTypeEnum) ?? null,
            } as TestItemResult;
          }
          pageAnswers.push(answer);
        });

        if (
          answerStatus === TestItemResultStatusEnum.Failed &&
          (page?.testItemType === TestItemTypeEnum.Mobile || page?.testItemType === TestItemTypeEnum.WebSite)
        ) {
          pageAnswers = pageAnswers.map((answerResult) => ({
            ...answerResult,
            status: answerStatus,
          }));
        }

        return saveAnswersAction({ answers: pageAnswers });
      }),
    );
  });

  saveQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveQueueAction),
      concatLatestFrom(() => [this.store.select(selectAnswersQueue), this.store.select(selectTestInfo)]),
      filter(([, answers]) => !!answers.length),
      concatMap(([, answers, testInfo]) => {
        return this.resultService.testsSaveResults({ preview: testInfo?.preview, body: answers }).pipe(
          map(() => {
            this.persistenceService.set('answersQueue', (this.persistenceService.get('answersQueue') ?? []).splice(answers.length));
            return saveAnswersSuccessAction({ removedCount: answers.length });
          }),
          timeout(SAVE_QUEUE_TIMEOUT),

          catchError((errorResponse: unknown) => of(saveAnswersErrorAction({ error: errorResponse as HttpErrorResponse }))),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private persistenceService: PersistenceService,
    private resultService: ResultService,
    private store: Store,
  ) {}
}
