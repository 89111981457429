import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FabDictionaryItem } from '../../../../api/testrunner/models/fab-dictionary-item';
import { ScaleResultItem } from '../../../../api/testrunner/models/scale-result-item';
import { Task } from '../../../../api/testrunner/models/task';
import { ScaleService } from '../../../services/scale.service';

@Component({
  selector: 'app-scale-sliders',
  templateUrl: './scale-sliders.component.html',
  styleUrls: ['./scale-sliders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleSlidersComponent {
  @Input() public columns: Array<FabDictionaryItem> = [];
  @Input() public formAnswer!: UntypedFormGroup;
  @Input() public rows: Array<FabDictionaryItem> = [];
  @Input() public scaleData!: Task;

  constructor(
    private sanitizer: DomSanitizer,
    private scaleService: ScaleService,
  ) {}

  get changeStep(): number | null {
    let step = null;

    if (this.scaleData?.gridEnabled && this.scaleData.valueGridAnchored && this.scaleData.gridLinesCount && this.scaleData.maxValue) {
      step = (this.scaleData.maxValue - (this.scaleData?.minValue ?? 0)) / this.scaleData.gridLinesCount;
    } else if (this.scaleData?.changeStep) {
      step = this.scaleData.changeStep;
    }

    return step;
  }

  get isGridEnabled(): boolean {
    return this.scaleData?.gridEnabled ?? false;
  }

  get isLabelsEnabled(): boolean {
    return this.scaleData?.labelsEnabled ?? false;
  }

  getAnswer(entityId: string): string {
    const answers = this.formAnswer.getRawValue().formRows.map((row: ScaleResultItem) => {
      const entry = Object.entries(row)[0];
      return {
        answer: entry[1],
        elementItemId: entry[0],
      };
    });

    const answer = answers.filter((row: ScaleResultItem) => row.elementItemId === entityId);
    const answerValue = this.value(answer[0].answer);
    const unit = this.scaleData?.unitOfMeasurement ? ' ' + this.scaleData?.unitOfMeasurement : '';
    return answerValue ? answerValue + unit : '-';
  }

  scalePoint(index: number): string {
    const intervalLength = (this.scaleData?.maxValue ?? 1) - (this.scaleData?.minValue ?? 0);
    const point = (intervalLength / (this.scaleData?.gridLinesCount ?? 1)) * index + (this.scaleData?.minValue ?? 0);
    let minimumFractionDigits;

    const match = this.scaleData?.mask?.match(/\.|,/);
    if (match) {
      const separator = match[0];
      const arr = this.scaleData?.mask?.split(separator, 2) ?? [];
      minimumFractionDigits = arr[1] ? arr[1].length : 0;
    }

    return new Intl.NumberFormat('en', {
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    }).format(point);
  }

  value(value: number, locale?: string): string {
    return this.scaleService.value(this.scaleData, value, locale);
  }
}
