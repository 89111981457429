import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormAnswerStructure } from 'src/api/testrunner/models/form-answer.interface';
import { FabDictionaryItem } from '../../../../api/testrunner/models/fab-dictionary-item';
import { Task } from '../../../../api/testrunner/models/task';

@Component({
  selector: 'app-scale-nps',
  templateUrl: './scale-nps.component.html',
  styleUrls: ['./scale-nps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleNpsComponent {
  @Input({ required: true }) public columns: FabDictionaryItem[] = [];
  @Input({ required: true }) public formAnswer!: FormGroup<FormAnswerStructure>;
  @Input({ required: true }) public rows: FabDictionaryItem[] = [];
  @Input({ required: true }) public scaleData!: Task;

  public readonly rowIndex: number = 0;
}
