import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxStarsModule } from 'ngx-stars';
import { provideNgxWebstorage, withLocalStorage, withNgxWebstorageConfig } from 'ngx-webstorage';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { ImageModule } from 'primeng/image';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SliderModule } from 'primeng/slider';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ApiModule } from 'src/api/testrunner/api.module';
import { environment } from 'src/environments/environment';
import { ApiModule as ClientLogsApiModule } from '../api/client-logs/api.module';
import { ApiModule as KvStorageApiModule } from '../api/kv-storage/api.module';
import { ApiModule as TelemetryApiModule } from '../api/telemetry/api.module';
import { AppComponent } from './app.component';
import { BrowserNotSupportComponent } from './components/browser-not-support/browser-not-support.component';
import { CameraSettingsComponent } from './components/camera-settings/camera-settings.component';
import { ArrayToColumnsPipe } from './components/card-group/array-to-columns.pipe';
import { CardGroupComponent } from './components/card-group/card-group.component';
import { CardSortingComponent } from './components/card-sorting/card-sorting.component';
import { DesktopOnlyComponent } from './components/desktop-only/desktop-only.component';
import { DraggableCardComponent } from './components/draggable-card/draggable-card.component';
import { EmptyIdComponent } from './components/empty-id/empty-id.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { ErrorComponent } from './components/error/error.component';
import { FileComponent } from './components/file/file.component';
import { FirstClickComponent } from './components/first-click/first-click.component';
import { FirstGlanceComponent } from './components/first-glance/first-glance.component';
import { FlowComponent } from './components/flow/flow.component';
import { TimerFormatPipe } from './components/flow/timer.pipe';
import { FreeFormComponent } from './components/free-form/free-form.component';
import { HeaderComponent } from './components/header/header.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { ImageComponent } from './components/image/image.component';
import { InfoComponent } from './components/info/info.component';
import { InstallerTestApkComponent } from './components/installer-test-apk/installer-test-apk.component';
import { KanoFeatureComponent } from './components/kano-feature/kano-feature.component';
import { KanoComponent } from './components/kano/kano.component';
import { LoadTokenComponent } from './components/load-token/load-token.component';
import { MatrixComponent } from './components/matrix/matrix.component';
import { MessagesComponent } from './components/messages/messages.component';
import { MicrophoneSettingsComponent } from './components/microphone-settings/microphone-settings.component';
import { MobileOnlyComponent } from './components/mobile-only/mobile-only.component';
import { MobileComponent } from './components/mobile/mobile.component';
import { NumberComponent } from './components/number/number.component';
import { PageComponent } from './components/page/page.component';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { RankingComponent } from './components/ranking/ranking.component';
import { ScaleEmotionsComponent } from './components/scale/scale-emotions/scale-emotions.component';
import { ScaleNpsComponent } from './components/scale/scale-nps/scale-nps.component';
import { ScaleRatingComponent } from './components/scale/scale-rating/scale-rating.component';
import { ScaleSlidersComponent } from './components/scale/scale-sliders/scale-sliders.component';
import { ScaleStandardComponent } from './components/scale/scale-standard/scale-standard.component';
import { ScaleComponent } from './components/scale/scale.component';
import { SelectCustomAnswersComponent } from './components/select-custom-answers/select-custom-answers.component';
import { SelectPrivateDictionaryComponent } from './components/select-private-dictionary/select-private-dictionary.component';
import { SelectSystemDictionaryComponent } from './components/select-system-dictionary/select-system-dictionary.component';
import { SelectComponent } from './components/select/select.component';
import { SessionStartComponent } from './components/session-start/session-start.component';
import { SwitchThemeButtonComponent } from './components/switch-theme-button/switch-theme-button.component';
import { TestCompletionProgressBarComponent } from './components/test-completion-progress-bar/test-completion-progress-bar.component';
import { TestEndComponent } from './components/test-end/test-end.component';
import { TestUnavailableComponent } from './components/test-unavailable/test-unavailable.component';
import { TestComponent } from './components/test/test.component';
import { TouchEnablerComponent } from './components/touch-enabler/touch-enabler.component';
import { VersionComponent } from './components/version/version.component';
import { WebSiteComponent } from './components/web-site/web-site.component';
import { WrongIdComponent } from './components/wrong-id/wrong-id.component';
import { setDynamicEnvironment } from './functions/set-dynamic-environment';
import { AppHttpInterceptor } from './services/interceptor.service';
import { LogicService } from './services/logic.service';
import { MacrosPipe } from './services/macros.pipe';
import { MetadataService } from './services/metadata.service';
import { SharedModule } from './shared/shared.module';
import { ActionTypes } from './store/action-types';
import { AnswerResultsEffects } from './store/effects/answer-results.effects';
import { BackendErrorsEffects } from './store/effects/backend-errors.effects';
import { ClientLogsEffects } from './store/effects/client-logs.effects';
import { MessageEffects } from './store/effects/message.effects';
import { PageEffects } from './store/effects/page.effects';
import { PromoCodeEffects } from './store/effects/promo-code.effects';
import { SessionEffects } from './store/effects/session.effects';
import { TelemetryEffects } from './store/effects/telemetry.effects';
import { TestItemStatisticsEffects } from './store/effects/test-item-statistics.effects';
import { WebSiteExtensionEffects } from './store/effects/web-site-extension.effects';
import { FeaturesEnum } from './store/features-enum';
import { answersReducers } from './store/reducers/answers.reducer';
import { moderatedReducers } from './store/reducers/moderated.reducer';
import { pageReducers } from './store/reducers/page.reducer';
import { statisticsReducers } from './store/reducers/test-item-statistics.reducer';
import { testItemsReducers } from './store/reducers/test-items.reducer';
import { testRunnerReducers } from './store/reducers/test-runner.reducer';
import { TranslocoRootModule as TranslocoRootModule_alias } from './transloco-root.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

setDynamicEnvironment();

const routes: Routes = [
  { path: 'environment', component: EnvironmentComponent },
  { path: 'version', component: VersionComponent },
  { path: 'eye', loadChildren: () => import('./eye-tracker/eye-tracker.module').then((module) => module.EyeTrackerModule) },
  { path: 'install', component: InstallerTestApkComponent },
  { path: 'touch-enabler', component: TouchEnablerComponent },
  {
    path: 'moderated',
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    loadChildren: () => import('./moderated/moderated.module').then((m) => m.ModeratedModule),
  },
  { path: ':id', component: TestComponent },
  { path: '**', component: EmptyIdComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    TestComponent,
    FlowComponent,
    FreeFormComponent,
    EmptyIdComponent,
    EnvironmentComponent,
    WrongIdComponent,
    TestUnavailableComponent,
    HeaderComponent,
    TestEndComponent,
    LoadTokenComponent,
    SessionStartComponent,
    InfoComponent,
    FileComponent,
    MatrixComponent,
    SelectComponent,
    ImageComponent,
    SwitchThemeButtonComponent,
    FirstClickComponent,
    FirstGlanceComponent,
    TimerFormatPipe,
    RankingComponent,
    NumberComponent,
    MacrosPipe,
    WebSiteComponent,
    MobileComponent,
    TestCompletionProgressBarComponent,
    PageComponent,
    VersionComponent,
    ScaleComponent,
    ScaleSlidersComponent,
    ScaleStandardComponent,
    ScaleNpsComponent,
    ScaleRatingComponent,
    ScaleEmotionsComponent,
    BrowserNotSupportComponent,
    MobileOnlyComponent,
    DesktopOnlyComponent,
    CameraSettingsComponent,
    MicrophoneSettingsComponent,
    SelectPrivateDictionaryComponent,
    SelectSystemDictionaryComponent,
    SelectCustomAnswersComponent,
    InstallerTestApkComponent,
    TouchEnablerComponent,
    ErrorComponent,
    PhoneNumberInputComponent,
    CardSortingComponent,
    DraggableCardComponent,
    IconButtonComponent,
    KanoComponent,
    KanoFeatureComponent,
    ImagePreviewComponent,
    ArrayToColumnsPipe,
    CardGroupComponent,
    MessagesComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
    ClientLogsApiModule.forRoot({ rootUrl: environment.clientLogsApiUrl }),
    KvStorageApiModule.forRoot({ rootUrl: environment.kvStorageApiUrl }),
    TelemetryApiModule.forRoot({ rootUrl: environment.telemetryApiUrl }),
    RouterModule.forRoot(routes),
    ButtonModule,
    DropdownModule,
    MenubarModule,
    PanelModule,
    PickListModule,
    FormsModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    MessagesModule,
    MessageModule,
    SidebarModule,
    TableModule,
    RadioButtonModule,
    ReactiveFormsModule,
    RippleModule,
    CheckboxModule,
    CommonModule,
    SelectButtonModule,
    TranslocoRootModule,
    AvatarModule,
    DialogModule,
    InputNumberModule,
    QRCodeModule,
    AutoCompleteModule,
    MultiSelectModule,
    ClipboardModule,
    ScrollPanelModule,
    FileUploadModule,
    SliderModule,
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([
      AnswerResultsEffects,
      SessionEffects,
      BackendErrorsEffects,
      TestItemStatisticsEffects,
      WebSiteExtensionEffects,
      PageEffects,
      MessageEffects,
      TelemetryEffects,
      ClientLogsEffects,
      PromoCodeEffects,
    ]),
    StoreModule.forRoot({}),
    /*@TODO Вернул, так как isTestLoading из модуля Moderated запрашивался раньше инициализации стора для moderated,
                  что приводило к ошибке "TypeError: can't access property "isTestLoading", moderatedState is undefined"*/
    StoreModule.forFeature(FeaturesEnum.answers, answersReducers),
    StoreModule.forFeature(FeaturesEnum.moderated, moderatedReducers),
    StoreModule.forFeature(FeaturesEnum.page, pageReducers),
    StoreModule.forFeature(FeaturesEnum.statistics, statisticsReducers),
    StoreModule.forFeature(FeaturesEnum.testItems, testItemsReducers),
    StoreModule.forFeature(FeaturesEnum.testRunner, testRunnerReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
      actionsBlocklist: [ActionTypes.LOCK_BTN_NEXT, ActionTypes.EMPTY, ActionTypes.SAVE_QUEUE],
      connectInZone: true,
    }),
    OverlayPanelModule,
    TagModule,
    ToastModule,
    InputMaskModule,
    NgxStarsModule,
    AccordionModule,
    SharedModule,
    ImageModule,
    InplaceModule,
    TranslocoRootModule_alias,
  ],
  providers: [
    LogicService,
    MacrosPipe,
    MetadataService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true,
    },
    provideNgxWebstorage(withNgxWebstorageConfig({ prefix: 'cabinet' }), withLocalStorage()),
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
  exports: [SessionStartComponent],
})
export class AppModule {}
