import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-mobile-only',
  templateUrl: './mobile-only.component.html',
  styleUrls: ['./mobile-only.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileOnlyComponent {
  public readonly url: string;

  constructor(router: Router, @Inject(DOCUMENT) document: Document) {
    this.url = document.location.origin + router.url;
  }
}
