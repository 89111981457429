import { Pipe, PipeTransform } from '@angular/core';

import { MacrosService } from './macros.service';
import { MacrosParams, MacrosValue } from '../models/macros-params';
import { PipingItem } from '../models/piping-item';

// import { MacrosVar } from '../models/macros-var';

@Pipe({
  name: 'macros',
})
export class MacrosPipe implements PipeTransform {
  macrosDictionary: MacrosParams = {};

  constructor(private macrosService: MacrosService) {}

  transform(value: string, testItem?: PipingItem): string {
    //@TODO заполнение макрос словаря происходит в каждом тексте, надо единожды, если есть хоть один макрос в тексте
    // получим массив объектов вида {macrosKey: macrosValue string | object}
    this.macrosDictionary = this.macrosService.getMacrosDictionary(testItem);

    // массив именований ключей
    const args: string[] = [];

    // массив соответствующих значений
    const values: Array<MacrosValue> = [];

    // заполним массивы
    Object.keys(this.macrosDictionary)?.forEach((key: string) => {
      args.push(key);
      values.push(this.macrosDictionary[key]);
    });

    // console.log(this.macrosDictionary);
    // console.log('args', args);
    // console.log('values', values);

    // выберем все вхождения вида {$ xxx $} из текста
    const regex = /{\$[\w\W]*?\$}/gm;

    // обработаем все вхождения
    return value.replace(regex, (match) => {
      // удалим окружающие символы-ограничители и окружающие пробелы
      const macrosText = this.macrosService.clearMacros(match.slice(2, -2).trim());
      //console.log('macrosText', macrosText);

      try {
        // фунция обработки макроса
        console.log('Macros code', macrosText);
        const code = new Function('macrosText, ' + args.join(', '), 'return ' + macrosText);
        //console.log(code.toString());

        // вернём результат обработки макроса в виде строки
        return code(macrosText, ...values);
      } catch (error: unknown) {
        this.macrosService.handleScriptError(error as Error, testItem?.id);
        return 'Macros error'; //@TODO Локализовать
      }
    });
  }

  /*getVar(key: string): string|number {
    let value: string | number = '';
    this.vars?.forEach((item: MacrosVar) => {
      if (item.key === key) {
        value = item.value;
      }
    });

    return value;
  }*/
}
