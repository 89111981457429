/* eslint-disable @typescript-eslint/naming-convention */
export enum MobileAppActionEnum {
  AnswerTask = 'AnswerTask',
  BackToTask = 'BackToTask',
  CheckInstalled = 'CheckInstalled',
  FinishTask = 'FinishTask',
  FinishTest = 'FinishTest',
  StartTask = 'StartTask',
  StartTest = 'StartTest',
}
