<div class="app-install-info" *transloco="let t">
  <h1>{{ t('touchEnabler.header') }}</h1>
  <p>{{ t('touchEnabler.instructionPart1') }}</p>
  <p>{{ t('touchEnabler.instructionPart2') }}</p>
  <p>{{ t('touchEnabler.instructionPart3') }}</p>
  <ol class="list-circle">
    <li>
      {{ t('touchEnabler.downloadFile') }}<br />
      <a pButton pRipple href="/assets/mobile-app/touch-enabler.apk" class="p-button-success mt-2" style="text-decoration: none">{{
        t('touchEnabler.downloadApp')
      }}</a>
    </li>
    <li>
      {{ t('touchEnabler.openFile') }} <strong>{{ t('touchEnabler.allowInstall') }}</strong>
    </li>
    <li>{{ t('touchEnabler.completeInstall') }}</li>
  </ol>
  <p>{{ t('touchEnabler.afterInstall') }}</p>
</div>
