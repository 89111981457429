/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PromoCodeResponse } from '../models/promo-code-response';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation promoCodeConsumePromoCode
   */
  static readonly PromoCodeConsumePromoCodePath = '/api/v1/promocodes/consume';

  /**
   * Берёт из базы свободный промокод и тратит его.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promoCodeConsumePromoCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoCodeConsumePromoCode$Response(params: {
    testItemId: string;
    preview?: boolean;
  }): Observable<StrictHttpResponse<PromoCodeResponse>> {

    const rb = new RequestBuilder(this.rootUrl, PromoCodeService.PromoCodeConsumePromoCodePath, 'post');
    if (params) {
      rb.query('testItemId', params.testItemId, {});
      rb.query('preview', params.preview, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PromoCodeResponse>;
      })
    );
  }

  /**
   * Берёт из базы свободный промокод и тратит его.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `promoCodeConsumePromoCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoCodeConsumePromoCode(params: {
    testItemId: string;
    preview?: boolean;
  }): Observable<PromoCodeResponse> {

    return this.promoCodeConsumePromoCode$Response(params).pipe(
      map((r: StrictHttpResponse<PromoCodeResponse>) => r.body as PromoCodeResponse)
    );
  }

}
