<ng-container *transloco="let t">
  <div>
    <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

    <div *ngIf="info.promoCodesIsEnabled">
      <ng-template
        [ngTemplateOutlet]="promoCode"
        [ngTemplateOutletContext]="{ promoCode: promoCode$ | async, isPreview: (testInfo$ | async)?.preview === true }"
      ></ng-template>
    </div>

    <ng-template #promoCode let-promoCode="promoCode" let-isPreview="isPreview">
      <div
        class="promo-code inline-flex align-items-center"
        [class]="!promoCode && !isPreview ? 'justify-content-center' : 'justify-content-between'"
      >
        <span [class.no-free]="!promoCode || isPreview">
          {{
            isPreview ? t('testrunner.promoCodes.patchText') : !promoCode ? t('testrunner.promoCodes.NoFreePromoCodesAvailable') : promoCode
          }}
        </span>
        <span *ngIf="promoCode || isPreview" class="copy-button">
          <i class="fa-regular fa-copy" (click)="copy(promoCode, isPreview)"></i>
        </span>
      </div>
    </ng-template>

    <div *ngIf="info.privacyPolicyIsEnabled" class="field-checkbox align-items-center">
      <p-checkbox [binary]="true" id="isPrivacyPoliceApproved" [formControl]="privacyPolicyResultControl"></p-checkbox>
      <p-button [label]="info.privacyPolicyCustomLinkText" styleClass="p-button-link" (click)="onOpen()"></p-button>

      <p-dialog [(visible)]="display" [modal]="true" [resizable]="false" [style]="{ maxWidth: '600px', height: '90vh' }">
        <div [innerHtml]="privacyPolicyText"></div>
        <ng-template pTemplate="footer">
          <p-button (click)="onClose()" autofocus [label]="t('testrunner.close')" styleClass="p-button-success mr-0 mt-2"></p-button>
        </ng-template>
      </p-dialog>
    </div>
  </div>

  <p-toast></p-toast>
</ng-container>
