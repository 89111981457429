<div
  cdkDrag
  cdkDragHandle
  [cdkDragData]="card"
  (cdkDragReleased)="onDragReleased()"
  (cdkDragMoved)="dragMove.emit($event)"
  (cdkDragStarted)="onDragStarted()"
  class="draggable text-left border-round-6px surface-0 card-shadow"
  [class]="styleClass"
>
  <!-- drop placeholder-->
  <div
    *cdkDragPlaceholder
    class="border-round-6px bg-gray-100 relative z-3"
    [class.h-3rem]="!cardHeight"
    [style.height.px]="cardHeight"
  ></div>

  <!-- card content -->
  <div #cardContainer>
    <!--картинка-->
    <app-image-preview
      *ngIf="card.imageUrl"
      [alt]="card.imageUrl"
      [preview]="true"
      [src]="card.imageUrl"
      styleClass="border-round-6px"
      width="100%"
      (imageLoaded)="onImageLoaded()"
    ></app-image-preview>

    <!--текст-->
    <div class="flex justify-content-between align-items-center h-3rem">
      <div *ngIf="!!order" class="ml-2 w-2rem h-2rem bg-green-100 border-round-6px flex justify-content-center align-items-center">
        {{ order }}
      </div>
      <div class="p-2 flex-grow-1 min-w-0">
        <span class="block overflow-x-hidden white-space-nowrap text-overflow-ellipsis w-0 min-w-full">{{ card.name }}</span>
      </div>
      <app-icon-button
        #descriptionTooltipRef
        *ngIf="card.description"
        [escape]="false"
        [icon]="'fa-regular fa-circle-info'"
        [pTooltip]="tooltipContent"
        showDelay="500"
        styleClass="mr-2 hover:bg-gray-50"
        [tabindex]="0"
        tooltipPosition="left"
        [tooltipEvent]="getTooltipActivationEvent()"
      ></app-icon-button>

      <ng-template #tooltipContent>
        <span class="p-text-light white-space-normal">
          <div class="font-light mt-2">{{ card.description }}</div>
        </span>
      </ng-template>
    </div>
  </div>
</div>
