import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  FirstClickResult,
  Task,
  TestItemClickArea,
  TestItemResult,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
} from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';

@Component({
  selector: 'app-first-click',
  templateUrl: './first-click.component.html',
  styleUrls: ['./first-click.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstClickComponent implements OnChanges, OnInit {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  @ViewChild('imageElement') public imageElementRef?: ElementRef<HTMLElement>;

  clientStartTime = '';
  correctAnswerClickAreas?: null | Array<TestItemClickArea>;
  imageUrl?: string | null;
  imageWidth = 0;
  realImageWidth?: number;
  isHighDpi?: boolean;
  isImageFitToScreenEnabled?: boolean;
  isImageLoading = true;
  isImageLoaded = true;
  isImageHidden = true;
  isHelperHidden = true;
  firstClick?: Task;
  description?: SafeHtml;
  helpText?: SafeHtml;
  readingTimeSpentMs = 0;
  devicePixelRatio = window.devicePixelRatio;

  constructor(
    private persistenceService: PersistenceService,
    private sanitizer: DomSanitizer,
    private macrosPipe: MacrosPipe,
  ) {}

  get hasAnswer(): boolean {
    const answer = this.getAnswer(this.firstClick?.id ?? '');
    return !!answer && answer.status !== TestItemResultStatusEnum.SkippedByLogic;
  }

  get imageElementWidth(): string {
    return this.imageWidth ? this.imageWidth + 'px' : (this.imageElementRef?.nativeElement.getBoundingClientRect().width ?? 0) + 'px';
  }

  getAnswer(itemTestId: string): FirstClickResult | null {
    return this.persistenceService.get('answer_' + itemTestId);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      if (this.testItemModel.data?.description != null) {
        this.description = this.sanitizer.bypassSecurityTrustHtml(
          this.macrosPipe.transform(this.testItemModel.data?.description, pipingItem),
        );
      }
      if (this.testItemModel.data?.helpText) {
        this.helpText = this.sanitizer.bypassSecurityTrustHtml(this.macrosPipe.transform(this.testItemModel.data.helpText, pipingItem));
      }
    }
  }

  ngOnInit(): void {
    this.firstClick = this.testItemModel?.data;

    this.imageUrl = this.firstClick?.imageUrl;
    this.isImageLoading = !!this.firstClick?.imageUrl;
    this.correctAnswerClickAreas = this.firstClick?.answerClickAreas;
    this.isImageFitToScreenEnabled = this.firstClick?.imageFitToScreenEnabled;
    this.isHighDpi = this.firstClick?.isHighDpi;

    window.addEventListener('resize', () => {
      this.imageWidth = this.imageElementRef?.nativeElement.getBoundingClientRect().width ?? 0;
    });
  }

  onImageClick(event: MouseEvent): void {
    const scale = (event.target as HTMLImageElement).width / (event.target as HTMLImageElement).naturalWidth;
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.clientStartTime),
      clientStartTimeUtc: this.clientStartTime,
      cursorPositionX: event.offsetX / scale,
      cursorPositionY: event.offsetY / scale,
      iterationId: this.testItemModel?.iterationId,
      readingTimeSpentMs: this.readingTimeSpentMs,
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.FirstClick,
      status: TestItemResultStatusEnum.Intermediate,
    } as FirstClickResult);
    this.isImageHidden = true;
  }

  onImageError(): void {
    this.isImageLoading = false;
    this.isImageLoaded = false;
  }

  onImageLoad(): void {
    this.isImageLoading = false;
    this.isImageLoaded = true;
    this.realImageWidth = (this.imageElementRef?.nativeElement as HTMLImageElement).naturalWidth;
  }

  showImage(): void {
    this.readingTimeSpentMs = DateTimeService.getDuration(this.testItemModel?.showStartTime);
    this.isImageHidden = false;
    this.clientStartTime = DateTimeService.currentDateTimeUTC;
  }
}
