<form [formGroup]="formAnswer">
  <ng-container formArrayName="formRows">
    <p-table [columns]="columns" [value]="rows" [autoLayout]="true" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-row let-columns="columns" let-i="rowIndex">
        <tr [formGroupName]="i">
          <td class="border-none text-center relative" [attr.colspan]="columns.length" style="padding-left: 0; width: 70%">
            <!-- Эмоции -->
            <ng-container>
              <div class="flex justify-content-start align-items-center mb-4 text-left">
                <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row }"></ng-template>
              </div>
              <div class="flex justify-content-between align-items-center">
                <div class="emotion-icons grid" [class.emotion-color]="isEmotionsColor">
                  <ng-container *ngFor="let emotion of filteredEmotions; let ind = index">
                    <ng-template
                      [ngTemplateOutlet]="emotionTemplateRef"
                      [ngTemplateOutletContext]="{ emotion: emotion, entityId: row?.entityId, rating: ind }"
                    >
                    </ng-template>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <!-- /Эмоции -->
          </td>
        </tr></ng-template
      >
    </p-table>
  </ng-container>
</form>

<ng-template #answerTemplateRef let-col="col">
  <span>
    <span class="mr-2" *ngIf="col?.imageUrl && col?.imageUrl.length">
      <app-image [url]="col?.imageUrl" [dialog]="true"></app-image>
    </span>
    <span class="text-left" [innerHTML]="col?.captionRu ?? '&nbsp;' | safeHtml"></span>
  </span>
</ng-template>

<ng-template #emotionTemplateRef let-emotion="emotion" let-entityId="entityId" let-rating="rating">
  <div class="flex flex-column flex-grow-0 col justify-content-start align-items-center">
    <i
      class="fa-light {{ emotion.icon }}"
      (click)="onRatingSet(rating, entityId)"
      [class.filled]="getRating(entityId) === rating + scaleData.minValue"
    ></i>
    <span *ngIf="scaleData.showValueEnabled" class="mt-2">
      {{ getColDictionaryItemByIndex(rating).captionRu }}
    </span>
  </div>
</ng-template>
