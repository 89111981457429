import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { Video } from '../models/video';
import { WebSite } from '../../api/testrunner/models/web-site';
import { WebSiteActionEnum } from '../models/web-site-action-enum';
import { WebSitePostMessage } from '../models/web-site-post-message';
import { PersistenceService } from './persistence.service';
import { MacrosParams } from '../models/macros-params';

@Injectable({
  providedIn: 'root',
})
export class WebSiteService {
  videos: Array<Video> = [];

  constructor(private persistenceService: PersistenceService) {}

  clearVideos(): void {
    this.videos = [];
  }

  postMessage(action: WebSiteActionEnum, data?: WebSite, macrosData?: MacrosParams): void {
    const message: WebSitePostMessage = {
      token: this.persistenceService.get('testRunnerToken'),
      anonymousId: this.persistenceService.get('anonymousId'),
      telemetryApiUrl: environment.telemetryApiUrl,
      clientLogsApiUrl: environment.clientLogsApiUrl,
      action,
      data,
      macrosData,
    };
    console.log('Website extension post message', message);
    window.postMessage(message, window.origin);
  }
}
