/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GetTokenRequest } from '../models/get-token-request';
import { TokenResponse } from '../models/token-response';
import { ValidateTokenRequest } from '../models/validate-token-request';
import { ValidateTokenResponse } from '../models/validate-token-response';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation tokensCreateTestRunnerToken
   */
  static readonly TokensCreateTestRunnerTokenPath = '/api/v1/Tokens';

  /**
   * Получение токена для дальнейших ответов.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokensCreateTestRunnerToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokensCreateTestRunnerToken$Response(params?: {
    body?: GetTokenRequest
  }): Observable<StrictHttpResponse<TokenResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TokenService.TokensCreateTestRunnerTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TokenResponse>;
      })
    );
  }

  /**
   * Получение токена для дальнейших ответов.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tokensCreateTestRunnerToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokensCreateTestRunnerToken(params?: {
    body?: GetTokenRequest
  }): Observable<TokenResponse> {

    return this.tokensCreateTestRunnerToken$Response(params).pipe(
      map((r: StrictHttpResponse<TokenResponse>) => r.body as TokenResponse)
    );
  }

  /**
   * Path part for operation tokensValidateTokenAsync
   */
  static readonly TokensValidateTokenAsyncPath = '/api/v1/Tokens/validate';

  /**
   * Метод валидации токена для других сервисов.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `tokensValidateTokenAsync()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokensValidateTokenAsync$Response(params?: {
    body?: ValidateTokenRequest
  }): Observable<StrictHttpResponse<ValidateTokenResponse>> {

    const rb = new RequestBuilder(this.rootUrl, TokenService.TokensValidateTokenAsyncPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidateTokenResponse>;
      })
    );
  }

  /**
   * Метод валидации токена для других сервисов.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `tokensValidateTokenAsync$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  tokensValidateTokenAsync(params?: {
    body?: ValidateTokenRequest
  }): Observable<ValidateTokenResponse> {

    return this.tokensValidateTokenAsync$Response(params).pipe(
      map((r: StrictHttpResponse<ValidateTokenResponse>) => r.body as ValidateTokenResponse)
    );
  }

}
