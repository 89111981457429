<ng-container *transloco="let translate">
  <div *ngIf="description$ | async as description" [innerHtml]="description" class="pb-4"></div>
  <p-fileUpload
    #imageUpload
    name="file"
    [accept]="acceptTypes"
    [invalidFileSizeMessageDetail]="translate('testrunner.file.invalidFileSizeMessageDetail')"
    [invalidFileSizeMessageSummary]="translate('testrunner.file.invalidFileSizeMessageSummary')"
    [invalidFileTypeMessageDetail]="translate('testrunner.file.invalidFileTypeMessageDetail')"
    [invalidFileTypeMessageSummary]="translate('testrunner.file.invalidFileTypeMessageSummary')"
    [maxFileSize]="maxFileSize"
    mode="advanced"
    styleClass="p-button-link"
    [showCancelButton]="false"
    [showUploadButton]="false"
    [auto]="true"
    [url]="uploadUrl$ | async"
    (onBeforeUpload)="isLoading = true"
    (onUpload)="uploadFile($event, imageUpload)"
    class="fileUpload"
    [disabled]="isAnswerSkippedControl.value"
  >
    <ng-template pTemplate="content">
      <input type="text" pInputText class="cell-editor-input" [formControl]="fileNameControl" />
      <button
        pButton
        pRipple
        type="button"
        class="p-button-sm p-button-success"
        [class.hidden]="hidden"
        [icon]="fileNameControl.value ? 'fa-solid fa-check fa-lg' : ''"
        iconPos="right"
        [label]="uploadButtonText$ | async"
        [disabled]="isAnswerSkippedControl.value"
        (click)="imageUpload.choose()"
      ></button>
    </ng-template>
  </p-fileUpload>

  <p-checkbox
    *ngIf="testItemModel?.data.isSkipButtonEnabled"
    inputId="isSkipButtonEnabled"
    name="isSkipButtonEnabled"
    [binary]="true"
    [formControl]="isAnswerSkippedControl"
    [label]="testItemModel?.data.skipButtonText || translate('testrunner.freeform.noAnswer')"
  ></p-checkbox>

  <p-toast></p-toast>
</ng-container>
