<ng-container *ngIf="isTestStarted$ | async; else errors">
  <app-error *ngIf="(sendAnswerAttempt$ | async) > maxAttemptCount; else flow"></app-error>

  <ng-template #flow>
    <app-flow *ngIf="isFlowVisible$ | async" class="p-overflow-hidden page"></app-flow>
  </ng-template>
</ng-container>

<ng-template #errors>
  <div class="flex align-items-center justify-content-center h-full">
    <app-load-token *ngIf="!isTokenLoad && isTestFound"></app-load-token>
    <app-empty-id *ngIf="!isGuid"></app-empty-id>
    <app-wrong-id *ngIf="!isGuidValid"></app-wrong-id>
    <app-test-unavailable *ngIf="(test$ | async) === null" [error]="error$ | async"></app-test-unavailable>
    <ng-container *ngIf="startSessionStatus$ | async as startSessionStatus" [ngSwitch]="startSessionStatus">
      <app-browser-not-support *ngSwitchCase="startSessionStatusEnum.IncorrectBrowserTypeError"></app-browser-not-support>
      <ng-container *ngSwitchCase="startSessionStatusEnum.IncorrectDeviceTypeError">
        <app-desktop-only *ngIf="isWrongDesktopDevice$ | async"></app-desktop-only>
        <app-mobile-only *ngIf="isWrongMobileDevice$ | async"></app-mobile-only>
      </ng-container>
      <app-test-end
        *ngSwitchCase="startSessionStatusEnum.SapOutdatedLinkError"
        [completeTestInfo]="completeTestInfo$ | async"
        [isFinishSession]="false"
        [redirectUrl]="redirectUrl$ | async"
        [test]="test$ | async"
      ></app-test-end>
      <p-message
        *ngSwitchDefault
        severity="error"
        [text]="'testrunner.test.startSessionErrors.' + startSessionStatus | transloco"
      ></p-message>
    </ng-container>
    <app-session-start *ngIf="(error$ | async) === null && (startSessionStatus$ | async) === null"></app-session-start>
  </div>
</ng-template>
