import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LanguageEnum } from 'src/api/testrunner/models';
import { TranslationsService } from '../../services/translations.service';
import { setLoaderStatus } from '../../store/actions/test-runner.actions';

@Component({
  selector: 'app-touch-enabler',
  templateUrl: './touch-enabler.component.html',
  styleUrls: ['./touch-enabler.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TouchEnablerComponent {
  constructor(route: ActivatedRoute, translationsService: TranslationsService, store: Store) {
    store.dispatch(setLoaderStatus());

    translationsService.changeTranslation(
      Object.values(LanguageEnum).find((lang) => lang.toLowerCase() === route.snapshot?.queryParamMap.get('lang')?.toLowerCase()) ??
        LanguageEnum.En,
    );
  }
}
