import { Clipboard } from '@angular/cdk/clipboard';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, of, timer } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';
import { Task, TestItemResult, TestItemResultStatusEnum, TestItemResultTypeEnum } from '../../../api/testrunner/models';
import { InfoExtendedResult } from '../../models/info-extended-result';
import { PipingItem } from '../../models/piping-item';
import { TestInfo } from '../../models/test-info';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { lockButtonNext } from '../../store/actions/page.actions';
import { getPromoCodeAction } from '../../store/actions/test-runner.actions';
import { selectPromoCodeByTaskId, selectTestInfo } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;
  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  private answer: InfoExtendedResult | null = null;
  public description?: SafeHtml;
  public display: boolean = false;
  public info?: Task;

  public privacyPolicyText: SafeHtml | null = null;
  public promoCode$: Observable<string | null> = of(null);
  public readonly testInfo$: Observable<TestInfo | null> = this.store.select(selectTestInfo);

  public readonly privacyPolicyResultControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly clipBoard: Clipboard,
    private readonly messageService: MessageService,
    private readonly persistenceService: PersistenceService,
    private readonly pipe: MacrosPipe,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store,
    private readonly transloco: TranslocoService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      if (this.testItemModel.data?.description && this.descriptionString !== this.testItemModel.data.description) {
        this.descriptionString = this.testItemModel.data.description;
        this.description = this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data?.description, pipingItem));
      }
      if (this.testItemModel.data?.privacyPolicyIsEnabled) {
        this.privacyPolicyText =
          this.testItemModel.data?.privacyPolicyText != null
            ? this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data?.privacyPolicyText, pipingItem))
            : null;
      }
    }
  }

  public ngOnInit(): void {
    this.info = this.testItemModel?.data;

    this.answer = this.persistenceService.get('answer_' + this.info?.id);

    if (this.info?.promoCodesIsEnabled && this.info.id) {
      this.promoCode$ = this.store.select(selectPromoCodeByTaskId(this.info?.id)).pipe(tap((promoCode) => this.save({ promoCode })));
      this.store.dispatch(getPromoCodeAction({ testItemId: this.info.id }));
    }

    if (this.info?.privacyPolicyIsEnabled) {
      this.privacyPolicyResultControl.setValue(Boolean(this.answer?.privacyPolicyResult));
      this.subscription.add(this.processPrivacyPolicyResultValueChanges());
    }

    if (!this.info?.promoCodesIsEnabled && !this.info?.promoCodesIsEnabled && !this.answer) {
      this.save({});
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public copy(promoCode: string, isPreview: boolean): void {
    const text = isPreview ? this.transloco.translate('testrunner.promoCodes.patchText') : promoCode;

    if (this.clipBoard.copy(text as string)) {
      this.messageService.add({
        severity: 'success',
        summary: '',
        detail: this.transloco.translate('testrunner.promoCodes.copySuccess'),
        closable: false,
        life: 3000,
      });
    }
  }

  public onClose(): void {
    this.display = false;
  }

  public onOpen(): void {
    this.display = true;
  }

  private processPrivacyPolicyResultValueChanges(): Subscription {
    return this.privacyPolicyResultControl.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(0);
        }),
      )
      .subscribe((result) => this.save({ privacyPolicyResult: result }));
  }

  private save(options: Partial<InfoExtendedResult>): void {
    this.answer = {
      ...(this.answer || {}),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      clientTimeZoneMinutes: DateTimeService.timezone,
      iterationId: this.testItemModel?.iterationId,
      status: TestItemResultStatusEnum.Intermediate,
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.Info,
      ...options,
    } as InfoExtendedResult;
    this.answerChanges.emit(this.answer);
  }
}
