import { Injectable } from '@angular/core';
import { IfCompleteResultStatusEnum, Test, TestEndByCondition, TestEndByQuota, TestItemQuota } from 'src/api/testrunner/models';
import { TranslocoService } from '@ngneat/transloco';
import { CompleteTestInfo } from '../models/complete-test-info';

interface RedirectData {
  completeContent: string;
  url: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  constructor(private translocoService: TranslocoService) {}

  getForceFinishTestData(test: Test | null): CompleteTestInfo {
    return {
      completeStatusType: IfCompleteResultStatusEnum.FiltersNotPassed,
      completeContent:
        test?.filtersNotPassedContent ?? this.translocoService.translate('testrunner.redirectLink.filtersNotPassedContent.defaultText'),
      testId: test?.id ?? '',
    };
  }

  getRedirectData(test: Test | null, testItem: TestEndByCondition | TestEndByQuota | TestItemQuota): RedirectData {
    let completeContent: string;
    let url: string | null;

    switch (testItem.completeStatusType) {
      case IfCompleteResultStatusEnum.FiltersNotPassed:
        url = test?.filtersNotPassedUrl ?? null;
        completeContent =
          test?.filtersNotPassedContent ?? this.translocoService.translate('testrunner.redirectLink.filtersNotPassedContent.defaultText');
        break;
      case IfCompleteResultStatusEnum.LateUser:
        url = test?.lateUserUrl ?? null;
        completeContent = test?.lateUserContent ?? this.translocoService.translate('testrunner.redirectLink.lateUserContent.defaultText');
        break;
      case IfCompleteResultStatusEnum.Cheater:
        url = test?.cheaterUrl ?? null;
        completeContent = test?.cheaterContent ?? this.translocoService.translate('testrunner.redirectLink.cheaterContent.defaultText');
        break;
      case IfCompleteResultStatusEnum.Custom:
        url = testItem?.customFinishRedirectUrl ?? null;
        completeContent =
          testItem?.customFinishContent ?? this.translocoService.translate('testrunner.redirectLink.customFinishContent.defaultText');
        break;
      case IfCompleteResultStatusEnum.Finished:
      default:
        url = test?.finishUrl ?? null;
        completeContent = test?.finishContent ?? this.translocoService.translate('testrunner.redirectLink.finishContent.defaultText');
        break;
    }

    return { completeContent, url };
  }
}
