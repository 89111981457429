import { Action, createReducer, on } from '@ngrx/store';
import { DateTimeService } from '../../services/date-time.service';
import { changePageAnswerSuccessAction } from '../actions/answers.actions';
import {
  getNextPageAction,
  getPrevPageAction,
  getTestItemModelsVisibilitySuccessAction,
  initPageSuccessAction,
  lockButtonNext,
  revalidatePageAnswersSuccessAction,
} from '../actions/page.actions';
import { finishMobileTestAction, screenOutAction } from '../actions/test-runner.actions';
import { PageState, pageAdapter } from '../states/page.state';

export const initialState: PageState = pageAdapter.getInitialState({
  isAnswersValid: false,
  isTaskAnswerSaved: true,
  isPageChanging: true,
  currentPage: null,
  pageShowStartTime: null,
});

export const reducer = createReducer(
  initialState,
  on(
    initPageSuccessAction,
    (state, { page, testItemModels, pageShowStartTime }): PageState =>
      pageAdapter.upsertMany(
        testItemModels,
        pageAdapter.removeAll({
          ...state,
          isPageChanging: false,
          currentPage: page,
          pageShowStartTime,
        }),
      ),
  ),
  on(getNextPageAction, (state): PageState => ({ ...state, isPageChanging: true })),
  on(getPrevPageAction, (state): PageState => ({ ...state, isPageChanging: true })),
  on(
    getTestItemModelsVisibilitySuccessAction,
    (state, { testItemModels }): PageState =>
      pageAdapter.updateMany(
        testItemModels.map((testItemModel) => {
          const stateTestItemModel = state.entities[testItemModel.id];
          return {
            id: testItemModel.id,
            changes: {
              isShown: testItemModel.isShown,
              showStartTime:
                testItemModel?.isShown && !stateTestItemModel?.isShown
                  ? DateTimeService.currentDateTimeUTC
                  : (stateTestItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC),
              lastUpdateTime: DateTimeService.currentDateTimeUTC,
            },
          };
        }),
        state,
      ),
    /*  pageAdapter.updateMany(
        Object.values(state.entities).map((stateTestItemModel) => {
          const changedTestItemModel =
            testItemModels.find(
              (testItemModel) =>
                (testItemModel?.id ?? '') + (testItemModel?.iterator ? '&' + testItemModel.iterator : '') === stateTestItemModel?.id
            ) ?? stateTestItemModel;
          // Обновить время показа, если тест айтем был скрыт логикой, но потом стал видимым.
          return {
            id: stateTestItemModel?.id ?? '',
            changes: {
              ...changedTestItemModel,
              showStartTime:
                changedTestItemModel?.isShown && !stateTestItemModel?.isShown
                  ? DateTimeService.currentDateTimeUTC
                  : stateTestItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
            },
          };
        }),
        state
      )*/
  ),
  on(revalidatePageAnswersSuccessAction, (state, { isAnswersValid }): PageState => ({ ...state, isAnswersValid })),
  on(lockButtonNext, (state): PageState => ({ ...state, isTaskAnswerSaved: false })),
  on(changePageAnswerSuccessAction, (state): PageState => ({ ...state, isTaskAnswerSaved: true })),
  on(
    finishMobileTestAction,
    (state): PageState => ({
      ...state,
      isPageChanging: false,
      currentPage: null,
      pageShowStartTime: null,
    }),
  ),
  on(
    screenOutAction,
    (state): PageState => ({
      ...state,
      isPageChanging: false,
      currentPage: null,
      pageShowStartTime: null,
    }),
  ),
);

export const pageReducers = (state: PageState, action: Action): PageState => reducer(state, action);
