import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../action-types';
import { TestItemStatistics } from 'src/api/testrunner/models/test-item-statistics';
import { Layout } from '../../../api/testrunner/models/layout';

export const getBalancingStatisticsSuccessAction = createAction(
  ActionTypes.GET_BALANCING_STATISTICS_SUCCESS,
  props<{ page: Layout | null; testItemStatistics: Array<TestItemStatistics>; lastUpdate?: number }>()
);
export const getLayoutStatisticsSuccessAction = createAction(
  ActionTypes.GET_LAYOUT_LOGIC_STATISTICS_SUCCESS,
  props<{ testItemStatistics: Array<TestItemStatistics>; lastUpdate?: number }>()
);
export const getPageItemsStatisticsSuccessAction = createAction(
  ActionTypes.GET_PAGE_ITEMS_STATISTICS_SUCCESS,
  props<{ testItemStatistics: Array<TestItemStatistics>; lastUpdate?: number }>()
);
