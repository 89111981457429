/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { ScreenRecordingMethodEnum } from '../models/screen-recording-method-enum';
import { VideoMetadata } from '../models/video-metadata';
import { VideoMetadataTypeEnum } from '../models/video-metadata-type-enum';

@Injectable({
  providedIn: 'root',
})
export class VideoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation videoSaveVideos
   */
  static readonly VideoSaveVideosPath = '/api/v1/Videos';

  /**
   * Сохраняем видео. Массив videos и videoFiles должны иметь одинаковую длинну.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoSaveVideos()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSaveVideos$Response(params: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Массив метаданных видео
     */
    videos: Array<VideoMetadata>;

    /**
     * Является ли пачка финальной для текущего шага. На основе него запускается генерация видео
     */
    final?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {
'videoFiles': Array<Blob>;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VideoService.VideoSaveVideosPath, 'post');
    if (params) {
      rb.query('testId', params.testId, {});
      rb.query('videos', params.videos, {});
      rb.query('final', params.final, {});
      rb.query('preview', params.preview, {});
      rb.query('screenRecordingMethod', params.screenRecordingMethod, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Сохраняем видео. Массив videos и videoFiles должны иметь одинаковую длинну.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoSaveVideos$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSaveVideos(params: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Массив метаданных видео
     */
    videos: Array<VideoMetadata>;

    /**
     * Является ли пачка финальной для текущего шага. На основе него запускается генерация видео
     */
    final?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {
'videoFiles': Array<Blob>;
}
  }): Observable<void> {

    return this.videoSaveVideos$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation videoSaveVideo
   */
  static readonly VideoSaveVideoPath = '/api/v1/Videos/single';

  /**
   * Сохраняем одно видео.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoSaveVideo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSaveVideo$Response(params?: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Является ли пачка финальной для текущего шага. На основе него запускается генерация видео
     */
    final?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {

/**
 * TestItemId
 */
'testItemId'?: string;

/**
 * Id повторения
 */
'iterationId'?: string;

/**
 * Клиентское UTC время начала записи
 */
'recordStartedClientTimeUtc'?: string;

/**
 * Клиентский часовой пояс выраженный в виде смещения от UTC в минутах
 */
'clientTimeZoneMinutes'?: number;

/**
 * Mimetype видео файла
 */
'mimeType'?: string;

/**
 * Тип видео
 */
'type'?: VideoMetadataTypeEnum;
'videoFile'?: Blob;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VideoService.VideoSaveVideoPath, 'post');
    if (params) {
      rb.query('testId', params.testId, {});
      rb.query('final', params.final, {});
      rb.query('preview', params.preview, {});
      rb.query('screenRecordingMethod', params.screenRecordingMethod, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Сохраняем одно видео.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoSaveVideo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSaveVideo(params?: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Является ли пачка финальной для текущего шага. На основе него запускается генерация видео
     */
    final?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {

/**
 * TestItemId
 */
'testItemId'?: string;

/**
 * Id повторения
 */
'iterationId'?: string;

/**
 * Клиентское UTC время начала записи
 */
'recordStartedClientTimeUtc'?: string;

/**
 * Клиентский часовой пояс выраженный в виде смещения от UTC в минутах
 */
'clientTimeZoneMinutes'?: number;

/**
 * Mimetype видео файла
 */
'mimeType'?: string;

/**
 * Тип видео
 */
'type'?: VideoMetadataTypeEnum;
'videoFile'?: Blob;
}
  }): Observable<void> {

    return this.videoSaveVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation videoSavePartVideo
   */
  static readonly VideoSavePartVideoPath = '/api/v1/Videos/part';

  /**
   * Сохранение чанка видео.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `videoSavePartVideo()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSavePartVideo$Response(params?: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Id видео, должен быть уникальным для каждого видео. На основании него бекенд собирает chunkи в одно общее видео.
     */
    videoId?: string;

    /**
     * long - порядковый индекс байта, с которого начинается переданная часть видео
     */
    fromByteIndex?: number;

    /**
     * long - длинна видео файла в байтах
     */
    partByteLength?: number;

    /**
     * Является ли кусок видео последним для текущего шага. На основе него запускается генерация видео
     */
    isLastVideoPart?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {

/**
 * TestItemId
 */
'testItemId'?: string;

/**
 * Id повторения
 */
'iterationId'?: string;

/**
 * Клиентское UTC время начала записи
 */
'recordStartedClientTimeUtc'?: string;

/**
 * Клиентский часовой пояс выраженный в виде смещения от UTC в минутах
 */
'clientTimeZoneMinutes'?: number;

/**
 * Mimetype видео файла
 */
'mimeType'?: string;

/**
 * Тип видео
 */
'type'?: VideoMetadataTypeEnum;
'videoFile'?: Blob;
}
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VideoService.VideoSavePartVideoPath, 'post');
    if (params) {
      rb.query('testId', params.testId, {});
      rb.query('videoId', params.videoId, {});
      rb.query('fromByteIndex', params.fromByteIndex, {});
      rb.query('partByteLength', params.partByteLength, {});
      rb.query('isLastVideoPart', params.isLastVideoPart, {});
      rb.query('preview', params.preview, {});
      rb.query('screenRecordingMethod', params.screenRecordingMethod, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Сохранение чанка видео.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `videoSavePartVideo$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  videoSavePartVideo(params?: {

    /**
     * тест Id
     */
    testId?: string;

    /**
     * Id видео, должен быть уникальным для каждого видео. На основании него бекенд собирает chunkи в одно общее видео.
     */
    videoId?: string;

    /**
     * long - порядковый индекс байта, с которого начинается переданная часть видео
     */
    fromByteIndex?: number;

    /**
     * long - длинна видео файла в байтах
     */
    partByteLength?: number;

    /**
     * Является ли кусок видео последним для текущего шага. На основе него запускается генерация видео
     */
    isLastVideoPart?: boolean;

    /**
     * включён ли превью режим
     */
    preview?: boolean;

    /**
     * Метод записи видео
     */
    screenRecordingMethod?: ScreenRecordingMethodEnum;
    body?: {

/**
 * TestItemId
 */
'testItemId'?: string;

/**
 * Id повторения
 */
'iterationId'?: string;

/**
 * Клиентское UTC время начала записи
 */
'recordStartedClientTimeUtc'?: string;

/**
 * Клиентский часовой пояс выраженный в виде смещения от UTC в минутах
 */
'clientTimeZoneMinutes'?: number;

/**
 * Mimetype видео файла
 */
'mimeType'?: string;

/**
 * Тип видео
 */
'type'?: VideoMetadataTypeEnum;
'videoFile'?: Blob;
}
  }): Observable<void> {

    return this.videoSavePartVideo$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
