import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { selectTestCompletionProgress } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-test-completion-progress-bar',
  templateUrl: './test-completion-progress-bar.component.html',
  styleUrls: ['./test-completion-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestCompletionProgressBarComponent {
  public readonly testCompletionProgress$: Observable<number | null> = this.store.select(selectTestCompletionProgress);

  constructor(private readonly store: Store) {}
}
