import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { AnswerUtilsService } from './answer-utils.service';
import { DictionaryToolsService } from './dictionary-tools.service';
import { FabDictionaryItem } from '../../api/testrunner/models/fab-dictionary-item';
import { FirstClickResult } from '../../api/testrunner/models/first-click-result';
import { FreeFormResult } from '../../api/testrunner/models/free-form-result';
import { MacrosParams, MacrosValue } from '../models/macros-params';
import { MatrixResult } from '../../api/testrunner/models/matrix-result';
import { NumberResult } from '../../api/testrunner/models/number-result';
import { PersistenceService } from './persistence.service';
import { PipingItem } from '../models/piping-item';
import { Task } from '../../api/testrunner/models/task';
import { ScaleResult, TestRunnerStartData } from '../../api/testrunner/models';
import { scriptErrorsAction } from '../store/actions/test-runner.actions';
import { TestItem } from '../../api/testrunner/models/test-item';
import { TestItemTypeEnum } from '../../api/testrunner/models/test-item-type-enum';
import { InfoExtendedResult } from '../models/info-extended-result';

@Injectable({
  providedIn: 'root',
})
export class MacrosService {
  readonly regex = /{$[^{}$]*\$}/gm;

  private testRunnerStartData?: TestRunnerStartData;
  private macrosDictionary: MacrosParams = {};
  private testItemOrderedList: Array<TestItem> = new Array<TestItem>();

  constructor(
    private answerUtilsService: AnswerUtilsService,
    private dictionaryToolsService: DictionaryToolsService,
    private persistenceService: PersistenceService,
    private store: Store
  ) {}

  clearMacros(macrosText: string): string {
    macrosText = macrosText
      .replace(/([^\\:]|^)\/\/.*$/gm, '')
      .replace(/&quot;/gm, '"')
      .replace(/&nbsp;/gm, '')
      .replace(/&#39;/gm, `'`)
      .replace(/&lt;/gm, '<')
      .replace(/&gt;/gm, '>')
      .replace(/(?:\r\n|\r|\n)/gm, '');
    //.replace(/<[^>]+>/g, '');

    return macrosText;
  }

  /*
   * Метод заполнения массива объектов вида {macrosKey: macrosValue string | object}
   * */
  getMacrosDictionary(currentItem?: PipingItem): MacrosParams {
    // console.log('this.store.test', this.store.test);

    // console.log('testItemOrderedList', this.testItemOrderedList);

    // SYSTEM PARAMS
    this.macrosDictionary.userId = this.persistenceService.get('anonymousId') ?? '';
    this.macrosDictionary.shortId = this.persistenceService.get('anonymousId') ?? '';
    this.macrosDictionary.currentPageId = this.persistenceService.get('test_' + this.testRunnerStartData?.test?.id)?.currentPageId ?? '';
    this.macrosDictionary.currentItem = currentItem ?? null;
    this.macrosDictionary.userIp = this.testRunnerStartData?.ip ?? '';
    this.macrosDictionary.userQuery = this.testRunnerStartData?.query ?? '';
    this.macrosDictionary.userOs = this.testRunnerStartData?.osSystemName ?? '';
    this.macrosDictionary.userBrowser = this.testRunnerStartData?.browserSystemName ?? '';
    this.macrosDictionary.userBrowserVersion = this.testRunnerStartData?.browserVersion ?? '';

    // QUERY PARAMS
    const queryParamsDictionary: MacrosParams = {};
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.forEach((value, key) => {
      queryParamsDictionary[key] = value;
    });
    this.macrosDictionary.Query = queryParamsDictionary;

    // PIPING
    const pipingDictionaryId = !this.guidIsEmpty(currentItem?.iteratorSourceFabDictionaryId)
      ? currentItem?.iteratorSourceFabDictionaryId
      : this.getIteratorDictionaryId(currentItem?.id ?? '');
    const iteratorDictionaryItem = (this.getDictionaryItemsById(pipingDictionaryId ?? '') ?? []).find(
      (dictionaryItem) => dictionaryItem.entityId === currentItem?.iterationId
    );

    this.macrosDictionary.I = iteratorDictionaryItem?.captionRu ?? '';

    (currentItem?.testItemIteratorCells || [])
      .filter((iteratorCell) => iteratorCell.dictionaryEntityId === currentItem?.iterationId)
      .forEach((iteratorCell) => {
        this.macrosDictionary['I' + ((iteratorCell.order ?? 0) + 1)] = iteratorCell.value ?? '';
      });

    // ANSWERS
    const guidAnswers: MacrosParams = {};
    const shortAnswers: MacrosParams = {};

    this.testItemOrderedList.forEach((testItem) => {
      const pipingDictionary = this.getDictionaryItemsById(testItem?.iteratorSourceFabDictionaryId ?? '');
      if (pipingDictionary.length) {
        pipingDictionary.forEach((dictionaryItem) => {
          guidAnswers[(testItem.id ?? '') + '&' + (dictionaryItem.entityId ?? '')] = this.parseAnswer(testItem, dictionaryItem.entityId);
          shortAnswers[(testItem.shortId ?? '') + '&' + (dictionaryItem.entityId ?? '')] = this.parseAnswer(
            testItem,
            dictionaryItem.entityId
          );
        });
      } else {
        guidAnswers[testItem.id ?? ''] = this.parseAnswer(testItem);
        shortAnswers[testItem.shortId ?? ''] = this.parseAnswer(testItem);
      }
    });

    this.macrosDictionary.Q = guidAnswers;
    this.macrosDictionary.q = shortAnswers;

    this.testItemOrderedList
      .filter((testItem) => this.isTask(testItem.testItemType))
      .forEach((testItem, index) => {
        const pipingDictionaryItems = this.getDictionaryItemsById(testItem?.iteratorSourceFabDictionaryId ?? '');
        if (pipingDictionaryItems?.length) {
          pipingDictionaryItems.forEach((dictionaryItem, iteratorIndex) => {
            this.macrosDictionary['Q' + (index + 1) + 'I' + (iteratorIndex + 1)] = this.parseAnswer(testItem, dictionaryItem.entityId);
          });
        } else {
          this.macrosDictionary['Q' + (index + 1)] = this.parseAnswer(testItem);
        }
      });

    // URL
    this.macrosDictionary.URL = this.getURL();

    return this.macrosDictionary;
  }

  evalScript(script: string, testItem?: PipingItem): unknown {
    const sanitizedScript = this.clearMacros(script);

    const macrosDictionary = this.getMacrosDictionary(testItem);
    console.log('macrosDictionary', macrosDictionary);

    const args: string[] = [];

    const values: Array<MacrosValue> = [];

    Object.keys(macrosDictionary)?.forEach((key: string) => {
      args.push(key);
      values.push(macrosDictionary[key]);
    });

    try {
      const code = new Function(args.join(', '), `console.log("Выполнение кастомного скрипта..."); ${sanitizedScript}`);
      return code(...values);
    } catch (error) {
      this.handleScriptError(error as Error, testItem?.id);
      return undefined;
    }
  }

  handleScriptError(error: Error, sourceItemId?: string): void {
    console.error(error);
    this.store.dispatch(scriptErrorsAction({ error: { message: error.message, name: error.name, stack: error.stack }, sourceItemId }));
  }

  initialize(testRunnerStartData: TestRunnerStartData): void {
    this.testRunnerStartData = testRunnerStartData;
    // построим список шагов
    this.testItemOrderedList = this.buildTestItemOrderedList(testRunnerStartData?.test?.rootGroup?.groupItems ?? []);
  }

  private buildTestItemOrderedList(testItems: Array<TestItem>, pipingDictionaryId: string | null = null): Array<TestItem> {
    return testItems.reduce(
      (testItemsOrdered, testItem) => [
        ...testItemsOrdered,
        { ...testItem, iteratorSourceFabDictionaryId: pipingDictionaryId ?? testItem.iteratorSourceFabDictionaryId },
        ...this.buildTestItemOrderedList(
          testItem?.groupItems ?? [],
          pipingDictionaryId ?? !this.guidIsEmpty(testItem.iteratorSourceFabDictionaryId) ? testItem.iteratorSourceFabDictionaryId : null
        ).sort((a, b) => a.order - b.order),
      ],
      new Array<TestItem>()
    );
  }

  private getDictionaryItemsById(id: string): Array<FabDictionaryItem> {
    const dictionary = this.testRunnerStartData?.dictionaries?.find((item) => id === item.id);
    return dictionary ? this.dictionaryToolsService.getFlattenDictionaryItems(dictionary.fabDictionaryItems ?? []) : [];
  }

  private getImgTag(url?: string | null): string {
    return url ? `<img alt="" src="${url}">` : '';
  }

  private getIteratorDictionaryId(testItemId: string | null): string | null {
    return this.testItemOrderedList.find((testItem) => testItem.id === testItemId)?.iteratorSourceFabDictionaryId ?? null;
  }

  private getURL(): {} {
    return {
      cheaterUrl: this.testRunnerStartData?.test?.cheaterUrl ?? '',
      filtersNotPassedUrl: this.testRunnerStartData?.test?.filtersNotPassedUrl ?? '',
      finishUrl: this.testRunnerStartData?.test?.finishUrl ?? '',
      lateUserUrl: this.testRunnerStartData?.test?.lateUserUrl ?? '',
    };
  }

  private guidIsEmpty = (guid?: string | null): boolean => '00000000-0000-0000-0000-000000000000' === guid || !guid;

  private isTask(testItemType?: TestItemTypeEnum): boolean {
    return (
      !!testItemType &&
      [
        TestItemTypeEnum.CardSorting,
        TestItemTypeEnum.FirstClick,
        TestItemTypeEnum.FirstGlance,
        TestItemTypeEnum.FreeForm,
        TestItemTypeEnum.Info,
        TestItemTypeEnum.Matrix,
        TestItemTypeEnum.Number,
        TestItemTypeEnum.Ranking,
        TestItemTypeEnum.Scale,
        TestItemTypeEnum.Select,
      ].includes(testItemType)
    );
  }

  private parseAnswer(testItem: TestItem, iterationId?: string): { [p: string]: MacrosValue } {
    let rowDictionaryItems = new Array<FabDictionaryItem>();
    let columnDictionaryItems = new Array<FabDictionaryItem>();

    const answerObj: { [key: string]: MacrosValue } = { answer: '' };

    if (!testItem) {
      return answerObj;
    }

    const answerKey = 'answer_' + testItem?.id + (iterationId ? '&' + iterationId : '');
    const testItemAnswer = this.persistenceService.get(answerKey);

    answerObj.id = testItem.id ?? '';
    answerObj.task = testItem;
    answerObj.type = testItem?.testItemType ?? TestItemTypeEnum.None;

    if (testItem && testItem.id && testItemAnswer) {
      answerObj.isRightAnswer = this.answerUtilsService.isRightAnswer(testItemAnswer);

      switch (testItem.testItemType) {
        case TestItemTypeEnum.FirstClick: {
          answerObj.x = (testItemAnswer as FirstClickResult).cursorPositionX?.toString() ?? '';
          answerObj.y = (testItemAnswer as FirstClickResult).cursorPositionY?.toString() ?? '';
          break;
        }
        case TestItemTypeEnum.FreeForm: {
          answerObj.answer = (testItemAnswer as FreeFormResult).elementStrings?.[0] ?? '';
          break;
        }
        case TestItemTypeEnum.Info: {
          answerObj.promoCode = (testItemAnswer as InfoExtendedResult).promoCode;
          break;
        }
        case TestItemTypeEnum.Number: {
          answerObj.answer = (testItemAnswer as NumberResult).answer?.toString() ?? '';
          break;
        }
        case TestItemTypeEnum.Matrix: {
          rowDictionaryItems = this.getDictionaryItemsById((testItem as Task)?.rowFabDictionaryId ?? '');
          columnDictionaryItems = this.getDictionaryItemsById((testItem as Task)?.columnFabDictionaryId ?? '');

          answerObj.answers2d = ((testItemAnswer as MatrixResult)?.elementItems || []).map((answerItem) => ({
            row: rowDictionaryItems.find((dictionaryItem) => answerItem.rowElementItemId === dictionaryItem.entityId)?.captionRu ?? '',
            column:
              columnDictionaryItems.find((dictionaryItem) => answerItem.columnElementItemId === dictionaryItem.entityId)?.captionRu ?? '',
          })) as [];
          break;
        }
        case TestItemTypeEnum.Ranking: {
          rowDictionaryItems = this.getDictionaryItemsById((testItem as Task)?.rowFabDictionaryId ?? '')
            .filter((fabDictionaryItem) => (testItemAnswer.elementRowItemIds || []).includes(fabDictionaryItem.entityId))
            .sort(
              (a, b) =>
                (testItemAnswer.elementRowItemIds || []).indexOf(a.entityId ?? '') -
                (testItemAnswer.elementRowItemIds || []).indexOf(b.entityId ?? '')
            );

          answerObj.answers = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.captionRu) as [];
          answerObj.answerImgUrl = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.imageUrl).join(', ') ?? '';
          answerObj.answerImgId = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.entityId).join(', ') ?? '';
          answerObj.answerImgTag =
            rowDictionaryItems
              ?.map((fabDictionaryItem) => (fabDictionaryItem.imageUrl ? this.getImgTag(fabDictionaryItem.imageUrl) : ''))
              .join(', ') ?? '';
          break;
        }
        case TestItemTypeEnum.Scale: {
          rowDictionaryItems = this.getDictionaryItemsById((testItem as Task)?.rowFabDictionaryId ?? '');

          answerObj.answers2d = ((testItemAnswer as ScaleResult)?.resultItems || []).map((answerItem) => ({
            row: rowDictionaryItems.find((dictionaryItem) => answerItem.elementItemId === dictionaryItem.entityId)?.captionRu ?? '',
            column: answerItem.answer,
          })) as [];
          break;
        }
        case TestItemTypeEnum.Select: {
          rowDictionaryItems = this.getDictionaryItemsById((testItem as Task)?.rowFabDictionaryId ?? '').filter((fabDictionaryItem) =>
            (testItemAnswer.elementRowItemIds || []).includes(fabDictionaryItem.entityId)
          );

          answerObj.answers = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.captionRu) as [];
          answerObj.answerImgUrl = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.imageUrl).join(', ') ?? '';
          answerObj.answerImgId = rowDictionaryItems?.map((fabDictionaryItem) => fabDictionaryItem.entityId).join(', ') ?? '';
          answerObj.answerImgTag =
            rowDictionaryItems
              ?.map((fabDictionaryItem) => (fabDictionaryItem.imageUrl ? this.getImgTag(fabDictionaryItem.imageUrl) : ''))
              .join(', ') ?? '';
          break;
        }
        default:
          break;
      }
    }

    return answerObj;
  }
}
