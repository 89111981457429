import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { TestItemResult } from 'src/api/testrunner/models/test-item-result';

export const answersAdapter: EntityAdapter<TestItemResult> = createEntityAdapter<TestItemResult>({
  selectId: (item) => item.testItemId + (item.iterationId ? '&' + item.iterationId : ''),
  sortComparer: false,
});

export interface AnswersState extends EntityState<TestItemResult> {
  answersQueue: Array<TestItemResult>;
  sendAnswersAttempt: number;
}
