import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-microphone-settings',
  templateUrl: './microphone-settings.component.html',
  styleUrls: ['./microphone-settings.component.scss'],
})
export class MicrophoneSettingsComponent implements OnInit {
  @Input() isNeedRequest!: boolean;
  @Output() selectMicrophoneId: EventEmitter<string | null> = new EventEmitter<string | null>();

  isMicrophoneRequested = false;
  microphoneDevices: Array<MediaDeviceInfo> = new Array<MediaDeviceInfo>();
  selectedMicrophoneDeviceId: string | null = null;

  private audioStream: MediaStream | null = null;

  constructor() {}

  ngOnInit(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.audioStream = stream;
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            console.log(devices);
            this.microphoneDevices = devices.filter((device) => device.kind === 'audioinput');
          })
          .catch((error) => console.error(error));
      });
    }
  }

  requestPermissions(): void {
    //this.store.dispatch(setMediaDevices({ microphoneDeviceId: this.selectedMicrophoneDeviceId ?? null }));
    this.selectMicrophoneId.emit(this.selectedMicrophoneDeviceId);
    this.isMicrophoneRequested = true;
  }

  selectMicrophone(deviceId: string): void {
    console.log('mic', deviceId);
  }
}
