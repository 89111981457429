<ng-container *transloco="let t">
  <div class="flex align-items-center justify-content-start mb-3">
    <span class="border-round-6px bg-gray-50 px-2 py-1 text-base">
      <span class="text-gray-700 mr-1">{{ feature.order + 1 }}</span>
      <span class="text-gray-500">{{ t('testrunner.kano.progress', {total: featuresCount}) }}</span>
    </span>
    <span class="font-bold text-lg ml-2">{{ feature.name }}</span>
  </div>

  <p [innerHTML]="feature.description"></p>

  <form [formGroup]="kanoForm">

    <!-- Функциональный вопрос -->
    <div class="mt-5" [innerHTML]="t('testrunner.kano.functional.question')"></div>
    <div class="flex flex-column mt-3">
      <div
        *ngFor="let score of kanoFeatureScores"
        class="p-field-checkbox justify-content-start border-round-6px w-full bg-gray-50 p-2 mt-1"
      >
        <label class="pt-2 pb-2 answer-label flex align-items-center justify-content-start">
          <p-radioButton
            formControlName="functionalQuestionScore"
            name="functionalQuestionScore"
            [inputId]="'functional_' + kanoFeatureScoreEnum[score]"
            [value]="score"
          ></p-radioButton>
          <label [for]="'functional_' + kanoFeatureScoreEnum[score]" class="ml-2" style="pointer-events: none;">
            {{ t('testrunner.kano.functional.' + kanoFeatureScoreEnum[score]) }}
          </label>
        </label>
      </div>
    </div>

    <!-- Дисфункциональный вопрос -->
    <div class="mt-5" [innerHTML]="t('testrunner.kano.dysfunctional.question')"></div>
    <div class="flex flex-column mt-3">
      <div
        *ngFor="let score of kanoFeatureScores"
        class="p-field-checkbox justify-content-start border-round-6px w-full bg-gray-50 p-2 mt-1">
        <label class="pt-2 pb-2 answer-label flex align-items-center justify-content-start">
          <p-radioButton
            formControlName="dysfunctionalQuestionScore"
            name="dysfunctionalQuestionScore"
            [inputId]="'dysfunctional_' + kanoFeatureScoreEnum[score]"
            [value]="score"
          ></p-radioButton>
          <label [for]="'dysfunctional_' + kanoFeatureScoreEnum[score]" class="ml-2" style="pointer-events: none;">
            {{ t('testrunner.kano.dysfunctional.' + kanoFeatureScoreEnum[score]) }}
          </label>
        </label>
      </div>
    </div>

    <!-- Оценка важности -->
    <ng-container *ngIf="isImportanceQuestionEnabled">
      <div class="mt-5" [innerHTML]="t('testrunner.kano.importance.question')"></div>
      <div class="grid mx-0 mt-3 w-full flex-column-reverse sm:flex-row-reverse sm:flex-nowrap sm:border-round-6px sm:overflow-hidden">
        <div
          *ngFor="let score of kanoFeatureScores"
          class="bg-gray-50 p-2 mt-1 sm:mt-0 max-w-full flex justify-content-start sm:justify-content-center sm:align-items-end
            border-round-6px sm:border-noround importance-answer"
        >
          <label
            class="pt-2 pb-2 answer-label flex align-items-center justify-content-start sm:justify-content-center
              sm:flex-column-reverse sm:text-center"
          >
            <p-radioButton
              class="justify-content-center"
              formControlName="valueQuestionScore"
              name="valueQuestionScore"
              [inputId]="'importance_' + kanoFeatureScoreEnum[score]"
              [value]="score"
            ></p-radioButton>
            <label [for]="'importance_' + kanoFeatureScoreEnum[score]" class="ml-2 sm:ml-0 sm:mb-2" style="pointer-events: none;">
              {{ t('testrunner.kano.importance.' + kanoFeatureScoreEnum[score]) }}
            </label>
          </label>
        </div>
      </div>
    </ng-container>

  </form>

</ng-container>
