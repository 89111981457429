/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DictionariesService } from './services/dictionaries.service';
import { PromoCodeService } from './services/promo-code.service';
import { TestItemStatisticsService } from './services/test-item-statistics.service';
import { SessionService } from './services/session.service';
import { ResultService } from './services/result.service';
import { TokenService } from './services/token.service';
import { WebSiteExtensionService } from './services/web-site-extension.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DictionariesService,
    PromoCodeService,
    TestItemStatisticsService,
    SessionService,
    ResultService,
    TokenService,
    WebSiteExtensionService,
    ApiConfiguration,
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' + 'See also https://github.com/angular/angular/issues/20575',
      );
    }
  }
}
