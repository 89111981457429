/* eslint-disable @typescript-eslint/naming-convention */
export enum MessageEventDataEnum {
  ExtensionInstalled = 'Fabuza non moderated extension installed',
  ExtensionFailedCapturePermission = 'Fabuza non moderated extension error capture permission',
  ExtensionTaskNotStarted = 'Fabuza non moderated extension task not started',
  ExtensionTaskInProgress = 'Fabuza non moderated extension task in progress',
  ExtensionTaskCompleted = 'Fabuza non moderated extension task completed',
  ExtensionTaskSkipped = 'Fabuza non moderated extension task skipped',
  ExtensionUploadingVideoInProgress = 'Fabuza non moderated extension uploading video in progress',
  ExtensionUploadingVideoCompleted = 'Fabuza non moderated extension uploading video completed',
  MobileAppInstalled = 'Fabuza mobile app installed',
  MobileAppTaskInProgress = 'Fabuza mobile app task in progress',
  MobileAppTaskCompleted = 'Fabuza mobile app task completed',
  MobileAppTestFinished = 'Fabuza mobile app test finished',
  MobileAppUploadingVideoInProgress = 'Fabuza mobile app uploading video in progress',
  MobileAppUploadingVideoCompleted = 'Fabuza mobile app uploading video completed',
}
