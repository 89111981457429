import { Injectable } from '@angular/core';
import { FabDictionaryItem } from '../../api/testrunner/models/fab-dictionary-item';
import { TestItemDictionaryItem } from '../../api/testrunner/models/test-item-dictionary-item';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  static shuffleItems(items: FabDictionaryItem[], lockedItems?: TestItemDictionaryItem[] | null): void {
    for (let i = items.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      if (!lockedItems?.some((lockedItem) => [items[i].entityId, items[j].entityId].includes(lockedItem.fabDictionaryItemEntityId))) {
        [items[i], items[j]] = [items[j], items[i]];
      }
    }
  }
}
