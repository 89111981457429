import { DeviceDetectorService } from 'ngx-device-detector';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';
import { MacrosParams } from '../models/macros-params';
import { Mobile } from '../../api/testrunner/models/mobile';
import { MobileAppActionEnum } from '../models/mobile-app-action-enum';
import { MobilePostMessage } from '../models/mobile-post-message';
import { MobileTaskTypeEnum } from '../../api/testrunner/models/mobile-task-type-enum';
import { RecordMode } from '../../api/testrunner/models/record-mode';
import { PersistenceService } from './persistence.service';
import { PipingItem } from '../models/piping-item';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private _finishUrl: string | null = null;

  constructor(private deviceDetectorService: DeviceDetectorService, private persistenceService: PersistenceService) {}

  set finishUrl(_finishUrl: string | null) {
    this._finishUrl = _finishUrl;
  }

  postMessage(action: MobileAppActionEnum, mobile?: Mobile, macrosData?: MacrosParams): void {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    const appUrl = deviceInfo?.os === 'iOS' ? mobile?.iosUrl : mobile?.androidUrl;
    const appName = deviceInfo?.os === 'iOS' ? mobile?.iosAppName : mobile?.androidAppName;
    const appPackageName = deviceInfo?.os === 'iOS' ? mobile?.iosPackageName : mobile?.androidPackageName;
    const message: MobilePostMessage = {
      action,
      token: this.persistenceService.get('testRunnerToken'),
      isMobileAppTest: mobile?.mobileTestType === MobileTaskTypeEnum.AppTest,
      isMobileSiteTest: mobile?.mobileTestType === MobileTaskTypeEnum.WebTest,
      isScreenRecording: mobile?.recordScreenMode === RecordMode.Enabled,
      isCameraRecording: mobile?.recordCameraMode === RecordMode.Enabled,
      isVoiceRecording: mobile?.recordVoiceMode === RecordMode.Enabled,
      finishUrl: this._finishUrl,
      macrosData,
      telemetryApiUrl: environment.telemetryApiUrl,
    };
    if (mobile) {
      message.task = {
        id: mobile?.id,
        mobile: {
          mobileTestType: mobile?.mobileTestType,
          siteStartUrl: mobile?.startUrl,
          appUrl,
          appName,
          appPackageName,
          customCss: mobile.customCSS as string,
          customJs: mobile.customJs as string,
          scriptJsOnChange: mobile.scriptJsOnChange as string,
          scriptJsOnHide: mobile.scriptJsOnHide as string,
          scriptJsOnLoad: mobile.scriptJsOnLoad as string,
          injectCss: '',
          injectJs: '',
          panelPosition: mobile?.panelPosition,
          fullScreen: mobile?.fullScreen,
          finishTemplates: mobile?.finishTemplates,
          iterationId: (mobile as PipingItem)?.iterationId,
        },
      };
    }

    window.postMessage(message, window.origin);
  }
}
