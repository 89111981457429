import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FabDictionaryItem } from 'src/api/testrunner/models/fab-dictionary-item';
import { FormAnswerStructure } from 'src/api/testrunner/models/form-answer.interface';
import { Task } from 'src/api/testrunner/models/task';

@Component({
  selector: 'app-scale-standard',
  templateUrl: './scale-standard.component.html',
  styleUrls: ['./scale-standard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleStandardComponent {
  @Input({ required: true }) public columns: FabDictionaryItem[] = [];
  @Input({ required: true }) public formAnswer!: FormGroup<FormAnswerStructure>;
  @Input({ required: true }) public rows: FabDictionaryItem[] = [];
  @Input({ required: true }) public scaleData!: Task;
}
