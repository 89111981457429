<ng-container *transloco="let t">
  <div [innerHTML]="description" class="pb-4"></div>

  <ng-container *ngIf="!kano.isSeparatePagesDisplayEnabled; else wizard">
    <div *ngFor="let feature of kanoFeatures" class="py-4">
      <app-kano-feature
        [answer]="answerFeatures[feature.id]"
        [feature]="feature"
        [featuresCount]="kanoFeatures.length"
        [isImportanceQuestionEnabled]="kano?.isImportanceQuestionEnabled"
        (answerChange)="save($event)"
      ></app-kano-feature>
    </div>

    <ng-container *ngTemplateOutlet="controlButtons"></ng-container>
  </ng-container>

  <ng-template #wizard>
    <div
      [@fadeInLeft]="fadeInLeftAnimationTrigger"
      (@fadeInLeft.done)="fadeInLeftAnimationDone($event)"
      [@fadeInRight]="fadeInRightAnimationTrigger"
      (@fadeInRight.done)="fadeInRightAnimationDone($event)"
      [@fadeOutLeft]="fadeOutLeftAnimationTrigger"
      (@fadeOutLeft.done)="fadeOutLeftAnimationDone($event)"
      [@fadeOutRight]="fadeOutRightAnimationTrigger"
      (@fadeOutRight.done)="fadeOutRightAnimationDone($event)"
    >
      <ng-container *ngIf="activeFeature">
        <app-kano-feature
          [answer]="answerFeatures[activeFeature.id]"
          [feature]="activeFeature"
          [featuresCount]="kanoFeatures.length"
          [isImportanceQuestionEnabled]="kano?.isImportanceQuestionEnabled"
          (answerChange)="save($event)"
        ></app-kano-feature>

        <ng-container *ngTemplateOutlet="controlButtons"></ng-container>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #controlButtons>
    <div class="flex justify-content-end mb-5" [style.margin-top.rem]="7.5">
      <!--<button
        pButton
        pRipple
        type="button"
        class="p-button-success p-button-sm mr-2 mb-2"
        [class.hidden]="false"
        [label]="t('testrunner.page.prevPage')"
        icon="fa-regular fa-left-long"
        data-cy="back-button"
        (click)="back()"
      ></button>-->
      <button
        pButton
        pRipple
        class="p-button-success p-button-sm mb-2"
        [disabled]="!isNextButtonEnabled()"
        icon="fa-regular fa-right-long"
        iconPos="right"
        [label]="t('testrunner.page.nextPage')"
        type="button"
        (click)="next()"
      ></button>
    </div>
  </ng-template>
</ng-container>
