<ng-container *transloco="let t">
  <div *ngIf="deviceDetectorService.isDesktop(); else mobileBlock" class="p-text-center">
    <p>Перейдите в мобильный браузер</p>
    <qrcode
      [qrdata]="testUrl"
      [width]="256"
      errorCorrectionLevel="H"
    ></qrcode>
  </div>

  <ng-template #mobileBlock>
    <ng-container *ngIf="isMobileAppInstalled$ | async; else mobileAppInstallBlock">
      <div [innerHtml]="description" class="task-description"></div>

      <div *ngIf="mobile?.mobileTestType === mobileTaskTypeEnum.AppTest || mobile?.startUrl" class="p-text-center">
        <!--        <p-button (click)="backToTask()" styleClass="p-button-success">-->
        <!--          {{ store.mobileAppStatus === mobileAppStatusEnum.TaskInProgress ? t('testrunner.mobile.returnToAssignment') : t('testrunner.mobile.goToAssignment') }}-->
        <!--        </p-button>        -->
        <ng-container *ngIf="taskInProgress">
          <p-button (click)="backToTask()" styleClass="p-button-success" [label]="goToTaskButtonText" [disabled]="!canStartTask">
          </p-button>
        </ng-container>
        <div *ngIf="!taskInProgress" class="p-text-center">
          <p-button (click)="completeTask()" styleClass="p-button-success m-1" [label]="completeButtonText">
          </p-button>
          <p-button (click)="backToTask()" styleClass="p-button-success m-1" [label]="returnButtonText">
          </p-button>
          <p-button *ngIf="mobile?.isSkipButtonEnabled" (click)="skipTask()" styleClass="p-button-secondary m-1" [label]="skipButtonText"
                    [disabled]="!canStartTask">
          </p-button>
        </div>
      </div>
    </ng-container>
    <ng-template #mobileAppInstallBlock>
      <app-mobile-app-install
        *ngIf="deviceInfo.os === 'Android' || deviceInfo.os === 'iOS'; else deviceNotSupportedBlock"
        class="flex flex-column h-full w-full"
        [appUri]="appUri"
        [appInstallPageUrl]="appInstallPageUrl"
        [os]="deviceInfo.os"
      ></app-mobile-app-install>
      <ng-template #deviceNotSupportedBlock>
        <p>{{ t('testrunner.mobile.deviceNotSupported') }}</p>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-container>
