import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

import {
  backendErrorsAction,
  getWebSiteExtensionStartDataAction,
  getWebSiteExtensionStartDataSuccessAction,
} from '../actions/test-runner.actions';
import { WebSiteExtensionService } from 'src/api/testrunner/services/web-site-extension.service';

@Injectable()
export class WebSiteExtensionEffects {
  startSession$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getWebSiteExtensionStartDataAction),
      concatMap(({ testId, testItemId, apiVersion }) =>
        this.webSiteExtensionService.webSiteExtensionStartSession({ testId, testItemId, 'api-version': apiVersion }).pipe(
          map((webSiteExtensionStartData) => getWebSiteExtensionStartDataSuccessAction({ webSiteExtensionStartData })),

          catchError((errorResponse: unknown) => of(backendErrorsAction({ error: errorResponse as HttpErrorResponse })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private webSiteExtensionService: WebSiteExtensionService) {}
}
