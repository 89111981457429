<div class="image-container relative" [class]="styleClass">
  <div
    *ngIf="preview"
    pRipple
    class="flex cursor-pointer hover:surface-100 text-700 hover:text-800 w-2rem h-2rem border-round-6px z-1 absolute"
    style="top: .5rem; right: .5rem"
    (click)="isPreviewVisible = true"
  >
    <i class="fa-regular fa-arrows-maximize m-auto"></i>
  </div>
  <img
    [alt]="alt"
    class="block top-0"
    [attr.height]="height"
    [src]="src"
    [attr.width]="width"
    (load)="imageLoaded.emit()"
    loading="lazy"
  >
</div>
<div
  *ngIf="isPreviewVisible"
  class="p-image-mask p-component-overlay p-component-overlay-enter z-1 flex flex-column justify-content-center align-items-center absolute"
  (click)="isPreviewVisible = false"
>
  <div class="p-image-toolbar flex justify-content-end align-items-center absolute w-full z-2 top-0 p-3" (click)="$event.stopPropagation()">
    <button class="p-image-action p-link text-center" type="button" (click)="isPreviewVisible = false">
      <i class="pi pi-times"></i>
    </button>
  </div>
  <img [alt]="alt" [attr.src]="src" style="max-width: 94vw; max-height: 94vh"/>
</div>

