<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4 mb-2"></div>

  <form [formGroup]="selectForm" [class.disabled]="selectForm.controls.isAnswerSkipped.value">
    <ng-container></ng-container>

    <app-select-system-dictionary
      *ngIf="isSystemDictionary$ | async; else privateDictionary"
      [answerQuantity]="selectItem?.answerQuantity"
      [dictionaryName]="dictionaryName$ | async"
      [fabDictionaryItems]="dictionaryItems$ | async"
      [columnGridWidth]="columnGridWidth"
      [model]="testItemModel"
      formControlName="elementRowItemIds"
    ></app-select-system-dictionary>

    <ng-template #privateDictionary>
      <app-select-private-dictionary
        [answerQuantity]="selectItem?.answerQuantity"
        [fabDictionaryItems]="dictionaryItems$ | async"
        [columnGridWidth]="columnGridWidth"
        [model]="testItemModel"
        formControlName="elementRowItemIds"
      ></app-select-private-dictionary>
    </ng-template>

    <ng-container *ngIf="selectItem.customAnswersEnabled">
      <app-select-custom-answers
        [answerQuantity]="selectItem?.answerQuantity"
        [customAnswersLabel]="selectItem.customAnswersLabel ? selectItem.customAnswersLabel : t('testrunner.select.customAnswer')"
        [customAnswersMaxCount]="selectItem?.customAnswersMaxCount ?? 1"
        [model]="testItemModel"
        formControlName="elementStrings"
      ></app-select-custom-answers>
    </ng-container>
  </form>
  <p-checkbox
    *ngIf="selectItem.isSkipButtonEnabled"
    inputId="isSkipButtonEnabled"
    name="isSkipButtonEnabled"
    [binary]="true"
    [formControl]="selectForm.controls.isAnswerSkipped"
    [label]="selectItem.skipButtonText || t('testrunner.freeform.noAnswer')"
  ></p-checkbox>
</ng-container>
