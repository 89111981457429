import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { LanguageEnum } from '../../../api/testrunner/models/language-enum';
import { TranslationsService } from '../../services/translations.service';
import { setLoaderStatus } from '../../store/actions/test-runner.actions';

const DEFAULT_APK_NAME: string = 'Fabuza Moderation';

@Component({
  selector: 'app-installer-test-apk',
  templateUrl: './installer-test-apk.component.html',
  styleUrls: ['./installer-test-apk.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallerTestApkComponent {
  public readonly nameApk: string = this.route.snapshot?.queryParamMap.get('appName') ?? DEFAULT_APK_NAME;
  public readonly urlApk: string = decodeURIComponent(this.route.snapshot?.queryParamMap.get('apkUrl') ?? '');

  constructor(
    private readonly route: ActivatedRoute,
    translationsService: TranslationsService,
    store: Store,
  ) {
    store.dispatch(setLoaderStatus());
    translationsService.changeTranslation(
      Object.values(LanguageEnum).find((lang) => lang.toLowerCase() === this.route.snapshot?.queryParamMap.get('lang')?.toLowerCase()) ??
        LanguageEnum.En,
    );
  }

  public getUrlApk(): void {
    location.href = this.urlApk;
  }

  // Для получения размера файла
  // getFile(url: string, callback?: (arg0: number) => void): any {
  //   const xhr = new XMLHttpRequest();
  //   xhr.open('HEAD', url, true);
  //   xhr.onreadystatechange = function (data) {
  //     if (this.readyState === this.DONE) {
  //       console.log('DONE');
  //       if (callback) {
  //         callback(parseInt(xhr.getResponseHeader('Content-Length') ?? '', 10));
  //       }
  //       if (this.readyState === 4) {
  //         if (this.status === 200) {
  //         }
  //       }
  //     }
  //   };
  //   // xhr.onprogress = (event) => {
  //   //   console.log('event', event);
  //   // };
  //   xhr.send();
  // }
}
