<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>
  <div *ngIf="columns.length < maxColumns && rows.length < maxRows" [class.disabled]="formAnswer.controls.isAnswerSkipped.value">
    <ng-container [ngSwitch]="scaleData.scaleType">
      <app-scale-sliders
        *ngSwitchCase="scaleTypeEnum.Sliders"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
      ></app-scale-sliders>

      <app-scale-rating
        *ngSwitchCase="scaleTypeEnum.Rating"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
        (_onRatingSet)="onRatingSet($event)"
      ></app-scale-rating>

      <app-scale-emotions
        *ngSwitchCase="scaleTypeEnum.EmotionsColor"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
        [emotions]="emotions"
        (_onRatingSet)="onRatingSet($event)"
      ></app-scale-emotions>

      <app-scale-emotions
        *ngSwitchCase="scaleTypeEnum.EmotionsGray"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
        [emotions]="emotions"
        (_onRatingSet)="onRatingSet($event)"
      ></app-scale-emotions>

      <app-scale-nps
        *ngSwitchCase="scaleTypeEnum.NpsRadioButton"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
        [scaleData]="scaleData"
      ></app-scale-nps>

      <app-scale-standard
        *ngSwitchCase="scaleTypeEnum.Standard"
        [columns]="columns"
        [rows]="rows"
        [scaleData]="scaleData"
        [formAnswer]="formAnswer"
        [scaleData]="scaleData"
      >
      </app-scale-standard>
    </ng-container>

    <ng-container *ngIf="columns.length > maxColumns || rows.length > maxRows">
      {{ t('testrunner.largeMatrix') }}
    </ng-container>
  </div>
  <p-checkbox
    *ngIf="scaleData.isSkipButtonEnabled"
    inputId="isSkipButtonEnabled"
    name="isSkipButtonEnabled"
    [binary]="true"
    [formControl]="formAnswer.controls.isAnswerSkipped"
    [label]="scaleData.skipButtonText || t('testrunner.freeform.noAnswer')"
  ></p-checkbox>
</ng-container>
