import { Injectable } from '@angular/core';
import { OperatorEnum } from '../../api/testrunner/models/operator-enum';
import { LogicResultEnum } from '../models/logic-result-enum';

export interface DiapasonValue {
  from: number | null;
  to: number | null;
}

export interface OperandInterface {
  isArray: boolean;
  periodValue: DiapasonValue | null;
  values: Array<boolean | number | string>;
}

@Injectable({
  providedIn: 'root',
})
export class CompareHelperService {
  constructor() {}

  public static compare(
    leftOperand: OperandInterface | null,
    rightOperand: OperandInterface | null,
    operator: OperatorEnum,
    negative: boolean,
  ): LogicResultEnum {
    console.log('compare', leftOperand, negative ? 'NOT' : '', operator, rightOperand);

    if (!leftOperand || !rightOperand) {
      console.log('compare result', LogicResultEnum.logicUndefined);
      return LogicResultEnum.logicUndefined;
    }

    if ((leftOperand.values.length <= 0 && !leftOperand.periodValue) || (rightOperand.values.length <= 0 && !rightOperand.periodValue)) {
      console.log('compare result', LogicResultEnum.logicUndefined);
      return LogicResultEnum.logicUndefined;
    }

    let result: boolean;
    if (rightOperand.periodValue) {
      result = this.comparePeriods(leftOperand.values[0] as string | number, rightOperand.periodValue, operator);
    } else if (rightOperand.isArray || leftOperand.isArray) {
      result = this.compareArrays(leftOperand.values, rightOperand.values, operator);
    } else {
      result = this.compareScalar(leftOperand.values[0], rightOperand.values[0], operator);
    }
    console.log('compare result', (negative ? !result : result) ? LogicResultEnum.logicTrue : LogicResultEnum.logicFalse);
    return (negative ? !result : result) ? LogicResultEnum.logicTrue : LogicResultEnum.logicFalse;
  }

  private static compareScalar(
    leftOperand: string | number | boolean,
    rightOperand: string | number | boolean,
    operator: OperatorEnum,
  ): boolean {
    switch (operator) {
      case OperatorEnum.Equal:
        return leftOperand === rightOperand;
      case OperatorEnum.GreaterOrEqual:
        return +leftOperand >= +rightOperand;
      case OperatorEnum.LessOrEqual:
        return +leftOperand <= +rightOperand;
      case OperatorEnum.Greater:
        return +leftOperand > +rightOperand;
      case OperatorEnum.Less:
        return +leftOperand < +rightOperand;
      case OperatorEnum.Contains:
        return leftOperand.toString().includes(rightOperand.toString());
      case OperatorEnum.Contained:
        return rightOperand.toString().includes(leftOperand.toString());
      case OperatorEnum.Regex:
        // @TODO convert to REGEXP
        return leftOperand.toString().search(new RegExp(rightOperand.toString())) !== -1;
      default:
        return false;
    }
  }

  private static compareArrays(
    leftOperand: Array<string | number | boolean>,
    rightOperand: Array<string | number | boolean>,
    operator: OperatorEnum,
  ): boolean {
    const foundArray = rightOperand.filter((value) => leftOperand.includes(value));

    switch (operator) {
      case OperatorEnum.Equal:
        return leftOperand.length === foundArray.length && leftOperand.length === rightOperand.length;
      case OperatorEnum.Contained:
        return leftOperand.length === foundArray.length;
      case OperatorEnum.Intersect:
        return foundArray.length > 0 && foundArray.length <= leftOperand.length;
      default:
        return false;
    }
  }

  private static comparePeriods(leftOperand: string | number, rightOperand: DiapasonValue, operator: OperatorEnum): boolean {
    switch (operator) {
      case OperatorEnum.Contained:
        return (
          Number(leftOperand) >= (rightOperand.from ?? Number.MIN_SAFE_INTEGER) &&
          Number(leftOperand) <= (rightOperand.to ?? Number.MAX_SAFE_INTEGER)
        );
      default:
        return false;
    }
  }
}
