<ng-container *transloco="let t">
  <div>
    <div class="col-12 p-field-checkbox">
      <p-checkbox
        *ngIf="answerQuantity === answerQuantityEnum.Multiple"
        [binary]="true"
        [disabled]="isDisabled && !isCustomAnswerSelected"
        [label]="customAnswersLabel"
        [(ngModel)]="isCustomAnswerSelected"
        (ngModelChange)="showCustomAnswers()"
        class="w-full"
      ></p-checkbox>

      <p-radioButton
        *ngIf="answerQuantity === answerQuantityEnum.Single"
        [value]="true"
        [label]="customAnswersLabel"
        [(ngModel)]="isCustomAnswerSelected"
        (ngModelChange)="showCustomAnswers()"
        class="w-full"
      ></p-radioButton>
    </div>

    <ng-container>
      <div class="col-12" *ngIf="isCustomAnswerSelected">
        <div *ngFor="let answerControl of answersFormArray.controls; let i = index" class="mb-2">
          <div class="p-inputgroup">
            <input #answer pInputText type="text" class="w-full" [formControl]="answerControl" />
            <button
              *ngIf="answersFormArray.controls.length > 1"
              type="button"
              pButton
              pRipple
              icon="pi pi-times"
              (click)="removeAnswer(i)"
            ></button>
          </div>
        </div>
        <p-button
          *ngIf="
            !isDisabled &&
            answerQuantity === answerQuantityEnum.Multiple &&
            answersFormArray.controls.length > 0 &&
            answersFormArray.controls.length < customAnswersMaxCount
          "
          label="Добавить вариант"
          icon="pi pi-plus"
          iconPos="left"
          (onClick)="addAnswer()"
        ></p-button>
      </div>
    </ng-container>
  </div>
</ng-container>
