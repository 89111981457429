import { CdkDragMove } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { TestItemCard } from 'src/api/testrunner/models/test-item-card';

@Component({
  selector: 'app-draggable-card',
  templateUrl: './draggable-card.component.html',
  styleUrls: ['./draggable-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DraggableCardComponent {
  @Input() public card!: TestItemCard;
  @Input() public order?: number;
  @Input() public styleClass: string = '';
  @Output() public readonly dragMove: EventEmitter<CdkDragMove<TestItemCard> | null> = new EventEmitter<CdkDragMove<TestItemCard> | null>();
  @ViewChild('cardContainer') public cardContainer?: ElementRef;

  public cardHeight?: number;

  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public getTooltipActivationEvent(): 'focus' | 'hover' {
    return 'ontouchstart' in document.documentElement ? 'focus' : 'hover';
  }

  // Костыли, но иначе никак не работает - курсор всегда сбрасывается в default при перетаскивании
  public onDragReleased(): void {
    this.setDefaultCursor();
    this.dragMove.emit(null);
  }

  public onDragStarted(): void {
    this.setGrabbingCursor();
  }

  onImageLoaded(): void {
    this.cardHeight = this.cardContainer?.nativeElement?.offsetHeight;
  }

  private setDefaultCursor(): void {
    this.document.body.style.cursor = 'default';
  }

  private setGrabbingCursor(): void {
    this.document.body.style.cursor = 'grabbing';
  }
}
