import { Injectable } from '@angular/core';

import {
  BinaryOperatorEnum,
  Boolean,
  Comparison,
  ConditionPair,
  ConditionTypeEnum,
  DateAndTime,
  DateAndTimeDiapasonOperand,
  DictionaryTaskResult,
  FirstClickResult,
  FirstGlanceResult,
  FloatingPoint,
  FloatingPointDiapasonOperand,
  Function,
  Integer,
  IntegerDiapasonOperand,
  MatrixResult,
  Operand,
  OperandEnum,
  ScaleResult,
  SessionOperand,
  SourceItem,
  TestItemConditionValue,
  TestItemConditionValueTypeEnum,
  TestItemOperand,
  TestItemResult,
  TestItemResultFieldEnum,
  TestItemResultTypeEnum,
  TestItemStatistics,
  TestItemStatisticsTypeEnum,
  TestSessionEnvironmentFieldEnum,
  Text,
  TimeInterval,
  TimeIntervalDiapasonOperand,
} from 'src/api/testrunner/models';
import { CompareHelperService, DiapasonValue, OperandInterface } from './compare-helper.service';
import { DateTimeService } from './date-time.service';
import { LogicEnvironmentParams } from '../models/logic-environment-params';
import { LogicResultEnum } from '../models/logic-result-enum';
import { MacrosService } from './macros.service';
import { PersistenceService } from './persistence.service';
import { PipingItem } from '../models/piping-item';
import { AnswerUtilsService } from './answer-utils.service';

const answerLocalStoragePrefix = 'answer_';

@Injectable({
  providedIn: 'root',
})
export class LogicCalculationService {
  private _logicEnvironmentParams: LogicEnvironmentParams | null = null;

  constructor(
    private answerUtilsService: AnswerUtilsService,
    private macrosService: MacrosService,
    private persistenceService: PersistenceService
  ) {}

  set logicEnvironmentParams(_logicEnvironmentParams: LogicEnvironmentParams | null) {
    this._logicEnvironmentParams = _logicEnvironmentParams;
  }

  /**
   * Вычисление логики
   */
  calculateComparison(condition: Comparison): LogicResultEnum {
    const comparison = condition as Comparison;

    const [leftOperand, rightOperand] = [this.evalOperand(comparison.operandLeft), this.evalOperand(comparison.operandRight)];
    return CompareHelperService.compare(leftOperand, rightOperand, comparison.operator, comparison.negative);
  }

  calculateFunction(func: Function, iterationId: string | null): LogicResultEnum {
    if (func?.script) {
      const sourceItem = this._logicEnvironmentParams?.testItems[func?.testItemId ?? '']
        ? ({
            ...this._logicEnvironmentParams?.testItems[func?.testItemId ?? ''],
            iterationId,
          } as PipingItem)
        : undefined;

      const functionResult = this.macrosService.evalScript(func.script, sourceItem) as boolean;
      return functionResult ? LogicResultEnum.logicTrue : !functionResult ? LogicResultEnum.logicFalse : LogicResultEnum.logicUndefined;
    }
    return LogicResultEnum.logicUndefined;
  }

  calculateLogic(conditionPair: ConditionPair | null, iterationId: string | null, isLogicUndefinedIgnored: boolean): LogicResultEnum {
    if (conditionPair === null || (!conditionPair?.leftItem && !conditionPair?.rightItem)) {
      return LogicResultEnum.logicTrue;
    }

    let leftResult: LogicResultEnum | null = null;
    let rightResult: LogicResultEnum | null = null;

    if (conditionPair?.leftItem) {
      leftResult =
        conditionPair.leftItem?.testItemConditionType === ConditionTypeEnum.Item
          ? this.calculateComparison(conditionPair.leftItem as Comparison)
          : conditionPair.leftItem?.testItemConditionType === ConditionTypeEnum.Function
          ? this.calculateFunction(conditionPair.leftItem as Function, iterationId)
          : this.calculateLogic(conditionPair.leftItem as ConditionPair, iterationId, isLogicUndefinedIgnored);
    }

    if (conditionPair.rightItem) {
      rightResult =
        conditionPair.rightItem?.testItemConditionType === ConditionTypeEnum.Item
          ? this.calculateComparison(conditionPair.rightItem as Comparison)
          : conditionPair.rightItem?.testItemConditionType === ConditionTypeEnum.Function
          ? this.calculateFunction(conditionPair.rightItem as Function, iterationId)
          : this.calculateLogic(conditionPair.rightItem as ConditionPair, iterationId, isLogicUndefinedIgnored);
    }

    if (leftResult === null && rightResult === null) {
      return LogicResultEnum.logicTrue;
    }

    if (leftResult === null) {
      return isLogicUndefinedIgnored && rightResult === LogicResultEnum.logicUndefined
        ? LogicResultEnum.logicTrue
        : (rightResult as LogicResultEnum);
    }

    if (rightResult === null) {
      return isLogicUndefinedIgnored && leftResult === LogicResultEnum.logicUndefined
        ? LogicResultEnum.logicTrue
        : (leftResult as LogicResultEnum);
    }

    if (rightResult === LogicResultEnum.logicUndefined || leftResult === LogicResultEnum.logicUndefined) {
      //Игнорировать андефайнед
      if (isLogicUndefinedIgnored && rightResult === leftResult) {
        return LogicResultEnum.logicTrue;
      }
      if (isLogicUndefinedIgnored && rightResult !== LogicResultEnum.logicUndefined) {
        return rightResult;
      }
      if (isLogicUndefinedIgnored && leftResult !== LogicResultEnum.logicUndefined) {
        return leftResult;
      }
      return LogicResultEnum.logicUndefined;
    }

    if (conditionPair.binaryOperator === BinaryOperatorEnum.And) {
      return rightResult === LogicResultEnum.logicTrue && leftResult === LogicResultEnum.logicTrue
        ? LogicResultEnum.logicTrue
        : LogicResultEnum.logicFalse;
    }

    if (conditionPair.binaryOperator === BinaryOperatorEnum.Or) {
      return rightResult === LogicResultEnum.logicTrue || leftResult === LogicResultEnum.logicTrue
        ? LogicResultEnum.logicTrue
        : LogicResultEnum.logicFalse;
    }

    return LogicResultEnum.logicUndefined;
  }

  isIteratesItemInAnswer(targetAnswer: TestItemResult, iterationId: string): boolean {
    switch (targetAnswer.type) {
      case TestItemResultTypeEnum.FirstClick:
        return this.answerUtilsService
          .getClickAreas(
            {
              x: (targetAnswer as FirstClickResult).cursorPositionX ?? -1,
              y: (targetAnswer as FirstClickResult).cursorPositionY ?? -1,
            },
            targetAnswer?.testItemId ?? ''
          )
          .some(({ fabDictionaryEntityId }) => fabDictionaryEntityId === iterationId);
      case TestItemResultTypeEnum.Matrix:
        return ((targetAnswer as MatrixResult)?.elementItems ?? []).some(
          ({ columnElementItemId, rowElementItemId }) => columnElementItemId === iterationId || rowElementItemId === iterationId
        );
      case TestItemResultTypeEnum.Scale:
        return ((targetAnswer as ScaleResult)?.resultItems ?? []).some(({ elementItemId }) => elementItemId === iterationId);
      case TestItemResultTypeEnum.Select:
      case TestItemResultTypeEnum.Ranking:
        return ((targetAnswer as DictionaryTaskResult)?.elementRowItemIds ?? []).includes(iterationId ?? '');
      default:
        return false;
    }
  }

  /**
   * Приведение значений оператора к массиву примитивных типов для сравнения
   */
  private evalOperand(operand: Operand): OperandInterface | null {
    switch (operand.operandType) {
      case OperandEnum.Const: {
        const values = this.getOperandValues(operand.values ?? []);
        return {
          isArray:
            values.length > 1 || (operand.values || []).some((value) => value.valueType === TestItemConditionValueTypeEnum.SourceItem),
          values,
          periodValue: null,
        } as OperandInterface;
      }
      case OperandEnum.TestItemEnvironment: {
        return this.getTestItemOperandValues(operand as TestItemOperand);
      }
      case OperandEnum.TestSessionEnvironment: {
        return this.getSessionOperandValues(operand as SessionOperand);
      }
      case OperandEnum.ConstDiapasonDateAndTime:
      case OperandEnum.ConstDiapasonTimeInterval:
      case OperandEnum.ConstDiapasonInteger:
      case OperandEnum.ConstDiapasonFloatingPoint: {
        const diapasonOperand = operand as
          | DateAndTimeDiapasonOperand
          | TimeIntervalDiapasonOperand
          | IntegerDiapasonOperand
          | FloatingPointDiapasonOperand;
        return {
          isArray: false,
          values: [],
          periodValue: {
            from: diapasonOperand?.from ? (this.getOperandValues([diapasonOperand.from])[0] as number) : null,
            to: diapasonOperand?.to ? (this.getOperandValues([diapasonOperand.to])[0] as number) : null,
          } as DiapasonValue,
        } as OperandInterface;
      }
      default: {
        return null;
      }
    }
  }

  /**
   * Получение значений константного операнда
   */
  private getOperandValues(
    values: Array<
      // eslint-disable-next-line @typescript-eslint/ban-types,id-blacklist
      TestItemConditionValue | DateAndTime | FloatingPoint | Integer | SourceItem | Text | TimeInterval | Boolean
    >
  ): Array<string | number | boolean> {
    const resultValues = new Array<string | number | boolean>();
    values.forEach((val) => {
      if ('dateTimeValue' in val) {
        resultValues.push(DateTimeService.getTimestampUTC(val.dateTimeValue));
      }
      if ('doubleValue' in val) {
        resultValues.push(val.doubleValue);
      }
      if ('int64Value' in val) {
        resultValues.push(val.int64Value);
      }
      if ('stringValue' in val) {
        resultValues.push(val.stringValue);
      }
      if ('timeSpanValue' in val) {
        resultValues.push(DateTimeService.convertTimeToMilliseconds(val.timeSpanValue));
      }
      if ('sourceItemId' in val) {
        resultValues.push(val.sourceItemId);
      }
      if ('boolValue' in val) {
        resultValues.push(val.boolValue);
      }
    });
    return resultValues;
  }

  /**
   * Получение значений операнда тест айтемов
   */
  private getTestItemOperandValues(operand: TestItemOperand): OperandInterface {
    const answer: TestItemResult | null = this.persistenceService.get(answerLocalStoragePrefix + operand.sourceTestItemId);
    let resultValues = new Array<string | number | boolean>();
    if (answer) {
      switch (operand.testItemResultField) {
        case TestItemResultFieldEnum.TestItemResult: {
          resultValues = this.answerUtilsService.getAnswerValues(answer, false, operand?.sourceTestItemRowDictionaryItemId ?? '');
          break;
        }
        case TestItemResultFieldEnum.TestItemCustomAnswer: {
          resultValues = this.answerUtilsService.getAnswerValues(answer, true, operand?.sourceTestItemRowDictionaryItemId ?? '');
          break;
        }
        case TestItemResultFieldEnum.TestItemIsAnswerCorrect: {
          const isRightAnswer = this.answerUtilsService.isRightAnswer(answer);
          resultValues = isRightAnswer !== null ? [isRightAnswer] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemIsAnswerPartialCorrect: {
          const isRightAnswer = this.answerUtilsService.isRightAnswer(answer, true);
          resultValues = isRightAnswer !== null ? [isRightAnswer] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemStartTime: {
          resultValues = answer?.clientStartTimeUtc ? [DateTimeService.getTimestampUTC(answer.clientStartTimeUtc)] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemAnswerTime: {
          resultValues = answer?.clientEndTimeUtc ? [DateTimeService.getTimestampUTC(answer.clientEndTimeUtc)] : [];
          break;
        }
        case TestItemResultFieldEnum.TestItemDuration: {
          resultValues = answer?.answerTimeSpentMs
            ? [answer.answerTimeSpentMs]
            : [answer?.clientStartTimeUtc ? DateTimeService.getDuration(answer?.clientStartTimeUtc) : 0];
          break;
        }
        case TestItemResultFieldEnum.TestItemReadingTimeSpent: {
          resultValues =
            'readingTimeSpentMs' in answer && (answer as FirstClickResult | FirstGlanceResult)?.readingTimeSpentMs
              ? [(answer as FirstClickResult | FirstGlanceResult).readingTimeSpentMs ?? 0]
              : [answer?.clientStartTimeUtc ? DateTimeService.getDuration(answer?.clientStartTimeUtc) : 0];
          break;
        }
        case TestItemResultFieldEnum.LogicResultTrueCount: {
          return this.getTestItemStatisticsOperand(operand.sourceTestItemId ?? '', TestItemStatisticsTypeEnum.LogicResultTrueAndCompleted);
        }
        case TestItemResultFieldEnum.LogicResultFalseCount: {
          return this.getTestItemStatisticsOperand(operand.sourceTestItemId ?? '', TestItemStatisticsTypeEnum.LogicResultFalseAndCompleted);
        }
      }
    }

    return {
      isArray: resultValues.length > 1,
      periodValue: null,
      values: resultValues,
    } as OperandInterface;
  }

  /**
   * Получение значений сессионного операнда
   */
  private getSessionOperandValues(operand: SessionOperand): OperandInterface {
    const resultValues = new Array<string | number | boolean>();

    switch (operand.testSessionEnvField) {
      case TestSessionEnvironmentFieldEnum.ScreenWidth: {
        resultValues.push(window.innerWidth);
        break;
      }
      case TestSessionEnvironmentFieldEnum.ScreenHeight: {
        resultValues.push(window.innerHeight);
        break;
      }
      case TestSessionEnvironmentFieldEnum.TestStartTime: {
        resultValues.push(this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC ?? 0);
        break;
      }
      case TestSessionEnvironmentFieldEnum.TestDuration: {
        resultValues.push(
          this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC
            ? DateTimeService.currentTimestampUTC - this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC
            : 0
        );
        break;
      }
      case TestSessionEnvironmentFieldEnum.Browser: {
        resultValues.push(this._logicEnvironmentParams?.browserInfo?.entityId ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.BrowserVersion: {
        resultValues.push(this._logicEnvironmentParams?.testRunnerStartData?.browserVersion ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.IsMobileDevice: {
        if (
          this._logicEnvironmentParams?.testRunnerStartData?.isMobileDevice !== undefined &&
          this._logicEnvironmentParams?.testRunnerStartData?.isMobileDevice !== null
        ) {
          resultValues.push(this._logicEnvironmentParams?.testRunnerStartData.isMobileDevice);
        }
        break;
      }
      case TestSessionEnvironmentFieldEnum.Os: {
        resultValues.push(this._logicEnvironmentParams?.osInfo?.entityId ?? '');
        break;
      }
      case TestSessionEnvironmentFieldEnum.QueryString: {
        resultValues.push(this._logicEnvironmentParams?.testRunnerStartData?.query ?? '');
        break;
      }
    }

    return {
      isArray: resultValues.length > 1,
      values: resultValues,
      periodValue: null,
    } as OperandInterface;
  }

  private getTestItemStatisticsOperand(testItemId: string, key: TestItemStatisticsTypeEnum): OperandInterface {
    const resultValues: Array<string | number | boolean> = (
      this._logicEnvironmentParams?.testItemStatistics || new Array<TestItemStatistics>()
    )
      .filter((item) => item?.type === key && item?.testItemId === testItemId)
      .map((item) => item?.count ?? 0);
    return {
      isArray: resultValues.length > 1,
      periodValue: null,
      values: resultValues.length > 0 ? resultValues : [0],
    } as OperandInterface;
  }
}
