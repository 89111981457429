import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { Environment } from '../../models/environment';
import { setLoaderStatus } from '../../store/actions/test-runner.actions';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentComponent {
  environment: Environment = environment;

  constructor(store: Store) {
    store.dispatch(setLoaderStatus());
  }
}
