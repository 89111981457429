import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { setLoaderStatus } from 'src/app/store/actions/test-runner.actions';

@Component({
  selector: 'app-empty-id',
  templateUrl: './empty-id.component.html',
  styleUrls: ['./empty-id.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyIdComponent {
  constructor(store: Store) {
    store.dispatch(setLoaderStatus());
  }
}
