import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { fadeInLeftAnimation, fadeInRightAnimation, fadeOutLeftAnimation, fadeOutRightAnimation } from 'angular-animations';
import { Observable, Subscription, combineLatest, interval, takeUntil } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IfCompleteResultStatusEnum, Layout, Test, TestItemResultStatusEnum } from 'src/api/testrunner/models';
import { CompleteTestInfo } from '../../models/complete-test-info';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { saveQueueAction } from '../../store/actions/answers.actions';
import { getNextPageAction, getPrevPageAction } from '../../store/actions/page.actions';
import { selectIsAnswersQueueEmpty } from '../../store/selectors/answers.selectors';
import { selectCurrentPage, selectIsLastPage, selectIsPageChanging } from '../../store/selectors/page.selectors';
import {
  selectCompleteTestInfo,
  selectExtensionStatus,
  selectIsTestFinished,
  selectIsVideoLoading,
  selectRedirectUrl,
  selectTestScripts,
  selectTestStartData,
} from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss'],
  animations: [
    fadeInLeftAnimation({ duration: 400 }),
    fadeInRightAnimation({ duration: 400 }),
    fadeOutLeftAnimation({ duration: 400 }),
    fadeOutRightAnimation({ duration: 400 }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlowComponent implements OnDestroy, OnInit {
  completeResultContent?: string | null;
  completeResultStatus?: IfCompleteResultStatusEnum;
  public readonly completeTestInfo$: Observable<CompleteTestInfo | null> = this.store.select(selectCompleteTestInfo);
  public readonly currentPage$: Observable<Layout | null> = this.store.select(selectCurrentPage);
  public readonly extensionStatus$: Observable<ExtensionStatusEnum | null> = this.store.select(selectExtensionStatus);
  public readonly isAnswersQueueEmpty$: Observable<boolean> = this.store.select(selectIsAnswersQueueEmpty);
  public readonly isLastPage$: Observable<boolean> = this.store.select(selectIsLastPage);
  public readonly isPageChanging$: Observable<boolean> = this.store.select(selectIsPageChanging);
  public readonly isTestFinished$: Observable<boolean> = this.store.select(selectIsTestFinished);
  public readonly isVideoLoading$: Observable<boolean> = this.store.select(selectIsVideoLoading);
  public readonly redirectUrl$: Observable<string | null> = this.store.select(selectRedirectUrl);
  public readonly test$: Observable<Test | null> = this.store.select(selectTestStartData);
  public readonly testScripts$: Observable<Partial<Test> | null> = this.store.select(selectTestScripts);
  public fadeInLeftAnimationTrigger = false;
  public fadeInRightAnimationTrigger = false;
  public fadeOutLeftAnimationTrigger = false;
  public fadeOutRightAnimationTrigger = false;

  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly store: Store) {}

  public fadeOutLeftAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeOutLeftAnimationTrigger = false;
      this.fadeInRightAnimationTrigger = true;
    }
  }

  public fadeInLeftAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeInLeftAnimationTrigger = false;
    }
  }

  public fadeOutRightAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeOutRightAnimationTrigger = false;
      this.fadeInLeftAnimationTrigger = true;
    }
  }

  public fadeInRightAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeInRightAnimationTrigger = false;
    }
  }

  public ngOnInit(): void {
    const isTestFinishedAndAllAnswersSaved$ = combineLatest([this.isTestFinished$, this.isAnswersQueueEmpty$]).pipe(
      filter(([isTestFinished, isAnswersQueueEmpty]) => isTestFinished && isAnswersQueueEmpty),
    );

    this.subscription.add(
      interval(5000)
        .pipe(takeUntil(isTestFinishedAndAllAnswersSaved$))
        .subscribe(() => this.store.dispatch(saveQueueAction())),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onNextPage(answerStatus: TestItemResultStatusEnum): void {
    this.fadeOutLeftAnimationTrigger = true;
    this.scrollToTop();
    console.log('check-getNextPageActionDispatch');
    this.store.dispatch(getNextPageAction({ answerStatus }));
  }

  public onPrevPage(): void {
    this.fadeOutRightAnimationTrigger = true;
    this.scrollToTop();

    this.store.dispatch(getPrevPageAction());
  }

  public scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
