import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../action-types';
import { Layout, TestItemResult, TestItemResultStatusEnum } from 'src/api/testrunner/models';
import { TestInfo } from 'src/app/models/test-info';
import { TestItemModel } from 'src/app/models/test-item-model';

export const initPageSuccessAction = createAction(
  ActionTypes.INIT_PAGE_SUCCESS,
  props<{ page: Layout | null; answers: Array<TestItemResult>; testItemModels: Array<TestItemModel>; pageShowStartTime: string }>()
);

export const getCurrentPageAction = createAction(ActionTypes.GET_CURRENT_PAGE);
export const getCurrentPageSuccessAction = createAction(
  ActionTypes.GET_CURRENT_PAGE_SUCCESS,
  props<{ page: Layout | null; testInfo: TestInfo | null }>()
);

export const getNextPageAction = createAction(ActionTypes.GET_NEXT_PAGE, props<{ answerStatus?: TestItemResultStatusEnum }>());
export const getNextPageSuccessAction = createAction(
  ActionTypes.GET_NEXT_PAGE_SUCCESS,
  props<{ page: Layout | null; testInfo: TestInfo | null; answers: Array<TestItemResult> }>()
);

export const getPrevPageAction = createAction(ActionTypes.GET_PREV_PAGE);
export const getPrevPageSuccessAction = createAction(
  ActionTypes.GET_PREV_PAGE_SUCCESS,
  props<{ page: Layout | null; testInfo: TestInfo | null }>()
);

export const getTestItemModelsVisibilitySuccessAction = createAction(
  ActionTypes.GET_TEST_ITEM_MODELS_VISIBILITY_SUCCESS,
  props<{ testItemModels: Array<TestItemModel>; answers: Array<TestItemResult> }>()
);

export const lockButtonNext = createAction(ActionTypes.LOCK_BTN_NEXT);

export const revalidatePageAnswersAction = createAction(ActionTypes.REVALIDATE_PAGE_ANSWERS);
export const revalidatePageAnswersSuccessAction = createAction(
  ActionTypes.REVALIDATE_PAGE_ANSWERS_SUCCESS,
  props<{ isAnswersValid: boolean }>()
);
