import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ExtensionErrorsEnum } from '../../models/extension-errors-enum';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { finishMobileTestAction } from '../actions/test-runner.actions';
import { getNextPageAction } from '../actions/page.actions';
import { MessageEventDataEnum } from '../../models/message-event-data-enum';
import { MobileAppStatusEnum } from '../../models/mobile-app-status-enum';
import { PersistenceService } from '../../services/persistence.service';
import {
  setExtensionErrorsSuccessAction,
  setExtensionStatusSuccessAction,
  setIsExtensionInstalledSuccessAction,
  setIsMobileAppInstalledSuccessAction,
  setMobileAppStatusSuccessAction,
  unknownMessageAction,
} from '../actions/message.actions';
import { TestItemResultStatusEnum } from '../../../api/testrunner/models/test-item-result-status-enum';

@Injectable()
export class MessageEffects {
  postMessages$ = createEffect(() => {
    return fromEvent<MessageEvent>(window, 'message').pipe(
      filter((event) => Object.values(MessageEventDataEnum).includes(event.data)),
      map((event) => {
        console.log('PostMessage', event);
        switch (event.data as MessageEventDataEnum) {
          case MessageEventDataEnum.ExtensionInstalled:
            return setIsExtensionInstalledSuccessAction({ isExtensionInstalled: true });
          case MessageEventDataEnum.ExtensionTaskNotStarted:
            return setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.TaskNotStarted });
          case MessageEventDataEnum.ExtensionTaskInProgress:
            return setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.TaskInProgress });
          case MessageEventDataEnum.ExtensionTaskCompleted:
            return setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.TaskCompleted });
          case MessageEventDataEnum.ExtensionTaskSkipped:
            return getNextPageAction({ answerStatus: TestItemResultStatusEnum.Failed });
          case MessageEventDataEnum.ExtensionUploadingVideoInProgress:
            return setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.UploadingVideoInProgress });
          case MessageEventDataEnum.ExtensionUploadingVideoCompleted:
            return setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.UploadingVideoCompleted });
          case MessageEventDataEnum.ExtensionFailedCapturePermission:
            return setExtensionErrorsSuccessAction({ status: ExtensionErrorsEnum.Error });
          case MessageEventDataEnum.MobileAppInstalled:
            this.persistenceService.set('isMobileAppInstalled', true);
            return setIsMobileAppInstalledSuccessAction({ isMobileAppInstalled: true });
          case MessageEventDataEnum.MobileAppTaskInProgress:
            return setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.TaskInProgress });
          case MessageEventDataEnum.MobileAppTaskCompleted:
            return setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.TaskCompleted });
          case MessageEventDataEnum.MobileAppTestFinished:
            return finishMobileTestAction();
          case MessageEventDataEnum.MobileAppUploadingVideoInProgress:
            return setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.UploadingVideoInProgress });
          case MessageEventDataEnum.MobileAppUploadingVideoCompleted:
            return setMobileAppStatusSuccessAction({ status: MobileAppStatusEnum.UploadingVideoCompleted });
          default:
            return unknownMessageAction();
        }
      })
    );
  });

  constructor(private actions$: Actions, private persistenceService: PersistenceService) {}
}
