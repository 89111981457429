import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { selectExtensionError } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent implements OnInit, OnDestroy {
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly store: Store,
    private readonly messageService: MessageService,
  ) {}

  public ngOnInit(): void {
    this.subscription.add(
      this.store.select(selectExtensionError).subscribe((errors) => {
        if (errors) {
          this.messageService.add({
            severity: 'error',
            summary: 'Ошибка захвата экрана',
            detail: 'Невозможно начать тестирование. В вашей системе нет разрешения на запись экрана.',
            life: 60000,
          });
        }
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
