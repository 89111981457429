<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

  <ng-container *ngIf="!isImageLoading">
    <button
      *ngIf="isImageHidden && !isShown else msg"
      type="button" pButton pRipple
      class="p-button-outlined p-button-secondary"
      [label]="t('testrunner.firstGlance.openImage')"
      (click)="openInfoDialog()"
    ></button>
    <ng-template #msg>
      <span class="image-shown px-3 py-2 text-primary">
        <i class="fa-solid fa-check"></i>
        <span class="ml-2 font-medium">{{t('testrunner.firstGlance.imageShown')}}</span>
      </span>
    </ng-template>
  </ng-container>

  <div *ngIf="isImageLoading" class="flex justify-content-center align-items-center h-full">
    <p-progressSpinner></p-progressSpinner>
  </div>

  <p-sidebar
    [visible]="!isImageHidden"
    [fullScreen]="true"
    [showCloseIcon]="false"
    [blockScroll]="true"
    [modal]="false"
  >
    <div class="flex justify-content-center align-items-center image-container">
      <img
        *ngIf="isImageLoaded"
        [src]="imageUrl"
        (load)="onImageLoad()"
        (error)="onImageError()"
        [hidden]="isImageHidden"
        class="image"
        alt=""
      />
      <i *ngIf="!isImageLoaded" class="fa-regular fa-image-slash fa-10x"></i>
    </div>
  </p-sidebar>

  <p-dialog
    [header]="t('testrunner.firstGlance.showImage')"
    [(visible)]="isInfoDialogVisible"
    [style]="{ width: '30rem' }"
    [draggable]="false"
    [resizable]="false"
    [closable]="false"
    [modal]="true"
  >
    <span [innerHtml]="info"></span>
    <ng-template pTemplate="footer">
      <p-button
        (click)="closeInfoDialog()"
        [label]="t('testrunner.cancel')"
        styleClass="p-button-secondary p-button-outlined mr-3"
      ></p-button>
      <p-button
        (click)="showImage()"
        [label]="t('testrunner.firstGlance.showImage')"
        styleClass="p-button mr-0"
      ></p-button>
    </ng-template>
  </p-dialog>
</ng-container>
