<ng-container *transloco="let t">
  <div *ngIf="answerQuantity === answerQuantityEnum.Multiple; then multiple; else single"></div>

  <ng-template #multiple>
    <div class="grid mx-0 option-group" [class.tiled]="hasLargeImage$ | async">
      <div *ngFor="let item of fabDictionaryItems" class="col-{{ columnGridWidth }} p-field-checkbox justify-content-start flex">
        <label class="answer-label flex align-items-center justify-content-start">
          <p-checkbox
            [disabled]="isDisabled && !selectedItems.includes(item.entityId ?? '')"
            [inputId]="getLabelId(item)"
            name="elementRowItemIds"
            [value]="item.entityId"
            [(ngModel)]="selectedItems"
            (ngModelChange)="updateValue()"
          ></p-checkbox>
          <div *ngIf="item.imageUrl" [for]="item.entityId">
            <app-image [url]="item.imageUrl ?? ''" [selected]="selectedItems.includes(item?.entityId ?? '')"></app-image>
          </div>
          <div [for]="getLabelId(item)" class="ml-2" [innerHTML]="item.captionRu ?? '' | safeHtml"></div>
        </label>
      </div>
    </div>
  </ng-template>

  <ng-template #single let-formControl="selectedValue">
    <div class="grid mx-0 option-group" [class.tiled]="hasLargeImage$ | async">
      <div *ngFor="let item of fabDictionaryItems; let i = index" class="col-{{ columnGridWidth }} p-field-checkbox">
        <label class="answer-label flex align-items-center justify-content-start">
          <p-radioButton
            name="elementRowItemIds"
            [(ngModel)]="selectedItem"
            (ngModelChange)="updateValue()"
            [inputId]="getLabelId(item)"
            [value]="item.entityId"
            [class]="item.imageUrl && item.imageUrl.length ? 'select-image' : ''"
          ></p-radioButton>

          <div *ngIf="item.imageUrl" [for]="getLabelId(item)" style="width: 100px">
            <app-image [url]="item.imageUrl ?? ''" [selected]="item.entityId === selectedItem"></app-image>
          </div>
          <div [for]="getLabelId(item)" style="pointer-events: none" [innerHTML]="item.captionRu ?? '' | safeHtml"></div>
        </label>
      </div>
    </div>
  </ng-template>
</ng-container>
