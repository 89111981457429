import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subscription, of, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { setMediaDevices } from 'src/app/store/actions/test-runner.actions';
import { RecordMode } from '../../../api/testrunner/models/record-mode';
import { TestRunnerStartData } from '../../../api/testrunner/models/test-runner-start-data';
import { WebSite } from '../../../api/testrunner/models/web-site';
import { environment } from '../../../environments/environment';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { TestInfo } from '../../models/test-info';
import { WebSiteActionEnum } from '../../models/web-site-action-enum';
import { MacrosPipe } from '../../services/macros.pipe';
import { MacrosService } from '../../services/macros.service';
import { VideoRecorderService } from '../../services/video-recorder.service';
import { WebSiteService } from '../../services/web-site.service';
import {
  selectCameraDeviceId,
  selectExtensionStatus,
  selectIsExtensionInstalled,
  selectMicroPhoneDeviceId,
  selectTestInfo,
  selectTestRunnerStartData,
} from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-web-site',
  templateUrl: './web-site.component.html',
  styleUrls: ['./web-site.component.scss'],
})
export class WebSiteComponent implements OnDestroy, OnInit {
  @Input() public canStartTask = true;
  @Input() public webSite?: WebSite;
  @Output() public readonly skipTask: EventEmitter<void> = new EventEmitter<void>();

  cameraDeviceId$: Observable<string | null> = of(null);
  currentBrowser?: string;
  description?: SafeHtml;
  extensionStatus$: Observable<ExtensionStatusEnum | null> = of(null);
  extensionStatusEnum = ExtensionStatusEnum;
  isAccessGranted = false;
  isCameraNotFound = false;
  isExtensionInstalled$: Observable<boolean> = of(false);
  isNeedCameraRequest$: Observable<boolean> = of(false);
  isNeedMicrophoneRequest$: Observable<boolean> = of(false);
  isEyeTrackerEnabled?: boolean;
  isEyeTrackerCalibrationRequest$: Observable<boolean | undefined> = of(false);
  isLoading = false;
  mediaStreamConstraints: MediaStreamConstraints = { video: false, audio: false };
  microphoneDeviceId$: Observable<string | null> = of(null);
  recordMode = RecordMode;
  selectedCameraDeviceId: string | null = null;
  selectedMicrophoneDeviceId: string | null = null;
  startData$: Observable<TestRunnerStartData | null> = of(null);
  testInfo$: Observable<TestInfo | null> = of(null);

  private isMediaStreamConstraintsChecked = false;
  private subscriptions: Subscription = new Subscription();
  private timerSubscription?: Subscription;

  constructor(
    private readonly macrosPipe: MacrosPipe,
    private readonly macrosService: MacrosService,
    private readonly sanitizer: DomSanitizer,
    public store: Store,
    private readonly translocoService: TranslocoService,
    private readonly videoRecorderService: VideoRecorderService,
    private readonly webSiteService: WebSiteService,
  ) {}

  get goToTaskButtonText(): string {
    return (this.webSite as WebSite).startButtonText || this.translocoService.translate('testrunner.webSite.goToAssignment');
  }

  get returnButtonText(): string {
    return (this.webSite as WebSite).returnButtonText || this.translocoService.translate('testrunner.webSite.returnToAssignment');
  }

  get skipButtonText(): string {
    return (this.webSite as WebSite).skipButtonText || this.translocoService.translate('testrunner.mobile.skipAssignment');
  }

  ngOnDestroy(): void {
    this.timerSubscription?.unsubscribe();
    this.subscriptions.unsubscribe();
  }

  ngOnInit(): void {
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.macrosPipe.transform(this.webSite?.description ?? '', this.webSite));
    this.isExtensionInstalled$ = this.store.select(selectIsExtensionInstalled);
    this.extensionStatus$ = this.store.select(selectExtensionStatus);
    this.startData$ = this.store.select(selectTestRunnerStartData);
    this.cameraDeviceId$ = this.store.select(selectCameraDeviceId);
    this.microphoneDeviceId$ = this.store.select(selectMicroPhoneDeviceId);
    this.testInfo$ = this.store.select(selectTestInfo);
    this.isNeedCameraRequest$ = this.cameraDeviceId$.pipe(
      map((cameraDeviceId) => {
        this.mediaStreamConstraints.video = cameraDeviceId ? { deviceId: cameraDeviceId, width: { ideal: 720 } } : false;
        // Для показа настроек выбора веб-камеры в тесте с айтрекером добавил проверку флага isEyeTrackerEnabled,
        //  чтобы показать данный компонент настройки без записи веб-камеры. В дальнейшем надо при включение айтрекера
        //  - включать запись веб-камеры в тесте.
        return (this.webSite?.recordCameraMode !== RecordMode.Disabled || this.isEyeTrackerEnabled === true) && cameraDeviceId === null;
      }),
    );
    this.isNeedMicrophoneRequest$ = this.microphoneDeviceId$.pipe(
      map((microphoneDeviceId) => {
        this.mediaStreamConstraints.audio = microphoneDeviceId ? { deviceId: microphoneDeviceId } : false;
        return this.webSite?.recordVoiceMode !== RecordMode.Disabled && microphoneDeviceId === null;
      }),
    );

    this.isEyeTrackerCalibrationRequest$ = this.testInfo$.pipe(
      map((testInfo) => {
        return (!testInfo?.eyeTrackerInfo?.statusCalibration && this.isEyeTrackerEnabled) ?? false;
      }),
    );

    this.subscriptions.add(
      this.isExtensionInstalled$.subscribe((isExtensionInstalled) => {
        if (isExtensionInstalled && !this.isMediaStreamConstraintsChecked) {
          this.checkMediaStreamConstraints();
        }
      }),
    );
    this.subscriptions.add(
      this.startData$.subscribe((data) => {
        if (data?.browserSystemName) {
          this.currentBrowser = data.browserSystemName;
        }
        if (data?.test) {
          this.isEyeTrackerEnabled = data.test.isEyeTrackerEnabled;
        }
      }),
    );

    /*if (this.store.extensionStatus === ExtensionStatusEnum.TaskCompleted) {
      this.store.extensionStatus = undefined;
    }*/

    this.mediaStreamConstraints = {
      video: this.webSite?.recordCameraMode !== RecordMode.Disabled,
      audio: this.webSite?.recordVoiceMode !== RecordMode.Disabled,
    };

    this.timerSubscription = timer(1000, 1000).subscribe(() => {
      if (this.isMediaStreamConstraintsChecked && !this.isAccessGranted) {
        this.checkMediaStreamConstraints();
      }
    });
  }

  openExtensionPage(): void {
    window.open(environment.extensionUrl);
  }

  setMediaDevices(): void {
    this.store.dispatch(
      setMediaDevices({
        cameraDeviceId: this.selectedCameraDeviceId,
        microphoneDeviceId: this.selectedMicrophoneDeviceId,
      }),
    );
  }

  startExtensionTask(): void {
    if (!this.webSite?.startUrl) {
      return;
    }

    this.videoRecorderService.startRecording(this.mediaStreamConstraints);
    this.webSiteService.postMessage(
      WebSiteActionEnum.StartTask,
      {
        ...this.webSite,
        startUrl: this.macrosPipe.transform(this.webSite?.startUrl ?? '', this.webSite),
      },
      this.macrosService.getMacrosDictionary(this.webSite),
    );
    console.log('Website extension start task');
  }

  /*  skipTask(): void {
      this.webSiteService.postMessage(WebSiteActionEnum.FinishTask, this.webSite);
      this.store.dispatch(setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.TaskSkipped }));
    }*/

  skipAssignment(): void {
    //this.store.dispatch(setExtensionStatusSuccessAction({ status: ExtensionStatusEnum.TaskSkipped }));
    this.skipTask.emit();
  }

  private checkMediaStreamConstraints(): void {
    if (!this.isMediaStreamConstraintsChecked) {
      // чтобы спиннер не крутился при проверках по таймеру в случае отказа предоставить доступ
      this.isLoading = true;
    }
    this.isMediaStreamConstraintsChecked = true;

    if (this.webSite?.recordCameraMode === RecordMode.Disabled && this.webSite?.recordVoiceMode === RecordMode.Disabled) {
      this.isAccessGranted = true;
      this.isLoading = false;
      return;
    }

    navigator.mediaDevices
      .getUserMedia(this.mediaStreamConstraints)
      .then((stream) => {
        stream?.getTracks().forEach((track) => {
          track.stop();
        });
        this.timerSubscription?.unsubscribe();
        this.isAccessGranted = true;
        this.isLoading = false;
      })
      .catch((error: Error) => {
        console.error(error);
        let isAccessGranted = true;
        if (this.mediaStreamConstraints.video && this.webSite?.recordCameraMode !== RecordMode.Optional) {
          isAccessGranted = false;
        }
        if (this.mediaStreamConstraints.audio && this.webSite?.recordVoiceMode !== RecordMode.Optional) {
          isAccessGranted = false;
        }
        this.isAccessGranted = isAccessGranted;

        if (error instanceof DOMException && error.code === DOMException.NOT_FOUND_ERR) {
          this.isCameraNotFound = true;
        }
        this.isLoading = false;
      });
  }
}
