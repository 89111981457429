import { HttpResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { Observable, ReplaySubject, Subscription, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { PersistenceService } from 'src/app/services/persistence.service';
import { environment } from 'src/environments/environment';
import { TestItemResult, TestItemResultStatusEnum, TestItemResultTypeEnum } from '../../../api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';

const MAX_SIZE_BYTES: number = 20_971_520;
const ACCEPT_TYPES: string =
  '.jpg, .jpeg, .png, .svg, .mp4, .m4v, .mkv, .mp3, .m4a, .aac, .ogg, .pdf, .doc, .docx, .pptx, .xls, .xlsx, .csv';

interface UploadedFile {
  link: string;
  fileName: string;
}

interface ResultFileData {
  fileName: string;
  fileUrl: string;
}

function isNil<T>(entity: T | null | undefined): entity is null | undefined {
  return entity === undefined || entity === null;
}

function isEmptyString(input: string): boolean {
  return Object.is(input.length, 0);
}

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class FileComponent implements OnChanges {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  private readonly testItemModel$: ReplaySubject<TestItemModel> = new ReplaySubject<TestItemModel>(1);

  private readonly savedFileName$: Observable<string> = this.testItemModel$.pipe(
    map(({ id }: TestItemModel) => this.persistenceService.get('answer_' + id)?.resultFileData ?? null),
    map((resultFileData: ResultFileData | null) => resultFileData?.fileName ?? null),
    filter((fileName: string | null): fileName is string => !isNil(fileName)),
  );

  public readonly fileNameControl: FormControl<string> = new FormControl<string>({ value: '', disabled: true }, { nonNullable: true });

  public readonly description$: Observable<SafeHtml> = this.testItemModel$.pipe(
    map(({ data, iterationId }: TestItemModel) => {
      if (isNil(data?.description)) {
        return null;
      }
      const pipingItem = {
        ...data,
        iterationId: iterationId,
      } as PipingItem;
      return this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(data?.description, pipingItem));
    }),
    filter((description: SafeHtml | null): description is SafeHtml => !isNil(description)),
  );

  private readonly isFileUploaded$: Observable<boolean> = this.fileNameControl.valueChanges.pipe(
    startWith(this.fileNameControl.value),
    map((fileName: string) => !isEmptyString(fileName)),
  );

  public readonly uploadButtonText$: Observable<string> = combineLatest([this.testItemModel$, this.isFileUploaded$]).pipe(
    switchMap(([{ data }, isFileUploaded]: [TestItemModel, boolean]) => {
      if (isFileUploaded) {
        return this.translocoService.selectTranslate('testrunner.file.uploaded');
      }
      return isNil(data.downloadButtonText) || isEmptyString(data.downloadButtonText)
        ? this.translocoService.selectTranslate('testrunner.file.upload')
        : of(data.downloadButtonText);
    }),
  );

  public readonly uploadUrl$: Observable<string | null> = this.testItemModel$.pipe(
    map(({ data, iterationId }: TestItemModel) => {
      const testId: string | undefined = data.testId;
      const testItemId: string | undefined = data.id;

      if (isNil(testId) || isNil(testItemId)) {
        return null;
      }
      const url: URL = new URL(`${environment.apiUrl}/api/v1/test/${testId}/results/${testItemId}/file`);
      if (!isNil(iterationId) && !isEmptyString(iterationId)) {
        url.searchParams.set('iterationId', iterationId);
      }
      return url.toString();
    }),
  );

  public readonly isAnswerSkippedControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });
  public readonly isAnswerSkipped$: Observable<boolean> = this.isAnswerSkippedControl.valueChanges.pipe(shareReplay(1));

  public readonly acceptTypes: string = ACCEPT_TYPES;
  public readonly maxFileSize: number = MAX_SIZE_BYTES;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly pipe: MacrosPipe,
    private readonly sanitizer: DomSanitizer,
    private readonly translocoService: TranslocoService,
    private readonly persistenceService: PersistenceService,
  ) {
    this.subscription.add(this.processSavedFileNameChanges());
    this.subscription.add(this.processIsAnswerSkippedChanges());
  }

  public uploadFile(event: { originalEvent: HttpResponse<UploadedFile>; files: File[] }, fileUploadRef: FileUpload): void {
    if (isNil(event.originalEvent.body)) {
      return;
    }
    this.onUploadComplete(event.originalEvent.body);

    fileUploadRef.clear();
  }

  private onUploadComplete({ link, fileName }: UploadedFile): void {
    this.fileNameControl.setValue(fileName);

    this.save({ link, fileName }, false);
  }

  private save(uploadedFile: UploadedFile | null, isAnswerSkipped: boolean = false): void {
    const result: TestItemResult = {
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      clientTimeZoneMinutes: DateTimeService.timezone,
      iterationId: this.testItemModel?.iterationId,
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.File,
      resultFileData: isNil(uploadedFile)
        ? null
        : {
            fileName: uploadedFile.fileName,
            fileUrl: uploadedFile.link,
          },
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as TestItemResult;

    this.answerChanges.emit(result);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('testItemModel')) {
      this.processTestItemModelChanges(changes.testItemModel);
    }
  }

  private processTestItemModelChanges({ currentValue, previousValue }: SimpleChange): void {
    if (previousValue?.lastUpdateTime === currentValue?.lastUpdateTime || isNil(currentValue.data)) {
      return;
    }
    const answer = this.persistenceService.get('answer_' + (currentValue?.data.id ?? '')) as TestItemResult;
    const isSkipped: boolean = Boolean(answer?.isAnswerSkipped && currentValue?.data.isSkipButtonEnabled);
    this.isAnswerSkippedControl.setValue(isSkipped, { emitEvent: false });

    this.testItemModel$.next(currentValue);
  }

  private processSavedFileNameChanges(): Subscription {
    return this.savedFileName$.subscribe((fileName: string) => this.fileNameControl.setValue(fileName));
  }

  private processIsAnswerSkippedChanges(): Subscription {
    return this.isAnswerSkipped$.subscribe((isAnswerSkipped: boolean) => {
      if (isAnswerSkipped) {
        this.fileNameControl.setValue('');
      }

      this.save(null, isAnswerSkipped);
    });
  }
}
