import { ChangeDetectionStrategy, Component, VERSION } from '@angular/core';
import { Store } from '@ngrx/store';
import { setLoaderStatus } from 'src/app/store/actions/test-runner.actions';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionComponent {
  public readonly version: string = VERSION.full;

  constructor(store: Store) {
    store.dispatch(setLoaderStatus());
  }
}
