<form [formGroup]="formAnswer" class="mx-3">
  <ng-container formArrayName="formRows">
    <p-table [columns]="columns" [value]="rows" [autoLayout]="true" responsiveLayout="scroll">
      <ng-template pTemplate="header" let-columns frozen="true">
        <tr class="firstRow">
          <th *ngIf="isGridEnabled" style="width: 20%" class="hidden md:inline-block bg-transparent border-none"></th>

          <th [attr.colspan]="columns.length" style="padding: 0 0 1rem 0; background: transparent; border: 0">
            <div *ngIf="isLabelsEnabled" class="flex">
              <div
                *ngFor="let col of columns; let index = index; let first = first; let last = last"
                class="bg-transparent border-none text-center flex-grow-1"
                [class.pl-0]="first"
                [class.pr-0]="last"
                [class.first]="first"
                [class.last]="last"
                [class.text-left]="first"
                [class.text-right]="last"
              >
                <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: col }"></ng-template>
              </div>
            </div>
          </th>
          <th style="width: 10%" class="hidden md:inline-block bg-transparent border-none"></th>
        </tr>
        <tr>
          <th *ngIf="isGridEnabled" class="hidden md:inline-block bg-transparent border-none"></th>
          <th [attr.colspan]="columns.length" class="bg-transparent border-none relative">
            <div *ngIf="isGridEnabled" class="scale-panel">
              <table class="h-full w-full border-none">
                <tr>
                  <ng-container
                    *ngFor="let j of [].constructor(scaleData.gridLinesCount); let index = index; let first = first; let last = last"
                  >
                    <td class="border-none relative">
                      <span class="scale-label" [class.first]="first">{{ scalePoint(index) }}</span>
                      <span *ngIf="last" class="scale-label last">
                        {{ scaleData.maxValue }}
                      </span>
                    </td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </th>
          <th class="hidden md:inline-block bg-transparent border-none"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-columns="columns" let-i="rowIndex">
        <tr *ngIf="!isGridEnabled">
          <td [attr.colspan]="columns.length" class="border-none p-0 flex justify-content-between align-items-end">
            <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row }"></ng-template>
            <span class="md:hidden ml-2 font-medium white-space-nowrap">
              {{ getAnswer(row?.entityId) }}
            </span>
          </td>
        </tr>

        <tr *ngIf="isGridEnabled" class="md:hidden">
          <td [attr.colspan]="columns.length" class="border-none p-0 flex justify-content-between align-items-end">
            <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row }"></ng-template>
            <span class="ml-2 font-medium white-space-nowrap"> {{ getAnswer(row?.entityId) }} {{ scaleData.unitOfMeasurement }} </span>
          </td>
        </tr>

        <tr [formGroupName]="i">
          <td *ngIf="isGridEnabled" class="border-none pl-0 text-right">
            <!-- style="width: 20%" -->
            <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row }"></ng-template>
          </td>
          <td
            class="border-none text-center relative"
            [attr.colspan]="columns.length"
            [class.pr-0]="true"
            [class.w-full]="true"
            style="{{ !isGridEnabled ? 'padding-left: 0.6rem' : 'padding-left: 0' }}; width: 70%"
          >
            <div *ngIf="isGridEnabled" class="scale-panel">
              <table class="scale-table">
                <tr>
                  <td *ngFor="let j of [].constructor(scaleData.gridLinesCount); let index = index">&nbsp;</td>
                </tr>
              </table>
            </div>

            <!-- Слайдер -->
            <ng-container>
              <p-slider
                [formControlName]="row?.entityId"
                [min]="scaleData.minValue"
                [max]="scaleData.maxValue"
                [step]="changeStep"
              ></p-slider>
            </ng-container>
            <!-- /Слайдер -->
          </td>

          <td class="border-none" style="width: 10%">
            <span class="ml-2 font-medium white-space-nowrap" style="position: absolute; margin-top: -0.5rem">
              {{ getAnswer(row?.entityId) }}
            </span>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</form>

<ng-template #answerTemplateRef let-col="col">
  <span>
    <span class="mr-2" *ngIf="col?.imageUrl && col?.imageUrl.length">
      <app-image [url]="col?.imageUrl" [dialog]="true"></app-image>
    </span>
    <span [innerHTML]="col?.captionRu ?? '&nbsp;' | safeHtml"></span>
  </span>
</ng-template>
