import { createFeatureSelector, createSelector } from '@ngrx/store';

import { answersAdapter, AnswersState } from '../states/answers.state';
import { FeaturesEnum } from '../features-enum';

const { selectEntities, selectAll } = answersAdapter.getSelectors();

const selectTestItemTreeFeature = createFeatureSelector<AnswersState>(FeaturesEnum.answers);

export const selectAnswersDictionary = createSelector(selectTestItemTreeFeature, selectEntities);

export const selectAnswers = createSelector(selectTestItemTreeFeature, selectAll);

export const selectAnswersQueue = createSelector(selectTestItemTreeFeature, (answersState) => answersState.answersQueue);

export const selectIsAnswersQueueEmpty = createSelector(
  selectTestItemTreeFeature,
  (answersState) => answersState.answersQueue.length === 0
);

export const selectSendAnswerAttempt = createSelector(selectTestItemTreeFeature, (answersState) => answersState.sendAnswersAttempt);
