import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';

export interface PageMetadata {
  description: string;
  imageUrl: string;
  siteUrl: string;
  title: string;
  type: string;
}

@Injectable()
export class MetadataService {
  private readonly defaultMetadata: PageMetadata;

  constructor(@Inject(DOCUMENT) private document: Document, private metaTagService: Meta, private router: Router) {
    this.defaultMetadata = {
      title: 'Usability test',
      imageUrl: `${document.location.origin}/assets/images/og/senso_Survey.png`,
      siteUrl: `${document.location.origin}${router.url}`,
      description: 'Usability testing system',
      type: 'website',
    };
  }

  public setMetadata(metadata: Partial<PageMetadata>): void {
    const pageMetadata: PageMetadata = { ...this.defaultMetadata, ...metadata };
    const metaTags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

    this.metaTagService.addTags(metaTags);
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: metadata.title },
      { property: 'og:title', content: metadata.title },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },

      /* { property: 'og:image:secure_url', content: metadata.imageUrl },
      { property: 'og:image:width', content: '1200' },
      { property: 'og:image:height', content: '630' },*/
      { property: 'og:type', content: metadata.type },
      { property: 'og:url', content: metadata.siteUrl },
    ];
  }
}
