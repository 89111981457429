import { Injectable } from '@angular/core';

import { PersistenceService } from './persistence.service';
import { Theme } from '../models/theme';
import { Themes } from '../models/themes';
import { ThemeFilename } from '../models/theme-filename';

import { THEMES } from '../constants/themes';
import { DEFAULT_THEME_FILENAME } from '../constants/default-theme-filename';

@Injectable({
  providedIn: 'root'
})
export class ThemesService {

  selectedTheme: ThemeFilename = DEFAULT_THEME_FILENAME;
  selectedThemeImage: string | undefined;
  themes: Themes;

  private readonly darkTheme: ThemeFilename = 'arya-green';

  constructor(private persistenceService: PersistenceService) {
    this.themes = THEMES;
  }

  get isDarkTheme(): boolean {
    const darkThemes: ThemeFilename[] = [
      'bootstrap4-dark-blue',
      'bootstrap4-dark-purple',
      'md-dark-indigo',
      'md-dark-deeppurple',
      'mdc-dark-indigo',
      'mdc-dark-deeppurple',
      'vela-blue',
      'vela-green',
      'vela-orange',
      'vela-purple',
      'arya-blue',
      'arya-green',
      'arya-orange',
      'arya-purple'
    ];

    return darkThemes.includes(this.selectedTheme);
  }

  get isFabuzaTheme(): boolean {
    const selectedTheme = this.persistenceService.get('selectedTheme');
    return selectedTheme === DEFAULT_THEME_FILENAME || selectedTheme === this.darkTheme;
  }

  changeTheme(filename: ThemeFilename): void {
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = `assets/css/themes/${filename}/theme.css`;
    this.setSelectedTheme(filename);
  }

  setDefaultTheme(): void {
    const selectedTheme = this.persistenceService.get('selectedTheme');
    // const defaultThemeFilename = selectedTheme || DEFAULT_THEME_FILENAME;
    if (selectedTheme) {
      this.changeTheme(selectedTheme);
    }
  }

  private setSelectedTheme(filename: ThemeFilename): void {
    Object.values(this.themes).forEach((themes: Theme[]) => {
      themes.forEach((theme: Theme) => {
        if (theme.filename === filename) {
          this.selectedTheme = theme.filename;
          this.selectedThemeImage = theme.image;
          this.persistenceService.set('selectedTheme', theme.filename);
          return;
        }
      });
    });
  }
}
