import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../action-types';
import { MobileAppStatusEnum } from '../../models/mobile-app-status-enum';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { ExtensionErrorsEnum } from '../../models/extension-errors-enum';

export const setExtensionErrorsSuccessAction = createAction(ActionTypes.SET_EXTENSION_ERROR, props<{ status: ExtensionErrorsEnum }>());

export const setExtensionStatusSuccessAction = createAction(ActionTypes.SET_EXTENSION_STATUS, props<{ status: ExtensionStatusEnum }>());

export const setIsExtensionInstalledSuccessAction = createAction(
  ActionTypes.SET_IS_EXTENSION_INSTALLED,
  props<{ isExtensionInstalled: boolean }>()
);

export const setIsMobileAppInstalledSuccessAction = createAction(
  ActionTypes.SET_IS_MOBILE_APP_INSTALLED,
  props<{ isMobileAppInstalled: boolean }>()
);

export const setMobileAppStatusSuccessAction = createAction(
  ActionTypes.SET_MOBILE_APP_STATUS,
  props<{ status: MobileAppStatusEnum | null }>()
);

export const unknownMessageAction = createAction(ActionTypes.UNKNOWN_MSG);
