import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subscription, of, timer } from 'rxjs';
import { debounce, distinctUntilKeyChanged, map, shareReplay } from 'rxjs/operators';
import { TestItemResultStatusEnum } from 'src/api/testrunner/models';
import { Task } from 'src/api/testrunner/models/task';
import { UtilsService } from 'src/app/services/utils.service';
import { FabDictionaryItem } from '../../../api/testrunner/models/fab-dictionary-item';
import { LanguageEnum } from '../../../api/testrunner/models/language-enum';
import { RowAnswerQuantityEnum } from '../../../api/testrunner/models/row-answer-quantity-enum';
import { SelectResult } from '../../../api/testrunner/models/select-result';
import { TestItemResult } from '../../../api/testrunner/models/test-item-result';
import { TestItemResultTypeEnum } from '../../../api/testrunner/models/test-item-result-type-enum';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { lockButtonNext } from '../../store/actions/page.actions';
import { selectDictionaryById, selectDictionaryItemsById } from '../../store/selectors/test-runner.selectors';

const EMPTY_SELECT_FORM_VALUE: Partial<SelectResult> = {
  elementRowItemIds: [],
  elementStrings: [],
};

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  public description?: SafeHtml;
  public dictionaryItems$: Observable<FabDictionaryItem[]> = of([]);
  public dictionaryName$: Observable<string> = of('');
  public isSystemDictionary$: Observable<boolean> = of(false);
  public selectForm!: UntypedFormGroup; // initialize readonly form
  public selectItem?: Task;

  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly domSanitizer: DomSanitizer,
    private readonly macrosPipe: MacrosPipe,
    private readonly translocoService: TranslocoService,
    private readonly store: Store,
  ) {}

  public get columnGridWidth(): number {
    const select = this.testItemModel?.data;
    return select?.columnCount ? 12 / select?.columnCount : 12;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel?.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.domSanitizer.bypassSecurityTrustHtml(
        this.macrosPipe.transform(this.testItemModel?.data?.description, pipingItem),
      );
    }
  }

  public ngOnInit(): void {
    this.selectItem = this.testItemModel?.data;

    this.dictionaryItems$ = this.store.select(selectDictionaryItemsById(this.selectItem?.rowFabDictionaryId ?? '')).pipe(
      distinctUntilKeyChanged('length'),
      map((dictionaryItems: FabDictionaryItem[]) => {
        if (this.selectItem?.shuffleRows) {
          UtilsService.shuffleItems(dictionaryItems, this.selectItem?.lockOnShuffleDictionaryItems);
        }
        return dictionaryItems;
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    this.dictionaryName$ = this.store
      .select(selectDictionaryById(this.selectItem?.rowFabDictionaryId ?? ''))
      .pipe(
        map(
          (fabDictionary) =>
            (this.translocoService.getActiveLang() === LanguageEnum.En ? fabDictionary?.nameEn : fabDictionary?.nameRu) as string,
        ),
      );
    this.isSystemDictionary$ = this.dictionaryItems$.pipe(
      map(
        (dictionaryItems) =>
          dictionaryItems.length > 0 && this.selectItem?.rowFabDictionaryId !== this.selectItem?.internalRowFabDictionaryId,
      ),
    );

    this.initializeForm();

    if (this.selectItem?.answerQuantity === RowAnswerQuantityEnum.Single) {
      this.checkForOnlySingleAnswer();
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private checkForOnlySingleAnswer(): void {
    this.subscription.add(this.processAnswersControlValueChanges());
    this.subscription.add(this.processCustomAnswersControlValueChanges());
  }

  private getSelectedExcludingAnswer(answer: Partial<SelectResult>): string | null {
    const excludingAnswersIds = (this.selectItem?.excludingDictionaryItems || []).map(
      (dictionaryItem) => dictionaryItem.fabDictionaryItemEntityId,
    );
    return answer.elementRowItemIds?.find((elementRowItemId) => excludingAnswersIds.includes(elementRowItemId)) ?? null;
  }

  private initializeForm(): void {
    const answer = this.persistenceService.get('answer_' + this.testItemModel?.id) ?? null;

    const isSkipped: boolean = Boolean(answer?.isAnswerSkipped && this.selectItem?.isSkipButtonEnabled ? true : false);
    this.selectForm = new UntypedFormGroup({
      elementRowItemIds: new UntypedFormControl({
        value: answer?.elementRowItemIds ?? [],
        disabled: isSkipped,
      }),
      elementStrings: new UntypedFormControl({
        value: answer?.elementStrings ?? [],
        disabled: isSkipped,
      }),
      isAnswerSkipped: new FormControl<boolean>(isSkipped, {
        nonNullable: true,
      }),
    });

    if (answer) {
      this.setSelectionRestrictions(answer, this.getSelectedExcludingAnswer(answer) !== null);
    }

    this.subscription.add(this.processSelectFormValueChanges());
  }

  private save(answer: Partial<SelectResult>, isAnswerSkipped: boolean): void {
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      iterationId: this.testItemModel?.iterationId,
      rowDictionaryId: this.testItemModel?.data?.rowFabDictionaryId ?? '',
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.Select,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
      ...answer,
    } as SelectResult);
  }

  private setSelectionRestrictions(answer: Partial<SelectResult>, isExcludingItemSelected: boolean): void {
    const answerCount = (answer?.elementRowItemIds?.length ?? 0) + (answer.elementStrings?.length ?? 0);
    if (
      (this.selectItem?.answerMaxSelectedItemsEnabled && answerCount >= (this.selectItem?.answerMaxSelectedItems ?? 0)) ||
      isExcludingItemSelected
    ) {
      this.selectForm.disable({ emitEvent: false });
    } else {
      this.selectForm.enable({ emitEvent: false });
    }
  }

  private processSelectFormValueChanges(): Subscription {
    return this.selectForm.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(0);
        }),
      )
      .subscribe((result: Partial<SelectResult>) => {
        if (result.isAnswerSkipped) {
          this.selectForm.patchValue(EMPTY_SELECT_FORM_VALUE, { emitEvent: false });
          this.selectForm.controls.elementRowItemIds.disable({ emitEvent: false });
          this.selectForm.controls.elementStrings.disable({ emitEvent: false });
          this.save(EMPTY_SELECT_FORM_VALUE, result.isAnswerSkipped);
          return;
        } else {
          this.selectForm.controls.elementRowItemIds.enable({ emitEvent: false });
          this.selectForm.controls.elementStrings.enable({ emitEvent: false });
        }

        result = {
          elementRowItemIds: result.elementRowItemIds,
          elementStrings: (result.elementStrings || []).filter((customAnswer) => !!customAnswer),
        };
        const selectedExcludedItem = this.getSelectedExcludingAnswer(result);
        if (selectedExcludedItem) {
          result = { elementStrings: [], elementRowItemIds: [selectedExcludedItem] } as Partial<SelectResult>;
          this.selectForm.patchValue(result, { emitEvent: false });
        }
        this.setSelectionRestrictions(result, selectedExcludedItem !== null);
        this.save(result, result.isAnswerSkipped ?? false);
      });
  }

  private processAnswersControlValueChanges(): Subscription {
    return this.selectForm.controls.elementRowItemIds.valueChanges.subscribe((answers: string[]) => {
      if (answers.length === 0) {
        return;
      }
      this.selectForm.controls.elementStrings.setValue([], { emitEvent: false });
    });
  }

  private processCustomAnswersControlValueChanges(): Subscription {
    return this.selectForm.controls.elementStrings.valueChanges.subscribe((answers: string[]) => {
      if (answers.length === 0) {
        return;
      }
      this.selectForm.controls.elementRowItemIds.setValue([], { emitEvent: false });
    });
  }
}
