import { Injectable } from '@angular/core';
import { Task } from '../../api/testrunner/models/task';

@Injectable({
  providedIn: 'root'
})
export class ScaleService {

  constructor() { }

  value(scaleData: Task, value: number, locale?: string): string {
    if (value === null) {
      return '';
    }
    let minimumIntegerDigits;
    let minimumFractionDigits;
    let maskLocal;

    const match = scaleData?.mask?.match(/\.|,/);
    if (match) {
      const separator = match[0];
      const arr = scaleData?.mask?.split(separator, 2) ?? [];
      maskLocal = separator === ',' ? 'fr' : 'en';
      minimumIntegerDigits = arr.length ? (match.index === 0 ? 1 : arr[0].length) : undefined;
      minimumFractionDigits = arr[1] ? arr[1].length : undefined;
    } else {
      minimumIntegerDigits = scaleData?.mask && scaleData?.mask?.length > 0 ? scaleData?.mask?.length : 1;
    }

    return new Intl.NumberFormat(locale ?? maskLocal, {
      minimumIntegerDigits,
      minimumFractionDigits,
      maximumFractionDigits: minimumFractionDigits,
    }).format(value);
  }
}
