import { CdkDragDrop, CdkDragMove, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';
import { Card } from 'primeng/card';
import {
  CardSortingResult,
  CardSortingResultItem,
  CardSortingTypeEnum,
  Task,
  TestItemCard,
  TestItemCardsGroup,
  TestItemResult,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
} from 'src/api/testrunner/models';
import { PipingItem } from 'src/app/models/piping-item';
import { TestItemCardGroupExtended } from 'src/app/models/test-item-card-group-extended';
import { TestItemModel } from 'src/app/models/test-item-model';
import { DateTimeService } from 'src/app/services/date-time.service';
import { MacrosPipe } from 'src/app/services/macros.pipe';
import { PersistenceService } from 'src/app/services/persistence.service';
import { v4 as uuidv4 } from 'uuid';

type CardSortingExtendedResult = CardSortingResult & {
  cardsOrderList?: string[];
  cardGroupsOrderList?: string[];
  customGroups?: TestItemCardGroupExtended[];
};

@Component({
  selector: 'app-card-sorting',
  templateUrl: './card-sorting.component.html',
  styleUrls: ['./card-sorting.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardSortingComponent implements OnChanges, OnInit {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  answer: CardSortingExtendedResult | null = null;
  cardGroupListDictionary: { [groupId: string]: TestItemCard[] } = {};
  cardSorting?: Task;
  dragMoveEvent: CdkDragMove<TestItemCard> | null = null;
  readonly initialCardListId = 'initial';

  initialCardList: TestItemCard[] = [];
  description?: SafeHtml;
  draggedItemContainerId: string | null = null;
  sortedCardGroups: TestItemCardGroupExtended[] = [];

  private descriptionString: string = '';

  constructor(
    public readonly breakpointObserver: BreakpointObserver,
    private readonly macrosPipe: MacrosPipe,
    private readonly persistenceService: PersistenceService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  public get canAddCustomGroup(): boolean {
    if (this.cardSorting?.cardSortingType === CardSortingTypeEnum.Closed) {
      return false;
    }

    if (!this.cardSorting?.maxCustomCardsGroupsCount) {
      return true;
    }

    return this.sortedCardGroups.length <= this.cardSorting.maxCustomCardsGroupsCount + (this.cardSorting?.cardsGroups?.length ?? 0);
  }

  public get progress(): number {
    return this.totalCardsCount ? 100 - Math.round((this.initialCardList.length / this.totalCardsCount) * 100) : 100;
  }

  public get totalCardsCount(): number {
    return this.cardSorting?.showedCardCount ? this.cardSorting.showedCardCount : (this.cardSorting?.cards || []).length;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel.iterationId,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.macrosPipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  public ngOnInit(): void {
    this.cardSorting = this.testItemModel?.data;
    this.answer = this.persistenceService.get('answer_' + this.cardSorting?.id) ?? null;

    // Исходный список карточек
    this.initialCardList = cloneDeep(this.cardSorting?.cards ?? [])
      .sort(this.initializeSortingFn(this.answer?.cardsOrderList ?? null, !!this.cardSorting?.isShuffledCards))
      .filter((card) => !this.answer?.cardsOrderList || (card.id && this.answer.cardsOrderList.includes(card.id)));

    // Показывать определенное количество карточек
    if (this.cardSorting?.showedCardCount) {
      this.initialCardList = this.initialCardList.splice(0, Math.min(this.initialCardList.length, this.cardSorting.showedCardCount));
    }

    // Список групп
    this.sortedCardGroups = [
      ...cloneDeep(this.cardSorting?.cardsGroups || []).sort(
        this.initializeSortingFn(this.answer?.cardGroupsOrderList ?? null, !!this.cardSorting?.isShuffledCardsGroups),
      ),
      ...(this.answer?.customGroups || []),
    ];

    // Инициализация словаря групп карточек
    this.sortedCardGroups.forEach((cardGroup) => {
      const cardsInGroup: TestItemCard[] = [];
      (this.answer?.resultItems || [])
        .filter((resultItem) => resultItem.cardsGroupId === cardGroup.id)
        .sort((a, b) => (a.orderInCardsGroup ?? 0) - (b.orderInCardsGroup ?? 0))
        .forEach((resultItem) => {
          const initialCard = (this.cardSorting?.cards || []).find((card) => card.id === resultItem.cardId);
          if (initialCard) {
            cardsInGroup.push(initialCard);
          }
        });
      this.cardGroupListDictionary[cardGroup.id ?? ''] = cardsInGroup;
    });
  }

  public addCustomGroup(): void {
    const customGroupId = uuidv4();
    this.sortedCardGroups.push({
      id: customGroupId,
      isCustomGroup: true,
      name: '',
      number: this.sortedCardGroups.length + 1,
      testItemId: this.testItemModel?.testItemId ?? '',
    });
    this.cardGroupListDictionary[customGroupId] = [];
  }

  //cdkDropListEnterPredicate нужно передать коллбэк возвращающий boolean
  public canDrop(group: TestItemCardsGroup | null, cardGroupList: TestItemCard[]): () => boolean {
    return (): boolean =>
      !this.cardSorting?.isCardsGroupsLimitEnabled || !group?.maxCardsCount || cardGroupList.length < group.maxCardsCount;
  }

  public dropCard(event: CdkDragDrop<TestItemCard[]>): void {
    console.log(event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.save();
  }

  public getConnectedGroupIds(groupId: string): string[] {
    return [this.initialCardListId, ...Object.keys(this.cardGroupListDictionary)].filter((cardGroupId) => cardGroupId !== groupId);
  }

  // для сложенных карточек иначе никак, приходится через координаты драга определять над какой группой перетаскиваемая карточка
  public isDragOver(cardGroupContainer: Card, cardGroupId: string): boolean {
    console.log(cardGroupContainer);
    if (!cardGroupContainer) {
      return false;
    }
    const containerRectangle = cardGroupContainer.getBlockableElement().getBoundingClientRect();
    return (
      !!this.dragMoveEvent &&
      this.dragMoveEvent.source.dropContainer.id !== cardGroupId &&
      this.dragMoveEvent.pointerPosition.x > containerRectangle.x &&
      this.dragMoveEvent.pointerPosition.x < containerRectangle.x + containerRectangle.width &&
      this.dragMoveEvent.pointerPosition.y > containerRectangle.y &&
      this.dragMoveEvent.pointerPosition.y < containerRectangle.y + containerRectangle.height
    );
  }

  public onRemoveGroup(removedCardGroup: TestItemCardGroupExtended): void {
    this.sortedCardGroups = this.sortedCardGroups.filter((cardGroup) => removedCardGroup.id !== cardGroup.id);
    this.initialCardList = [...this.initialCardList, ...(this.cardGroupListDictionary?.[removedCardGroup?.id ?? ''] || [])];
    delete this.cardGroupListDictionary[removedCardGroup?.id ?? ''];
    this.save();
  }

  public updateCardGroup(cardGroup: TestItemCardGroupExtended): void {
    const updatedCardGroupIndex = this.sortedCardGroups.findIndex((group) => group.id === cardGroup.id);
    if (updatedCardGroupIndex < 0) {
      return;
    }
    this.sortedCardGroups = [
      ...this.sortedCardGroups.slice(0, updatedCardGroupIndex),
      cardGroup,
      ...this.sortedCardGroups.slice(updatedCardGroupIndex + 1),
    ];
    this.save();
  }

  private initializeSortingFn(
    sortingList: string[] | null,
    isShuffle: boolean,
  ): (a: TestItemCard | TestItemCardsGroup, b: TestItemCard | TestItemCardsGroup) => number {
    return sortingList
      ? (a, b): number => sortingList.indexOf(a.id ?? '') - sortingList.indexOf(b.id ?? '') // восстановление порядка карточек
      : isShuffle
        ? (): number => Math.random() - 0.5 // рандомизация
        : (a, b): number => (a.number ?? 0) - (b.number ?? 0); // сортировка по ордеру
  }

  private save(): void {
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      cardsOrderList: this.initialCardList.map((card) => card.id),
      cardGroupsOrderList: this.sortedCardGroups.map((cardGroup) => cardGroup.id),
      customGroups: this.sortedCardGroups.filter((cardGroup) => cardGroup.isCustomGroup),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      iterationId: this.testItemModel?.iterationId,
      resultItems: this.sortedCardGroups.reduce(
        (resultItems: CardSortingResultItem[], cardsGroup) => [
          ...resultItems,
          ...(this.cardGroupListDictionary?.[cardsGroup.id ?? ''] || []).map(
            (card, order) =>
              ({
                cardsGroupId: cardsGroup.id,
                customGroupName: cardsGroup?.name ?? '',
                cardId: card.id,
                isCustomGroup: cardsGroup?.isCustomGroup,
                orderInCardsGroup: order,
              }) as CardSortingResultItem,
          ),
        ],
        [],
      ),
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.CardSorting,
      status: TestItemResultStatusEnum.Intermediate,
    } as CardSortingExtendedResult);
  }
}
