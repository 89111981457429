import { createAction, props } from '@ngrx/store';

import { ActionTypes } from '../action-types';
import { TestItemResult } from 'src/api/testrunner/models/test-item-result';
import { TestItemResultStatusEnum } from 'src/api/testrunner/models/test-item-result-status-enum';
import { HttpErrorResponse } from '@angular/common/http';

export const changePageAnswerAction = createAction(ActionTypes.CHANGE_PAGE_ANSWER, props<{ answers: Array<TestItemResult> }>());
export const changePageAnswerSuccessAction = createAction(
  ActionTypes.CHANGE_PAGE_ANSWER_SUCCESS,
  props<{ answers: Array<TestItemResult> }>()
);

export const saveAnswersAction = createAction(ActionTypes.SAVE_ANSWERS, props<{ answers: Array<TestItemResult> }>());
export const saveAnswersToQueueAction = createAction(ActionTypes.SAVE_ANSWERS_TO_QUEUE, props<{ answers: Array<TestItemResult> }>());
export const saveQueueAction = createAction(ActionTypes.SAVE_QUEUE);
export const saveAnswersErrorAction = createAction(ActionTypes.SAVE_ANSWERS_ERROR, props<{ error: HttpErrorResponse }>());
export const saveAnswersSuccessAction = createAction(ActionTypes.SAVE_ANSWERS_SUCCESS, props<{ removedCount: number }>());

export const savePageAnswersAction = createAction(ActionTypes.SAVE_PAGE_ANSWERS, props<{ answerStatus: TestItemResultStatusEnum }>());
