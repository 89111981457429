import { Action, createReducer, on } from '@ngrx/store';
import { answersAdapter, AnswersState } from '../states/answers.state';

import { TestItemResult } from 'src/api/testrunner/models/test-item-result';
import { saveAnswersErrorAction, saveAnswersSuccessAction, saveAnswersToQueueAction } from '../actions/answers.actions';
import { startSessionSuccessAction } from '../actions/test-runner.actions';

export const initialState: AnswersState = answersAdapter.getInitialState({
  answersQueue: new Array<TestItemResult>(),
  sendAnswersAttempt: 0,
});

export const reducer = createReducer(
  initialState,
  on(
    saveAnswersToQueueAction,
    (state, { answers }): AnswersState =>
      answersAdapter.upsertMany(answers, { ...state, answersQueue: [...state.answersQueue, ...answers] })
  ),
  /*on(
    saveQueueAction,
    (state): AnswersState => ({ ...state, sendAnswersAttempt: state.sendAnswersAttempt + (state.answersQueue.length ? 1 : 0) })
  ),*/
  on(saveAnswersErrorAction, (state): AnswersState => ({ ...state, sendAnswersAttempt: state.sendAnswersAttempt + 1 })),
  on(
    saveAnswersSuccessAction,
    (state, { removedCount }): AnswersState => ({
      ...state,
      answersQueue: [...state.answersQueue].splice(removedCount),
      sendAnswersAttempt: 0,
    })
  ),
  on(startSessionSuccessAction, (state, { answersQueue }): AnswersState => ({ ...state, answersQueue }))
);

export const answersReducers = (state: AnswersState, action: Action): AnswersState => reducer(state, action);
