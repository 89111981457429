<div class="flex flex-row w-full justify-content-start align-content-center">
  <p-autoComplete
    appendTo="body"
    class="w-6rem"
    [dropdown]="true"
    field="phoneCode"
    [ngModel]="selectedPhoneCode"
    (ngModelChange)="setPhoneMask($event)"
    [suggestions]="filteredPhoneCodes"
    (completeMethod)="filterPhoneCode($event.query)"
  >
    <ng-template let-countryPhoneCode pTemplate="selectedItem">
      <span class="country-item">+{{ countryPhoneCode.phoneCode }}</span>
    </ng-template>
    <ng-template let-countryPhoneCode pTemplate="item">
      <span class="country-item">+{{ countryPhoneCode.phoneCode }} ({{ countryPhoneCode.countryCode }})</span>
    </ng-template>
  </p-autoComplete>
  <p-inputMask
    class="flex-grow-1"
    [disabled]="phoneNumberMask === null"
    [mask]="phoneNumberMask ?? ''"
    [ngModel]="phoneNumberWithoutCode"
    (ngModelChange)="onPhoneNumberChange($event)"
    [placeholder]="placeholderPhoneNumber"
  ></p-inputMask>
</div>
