import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'timerFormat',
})
export class TimerFormatPipe implements PipeTransform {
  // eslint-disable-next-line
  transform(value: number, args?: any): string {
    const res = value > 0 ? value / 1000 : '';
    return res.toString();
  }
}
