<ng-container *transloco="let t">
  <div class="browser-header flex justify-content-center align-items-center">
    <p class="p-3 block text-center">{{ t('testrunner.mobileOnly.notSupport') }}</p>
  </div>
  <div class="browser-content">
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="mt-6 mb-3 w-30rem">{{ t('testrunner.mobileOnly.scanQr') }}</span>
      <div class="mb-5 flex justify-content-center w-full">
        <qrcode [qrdata]="url" [width]="256"></qrcode>
      </div>
    </div>
    <div class="mb-6 text-center">
      <span>{{ t('testrunner.mobileOnly.availableOs') }}</span>
      <ul class="browser-list flex flex-row justify-content-center w-full pl-0">
        <li class="mx-5 flex flex-row justify-content-center align-items-end">
          <svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_918_402)">
              <path
                d="M18.9685 8.1816C18.8289 8.2896 16.3642 9.6744 16.3642 12.7536C16.3642 16.3152 19.5004 17.5752 19.5942 17.6064C19.5798 17.6832 19.096 19.332 17.9407 21.012C16.9106 22.4904 15.8347 23.9664 14.1981 23.9664C12.5614 23.9664 12.1402 23.0184 10.2508 23.0184C8.40957 23.0184 7.7549 23.9976 6.25783 23.9976C4.76077 23.9976 3.71619 22.6296 2.51517 20.9496C1.124 18.9768 0 15.912 0 13.0032C0 8.3376 3.04227 5.8632 6.0364 5.8632C7.62734 5.8632 8.95352 6.9048 9.95236 6.9048C10.9031 6.9048 12.3857 5.8008 14.1957 5.8008C14.8816 5.8008 17.3462 5.8632 18.9685 8.1816ZM13.3364 3.8256C14.0849 2.94 14.6145 1.7112 14.6145 0.4824C14.6145 0.312 14.6 0.1392 14.5687 0C13.3508 0.0456 11.9019 0.8088 11.0282 1.8192C10.3423 2.5968 9.70205 3.8256 9.70205 5.0712C9.70205 5.2584 9.73334 5.4456 9.74778 5.5056C9.8248 5.52 9.94996 5.5368 10.0751 5.5368C11.1678 5.5368 12.5421 4.8072 13.3364 3.8256Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_918_402">
                <rect width="19.5918" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div class="block ml-2">iOS: 12-15</div>
        </li>
        <li class="mx-5 flex flex-row justify-content-center align-items-end">
          <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_918_406)">
              <path
                d="M6.18861 0.0683932C6.07774 0.0694618 5.96265 0.0964754 5.8578 0.155173C5.52484 0.341576 5.40272 0.773723 5.58715 1.10975L6.33897 2.48086C4.52765 3.61278 3.32737 5.54612 3.32737 7.75271C3.32737 7.76197 3.32734 7.77589 3.32737 7.78959C3.32738 7.79443 3.32732 7.79718 3.32737 7.80261C3.32737 7.80982 3.32737 7.82035 3.32737 7.82213V8.16274C2.97949 7.75849 2.46811 7.50322 1.89675 7.50322C0.849993 7.50322 0 8.36168 0 9.41887V15.3199C0 16.3771 0.849993 17.2355 1.89675 17.2355C2.46811 17.2355 2.97949 16.9803 3.32737 16.576V17.4026C3.32737 18.517 4.23026 19.4289 5.33367 19.4289H5.84277V22.054C5.84277 23.1111 6.69276 23.9696 7.73952 23.9696C8.78627 23.9696 9.63627 23.1111 9.63627 22.054V19.4289H10.517V22.054C10.517 23.1111 11.367 23.9696 12.4137 23.9696C13.4605 23.9696 14.3105 23.1111 14.3105 22.054V19.4289H14.8196C15.923 19.4289 16.8259 18.517 16.8259 17.4026V16.576C17.1738 16.9803 17.6851 17.2355 18.2565 17.2355C19.3033 17.2355 20.1532 16.3771 20.1532 15.3199V9.41887C20.1532 8.36168 19.3033 7.50322 18.2565 7.50322C17.6851 7.50322 17.1738 7.75849 16.8259 8.16274V7.82864V7.82213C16.8259 7.81169 16.8259 7.80131 16.8259 7.80261C16.826 7.78181 16.8259 7.76524 16.8259 7.75271C16.8259 5.54651 15.6229 3.61503 13.8121 2.48303L14.5661 1.10974C14.7505 0.773721 14.6284 0.341574 14.2954 0.155171C14.1906 0.0964734 14.0755 0.0694611 13.9646 0.0683912C13.7188 0.0660144 13.4774 0.196889 13.3503 0.428526L12.5619 1.8669C11.7911 1.5796 10.9524 1.42215 10.0766 1.42215C9.20177 1.42215 8.3615 1.57799 7.5913 1.86473L6.80296 0.428526C6.67583 0.196889 6.43449 0.0660198 6.18861 0.0683912V0.0683932Z"
                fill="white"
              />
              <path
                d="M1.89683 8.00195C1.1157 8.00195 0.494141 8.62971 0.494141 9.41863V15.3196C0.494141 16.1086 1.1157 16.7363 1.89683 16.7363C2.67797 16.7363 3.29953 16.1086 3.29953 15.3196V9.41863C3.29953 8.62971 2.67797 8.00195 1.89683 8.00195ZM18.2566 8.00195C17.4754 8.00195 16.8539 8.62971 16.8539 9.41863V15.3196C16.8539 16.1086 17.4754 16.7363 18.2566 16.7363C19.0377 16.7363 19.6593 16.1086 19.6593 15.3196V9.41863C19.6593 8.62971 19.0377 8.00195 18.2566 8.00195Z"
                fill="#A4C639"
              />
              <path
                d="M7.73863 14.7363C6.9575 14.7363 6.33594 15.3641 6.33594 16.153V22.054C6.33594 22.8429 6.9575 23.4707 7.73863 23.4707C8.51977 23.4707 9.14132 22.8429 9.14132 22.054V16.153C9.14132 15.3641 8.51977 14.7363 7.73863 14.7363ZM12.4128 14.7363C11.6317 14.7363 11.0102 15.3641 11.0102 16.153V22.054C11.0102 22.8429 11.6317 23.4707 12.4128 23.4707C13.194 23.4707 13.8155 22.8429 13.8155 22.054V16.153C13.8155 15.3641 13.194 14.7363 12.4128 14.7363Z"
                fill="#A4C639"
              />
              <path
                d="M6.19374 0.567393C6.16058 0.567735 6.12968 0.576375 6.09923 0.593426C5.9992 0.649423 5.96631 0.765506 6.0219 0.866782L7.01001 2.66962C5.10892 3.66867 3.82484 5.56726 3.82227 7.74622H16.3327C16.3301 5.56726 15.046 3.66867 13.1449 2.66962L14.133 0.866782C14.1886 0.765506 14.1557 0.649423 14.0557 0.593426C14.0252 0.576375 13.9943 0.567712 13.9612 0.567393C13.8902 0.566701 13.8229 0.602522 13.785 0.671528L12.784 2.4939C11.9652 2.12706 11.0467 1.92115 10.0775 1.92115C9.10823 1.92115 8.18975 2.12706 7.37089 2.4939L6.36988 0.671528C6.33201 0.602522 6.26472 0.566705 6.19374 0.567393ZM3.82227 8.2452V17.4026C3.82227 18.2487 4.49673 18.9299 5.33451 18.9299H14.8204C15.6582 18.9299 16.3327 18.2487 16.3327 17.4026V8.2452H3.82227Z"
                fill="#A4C639"
              />
              <path
                d="M7.18995 4.37891C6.90436 4.37891 6.66797 4.61766 6.66797 4.90609C6.66797 5.19453 6.90436 5.43328 7.18995 5.43328C7.47554 5.43328 7.71193 5.19453 7.71193 4.90609C7.71193 4.61766 7.47554 4.37891 7.18995 4.37891ZM12.964 4.37891C12.6784 4.37891 12.442 4.61766 12.442 4.90609C12.442 5.19453 12.6784 5.43328 12.964 5.43328C13.2496 5.43328 13.486 5.19453 13.486 4.90609C13.486 4.61766 13.2496 4.37891 12.964 4.37891Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_918_406">
                <rect width="20.2105" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
          <div class="block ml-2">Android: 8-12</div>
        </li>
      </ul>
    </div>
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="mb-3">{{ t('testrunner.mobileOnly.copyLink') }}</span>
      <div class="mb-5 flex justify-content-center w-full">
        <input class="browser-input w-24rem" id="link" type="text" readonly pInputText [value]="url" />
        <button
          class="p-button-outlined p-button-secondary ml-2"
          style="border-radius: 6px"
          [cdkCopyToClipboard]="url"
          type="button"
          pButton
          pRipple
          icon="pi pi-clone"
          [label]="t('testrunner.mobileOnly.copy')"
        ></button>
      </div>
    </div>
  </div>
</ng-container>
