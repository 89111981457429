import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FabDictionaryItem } from '../../../api/testrunner/models/fab-dictionary-item';
import { RowAnswerQuantityEnum } from '../../../api/testrunner/models/row-answer-quantity-enum';
import { TestItemModel } from '../../models/test-item-model';
import { TranslationCaption } from '../../models/translation-caption';
import { DictionaryToolsService } from '../../services/dictionary-tools.service';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-select-system-dictionary',
  templateUrl: './select-system-dictionary.component.html',
  styleUrls: ['./select-system-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSystemDictionaryComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSystemDictionaryComponent implements ControlValueAccessor, OnInit {
  @Input() public answerQuantity?: RowAnswerQuantityEnum;
  @Input() public dictionaryName = '';
  @Input() public fabDictionaryItems: FabDictionaryItem[] = [];
  @Input() public columnGridWidth: number = 12;
  @Input() public model?: TestItemModel;

  public readonly answerQuantityEnum: typeof RowAnswerQuantityEnum = RowAnswerQuantityEnum;
  public readonly dictionaryLanguage: TranslationCaption = this.translationService.getDictionaryLanguages();
  public dictionaryDepthLevel: number = 0;
  private flattenDictionaryItems: FabDictionaryItem[] = [];
  private groupedDictionaryItems: FabDictionaryItem[] = [];
  private isDisabled: boolean = false;
  public searchResults: FabDictionaryItem[] = [];
  public selectedItem: FabDictionaryItem | null = null;
  public selectedItems: FabDictionaryItem[] = [];

  constructor(
    private readonly dictionaryToolsService: DictionaryToolsService,
    private readonly translationService: TranslationsService,
  ) {}

  public getDictionaryItemCaption(entity: FabDictionaryItem | string): string {
    return this.dictionaryToolsService.getDictionaryItemCaption(entity, this.flattenDictionaryItems);
  }

  public ngOnInit(): void {
    this.dictionaryDepthLevel = this.dictionaryToolsService.getDictionaryDepth(this.fabDictionaryItems);
    this.flattenDictionaryItems = this.dictionaryToolsService.getFlattenDictionaryItems(this.fabDictionaryItems);
    this.groupedDictionaryItems =
      this.dictionaryDepthLevel > 0
        ? this.dictionaryToolsService.convertDictionaryToTwoLevelList(this.fabDictionaryItems, this.dictionaryDepthLevel)
        : this.fabDictionaryItems;
  }

  public writeValue(elementRowItemIds: string[]): void {
    if (this.answerQuantity === RowAnswerQuantityEnum.Multiple) {
      this.selectedItems = this.flattenDictionaryItems.filter((dictionaryItem) =>
        elementRowItemIds.includes(dictionaryItem?.entityId ?? ''),
      );
    } else {
      this.selectedItem = this.flattenDictionaryItems.find((dictionaryItem) => elementRowItemIds?.[0] === dictionaryItem?.entityId) ?? null;
    }
  }

  public registerOnChange(fn: (elementRowItemIds: string[]) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public searchInDictionary(searchQuery: string): void {
    if (!this.isDisabled) {
      this.searchResults = this.dictionaryToolsService.filterRecursive(
        this.groupedDictionaryItems,
        (fabDictionaryItems) =>
          fabDictionaryItems[this.translationService.getDictionaryLanguages()]?.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1,
      );
    } else {
      this.searchResults = [];
    }
  }

  public updateValue(): void {
    if (this.answerQuantity === RowAnswerQuantityEnum.Multiple) {
      this.onChange(this.selectedItems.map((dictionaryItem) => dictionaryItem.entityId as string));
    } else {
      this.onChange(this.selectedItem?.entityId ? [this.selectedItem.entityId] : []);
    }
    this.onTouched();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: string[]): void => {};
  private onTouched = (): void => {};
}
