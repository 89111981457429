<div *ngIf="isTestLoading$ | async" class="flex w-full h-screen align-items-center justify-content-center spinner-container">
  <p-progressSpinner class="align-self-center"></p-progressSpinner>
</div>
<div class="wrapper flex flex-column flex-nowrap">
  <!--  <header-->
  <!--    [@fadeInDownOnEnter]-->
  <!--    [@fadeInDown]="fadeInDownAnimationTrigger"-->
  <!--    (@fadeInDown.done)="fadeInDownAnimationTrigger = false"-->
  <!--  >-->
  <header *ngIf="areHeaderAndFooterVisible$ | async" class="flex-shrink-0">
    <app-header *ngIf="(isHeaderVisible$ | async) === true"></app-header>
  </header>

  <!--  <article class="main" [@fadeInDownOnEnter] [@fadeInDown]="fadeInDownAnimationTrigger">-->
  <article class="article w-full flex justify-content-center p-3 flex-shrink-1">
    <!--    <div class="xl:w-6 lg:w-8 md:w-10 align-self-center">-->
    <div class="xl:w-8 lg:w-10 md:w-12 sm:w-12 w-full align-self-center h-full">
      <router-outlet></router-outlet>
    </div>
  </article>

  <!--  <footer [@fadeInDownOnEnter] [@fadeInDown]="fadeInDownAnimationTrigger">-->
  <footer *ngIf="areHeaderAndFooterVisible$ | async" class="flex-shrink-0">
    <!--    <app-footer></app-footer>-->
    <app-test-completion-progress-bar></app-test-completion-progress-bar>
  </footer>
</div>
