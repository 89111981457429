import { Action, createReducer, on } from '@ngrx/store';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { saveAnswersErrorAction } from '../actions/answers.actions';
import {
  setExtensionErrorsSuccessAction,
  setExtensionStatusSuccessAction,
  setIsExtensionInstalledSuccessAction,
  setIsMobileAppInstalledSuccessAction,
  setMobileAppStatusSuccessAction,
} from '../actions/message.actions';
import { getNextPageSuccessAction, getPrevPageSuccessAction, initPageSuccessAction } from '../actions/page.actions';
import { saveVideoAction, saveVideoSuccessAction } from '../actions/telemetry.actions';
import {
  backendErrorsAction,
  finishTestAction,
  getPromoCodeErrorAction,
  getPromoCodeSuccessAction,
  getSessionCacheAction,
  setLoaderStatus,
  setMediaDevices,
  setTestInfoAction,
  startSessionAction,
  startSessionErrorAction,
  startSessionSuccessAction,
} from '../actions/test-runner.actions';
import { TestRunnerState } from '../states/test-runner.state';

export const initialState: TestRunnerState = {
  backendErrors: null,
  cameraDeviceId: null,
  completeTestInfo: null,
  extensionErrors: null,
  extensionStatus: null,
  isExtensionInstalled: false,
  isLoading: false,
  isMobileAppInstalled: false,
  isTestLoading: true,
  isVideoLoading: false,
  microphoneDeviceId: null,
  mobileAppStatus: null,
  promoCodes: {},
  redirectUrl: null,
  testInfo: null,
  testRunnerStartData: null,
};

export const reducer = createReducer(
  initialState,
  on(
    backendErrorsAction,
    (state, { error }): TestRunnerState => ({
      ...state,
      backendErrors: error,
      isLoading: false,
      isTestLoading: false,
      isVideoLoading: false,
    }),
  ),
  on(initPageSuccessAction, (state): TestRunnerState => ({ ...state, mobileAppStatus: null })),
  on(
    finishTestAction,
    (state, { testInfo, completeTestInfo, url }): TestRunnerState => ({
      ...state,
      testInfo,
      completeTestInfo: completeTestInfo ?? null,
      redirectUrl: url,
    }),
  ),
  on(getNextPageSuccessAction, (state, { testInfo }): TestRunnerState => ({ ...state, testInfo })),
  on(getPrevPageSuccessAction, (state, { testInfo }): TestRunnerState => ({ ...state, testInfo })),
  on(
    getPromoCodeSuccessAction,
    (state, { promoCode, testItemId }): TestRunnerState => ({ ...state, promoCodes: { ...state.promoCodes, [testItemId]: promoCode } }),
  ),
  on(
    getPromoCodeErrorAction,
    (state, { testItemId }): TestRunnerState => ({ ...state, promoCodes: { ...state.promoCodes, [testItemId]: null } }),
  ),
  on(getSessionCacheAction, (state): TestRunnerState => ({ ...state, isLoading: true })),
  on(
    saveAnswersErrorAction,
    (state, { error }): TestRunnerState => ({
      ...state,
      backendErrors: error,
      isLoading: false,
      isTestLoading: false,
      isVideoLoading: false,
    }),
  ),
  on(saveVideoAction, (state): TestRunnerState => ({ ...state, isVideoLoading: true })),
  on(saveVideoSuccessAction, (state): TestRunnerState => ({ ...state, isVideoLoading: false })),
  on(setTestInfoAction, (state, { testInfo }): TestRunnerState => ({ ...state, testInfo })),
  on(
    setExtensionStatusSuccessAction,
    (state, { status }): TestRunnerState => ({
      ...state,
      extensionStatus: status !== ExtensionStatusEnum.UploadingVideoCompleted ? status : null,
    }),
  ),
  on(
    setExtensionErrorsSuccessAction,
    (state, { status }): TestRunnerState => ({
      ...state,
      extensionErrors: status ? status : null,
    }),
  ),
  on(setIsExtensionInstalledSuccessAction, (state, { isExtensionInstalled }): TestRunnerState => ({ ...state, isExtensionInstalled })),
  on(setIsMobileAppInstalledSuccessAction, (state, { isMobileAppInstalled }): TestRunnerState => ({ ...state, isMobileAppInstalled })),
  on(setMobileAppStatusSuccessAction, (state, { status }): TestRunnerState => ({ ...state, mobileAppStatus: status })),
  on(setMediaDevices, (state, permissions): TestRunnerState => ({ ...state, ...permissions })),
  on(startSessionAction, (state): TestRunnerState => ({ ...state, isLoading: true })),
  on(
    startSessionErrorAction,
    (state, { completeTestInfo, testRunnerStartData, url }): TestRunnerState => ({
      ...state,
      completeTestInfo,
      redirectUrl: url,
      isLoading: false,
      isTestLoading: false,
      isMobileAppInstalled: false,
      testRunnerStartData,
    }),
  ),
  on(
    startSessionSuccessAction,
    (state, { testRunnerStartData, isMobileAppInstalled }): TestRunnerState => ({
      ...state,
      testRunnerStartData,
      isLoading: false,
      isTestLoading: false,
      isMobileAppInstalled,
    }),
  ),
  on(setLoaderStatus, (state): TestRunnerState => ({ ...state, isTestLoading: false })),
);

export const testRunnerReducers = (state: TestRunnerState, action: Action): TestRunnerState => reducer(state, action);
