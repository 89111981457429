import { ChangeDetectionStrategy, Component } from '@angular/core';
import adapter from 'webrtc-adapter';
import { TranslationsService } from '../../services/translations.service';

@Component({
  selector: 'app-browser-not-support',
  templateUrl: './browser-not-support.component.html',
  styleUrls: ['./browser-not-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowserNotSupportComponent {
  public readonly currentBrowser: string;
  public readonly url: string = document.documentURI;

  constructor(translationsService: TranslationsService) {
    this.currentBrowser = adapter.browserDetails.browser + ' v.' + adapter.browserDetails.version;
    translationsService.setDefaultTranslation();
  }
}
