import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { KanoFeature, KanoFeatureAnswer } from 'src/api/testrunner/models';
import { KanoFeatureScoreEnum } from 'src/app/models/kano-feature-score-enum';

interface KanoFormStructure {
  functionalQuestionScore: FormControl<number>;
  dysfunctionalQuestionScore: FormControl<number>;
  valueQuestionScore: FormControl<number>;
}

@Component({
  selector: 'app-kano-feature',
  templateUrl: './kano-feature.component.html',
  styleUrls: ['./kano-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanoFeatureComponent implements OnInit, OnDestroy {
  @Input() public answer?: KanoFeatureAnswer;
  @Input() public feature!: KanoFeature;
  @Input() public featuresCount: number = 0;
  @Input() public isImportanceQuestionEnabled: boolean = false;
  @Output() public readonly answerChange: EventEmitter<KanoFeatureAnswer> = new EventEmitter<KanoFeatureAnswer>();

  public readonly kanoFeatureScoreEnum: typeof KanoFeatureScoreEnum = KanoFeatureScoreEnum;

  public readonly kanoForm: FormGroup<KanoFormStructure> = new FormGroup<KanoFormStructure>({
    functionalQuestionScore: new FormControl<number>(0, { nonNullable: true }),
    dysfunctionalQuestionScore: new FormControl<number>(0, { nonNullable: true }),
    valueQuestionScore: new FormControl<number>(0, { nonNullable: true }),
  });

  public readonly kanoFeatureScores = [
    KanoFeatureScoreEnum.like,
    KanoFeatureScoreEnum.except,
    KanoFeatureScoreEnum.neutral,
    KanoFeatureScoreEnum.canLive,
    KanoFeatureScoreEnum.dislike,
  ];

  private readonly subscription: Subscription = new Subscription();

  constructor() {}

  public ngOnInit(): void {
    this.kanoForm.patchValue(
      {
        functionalQuestionScore: this.answer?.functionalQuestionScore ?? 0,
        dysfunctionalQuestionScore: this.answer?.dysfunctionalQuestionScore ?? 0,
        valueQuestionScore: this.answer?.valueQuestionScore ?? 0,
      },
      { emitEvent: false },
    );
    this.subscription.add(this.processKanoFormValueChanges());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private processKanoFormValueChanges(): Subscription {
    return this.kanoForm.valueChanges
      .pipe(debounceTime(400))
      .subscribe(({ functionalQuestionScore, dysfunctionalQuestionScore, valueQuestionScore }) =>
        this.answerChange.emit({
          featureId: this.feature.id,
          functionalQuestionScore: functionalQuestionScore ?? 0,
          dysfunctionalQuestionScore: dysfunctionalQuestionScore ?? 0,
          valueQuestionScore: valueQuestionScore ?? 0,
        }),
      );
  }
}
