<div class="flex flex-column align-content-center justify-content-start p-0 w-full h-full" *transloco="let t">
  <div class="text-xl mb-3 ml-3">{{ t('testrunner.webSite.microphone') }}</div>
  <div
    class="flex align-items-center justify-content-center surface-hover h-full"
    style="border-radius: 1rem; min-height: 25vw;"
  >
    <i class="fa-regular fa-microphone-slash text-6xl md:text-7xl xl:text-8xl p-text-secondary"
       [class.hidden]="selectedMicrophoneDeviceId"></i>
    <i class="fa-regular fa-microphone text-6xl md:text-7xl xl:text-8xl p-text-secondary" [class.hidden]="!selectedMicrophoneDeviceId"></i>
  </div>
  <div>
    <p-dropdown
      [disabled]="isMicrophoneRequested"
      [options]="microphoneDevices"
      [(ngModel)]="selectedMicrophoneDeviceId"
      optionLabel="label"
      optionValue="deviceId"
      (onChange)="selectMicrophone($event.value)"
      styleClass="w-12"
    ></p-dropdown>
  </div>

  <button
    *ngIf="!isMicrophoneRequested"
    pButton
    pRipple
    type="button"
    (click)="requestPermissions()"
    [disabled]="!selectedMicrophoneDeviceId"
    [label]="t('testrunner.webSite.allowMicrophone')"
    class="p-button-success"
  ></button>
  <button
    *ngIf="isMicrophoneRequested"
    pButton
    pRipple
    type="button"
    icon="pi pi-check"
    [label]="t('testrunner.webSite.microphoneAllowed')"
    class="p-button-outlined p-button-success"
  ></button>
</div>
