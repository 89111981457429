import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, DoCheck, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeNGConfig } from 'primeng/api';
import { Observable, Subscription, combineLatest, map, shareReplay } from 'rxjs';
import { environment } from '../environments/environment';
import { ThemeFilename } from './models/theme-filename';
import { MetadataService } from './services/metadata.service';
import { ThemesService } from './services/themes.service';
import { TranslationsService } from './services/translations.service';
import {
  selectIsDarkTheme,
  selectIsHeaderVisible,
  selectIsTestLoading,
  selectIsWrongBrowser,
  selectIsWrongDesktopDevice,
  selectIsWrongMobileDevice,
} from './store/selectors/test-runner.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements DoCheck, OnInit, OnDestroy {
  public fadeInDownAnimationTrigger = false;
  public readonly isHeaderVisible$: Observable<boolean> = this.store.select(selectIsHeaderVisible);
  public readonly isTestLoading$: Observable<boolean> = this.store.select(selectIsTestLoading);
  private prevSelectedTheme?: ThemeFilename;

  public readonly areHeaderAndFooterVisible$: Observable<boolean> = combineLatest([
    this.store.select(selectIsWrongBrowser),
    this.store.select(selectIsWrongDesktopDevice),
    this.store.select(selectIsWrongMobileDevice),
  ]).pipe(
    map(([isWrongBrowser, isWrongDesktopDevice, isWrongMobileDevice]) => !(isWrongBrowser || isWrongDesktopDevice || isWrongMobileDevice)),
    shareReplay(1),
  );

  private readonly subscription: Subscription = new Subscription();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Optional() private readonly metadataService: MetadataService,
    private readonly primengConfig: PrimeNGConfig,
    private readonly store: Store,
    private readonly themesService: ThemesService,
    private readonly translationsService: TranslationsService,
  ) {}

  /*@HostListener('window:message', ['$event'])
  handleMessage(event: MessageEvent): void {
    switch (event.data as MessageEventDataEnum) {
      case MessageEventDataEnum.ExtensionInstalled:
        this.store.isExtensionInstalled = true;
        break;
      case MessageEventDataEnum.ExtensionTaskInProgress:
        this.store.extensionStatus = ExtensionStatusEnum.TaskInProgress;
        break;
      case MessageEventDataEnum.ExtensionTaskCompleted:
        this.store.extensionStatus = ExtensionStatusEnum.TaskCompleted;
        break;
      case MessageEventDataEnum.ExtensionUploadingVideoInProgress:
        this.store.extensionStatus = ExtensionStatusEnum.UploadingVideoInProgress;
        break;
      case MessageEventDataEnum.ExtensionUploadingVideoCompleted:
        this.store.extensionStatus = ExtensionStatusEnum.UploadingVideoCompleted;
        break;
      case MessageEventDataEnum.MobileAppInstalled:
        this.store.isMobileAppInstalled = true;
        this.persistenceService.set('isMobileAppInstalled', true);
        break;
      case MessageEventDataEnum.MobileAppTaskInProgress:
        this.store.mobileAppStatus = MobileAppStatusEnum.TaskInProgress;
        break;
      case MessageEventDataEnum.MobileAppTaskCompleted:
        this.store.mobileAppStatus = MobileAppStatusEnum.TaskCompleted;
        break;
      case MessageEventDataEnum.MobileAppUploadingVideoInProgress:
        this.store.mobileAppStatus = MobileAppStatusEnum.UploadingVideoInProgress;
        break;
      case MessageEventDataEnum.MobileAppUploadingVideoCompleted:
        this.store.mobileAppStatus = MobileAppStatusEnum.UploadingVideoCompleted;
        break;
    }
    console.log(event.data);
  }*/

  ngDoCheck(): void {
    if (this.themesService.selectedTheme !== this.prevSelectedTheme) {
      this.prevSelectedTheme = this.themesService.selectedTheme;
      this.fadeInDownAnimationTrigger = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
    this.themesService.setDefaultTheme();
    if (!this.themesService.isFabuzaTheme) {
      this.subscription.add(
        this.store.select(selectIsDarkTheme).subscribe((theme) => {
          this.themesService.changeTheme(theme as ThemeFilename);
        }),
      );
    }
    this.translationsService.setDefaultTranslation();
    this.metadataService.setMetadata({
      /*description: this.translocoService.translate('metadata.description'),
      title: this.translocoService.translate('metadata.title'),*/
    });

    const favicon = this.document.querySelector('#favicon') as HTMLLinkElement;
    favicon.href = environment.favicon as string;
  }
}
