<ng-container *transloco="let t">
  <div *ngIf="description">
    <div [innerHtml]="description" class="inline-block task-description pb-4"></div>
    <!--<span *ngIf="ranking.data?.answerRequired" class="p-text-bold ml-1 required">*</span>-->
    <span *ngIf="!ranking.answerRequired" class="ml-1">({{ t('testrunner.optional') }})</span>
  </div>

  <p-pickList
    [source]="sourceItems"
    [target]="targetItems"
    [disabled]="isAnswerSkipped$ | async"
    [dragdrop]="true"
    [showTargetControls]="false"
    [showSourceControls]="false"
    (onMoveToTarget)="onMoveToTarget($event)"
    (onMoveToSource)="saveAnswer()"
    (onTargetReorder)="saveAnswer()"
  >
    <ng-template let-item pTemplate="item" let-i="index">
      <div class="flex justify-content-start align-items-center">
        <div *ngIf="item.imageUrl && item.imageUrl.length" class="mr-2">
          <app-image [url]="item.imageUrl"></app-image>
        </div>
        <div class="word-break" [innerHTML]="item.captionRu ?? '' | safeHtml"></div>
      </div>
    </ng-template>
    <ng-template pTemplate="emptymessagetarget">
      <div class="flex justify-content-center">
        {{ t('testrunner.ranking.dragCards') }}
      </div>
    </ng-template>
  </p-pickList>

  <p-checkbox
    *ngIf="ranking.isSkipButtonEnabled"
    inputId="isSkipButtonEnabled"
    name="isSkipButtonEnabled"
    [binary]="true"
    [formControl]="isAnswerSkippedControl"
    [label]="ranking.skipButtonText || t('testrunner.freeform.noAnswer')"
  ></p-checkbox>
</ng-container>
