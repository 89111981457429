<ng-container *transloco="let t">
  <div class="browser-header flex justify-content-center align-items-center flex-column">
    <p class="p-2 block text-center">
      {{ t('testrunner.browserNotSupport.browser') }} <span>{{ this.currentBrowser }}</span>
    </p>
    <p class="p-2 block text-center">{{ t('testrunner.browserNotSupport.notSupport') }}</p>
  </div>
  <div class="browser-content">
    <div class="flex flex-column align-items-center justify-content-center text-center">
      <span class="mt-6 mb-3">{{ t('testrunner.browserNotSupport.copyLink') }}</span>
      <div class="mb-5 flex justify-content-center w-full">
        <input class="browser-input w-24rem" id="link" type="text" readonly pInputText [value]="url" />
        <button
          class="p-button-outlined p-button-secondary ml-2"
          style="border-radius: 6px"
          [cdkCopyToClipboard]="url"
          type="button"
          pButton
          pRipple
          icon="pi pi-clone"
          [label]="t('testrunner.mobileOnly.copy')"
        ></button>
      </div>
      <div class="mb-5 flex justify-content-center w-full">
        <qrcode [qrdata]="url" [width]="256"></qrcode>
      </div>
    </div>
    <div class="mb-6 text-center">
      <span>{{ t('testrunner.browserNotSupport.insertInBrowser') }}</span>
      <ul class="browser-list flex flex-row justify-content-center w-full pl-0">
        <li class="w-4rem mx-3">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_918_473" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
              <circle cx="20" cy="20" r="20" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_918_473)">
              <path d="M3.19727 0.0244141V24.5392H12.1384L20.0013 10.9199H39.9763V0.0244141H3.19727Z" fill="#DB4437" />
              <path d="M3.19727 0.0244141V24.5392H12.1384L20.0013 10.9199H39.9763V0.0244141H3.19727Z" fill="url(#paint0_linear_918_473)" />
              <path
                d="M12.3523 24.4597L3.31134 8.9541L3.17969 9.18109L12.1548 24.5709L12.3523 24.4597Z"
                fill="#3E2723"
                fill-opacity="0.15"
              />
              <path d="M0.0253906 39.9749H19.0403L24.8318 30.3744L27.8656 24.5397H12.1375L0.0253906 3.76562V39.9749Z" fill="#0F9D58" />
              <path
                d="M0.0253906 39.9749H19.0403L24.9067 30.2621L27.8656 24.5397H12.1375L0.0253906 3.76562V39.9749Z"
                fill="url(#paint1_linear_918_473)"
              />
              <path
                d="M27.6818 24.8414L27.4934 24.7324L18.7793 39.9747H19.0403L27.6886 24.8482L27.6818 24.8414Z"
                fill="#263238"
                fill-opacity="0.15"
              />
              <path d="M19.9992 10.9199L27.8644 24.5393L19.0391 39.9745H39.9743V10.9199H19.9992Z" fill="#FFCD40" />
              <path d="M19.9992 10.9199L27.8644 24.5393L19.0391 39.9745H39.9743V10.9199H19.9992Z" fill="url(#paint2_linear_918_473)" />
              <path d="M19.9992 10.9199L27.8644 24.5393L19.0391 39.9745H39.9743V10.9199H19.9992Z" fill="#FFCD40" />
              <path d="M19.9992 10.9199L27.8644 24.5393L19.0391 39.9745H39.9743V10.9199H19.9992Z" fill="url(#paint3_linear_918_473)" />
              <path d="M3.19727 0.0244141V24.5392H12.1384L20.0013 10.9199H39.9763V0.0244141H3.19727Z" fill="#DB4437" />
              <path d="M3.19727 0.0244141V24.5392H12.1384L20.0013 10.9199H39.9763V0.0244141H3.19727Z" fill="url(#paint4_linear_918_473)" />
              <path d="M20 10.9209V15.6763L37.7959 10.9209H20Z" fill="url(#paint5_radial_918_473)" />
              <path d="M0.0253906 39.9749H19.0403L24.7195 30.5617L27.8656 24.5397H12.1375L0.0253906 3.76562V39.9749Z" fill="#0F9D58" />
              <path
                d="M0.0253906 39.9749H19.0403L24.9816 30.1497L27.8656 24.5397H12.1375L0.0253906 3.76562V39.9749Z"
                fill="url(#paint6_linear_918_473)"
              />
              <path d="M3.19531 9.20605L16.1904 22.1989L12.1364 24.5392L3.19531 9.20605Z" fill="url(#paint7_radial_918_473)" />
              <path d="M19.0527 39.9498L23.8104 22.1992L27.8644 24.5395L19.0527 39.9498Z" fill="url(#paint8_radial_918_473)" />
              <path
                d="M19.9995 29.079C25.014 29.079 29.079 25.014 29.079 19.9995C29.079 14.985 25.014 10.9199 19.9995 10.9199C14.985 10.9199 10.9199 14.985 10.9199 19.9995C10.9199 25.014 14.985 29.079 19.9995 29.079Z"
                fill="#F1F1F1"
              />
              <path
                d="M20 27.2636C24.0116 27.2636 27.2636 24.0116 27.2636 20C27.2636 15.9884 24.0116 12.7363 20 12.7363C15.9884 12.7363 12.7363 15.9884 12.7363 20C12.7363 24.0116 15.9884 27.2636 20 27.2636Z"
                fill="#4285F4"
              />
              <path
                d="M20.0014 10.6934C14.9872 10.6934 10.9219 14.7587 10.9219 19.7729V19.9999C10.9219 14.9857 14.9872 10.9203 20.0014 10.9203H39.9765V10.6934H20.0014Z"
                fill="#3E2723"
                fill-opacity="0.2"
              />
              <path
                d="M27.8542 24.5397C26.2835 27.2499 23.3576 29.0794 20.0004 29.0794C16.641 29.0794 13.7151 27.2499 12.1443 24.5397H12.1353L0.0253906 3.76562V3.99261L12.1375 24.7666H12.1466C13.7174 27.4769 16.6433 29.3064 20.0027 29.3064C23.3599 29.3064 26.2857 27.4792 27.8565 24.7666H27.8679V24.5397H27.8542Z"
                fill="white"
                fill-opacity="0.1"
              />
              <path
                opacity="0.1"
                d="M20.2268 10.9209C20.1882 10.9209 20.1519 10.9254 20.1133 10.9277C25.0753 10.989 29.0793 15.0249 29.0793 20.0005C29.0793 24.9761 25.0753 29.0119 20.1133 29.0732C20.1519 29.0732 20.1882 29.08 20.2268 29.08C25.241 29.08 29.3063 25.0146 29.3063 20.0005C29.3063 14.9863 25.241 10.9209 20.2268 10.9209Z"
                fill="#3E2723"
              />
              <path
                d="M27.9458 24.8414C28.7176 23.5067 29.1647 21.9632 29.1647 20.3107C29.1647 19.3528 29.0149 18.4312 28.7403 17.5664C28.9559 18.3427 29.0807 19.1576 29.0807 20.002C29.0807 21.6545 28.6336 23.198 27.8618 24.5327L27.8663 24.5418L19.041 39.977H19.3043L27.9503 24.8505L27.9458 24.8414Z"
                fill="white"
                fill-opacity="0.2"
              />
              <path
                d="M20.0004 0.251403C30.9935 0.251403 39.9119 9.13348 39.9732 20.1129C39.9732 20.0744 39.9755 20.038 39.9755 19.9994C39.9755 8.96778 31.0321 0.0244141 20.0004 0.0244141C8.96876 0.0244141 0.0253906 8.96778 0.0253906 19.9994C0.0253906 20.038 0.0276606 20.0744 0.0276606 20.1129C0.0889476 9.13348 9.00735 0.251403 20.0004 0.251403Z"
                fill="white"
                fill-opacity="0.2"
              />
              <path
                d="M20.0004 39.7473C30.9935 39.7473 39.9119 30.8652 39.9732 19.8857C39.9732 19.9243 39.9755 19.9606 39.9755 19.9992C39.9755 31.0309 31.0321 39.9743 20.0004 39.9743C8.96876 39.9743 0.0253906 31.0309 0.0253906 19.9992C0.0253906 19.9606 0.0276606 19.9243 0.0276606 19.8857C0.0889476 30.8652 9.00735 39.7473 20.0004 39.7473Z"
                fill="#3E2723"
                fill-opacity="0.15"
              />
              <path
                d="M19.975 39.6503C31.0069 39.6503 39.9501 30.7071 39.9501 19.6752C39.9501 8.64332 31.0069 -0.299805 19.975 -0.299805C8.94313 -0.299805 0 8.64332 0 19.6752C0 30.7071 8.94313 39.6503 19.975 39.6503Z"
                fill="url(#paint9_radial_918_473)"
              />
            </g>
            <defs>
              <linearGradient id="paint0_linear_918_473" x1="4.86949" y1="15.2374" x2="16.7864" y2="8.27637" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A52714" stop-opacity="0.6" />
                <stop offset="0.66" stop-color="#A52714" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint1_linear_918_473" x1="23.3757" y1="35.5486" x2="10.135" y2="27.7924" gradientUnits="userSpaceOnUse">
                <stop stop-color="#055524" stop-opacity="0.4" />
                <stop offset="0.33" stop-color="#055524" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint2_linear_918_473" x1="25.8692" y1="9.5135" x2="29.2036" y2="24.1148" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EA6100" stop-opacity="0.3" />
                <stop offset="0.66" stop-color="#EA6100" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint3_linear_918_473" x1="25.8692" y1="9.5135" x2="29.2036" y2="24.1148" gradientUnits="userSpaceOnUse">
                <stop stop-color="#EA6100" stop-opacity="0.3" />
                <stop offset="0.66" stop-color="#EA6100" stop-opacity="0" />
              </linearGradient>
              <linearGradient id="paint4_linear_918_473" x1="4.86949" y1="15.2374" x2="16.7864" y2="8.27637" gradientUnits="userSpaceOnUse">
                <stop stop-color="#A52714" stop-opacity="0.6" />
                <stop offset="0.66" stop-color="#A52714" stop-opacity="0" />
              </linearGradient>
              <radialGradient
                id="paint5_radial_918_473"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(19.1329 10.9091) scale(19.0848)"
              >
                <stop stop-color="#3E2723" stop-opacity="0.2" />
                <stop offset="1" stop-color="#3E2723" stop-opacity="0" />
              </radialGradient>
              <linearGradient id="paint6_linear_918_473" x1="23.3757" y1="35.5486" x2="10.135" y2="27.7924" gradientUnits="userSpaceOnUse">
                <stop stop-color="#055524" stop-opacity="0.4" />
                <stop offset="0.33" stop-color="#055524" stop-opacity="0" />
              </linearGradient>
              <radialGradient
                id="paint7_radial_918_473"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(3.17488 9.22194) scale(17.7151)"
              >
                <stop stop-color="#3E2723" stop-opacity="0.2" />
                <stop offset="1" stop-color="#3E2723" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint8_radial_918_473"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(19.963 20.031) scale(19.9455)"
              >
                <stop stop-color="#263238" stop-opacity="0.2" />
                <stop offset="1" stop-color="#263238" stop-opacity="0" />
              </radialGradient>
              <radialGradient
                id="paint9_radial_918_473"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(5.96663 5.15111) scale(40.1203)"
              >
                <stop stop-color="white" stop-opacity="0.1" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <div>Chrome</div>
        </li>
        <li class="w-4rem mx-3">
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M40 20C40 31.0456 31.0456 40 20 40C8.95437 40 0 31.0456 0 20C0 8.95437 8.95437 0 20 0C31.0456 0 40 8.95437 40 20Z"
              fill="url(#paint0_linear_918_509)"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M39.0312 20C39.0312 30.5106 30.5106 39.0312 20 39.0312C9.48935 39.0312 0.96875 30.5106 0.96875 20C0.96875 9.48935 9.48935 0.96875 20 0.96875C30.5106 0.96875 39.0312 9.48935 39.0312 20Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.8753 11.7504L22.1722 22.2191V35.2347C22.1722 35.4691 21.1566 35.6566 20.0003 35.6722C18.8909 35.6566 17.8284 35.4691 17.8284 35.2347V22.2191L7.12534 11.7504C6.95344 11.5785 7.50034 10.766 8.34409 9.92221C9.18784 9.07846 10.0159 8.53161 10.1722 8.70346L19.9847 18.2816L29.7972 8.70346C29.9691 8.53161 30.7816 9.07846 31.6253 9.92221C32.4691 10.766 33.0316 11.5785 32.8753 11.7504Z"
              fill="#EC1C24"
            />
            <defs>
              <linearGradient id="paint0_linear_918_509" x1="20" y1="0" x2="20" y2="40" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F5F5F6" />
                <stop offset="1" stop-color="#EDEDEE" />
              </linearGradient>
            </defs>
          </svg>
          <div>
            Yandex <br />
            Browser
          </div>
        </li>
        <li class="w-4rem mx-3">
          <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M36.1038 29.7702C35.5705 30.0491 35.0205 30.2949 34.457 30.5061C32.6633 31.1773 30.7632 31.5192 28.848 31.5154C21.4547 31.5154 15.0145 26.4298 15.0145 19.9036C15.0239 19.0283 15.2668 18.1714 15.7181 17.4213C16.1694 16.6712 16.8126 16.0552 17.5815 15.6367C10.8944 15.918 9.17578 22.8863 9.17578 26.9688C9.17578 38.5118 19.8142 39.6821 22.1062 39.6821C23.3421 39.6821 25.206 39.3227 26.3247 38.9696L26.5294 38.9009C30.8342 37.4127 34.504 34.5032 36.935 30.6514C37.0094 30.5341 37.0427 30.3953 37.0294 30.257C37.0162 30.1187 36.9573 29.9887 36.8619 29.8877C36.7666 29.7866 36.6403 29.7202 36.503 29.6989C36.3657 29.6776 36.2252 29.7027 36.1038 29.7702Z"
              fill="url(#paint0_linear_918_524)"
            />
            <path
              opacity="0.35"
              d="M36.1038 29.7702C35.5705 30.0491 35.0205 30.2949 34.457 30.5061C32.6633 31.1773 30.7632 31.5192 28.848 31.5154C21.4547 31.5154 15.0145 26.4298 15.0145 19.9036C15.0239 19.0283 15.2668 18.1714 15.7181 17.4213C16.1694 16.6712 16.8126 16.0552 17.5815 15.6367C10.8944 15.918 9.17578 22.8863 9.17578 26.9688C9.17578 38.5118 19.8142 39.6821 22.1062 39.6821C23.3421 39.6821 25.206 39.3227 26.3247 38.9696L26.5294 38.9009C30.8342 37.4127 34.504 34.5032 36.935 30.6514C37.0094 30.5341 37.0427 30.3953 37.0294 30.257C37.0162 30.1187 36.9573 29.9887 36.8619 29.8877C36.7666 29.7866 36.6403 29.7202 36.503 29.6989C36.3657 29.6776 36.2252 29.7027 36.1038 29.7702Z"
              fill="url(#paint1_radial_918_524)"
            />
            <path
              d="M16.5185 37.7196C15.125 36.8547 13.9173 35.7213 12.9656 34.3854C11.881 32.8994 11.1346 31.1941 10.7788 29.389C10.423 27.584 10.4665 25.723 10.9061 23.9365C11.3457 22.1501 12.1709 20.4814 13.3237 19.0476C14.4765 17.6138 15.929 16.4496 17.5794 15.6366C18.0669 15.4069 18.8996 14.9913 20.0074 15.0116C20.7878 15.0173 21.5563 15.2037 22.2527 15.556C22.9491 15.9084 23.5544 16.4173 24.0212 17.0428C24.6521 17.8852 25 18.9058 25.0149 19.9582C25.0149 19.9254 28.8365 7.52148 12.5157 7.52148C5.65671 7.52148 0.0164406 14.0305 0.0164406 19.741C-0.0106141 22.7619 0.635735 25.7508 1.90851 28.4905C3.98858 32.9286 7.62605 36.4483 12.1302 38.3813C16.6343 40.3142 21.6915 40.5258 26.3414 38.9758C24.7133 39.4891 22.9932 39.6432 21.2998 39.4274C19.6064 39.2116 17.9799 38.6311 16.5326 37.7258L16.5185 37.7196Z"
              fill="url(#paint2_linear_918_524)"
            />
            <path
              opacity="0.41"
              d="M16.5185 37.7196C15.125 36.8547 13.9173 35.7213 12.9656 34.3854C11.881 32.8994 11.1346 31.1941 10.7788 29.389C10.423 27.584 10.4665 25.723 10.9061 23.9365C11.3457 22.1501 12.1709 20.4814 13.3237 19.0476C14.4765 17.6138 15.929 16.4496 17.5794 15.6366C18.0669 15.4069 18.8996 14.9913 20.0074 15.0116C20.7878 15.0173 21.5563 15.2037 22.2527 15.556C22.9491 15.9084 23.5544 16.4173 24.0212 17.0428C24.6521 17.8852 25 18.9058 25.0149 19.9582C25.0149 19.9254 28.8365 7.52148 12.5157 7.52148C5.65671 7.52148 0.0164406 14.0305 0.0164406 19.741C-0.0106141 22.7619 0.635735 25.7508 1.90851 28.4905C3.98858 32.9286 7.62605 36.4483 12.1302 38.3813C16.6343 40.3142 21.6915 40.5258 26.3414 38.9758C24.7133 39.4891 22.9932 39.6432 21.2998 39.4274C19.6064 39.2116 17.9799 38.6311 16.5326 37.7258L16.5185 37.7196Z"
              fill="url(#paint3_radial_918_524)"
            />
            <path
              d="M23.8001 23.2579C23.6735 23.422 23.2845 23.6485 23.2845 24.1422C23.2845 24.55 23.5501 24.9422 24.0219 25.2719C26.2687 26.8343 30.5043 26.628 30.5153 26.628C32.1801 26.624 33.8133 26.1735 35.2447 25.3234C36.6886 24.4803 37.8871 23.2742 38.7211 21.825C39.5551 20.3757 39.9956 18.7335 39.9991 17.0614C40.0397 13.5601 38.7491 11.2321 38.2273 10.2009C34.9166 3.72477 27.7701 9.79067e-08 19.9987 9.79067e-08C14.7431 -0.000519588 9.69852 2.06784 5.95601 5.75772C2.21349 9.4476 0.0739073 14.4624 0 19.7175C0.0749953 14.0085 5.74964 9.39785 12.4992 9.39785C13.0461 9.39785 16.1646 9.45097 19.0613 10.9712C21.6143 12.3117 22.9517 13.9304 23.8813 15.535C24.8469 17.202 25.0187 19.3082 25.0187 20.1472C25.0187 20.9862 24.5906 22.2299 23.8001 23.2579Z"
              fill="url(#paint4_radial_918_524)"
            />
            <path
              d="M23.8001 23.2579C23.6735 23.422 23.2845 23.6485 23.2845 24.1422C23.2845 24.55 23.5501 24.9422 24.0219 25.2719C26.2687 26.8343 30.5043 26.628 30.5153 26.628C32.1801 26.624 33.8133 26.1735 35.2447 25.3234C36.6886 24.4803 37.8871 23.2742 38.7211 21.825C39.5551 20.3757 39.9956 18.7335 39.9991 17.0614C40.0397 13.5601 38.7491 11.2321 38.2273 10.2009C34.9166 3.72477 27.7701 9.79067e-08 19.9987 9.79067e-08C14.7431 -0.000519588 9.69852 2.06784 5.95601 5.75772C2.21349 9.4476 0.0739073 14.4624 0 19.7175C0.0749953 14.0085 5.74964 9.39785 12.4992 9.39785C13.0461 9.39785 16.1646 9.45097 19.0613 10.9712C21.6143 12.3117 22.9517 13.9304 23.8813 15.535C24.8469 17.202 25.0187 19.3082 25.0187 20.1472C25.0187 20.9862 24.5906 22.2299 23.8001 23.2579Z"
              fill="url(#paint5_radial_918_524)"
            />
            <defs>
              <linearGradient id="paint0_linear_918_524" x1="9.17578" y1="27.6625" x2="37.0397" y2="27.6625" gradientUnits="userSpaceOnUse">
                <stop stop-color="#0C59A4" />
                <stop offset="1" stop-color="#114A8B" />
              </linearGradient>
              <radialGradient
                id="paint1_radial_918_524"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(24.5654 27.8821) scale(14.9022 14.1571)"
              >
                <stop offset="0.72" stop-opacity="0" />
                <stop offset="0.95" stop-opacity="0.53" />
                <stop offset="1" />
              </radialGradient>
              <linearGradient id="paint2_linear_918_524" x1="23.8634" y1="15.5757" x2="6.45979" y2="34.5323" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1B9DE2" />
                <stop offset="0.16" stop-color="#1595DF" />
                <stop offset="0.67" stop-color="#0680D7" />
                <stop offset="1" stop-color="#0078D4" />
              </linearGradient>
              <radialGradient
                id="paint3_radial_918_524"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(11.029 31.0932) rotate(-81.3844) scale(22.4089 18.1042)"
              >
                <stop offset="0.76" stop-opacity="0" />
                <stop offset="0.95" stop-opacity="0.5" />
                <stop offset="1" />
              </radialGradient>
              <radialGradient
                id="paint4_radial_918_524"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(4.04076 7.40216) rotate(92.2906) scale(31.653 67.4143)"
              >
                <stop stop-color="#35C1F1" />
                <stop offset="0.11" stop-color="#34C1ED" />
                <stop offset="0.23" stop-color="#2FC2DF" />
                <stop offset="0.31" stop-color="#2BC3D2" />
                <stop offset="0.67" stop-color="#36C752" />
              </radialGradient>
              <radialGradient
                id="paint5_radial_918_524"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(37.5089 12.0939) rotate(73.7398) scale(15.2084 12.3675)"
              >
                <stop stop-color="#66EB6E" />
                <stop offset="1" stop-color="#66EB6E" stop-opacity="0" />
              </radialGradient>
            </defs>
          </svg>
          <div>Edge</div>
        </li>
        <li class="w-4rem mx-3">
          <svg width="38" height="40" viewBox="0 0 38 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.5554 3.53349C24.5265 3.53349 25.741 11.8658 25.741 19.4317C25.741 26.4403 25.0655 35.823 18.6286 35.823C12.1915 35.823 11.3592 26.3485 11.3592 19.3405C11.3592 11.775 12.5833 3.53349 18.5554 3.53349ZM29.2022 3.14432C26.2715 1.11831 22.6457 0 18.5559 0C14.8154 0 11.4633 0.934872 8.67577 2.64346C3.30597 5.90029 0 11.9971 0 19.8091C0 30.376 7.41886 40 18.5493 40C29.6827 40 37.1003 30.376 37.1003 19.8091C37.1003 12.3813 34.1122 6.50442 29.2022 3.14432Z"
              fill="#EA212D"
            />
          </svg>
          <div>Opera</div>
        </li>
      </ul>
    </div>
  </div>
</ng-container>
