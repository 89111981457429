<div class="flex flex-column align-content-center justify-content-start p-0 w-full h-full" *transloco="let t">
  <div class="text-xl mb-3 ml-3">{{ t('testrunner.webSite.camera') }}</div>
  <div
    class="flex align-items-center justify-content-center surface-hover h-full"
    style="border-radius: 1rem; min-height: 25vw;"
  >
    <i class="fa-regular fa-video-slash text-6xl md:text-7xl xl:text-8xl p-text-secondary" [class.hidden]="selectedCameraDeviceId"></i>
    <video
      #video
      id="video"
      autoplay
      [class.hidden]="!selectedCameraDeviceId"
      [style]="{'overflow': 'auto', 'border-radius': '1rem', 'width': '100%', 'height': '100%'}"
    ></video>
  </div>
  <div>
    <p-dropdown
      [disabled]="isCameraRequested"
      [options]="cameraDevices"
      [(ngModel)]="selectedCameraDeviceId"
      optionLabel="label"
      optionValue="deviceId"
      (onChange)="selectCamera($event.value)"
      styleClass="w-12"
    ></p-dropdown>
  </div>
  <button
    *ngIf="!isCameraRequested"
    pButton
    pRipple
    type="button"
    (click)="requestPermissions()"
    [disabled]="!selectedCameraDeviceId"
    [label]="t('testrunner.webSite.allowCamera')"
    class="p-button-success"
  ></button>
  <button
    *ngIf="isCameraRequested"
    pButton
    pRipple
    type="button"
    icon="pi pi-check"
    [label]="t('testrunner.webSite.cameraAllowed')"
    class="p-button-outlined p-button-success"
  ></button>
</div>
