import { Injectable } from '@angular/core';

import {
  Comparison,
  ConditionPair,
  ConditionTypeEnum,
  DistributionTypeEnum,
  IteratorSourceTypeEnum,
  Layout,
  LayoutGroup,
  LayoutGroupResult,
  RandomizeTypeEnum,
  Task,
  TaskGroup,
  TaskGroupResult,
  TestEndByCondition,
  TestEndByConditionResult,
  TestEndByQuota,
  TestEndByQuotaResult,
  TestItem,
  TestItemOperand,
  TestItemQuota,
  TestItemResult,
  TestItemResultFieldEnum,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
  TestItemStatisticsKey,
  TestItemStatisticsTypeEnum,
  TestItemTypeEnum,
} from 'src/api/testrunner/models';
import { EMPTY_GUID } from '../constants/constants';
import { LogicEnvironmentParams } from '../models/logic-environment-params';
import { LogicResultEnum } from '../models/logic-result-enum';
import { PipingItem } from '../models/piping-item';
import { TestItemModel } from '../models/test-item-model';
import { DateTimeService } from './date-time.service';
import { LogicCalculationService } from './logic-calculation.service';
import { PersistenceService } from './persistence.service';

const answerPrefix = 'answer_';
const randomPrefix = 'random_';

interface LogicResultInterface {
  trueValue: TestItem[];
  falseValue: TestItem[];
}

interface QuotaInfo {
  quota: TestItemQuota | null;
  quotaResult: boolean;
  isQuotaFull: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LogicService {
  private _answers: TestItemResult[] = [];
  private _screenOutItem: TestItem | TestItemQuota | null = null;
  private _logicEnvironmentParams: LogicEnvironmentParams | null = null;

  constructor(
    private readonly logicCalculation: LogicCalculationService,
    private readonly persistenceService: PersistenceService,
  ) {}

  get answers(): TestItemResult[] {
    return this._answers;
  }

  get screenOutItem(): TestItem | TestItemQuota | null {
    return this._screenOutItem;
  }

  /**
   * Синхронный поиск всех тест айтемов в пейдже без учета логики
   */
  getAllTestItemsInPage(groupItem: LayoutGroup): TestItemModel[] {
    const resultItems: TestItemModel[] = [];
    let stack = this.randomizeItems(groupItem);

    if ((groupItem as PipingItem).iterationId) {
      stack = stack.map(
        (testItem) =>
          ({
            ...testItem,
            iteratorSourceFabDictionaryId: groupItem.iteratorSourceFabDictionaryId,
            iteratorSourceTestItemId: groupItem.iteratorSourceTestItemId,
            iterationId: (groupItem as PipingItem).iterationId,
            testItemIteratorCells: groupItem.testItemIteratorCells,
          }) as PipingItem,
      );
    }

    while (stack.length > 0) {
      const item = stack.shift();

      if (!item) {
        continue;
      }

      if (item.iteratorSourceFabDictionaryId && item.iteratorSourceTestItemId && !(item as PipingItem)?.iterationId) {
        const iteratedItems: PipingItem[] = [];
        (
          (this._logicEnvironmentParams?.testRunnerStartData?.dictionaries || []).find(
            (dictionary) => dictionary.id === item.iteratorSourceFabDictionaryId,
          )?.fabDictionaryItems || []
        ).forEach((dictionaryItem) =>
          iteratedItems.push({
            ...item,
            iterationId: dictionaryItem.entityId,
          }),
        );
        stack = [...iteratedItems, ...stack];
        continue;
      }

      if (![TestItemTypeEnum.Mobile, TestItemTypeEnum.Page, TestItemTypeEnum.WebSite, TestItemTypeEnum.Else].includes(item.testItemType)) {
        resultItems.push({
          id: item.id + ((item as PipingItem)?.iterationId ? '&' + (item as PipingItem)?.iterationId : ''),
          iterationId: (item as PipingItem)?.iterationId ?? null,
          isShown: [TestItemTypeEnum.LayoutGroup, TestItemTypeEnum.TaskGroup].includes(item.testItemType),
          data: item,
          showStartTime: DateTimeService.currentDateTimeUTC,
          testItemId: item.id,
        } as TestItemModel);
      }

      if ([TestItemTypeEnum.TaskGroup, TestItemTypeEnum.Else].includes(item.testItemType)) {
        const items = this.randomizeItems(item);
        stack = [
          ...items.map(
            (testItem) =>
              ({
                ...testItem,
                iteratorSourceFabDictionaryId: item.iteratorSourceFabDictionaryId,
                iteratorSourceTestItemId: item.iteratorSourceTestItemId,
                iterationId: (item as PipingItem).iterationId,
                testItemIteratorCells: item.testItemIteratorCells,
              }) as PipingItem,
          ),
          ...stack,
        ];
      }
    }

    return resultItems;
  }

  /**
   * Получение следующей страницы
   */
  getNextPage(groupItems: TestItem[]): Layout | null {
    const currentPageId = this._logicEnvironmentParams?.testInfo?.currentPageId ?? null;
    const currentIteratorId = this._logicEnvironmentParams?.testInfo?.currentIteratorInfo?.iterationId ?? null;
    let isCurrentFound = currentPageId === null;
    let stack = groupItems.slice();

    while (stack.length > 0) {
      const layoutItem = stack.shift();

      if (!layoutItem) {
        continue;
      }

      //Пайпинг
      if (layoutItem.iteratorSourceFabDictionaryId && layoutItem.iteratorSourceTestItemId && !(layoutItem as PipingItem)?.iterationId) {
        const iteratedItems: PipingItem[] = [];
        (
          (this._logicEnvironmentParams?.testRunnerStartData?.dictionaries || []).find(
            (dictionary) => dictionary.id === layoutItem.iteratorSourceFabDictionaryId,
          )?.fabDictionaryItems || []
        ).forEach((dictionaryItem) => {
          if (dictionaryItem.entityId && this.filterByIteratorSourceType(layoutItem, dictionaryItem.entityId)) {
            iteratedItems.push({
              ...layoutItem,
              iterationId: dictionaryItem.entityId,
            });
          } else {
            this._answers.push(
              ...this.getSkippedByLogicTestItems([
                {
                  ...layoutItem,
                  iterationId: dictionaryItem.entityId,
                } as PipingItem,
              ]),
            );
          }
        });
        stack = [...iteratedItems, ...stack];
        continue;
      }

      //Страничные элементы
      if (
        layoutItem &&
        [
          TestItemTypeEnum.Kano,
          TestItemTypeEnum.Mobile,
          TestItemTypeEnum.Page,
          TestItemTypeEnum.TestEndByCondition,
          TestItemTypeEnum.TestEndByQuota,
          TestItemTypeEnum.WebSite,
        ].includes(layoutItem.testItemType)
      ) {
        if (isCurrentFound) {
          const logicResult = this.logicCalculation.calculateLogic(
            layoutItem?.rootConditionGroup ?? null,
            (layoutItem as PipingItem)?.iterationId ?? null,
            layoutItem.logicUndefinedIgnored ?? false,
          );

          console.log(layoutItem.testItemType, logicResult, this._screenOutItem);
          if (
            !this._screenOutItem &&
            (logicResult === LogicResultEnum.logicTrue || this._logicEnvironmentParams?.testInfo?.logicOn === false)
          ) {
            //обработка скринаута
            if (TestItemTypeEnum.TestEndByCondition === layoutItem.testItemType) {
              this._answers.push(this.getLogicResult(layoutItem, logicResult));
              /*if (layoutItem.isScreenOut) {*/
              this._screenOutItem = layoutItem;
              continue;
              /*}*/
            } else if (TestItemTypeEnum.TestEndByQuota === layoutItem.testItemType) {
              const quotaResults = this.checkQuotas(layoutItem);
              this._answers.push(
                this.getQuotaResult(
                  layoutItem,
                  logicResult,
                  quotaResults.map((quotaResult) => quotaResult.quota?.id as string),
                ),
              );
              //Выкинуть если не подошел ни под одну квоту
              if (!quotaResults.length && (layoutItem as TestEndByQuota).isScreenOut) {
                this._screenOutItem = layoutItem;
                continue;
              }
              //Выкинуть если квота переполнена
              const quotaFullScreenOut = quotaResults.find((quotaResult) => quotaResult.isQuotaFull && quotaResult.quota?.isScreenOut);
              if (quotaFullScreenOut) {
                this._screenOutItem = quotaFullScreenOut.quota;
                continue;
              }
            } else {
              return layoutItem;
            }
          } else {
            this._answers.push(...this.getSkippedByLogicTestItems([layoutItem], logicResult));
          }
        }

        if (layoutItem.id === currentPageId && ((layoutItem as PipingItem)?.iterationId ?? null) === currentIteratorId) {
          isCurrentFound = true;
        }
      }

      //Группы
      if (layoutItem && layoutItem.testItemType === TestItemTypeEnum.LayoutGroup) {
        const conditionResult = this.checkCondition({
          ...layoutItem,
          groupItems: this.randomizeItems(layoutItem),
          iterationId: (layoutItem as PipingItem)?.iterationId ?? null,
        } as PipingItem);

        if (this._logicEnvironmentParams?.testInfo?.logicOn === false) {
          stack = [
            ...conditionResult.falseValue.map(
              (item) =>
                ({
                  ...item,
                  iteratorSourceFabDictionaryId: layoutItem.iteratorSourceFabDictionaryId,
                  iteratorSourceTestItemId: layoutItem.iteratorSourceTestItemId,
                  iterationId: (layoutItem as PipingItem).iterationId,
                  testItemIteratorCells: layoutItem.testItemIteratorCells,
                }) as PipingItem,
            ),
            ...stack,
          ];
        }
        stack = [
          ...conditionResult.trueValue.map(
            (item) =>
              ({
                ...item,
                iteratorSourceFabDictionaryId: layoutItem.iteratorSourceFabDictionaryId,
                iteratorSourceTestItemId: layoutItem.iteratorSourceTestItemId,
                iterationId: (layoutItem as PipingItem).iterationId,
                testItemIteratorCells: layoutItem.testItemIteratorCells,
              }) as PipingItem,
          ),
          ...stack,
        ];

        if (!this._screenOutItem) {
          this._answers.push(...this.getSkippedByLogicTestItems(conditionResult.falseValue));
        }
      }
    }

    return null;
  }

  /**
   * Получение всех testItemId требующих запрос данных с бэка
   *
   * @param condition - rootConditionGroup testItem'а
   * @param fields - массив правил логики по которым необходимо запрашивать бэк
   */
  getTestItemStatisticsIds(
    condition: ConditionPair | Comparison | null,
    fields: (TestItemResultFieldEnum | null)[],
  ): TestItemStatisticsKey[] {
    if (condition?.testItemConditionType === ConditionTypeEnum.Pair) {
      return [
        ...this.getTestItemStatisticsIds((condition as ConditionPair).leftItem || null, fields),
        ...this.getTestItemStatisticsIds((condition as ConditionPair).rightItem || null, fields),
      ];
    } else if (condition?.testItemConditionType === ConditionTypeEnum.Item) {
      const comparison = condition as Comparison;

      if (
        fields.includes((comparison.operandLeft as TestItemOperand)?.testItemResultField ?? null) ||
        fields.includes((comparison.operandRight as TestItemOperand)?.testItemResultField ?? null)
      ) {
        const leftOperandId = (comparison.operandLeft as TestItemOperand)?.sourceTestItemId ?? null;
        const rightOperandId = (comparison.operandRight as TestItemOperand)?.sourceTestItemId ?? null;
        return [
          ...(leftOperandId
            ? [
                {
                  testItemId: leftOperandId,
                  iterationId: EMPTY_GUID,
                } as TestItemStatisticsKey,
              ]
            : []),
          ...(rightOperandId
            ? [
                {
                  testItemId: rightOperandId,
                  iterationId: EMPTY_GUID,
                } as TestItemStatisticsKey,
              ]
            : []),
        ];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  /**
   * Получение видимости тест айтемов в пейдже с учетом логики
   */
  getTestItemModelsVisibility(groupItems: TestItem[], isShown: boolean = true, iterationId: string | null = null): TestItemModel[] {
    return groupItems.reduce((children, testItem) => {
      // итерируем пайпинг если висит на шаге
      if (testItem.iteratorSourceFabDictionaryId && testItem.iteratorSourceTestItemId && iterationId === null) {
        (
          (this._logicEnvironmentParams?.testRunnerStartData?.dictionaries || []).find(
            (dictionary) => dictionary.id === testItem.iteratorSourceFabDictionaryId,
          )?.fabDictionaryItems || []
        ).forEach((dictionaryItem) => {
          if (dictionaryItem.entityId) {
            children = [
              ...children,
              ...this.getTestItemModelsVisibility(
                [testItem],
                isShown && this.filterByIteratorSourceType(testItem, dictionaryItem.entityId),
                dictionaryItem.entityId,
              ),
            ];
          }
        });
        return children;
      }

      if (
        ![
          TestItemTypeEnum.Else,
          TestItemTypeEnum.Kano,
          TestItemTypeEnum.LayoutGroup,
          TestItemTypeEnum.Mobile,
          TestItemTypeEnum.Page,
          TestItemTypeEnum.TaskGroup,
          TestItemTypeEnum.WebSite,
        ].includes(testItem.testItemType)
      ) {
        // Вычисляем логику навешенную на шаг
        const logicResult = this.logicCalculation.calculateLogic(
          (testItem as Task)?.rootConditionGroup ?? null,
          iterationId,
          testItem.logicUndefinedIgnored ?? false,
        );
        if (logicResult !== LogicResultEnum.logicTrue && this._logicEnvironmentParams?.testInfo?.logicOn !== false) {
          // Если айтем был пропущен логикой то сохраняем его ответ со статусом SkippedByLogic
          this._answers.push(...this.getSkippedByLogicTestItems([testItem], LogicResultEnum.logicFalse));
        }

        children = [
          ...children,
          {
            id: testItem.id + (iterationId ? '&' + iterationId : ''),
            isShown: (isShown && logicResult === LogicResultEnum.logicTrue) || this._logicEnvironmentParams?.testInfo?.logicOn === false,
            iterationId,
            data: testItem,
            logicResult:
              logicResult === LogicResultEnum.logicUndefined
                ? null
                : logicResult === LogicResultEnum.logicTrue || this._logicEnvironmentParams?.testInfo?.logicOn === false,
            testItemId: testItem.id,
          } as TestItemModel,
        ];
      }

      if ([TestItemTypeEnum.LayoutGroup, TestItemTypeEnum.TaskGroup].includes(testItem.testItemType) && isShown) {
        const conditionResult = this.checkCondition({ ...testItem, iterationId } as PipingItem);
        children = [
          ...children,
          ...this.getTestItemModelsVisibility(conditionResult.trueValue, isShown ? true : isShown, iterationId),
          ...this.getTestItemModelsVisibility(conditionResult.falseValue, isShown ? false : isShown, iterationId),
        ];
      } else {
        children = [...children, ...this.getTestItemModelsVisibility(testItem.groupItems ?? [], isShown, iterationId)];
      }

      return children;
    }, [] as TestItemModel[]);
  }

  /**
   * Обновление глобальных параметров сервиса
   */
  resetGlobParams(_logicEnvironmentParams: LogicEnvironmentParams): void {
    this._answers = [];
    this._logicEnvironmentParams = _logicEnvironmentParams;
    this.logicCalculation.logicEnvironmentParams = _logicEnvironmentParams;
  }

  private balanceItems(groupItems: TestItem[]): TestItem[] {
    const balancedItems = groupItems
      .map((testItem) => ({
        ...testItem,
        count:
          (this._logicEnvironmentParams?.testItemStatistics || []).find(
            (statistic) => statistic.testItemId === testItem.id && statistic.type === TestItemStatisticsTypeEnum.TotalAnswered,
          )?.count ?? 0,
      }))
      .sort((a, b) => a.count - b.count);
    console.log(
      'BALANCED',
      groupItems.map((item) => ({ id: item.id })),
      balancedItems.map((item) => ({ id: item.id, count: item.count })),
    );
    return balancedItems;
  }

  /**
   * Получение результата логики, как массив тест айтемов подходящих по условию логики и массив не подходящий по условию
   */
  private checkCondition(logic: LayoutGroup | TaskGroup): LogicResultInterface {
    const _then: TestItem[] = (logic?.groupItems || []).filter((testItem) => testItem.testItemType !== TestItemTypeEnum.Else);
    const _else: TestItem[] =
      (logic?.groupItems || []).find((testItem) => testItem.testItemType === TestItemTypeEnum.Else)?.groupItems ?? [];

    if (!logic?.rootConditionGroup) {
      return { trueValue: _then, falseValue: _else } as LogicResultInterface;
    }

    const logicResult = this.logicCalculation.calculateLogic(
      logic.rootConditionGroup,
      (logic as PipingItem).iterationId ?? null,
      logic.logicUndefinedIgnored ?? false,
    );

    if (logicResult === LogicResultEnum.logicUndefined) {
      return {
        trueValue: [],
        falseValue: [..._then, ..._else],
      };
    }

    this._answers.push(this.getLogicResult(logic, logicResult));

    return {
      trueValue: logicResult === LogicResultEnum.logicTrue ? _then : _else,
      falseValue: logicResult === LogicResultEnum.logicFalse ? _then : _else,
    } as LogicResultInterface;
  }

  /**
   * Обработка квот
   *
   * @param testEndByQuota - сорсайтем
   * @private
   */
  private checkQuotas(testEndByQuota: TestEndByQuota): QuotaInfo[] {
    return (testEndByQuota.testItemQuotas || [])
      .map((quota) => {
        const quotaResult =
          this.logicCalculation.calculateLogic(
            quota?.rootConditionGroup ?? null,
            (testEndByQuota as PipingItem).iterationId ?? null,
            false,
          ) === LogicResultEnum.logicTrue;
        const isQuotaFull = (this._logicEnvironmentParams?.testItemStatistics || []).some(
          (testItemStatistic) =>
            testItemStatistic.testItemId === quota.testItemId &&
            testItemStatistic.testItemQuotaId === quota.id &&
            testItemStatistic.type === TestItemStatisticsTypeEnum.TestEndByQuota &&
            (testItemStatistic.count ?? 0) >= (quota.limit ?? 0),
        );
        return { quota: quota ?? null, quotaResult, isQuotaFull } as QuotaInfo;
      })
      .filter((quotaResult) => quotaResult.quota && quotaResult.quotaResult);
  }

  /**
   *  Создание результатов логики
   */
  private getLogicResult(
    logic: LayoutGroup | TaskGroup | TestEndByCondition,
    logicResult: LogicResultEnum,
  ): LayoutGroupResult | TaskGroupResult | TestEndByConditionResult {
    console.log('save logic result', logic, logicResult);
    return {
      answerTimeSpentMs: 0,
      clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
      clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
      clientStartTimeUtc: DateTimeService.currentDateTimeUTC,
      clientTimeZoneMinutes: DateTimeService.timezone,
      //elementBoolean: logicResult === LogicResultEnum.logicTrue ? true : logicResult === LogicResultEnum.logicFalse ? false : null,
      iterationId: (logic as PipingItem).iterationId,
      logicResult: logicResult === LogicResultEnum.logicTrue ? true : logicResult === LogicResultEnum.logicFalse ? false : null,
      status: [TestItemTypeEnum.LayoutGroup, TestItemTypeEnum.TestEndByCondition].includes(logic.testItemType)
        ? TestItemResultStatusEnum.SubmittedByLogic
        : TestItemResultStatusEnum.Intermediate,
      testId: logic?.testId,
      testItemId: logic?.id,
      type: logic.testItemType as string as TestItemResultTypeEnum,
    } as LayoutGroupResult | TaskGroupResult;
  }

  /**
   *  Создание результатов логики
   */
  private getQuotaResult(
    testEndByQuota: TestEndByQuota,
    logicResult: LogicResultEnum,
    quotaIds: string[],
  ): LayoutGroupResult | TaskGroupResult {
    return {
      answerTimeSpentMs: 0,
      clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
      clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
      clientStartTimeUtc: DateTimeService.currentDateTimeUTC,
      clientTimeZoneMinutes: DateTimeService.timezone,
      iterationId: (testEndByQuota as PipingItem).iterationId,
      logicResult: logicResult === LogicResultEnum.logicTrue ? true : logicResult === LogicResultEnum.logicFalse ? false : null,
      testItemQuotaIds: quotaIds,
      status:
        logicResult !== LogicResultEnum.logicTrue ? TestItemResultStatusEnum.SkippedByLogic : TestItemResultStatusEnum.SubmittedByUser,
      testId: testEndByQuota?.testId,
      testItemId: testEndByQuota?.id,
      type: testEndByQuota.testItemType as string as TestItemResultTypeEnum,
    } as TestEndByQuotaResult;
  }

  /**
   * Генерация ответа на пропущенный логикой test item
   */
  private getSkippedByLogicTestItems(
    skippedChildren: TestItem[],
    logicResult: LogicResultEnum = LogicResultEnum.logicUndefined,
  ): TestItemResult[] {
    return (skippedChildren || []).reduce((result: TestItemResult[], testItem) => {
      if (!this.persistenceService.hasKey(answerPrefix + (testItem?.id ?? '')) && testItem.testItemType !== TestItemTypeEnum.Else) {
        const type = (testItem.testItemType.toString() as TestItemResultTypeEnum) ?? null;
        result = [
          ...result,
          {
            answerTimeSpentMs: DateTimeService.getDuration(
              DateTimeService.convertToDateTimeStringUTC(this._logicEnvironmentParams?.testInfo?.testStartTimestampUTC ?? 0),
            ),
            clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
            clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
            clientTimeZoneMinutes: DateTimeService.timezone,
            iterationId: (testItem as PipingItem).iterationId,
            logicResult:
              logicResult === LogicResultEnum.logicUndefined
                ? null
                : logicResult === LogicResultEnum.logicTrue || this._logicEnvironmentParams?.testInfo?.logicOn === false,
            testItemId: testItem.id,
            testId: testItem.testId,
            status: TestItemResultStatusEnum.SkippedByLogic,
            type,
          } as TestItemResult,
          ...this.getSkippedByLogicTestItems(
            (testItem?.groupItems ?? []).map(
              (childrenItem) =>
                ({
                  ...childrenItem,
                  iterationId: (testItem as PipingItem).iterationId,
                }) as PipingItem,
            ),
            logicResult,
          ),
        ];
      }
      return result;
    }, []);
  }

  private filterByIteratorSourceType(testItem: TestItem, iterationId: string): boolean {
    const targetAnswer: TestItemResult | null = this.persistenceService.get('answer_' + testItem.iteratorSourceTestItemId);
    const isIteratedItemInAnswer = targetAnswer && this.logicCalculation.isIteratesItemInAnswer(targetAnswer, iterationId);
    return (
      testItem.iteratorSourceType === IteratorSourceTypeEnum.All ||
      (testItem.iteratorSourceType === IteratorSourceTypeEnum.Selected && isIteratedItemInAnswer) ||
      (testItem.iteratorSourceType === IteratorSourceTypeEnum.NotSelected && !isIteratedItemInAnswer)
    );
  }

  private randomizeItems(group: Layout | LayoutGroup | TaskGroup): TestItem[] {
    if (!group?.randomizeType || group.randomizeType === RandomizeTypeEnum.None) {
      return (group?.groupItems || []).slice();
    }

    const savedOrder: string[] | null = this.persistenceService.get(randomPrefix + (group.id ?? '')) ?? null;
    if (savedOrder) {
      //восстановление рандомизации из localstorage
      return (group?.groupItems || [])
        .filter((testItem) => savedOrder.includes(testItem?.id ?? ''))
        .sort((a, b) => savedOrder.indexOf(a.id ?? '') - savedOrder.indexOf(b.id ?? ''));
    } else {
      //алгоритм рандомизации
      //отделение от элементов и блока элсе
      const groupItems: TestItem[] = (group?.groupItems || []).filter((testItem) => testItem.testItemType !== TestItemTypeEnum.Else);
      const elseItem: TestItem[] = (group?.groupItems || []).filter((testItem) => testItem.testItemType === TestItemTypeEnum.Else);
      //рандомизация
      const shuffledItems = groupItems.sort(() => 0.5 - Math.random());
      let balancedItems = shuffledItems;
      //частичная рандомизация и балансировка
      if (group.randomizeType === RandomizeTypeEnum.SpecifiedCount && group?.distributedElementCount) {
        balancedItems = group.distributionType === DistributionTypeEnum.Balancing ? this.balanceItems(groupItems) : groupItems;
        //сохранение пропущенных ответов
        const skippedItems = balancedItems.slice(group?.distributedElementCount, group?.distributedElementCount + shuffledItems.length);
        this._answers = [...this._answers, ...this.getSkippedByLogicTestItems(skippedItems)];
        //обрезка элементов до нужного количества
        balancedItems = balancedItems.slice(0, group.distributedElementCount);
      }
      //добавление блока элсе и сохранение порядка в localstorage
      const randomizedItems = [...balancedItems, ...elseItem];
      this.persistenceService.set(
        randomPrefix + (group.id ?? ''),
        randomizedItems.map((testItem) => testItem.id as string),
      );
      return randomizedItems;
    }
  }
}
