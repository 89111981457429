import { createFeatureSelector, createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';

import { FeaturesEnum } from '../features-enum';
import { Page } from 'src/api/testrunner/models/page';
import { pageAdapter, PageState } from '../states/page.state';
import { selectLayoutTestItems, selectTestItems } from './test-items.selectors';
import { selectIsExtensionInstalled, selectIsMobileAppInstalled, selectTestInfo, selectTestStartData } from './test-runner.selectors';
import { TestItemTypeEnum, TestTypeEnum } from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';

const { selectAll } = pageAdapter.getSelectors();

const selectPageFeature = createFeatureSelector<PageState>(FeaturesEnum.page);

export const selectAllTestItemModelsOnPage = createSelector(selectPageFeature, selectAll);

export const selectCurrentPage = createSelector(selectPageFeature, (pageState) => pageState.currentPage);

export const selectIsAnswersValid = createSelector(selectPageFeature, (pageState) => pageState.isAnswersValid);
export const selectIsBackButtonEnabled = createSelector(
  selectPageFeature,
  selectTestItems,
  selectTestInfo,
  (pageState, testItems, testInfo) => {
    const prevPage = testItems[testInfo?.stack?.[testInfo?.stack?.length - 1]?.split('&')[0] ?? ''] ?? null;
    return !!(
      ((testInfo?.preview && (pageState?.currentPage as Page)?.isBackEnabled) ?? false) ||
      (((pageState?.currentPage as Page)?.isBackEnabled ?? false) && prevPage && prevPage?.testItemType !== TestItemTypeEnum.WebSite)
    );
  }
);
export const selectIsPageReady = createSelector(
  selectTestStartData,
  selectIsMobileAppInstalled,
  selectIsExtensionInstalled,
  (test, isMobileInstalled, isExtensionInstalled) =>
    (test?.type === TestTypeEnum.Mobile && isMobileInstalled) ||
    (test?.type === TestTypeEnum.WebSite && isExtensionInstalled) ||
    (test?.type !== TestTypeEnum.Mobile && test?.type !== TestTypeEnum.WebSite)
);
export const selectIsPageChanging = createSelector(selectPageFeature, (pageState) => pageState.isPageChanging);
export const selectIsLastPage = createSelector(
  selectPageFeature,
  selectLayoutTestItems,
  (pageState, rootTestItems) =>
    !!rootTestItems?.[rootTestItems.length - 1]?.id &&
    !!pageState.currentPage?.id &&
    pageState.currentPage.id === rootTestItems[rootTestItems.length - 1].id
);
export const selectIsTaskAnswerSaved = createSelector(selectPageFeature, (pageState) => pageState.isTaskAnswerSaved);
export const selectPageShowStartTime = createSelector(selectPageFeature, (pageState) => pageState.pageShowStartTime);
export const selectPreviousPage: MemoizedSelector<object, PipingItem | null, DefaultProjectorFn<PipingItem | null>> = createSelector(
  selectTestInfo,
  selectTestItems,
  (testInfo, testItems) => {
    const [testItemId, iterationId] = testInfo?.stack?.[testInfo?.stack?.length - 1]?.split('&') ?? [];
    return testItems[testItemId] ? ({ ...testItems[testItemId], iterationId } as PipingItem) : null;
  }
);
