import { ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEmpty, isNil } from 'lodash';
import { fromEvent, map, merge, Observable, ReplaySubject, shareReplay, switchMap, takeWhile } from 'rxjs';
import { FabDictionaryItem, RowAnswerQuantityEnum } from 'src/api/testrunner/models';
import { TestItemModel } from '../../models/test-item-model';

@Component({
  selector: 'app-select-private-dictionary',
  templateUrl: './select-private-dictionary.component.html',
  styleUrls: ['./select-private-dictionary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPrivateDictionaryComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectPrivateDictionaryComponent implements ControlValueAccessor, OnChanges {
  @Input() public answerQuantity?: RowAnswerQuantityEnum;
  @Input() public fabDictionaryItems: FabDictionaryItem[] = [];
  @Input() public columnGridWidth: number = 12;
  @Input() public model?: TestItemModel;

  public readonly answerQuantityEnum: typeof RowAnswerQuantityEnum = RowAnswerQuantityEnum;
  public isDisabled = false;
  public selectedItem: string | null = null;
  public selectedItems: string[] = [];

  private readonly fabDictionaryItemsState$: ReplaySubject<FabDictionaryItem[]> = new ReplaySubject<FabDictionaryItem[]>(1);

  public readonly hasLargeImage$: Observable<boolean> = this.fabDictionaryItemsState$.pipe(
    switchMap((dictionaryItems: FabDictionaryItem[]) => {
      const streams: Observable<boolean>[] = dictionaryItems
        .filter(({ imageUrl, captionRu }: FabDictionaryItem) => !isEmpty(imageUrl) && isEmpty(captionRu))
        .map(({ imageUrl }: FabDictionaryItem) => {
          const image: HTMLImageElement = new Image();
          image.src = imageUrl as string;
          return fromEvent(image, 'load').pipe(map(() => image.width > 200 && image.height > 200));
        });
      return merge(...streams);
    }),
    takeWhile((isLargeImage) => {
      return !isLargeImage;
    }, true),
    shareReplay(1),
  );

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('fabDictionaryItems') && !isNil(changes.fabDictionaryItems.currentValue)) {
      this.fabDictionaryItemsState$.next(changes.fabDictionaryItems.currentValue);
    }
  }

  getLabelId(item: FabDictionaryItem): string {
    return (item?.entityId ?? '').concat(item?.fabDictionaryId ?? '', this.model?.iterationId as string);
  }

  public registerOnChange(fn: (elementRowItemIds: string[]) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public updateValue(): void {
    if (this.answerQuantity === RowAnswerQuantityEnum.Multiple) {
      this.onChange(this.selectedItems);
    } else {
      this.onChange(this.selectedItem ? [this.selectedItem] : []);
    }
    this.onTouched();
  }

  public writeValue(elementRowItemIds: Array<string>): void {
    console.log(elementRowItemIds, this.answerQuantity);
    if (this.answerQuantity === RowAnswerQuantityEnum.Multiple) {
      this.selectedItems = elementRowItemIds;
    } else {
      this.selectedItem = elementRowItemIds?.[0] ?? null;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: Array<string>): void => {};
  private onTouched = (): void => {};
}
