import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subscription, distinctUntilChanged, of, scan, share, skipWhile, takeWhile, timer } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { Mobile, Test, TestItemResult, TestItemResultStatusEnum, TestItemTypeEnum, WebSite } from 'src/api/testrunner/models';
import { Page } from 'src/api/testrunner/models/page';
import { ExtensionStatusEnum } from '../../models/extension-status-enum';
import { MobileAppActionEnum } from '../../models/mobile-app-action-enum';
import { MobileAppStatusEnum } from '../../models/mobile-app-status-enum';
import { TestInfo } from '../../models/test-info';
import { TestItemModel } from '../../models/test-item-model';
import { WebSiteActionEnum } from '../../models/web-site-action-enum';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosService } from '../../services/macros.service';
import { MobileService } from '../../services/mobile.service';
import { PersistenceService } from '../../services/persistence.service';
import { WebSiteService } from '../../services/web-site.service';
import { changePageAnswerAction } from '../../store/actions/answers.actions';
import {
  selectAllTestItemModelsOnPage,
  selectIsAnswersValid,
  selectIsBackButtonEnabled,
  selectIsPageReady,
  selectIsTaskAnswerSaved,
} from '../../store/selectors/page.selectors';
import {
  selectExtensionStatus,
  selectIsMobileInProgress,
  selectIsWebsiteInProgress,
  selectMobileAppStatus,
  selectTestInfo,
} from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() public isLastPage = false;
  @Input() public page!: Mobile | Page | WebSite;
  @Input() public testScripts: Partial<Test> | null = null;
  @Output() public readonly toNextPage: EventEmitter<TestItemResultStatusEnum> = new EventEmitter<TestItemResultStatusEnum>();
  @Output() public readonly toPrevPage: EventEmitter<void> = new EventEmitter<void>();

  //@TODO
  /*kano?: Kano;*/

  answerTimeLimit$: Observable<number> = of(-1);
  private readonly defaultNextButtonText: string = this.translocoService.translate('testrunner.page.nextPage');
  private readonly defaultPrevButtonText: string = this.translocoService.translate('testrunner.page.prevPage');
  private readonly defaultReturnButtonText: string = this.translocoService.translate('testrunner.webSite.returnToAssignment');
  delayBeforeAnswer$: Observable<number> = of(-1);
  delayBeforeAnswer = false;
  isBtnClickDisabled = false;
  public readonly isAnswersValid$: Observable<boolean> = this.store.select(selectIsAnswersValid);
  public readonly isBackButtonEnabled$: Observable<boolean> = this.store.select(selectIsBackButtonEnabled);
  public isMobileInProgress$: Observable<boolean> = of(false);
  public isWebSiteInProgress$: Observable<boolean> = of(false);
  private readonly isTaskAnswerSaved$: Observable<boolean> = this.store.select(selectIsTaskAnswerSaved);
  public readonly testInfo$: Observable<TestInfo | null> = this.store.select(selectTestInfo);
  public readonly testItemModels$: Observable<Array<TestItemModel>> = this.store.select(selectAllTestItemModelsOnPage);
  timeDelay = 0;
  timeout = 0;
  testItemTypes = TestItemTypeEnum;

  private prevMobileAppStatus: MobileAppStatusEnum | null = null;
  private prevExtensionStatus: ExtensionStatusEnum | null = null;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly macrosService: MacrosService,
    private readonly mobileService: MobileService,
    private readonly persistenceService: PersistenceService,
    private readonly store: Store,
    private readonly translocoService: TranslocoService,
    private readonly webSiteService: WebSiteService,
  ) {}

  get backButtonText(): string {
    return (this.page as Page)?.backButtonText || this.defaultPrevButtonText;
  }

  get finishWebSiteButtonText(): string {
    return (this.page as WebSite)?.finishButtonText || this.translocoService.translate('testrunner.webSite.completeTheTask');
  }

  get nextButtonText(): string {
    return (this.page as Page)?.nextButtonText || this.defaultNextButtonText;
  }

  get returnButtonText(): string {
    return (this.page as WebSite).returnButtonText || this.defaultReturnButtonText;
  }

  public ngOnInit(): void {
    this.store.dispatch(
      changePageAnswerAction({
        answers: [],
      }),
    );

    this.subscription.add(
      this.store
        .select(selectMobileAppStatus)
        .pipe(
          filter((mobileAppStatus) => this.page?.testItemType === TestItemTypeEnum.Mobile && this.prevMobileAppStatus !== mobileAppStatus),
        )
        .subscribe((mobileAppStatus) => {
          if (mobileAppStatus === MobileAppStatusEnum.TaskCompleted) {
            this.mobileService.postMessage(MobileAppActionEnum.AnswerTask, this.page);
          } else if (mobileAppStatus === MobileAppStatusEnum.TaskSkipped) {
            this.goToNextPage(TestItemResultStatusEnum.Failed);
          }
        }),
    );

    this.subscription.add(
      this.store
        .select(selectExtensionStatus)
        .pipe(
          filter((extensionStatus) => this.page?.testItemType === TestItemTypeEnum.WebSite && this.prevExtensionStatus !== extensionStatus),
        )
        .subscribe((extensionStatus) => {
          this.prevExtensionStatus = extensionStatus;

          /*if (this.storeService.extensionStatus === ExtensionStatusEnum.TaskCompleted) {
            this.webSiteService.postMessage(WebSiteActionEnum.FinishTask, this.page);
          } else*/
          if (extensionStatus === ExtensionStatusEnum.TaskSkipped) {
            this.goToNextPage(TestItemResultStatusEnum.Failed);
          }
          if (extensionStatus === ExtensionStatusEnum.TaskCompleted) {
            this.goToNextPage(TestItemResultStatusEnum.SubmittedByUser);
          }
        }),
    );

    this.isMobileInProgress$ = this.store.select(selectIsMobileInProgress(this.page?.testItemType === TestItemTypeEnum.Mobile));
    this.isWebSiteInProgress$ = this.store.select(selectIsWebsiteInProgress(this.page?.testItemType === TestItemTypeEnum.WebSite));

    this.setAnswerTimeLimit();
    this.setDelayBeforeAnswer();

    //@TODO
    /*this.kano = {
      ...this.page,
      testItemType: TestItemTypeEnum.Kano,
      description:
        'Наша компания планирует запускать интернет-магазин. Сейчас вам будет представлено несколько функций, ' +
        'которые будут доступны в этом интернет-магазине. Пожалуйста, ответьте на несколько вопросов про каждую из них.',
      isSeparatePagesDisplayEnabled: true,
      isImportanceQuestionEnabled: true,
      functions: [
        {
          testItemId: this.page.id,
          description:
            'Кассовый чек посылают по смс или электронной почте по просьбе клиента. ' +
            'Продавец может отказаться отправлять электронный чек, если это технически невозможно — пропала связь с провайдерами, ' +
            'сломалось оборудование или магазин находится в удаленном месте, где нет стабильного интернет-покрытия.',
          name: 'Отправка чека на E-mail',
          order: 0,
        } as KanoFunction,
        {
          testItemId: this.page.id,
          description: '',
          name: 'Корзина для товаров',
          order: 1,
        } as KanoFunction,
        {
          testItemId: this.page.id,
          description: 'Описание фичи',
          name: 'Третья фича',
          order: 2,
        } as KanoFunction,
      ],
    } as Kano;*/
  }

  public ngAfterViewInit(): void {
    this.injectJsScript(this.testScripts?.customJSOnLoad ?? '');
    this.injectJsScript(this.page?.scriptJsOnLoad ?? '');
    this.changeDetectorRef.detectChanges(); //NG0100: ExpressionChangedAfterItHasBeenCheckedError
  }

  public ngOnDestroy(): void {
    this.injectJsScript(this.testScripts?.customJsOnExit ?? '');
    this.injectJsScript(this.page?.scriptJsOnHide ?? '');
    this.subscription.unsubscribe();
  }

  public trackByFn = (_index: number, testItemModel: TestItemModel): string => testItemModel.id;

  public goToNextPage(answerStatus: TestItemResultStatusEnum = TestItemResultStatusEnum.SubmittedByUser): void {
    this.isBtnClickDisabled = true;
    this.isTaskAnswerSaved$
      .pipe(
        filter((isAnswerSaved) => isAnswerSaved),
        take(1),
        switchMap(() => this.isAnswersValid$),
        take(1),
      )
      .subscribe((isAnswerValid) => {
        //для тайм аутов и скипов не проверяется валидность
        if (answerStatus !== TestItemResultStatusEnum.SubmittedByUser || isAnswerValid) {
          // this.toNextPage.emit(); - перенесено ниже
          // возвращено так как экшн на следующую страницу диспатчится в обработчике событий компонента flow
          // нужно эмитить только вылидную форму, так как было решено не блокировать кнопку некст.
          // @TODO перепроверить как поведет с мобилкой
          this.toNextPage.emit(answerStatus);
        }
        this.isBtnClickDisabled = false;
      });
  }

  public goToPrevPage(): void {
    this.toPrevPage.emit();
  }

  public onAnswerChanges($event: TestItemResult): void {
    this.store.dispatch(
      changePageAnswerAction({
        answers: [
          {
            clientSendTimeUtc: DateTimeService.currentDateTimeUTC,
            clientEndTimeUtc: DateTimeService.currentDateTimeUTC,
            clientTimeZoneMinutes: DateTimeService.timezone,
            status: TestItemResultStatusEnum.Intermediate,
            logicResult: true,
            ...$event,
          } as TestItemResult,
        ],
      }),
    );

    //if ($event.type !== TestItemResultTypeEnum.If) {
    this.injectJsScript(this.testScripts?.customJSOnChange ?? '');
    this.injectJsScript(this.page?.scriptJsOnChange ?? '');
    //}
  }

  public onSkipTask(): void {
    this.goToNextPage(TestItemResultStatusEnum.IgnoredByUser);
  }

  public returnToTask(): void {
    this.webSiteService.postMessage(WebSiteActionEnum.StartTask, this.page as WebSite);
  }

  private setDelayBeforeAnswer(): void {
    if (this.page?.delayBeforeAnswerEnabled && this.page?.delayBeforeAnswerMS) {
      const isPassed = this.persistenceService.get('passed');
      if (!isPassed || isPassed.indexOf(this.page?.id as string) === -1) {
        this.delayBeforeAnswer$ = this.createTimer$(this.page?.delayBeforeAnswerMS ?? 0);
        this.subscription.add(
          this.delayBeforeAnswer$.pipe(skipWhile((time) => time > 0)).subscribe(() => {
            if (this.page?.id) {
              const passed = this.persistenceService.get('passed') ?? [];
              passed.push(this.page?.id as string);
              this.persistenceService.set('passed', passed);
            }
          }),
        );
      }
    }
  }

  private setAnswerTimeLimit(): void {
    if (this.page?.answerTimeLimitEnabled && this.page?.answerTimeLimitMS) {
      this.answerTimeLimit$ = this.createTimer$(this.page?.answerTimeLimitMS ?? 0);
      this.subscription.add(
        this.answerTimeLimit$.pipe(skipWhile((time) => time > 0)).subscribe(() => this.goToNextPage(TestItemResultStatusEnum.TimeOut)),
      );
    }
  }

  private injectJsScript(script: string): void {
    this.macrosService.evalScript(script, this.page);
  }

  private createTimer$(duration: number): Observable<number> {
    return this.store.select(selectIsPageReady).pipe(
      filter((isReady) => isReady),
      switchMap(() => timer(0, 1000)),
      distinctUntilChanged(),
      scan((time) => time - 1000, duration),
      takeWhile((time) => time >= 0),
      share(),
    );
  }
}
