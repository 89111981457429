<ng-container *transloco="let t">
  <div *ngIf="description" [innerHtml]="description" class="pb-4"></div>

  <div class="grid w-full">
    <div class="col-6 md:col-4 sticky top-0" style="min-height: 470px; max-height: 85vh;">
      <p-card #cardGroupContainerRef class="w-full h-full scroll-no-padding" styleClass="bg-gray-50 text-center border-round-6px">
        <ng-template pTemplate="header">
          <div class="mt-3">
            <span class="text-sm text-gray-400">
              {{ t('testrunner.cardSorting.cardsCount', {count: initialCardList.length, total: totalCardsCount}) }}
            </span>
            <p-progressBar
              [showValue]="false"
              styleClass="mx-2 mt-2 h-1rem surface-0 border-round-6px"
              [value]="progress"
            ></p-progressBar>
          </div>
        </ng-template>

        <ng-container
          *ngIf="initialCardList"
          [ngTemplateOutlet]="cardSorting.isCardsCollapseModeEnabled ? stackedCardList : simpleCardList"
          [ngTemplateOutletContext]="{cardList: initialCardList, cardGroup: null, cardGroupContainerRef}">
        </ng-container>
      </p-card>
    </div>
    <div class="col-6 md:col-8">
      <ng-container *ngIf="breakpointObserver.isMatched(['(min-width: 768px)']); else mobileLayout">
        <div class="grid card-groups">
          <div class="flex flex-column col-6">
            <ng-container
              *ngFor="let cardGroup of sortedCardGroups | arrayToColumns: 2: 1"
              [ngTemplateOutlet]="cardGroupTemplate"
              [ngTemplateOutletContext]="{ cardGroup }">
            </ng-container>
            <ng-container *ngIf="canAddCustomGroup && ((sortedCardGroups.length ?? 0) % 2 === 0)" [ngTemplateOutlet]="addCustomGroupTemplate"></ng-container>
          </div>
          <div class="flex flex-column col-6">
            <ng-container
              *ngFor="let cardGroup of sortedCardGroups | arrayToColumns: 2: 2"
              [ngTemplateOutlet]="cardGroupTemplate"
              [ngTemplateOutletContext]="{cardGroup}">
            </ng-container>
            <ng-container *ngIf="canAddCustomGroup && ((sortedCardGroups.length ?? 0) % 2 === 1)"  [ngTemplateOutlet]="addCustomGroupTemplate"></ng-container>
          </div>
        </div>
      </ng-container>

      <ng-template #mobileLayout>
        <div class="flex flex-column card-groups">
          <ng-container
            *ngFor="let cardGroup of sortedCardGroups"
            [ngTemplateOutlet]="cardGroupTemplate"
            [ngTemplateOutletContext]="{ cardGroup }">
          </ng-container>
          <ng-container *ngIf="canAddCustomGroup" [ngTemplateOutlet]="addCustomGroupTemplate"></ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- Новая группа -->
  <ng-template #addCustomGroupTemplate>
    <p-card
      styleClass="hover:bg-gray-50 border-round-6px mb-3 border-dashed add-group text-lg flex justify-content-center align-items-center"
      (click)="addCustomGroup()"
    >
      <span class="fa-solid fa-plus mr-2"></span>
      <span> {{ t('testrunner.cardSorting.addGroup') }}</span>
    </p-card>
  </ng-template>

  <!-- Группа карточек -->
  <!-- в ng-template передается readOnly копия, поэтому обернут объект в cardGroupRef, чтобы передать ссылку на исходный объект  -->
  <ng-template #cardGroupTemplate let-cardGroup="cardGroup">
    <app-card-group
      [cardsCount]="cardGroupListDictionary[cardGroup.id]?.length ?? 0"
      [cardGroup]="cardGroup"
      [isCardsGroupsLimitEnabled]="cardSorting.isCardsGroupsLimitEnabled"
      [isCardsGroupNameRequired]="cardSorting.isMustDefineNameForCustomCardGroups"
      (cardGroupChange)="updateCardGroup($event)"
      (removeGroup)="onRemoveGroup($event)"
    >
      <ng-container
        *ngIf="cardGroupListDictionary[cardGroup.id] as cardList;"
        [ngTemplateOutlet]="cardSorting.isCardGroupsCollapseModeEnabled ? stackedCardList : simpleCardList"
        [ngTemplateOutletContext]="{cardList, cardGroup, cardGroupContainerRef}">
      </ng-container>
    </app-card-group>
  </ng-template>

  <!-- Расположение карточек в группах списком -->
  <ng-template #simpleCardList let-cardList="cardList" let-cardGroup="cardGroup">
    <div
      cdkDropList
      [cdkDropListConnectedTo]="getConnectedGroupIds(cardGroup?.id ?? initialCardListId)"
      [cdkDropListData]="cardList"
      [cdkDropListEnterPredicate]="canDrop(cardGroup, cardList)"
      [class.px-3]="!cardGroup"
      [class.scrolled-card-group]="!cardGroup"
      [id]="cardGroup?.id ?? initialCardListId"
      style="min-height: 4rem"
      (cdkDropListDropped)="dropCard($event)"
    >
      <app-draggable-card
        *ngFor="let card of cardList; let order = index"
        [card]="card"
        [order]="cardSorting.isCardNumberShowed && cardGroup ? order + 1 : undefined"
        styleClass="my-2"
        (dragMove)="dragMoveEvent = $event"
      ></app-draggable-card>
    </div>
  </ng-template>

  <!-- Сложенные в стопку карточки в группах -->
  <ng-template #stackedCardList let-cardList="cardList" let-cardGroup="cardGroup" let-cardGroupContainerRef="cardGroupContainerRef">
    <div class="h-5rem" [class.mx-2]="!cardGroup">
      <div
        cdkDropList
        [cdkDropListConnectedTo]="getConnectedGroupIds(cardGroup?.id ?? initialCardListId)"
        [cdkDropListData]="cardList"
        [cdkDropListEnterPredicate]="canDrop(cardGroup, cardList)"
        class="border-round-6px h-3rem m-2 relative z-3 overflow-hidden"
        [class.surface-0]="cardList.length"
        [class.card-shadow]="cardList.length"
        [id]="cardGroup?.id ?? initialCardListId"
        (cdkDropListDropped)="dropCard($event)"
      >
        <app-draggable-card
          *ngIf="cardList?.[0] && (!isDragOver(cardGroupContainerRef, cardGroup?.id ?? initialCardListId)
            || (isDragOver(cardGroupContainerRef, cardGroup?.id ?? initialCardListId) && !canDrop(cardGroup, cardList)()))"
          [card]="cardList?.[0]" class="w-full relative z-3"
          (dragMove)="dragMoveEvent = $event"
        ></app-draggable-card>
      </div>
      <div
        *ngIf="cardList?.[1]"
        class="border-round-6px h-3rem surface-0 m-2 relative card-shadow z-2"
        style="bottom: 3rem"
      ></div>
      <div
        *ngIf="cardList?.[2]"
        class="border-round-6px h-3rem surface-0 m-2 relative card-shadow z-1"
        style="bottom: 6rem"
      ></div>
    </div>
  </ng-template>

  <!--<pre>{{ cardSorting | json }}</pre>
  isCardsCollapseModeEnabled
isCardGroupsCollapseModeEnabled-->

</ng-container>
