<div class="app-install-info" *transloco="let t">
  <h1>{{ t('customAppInstall.appInstall') }}</h1>
  <p>{{ t('customAppInstall.beforeInstall') }}</p>
  <ol class="list-circle">
    <li>
      {{ t('customAppInstall.downloadFile') }} <strong id="appName">{{ nameApk }}</strong
      ><br />
      <button pButton pRipple class="p-button-success mt-2" (click)="getUrlApk()" [disabled]="!urlApk">
        {{ urlApk ? t('customAppInstall.downloadApp') : t('customAppInstall.noAppPath') }}
      </button>
    </li>
    <li>
      {{ t('customAppInstall.openFile') }} <strong>{{ t('customAppInstall.allowInstall') }}</strong>
    </li>
    <li>{{ t('customAppInstall.completeInstall') }}</li>
  </ol>
  <p>{{ t('customAppInstall.afterInstall') }}</p>
</div>
