import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { NgxStarsComponent } from 'ngx-stars';
import { Observable, ReplaySubject, combineLatest, distinctUntilChanged, map } from 'rxjs';
import { FabDictionaryItem } from 'src/api/testrunner/models/fab-dictionary-item';
import { InteractionEnum } from 'src/api/testrunner/models/interaction-enum';
import { RatingTypeEnum } from 'src/api/testrunner/models/rating-type-enum';
import { Task } from 'src/api/testrunner/models/task';
import { ScaleService } from '../../../services/scale.service';

enum RatingIconColors {
  star = 'var(--orange-300)',
  heart = 'var(--pink-500)',
  like = 'var(--blue-600)',
}

interface IconUrls {
  empty: string;
  half: string;
  full: string;
}

const HEART__URLS: IconUrls = {
  empty: '../../../../assets/fab-icons/rating/heart/empty.svg',
  half: '../../../../assets/fab-icons/rating/heart/half.svg',
  full: '../../../../assets/fab-icons/rating/heart/full.svg',
};
const STAR__URLS: IconUrls = {
  empty: '../../../../assets/fab-icons/rating/star/empty.svg',
  half: '../../../../assets/fab-icons/rating/star/half.svg',
  full: '../../../../assets/fab-icons/rating/star/full.svg',
};
const LIKE__URLS: IconUrls = {
  empty: '../../../../assets/fab-icons/rating/like/empty.svg',
  half: '../../../../assets/fab-icons/rating/like/half.svg',
  full: '../../../../assets/fab-icons/rating/like/full.svg',
};

const ICON_WIDTH: number = 28;

@Component({
  selector: 'app-scale-rating',
  templateUrl: './scale-rating.component.html',
  styleUrls: ['./scale-rating.component.scss'],
})
export class ScaleRatingComponent implements OnChanges, AfterViewChecked {
  @Input() columns: Array<FabDictionaryItem> = [];
  @Input() formAnswer!: UntypedFormGroup;
  @Input() rows: Array<FabDictionaryItem> = [];
  @Input() scaleData!: Task;

  @Output() _onRatingSet = new EventEmitter<{ rating: number; entityId: string }>();

  @ViewChild('starsContainer') public starsContainer?: ElementRef<HTMLElement>;

  interactionEnum = InteractionEnum;

  public readonly iconColorMap: Map<RatingTypeEnum, RatingIconColors> = new Map<RatingTypeEnum, RatingIconColors>([
    [RatingTypeEnum.Heart, RatingIconColors.heart],
    [RatingTypeEnum.Like, RatingIconColors.like],
    [RatingTypeEnum.Star, RatingIconColors.star],
  ]);

  public readonly iconUrlsMap: Map<RatingTypeEnum, IconUrls> = new Map<RatingTypeEnum, IconUrls>([
    [RatingTypeEnum.Heart, HEART__URLS],
    [RatingTypeEnum.Like, LIKE__URLS],
    [RatingTypeEnum.Star, STAR__URLS],
  ]);

  public readonly scaleData$: ReplaySubject<Task> = new ReplaySubject<Task>(1);
  private readonly starsContainerClientWidth$: ReplaySubject<number> = new ReplaySubject<number>(1);

  public readonly iconPadding$: Observable<string> = combineLatest([
    this.scaleData$,
    this.starsContainerClientWidth$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([scaleData, starsContainerClientWidth]: [Task, number]) => {
      const padding = Math.floor((starsContainerClientWidth - ICON_WIDTH * (scaleData.maxValue ?? 1)) / (scaleData?.maxValue ?? 1));
      return padding > 15 ? '1rem' : padding < 1 ? '1px' : padding + 'px';
    }),
  );

  constructor(private readonly scaleService: ScaleService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('scaleData') && !isNil(changes.scaleData.currentValue)) {
      this.scaleData$.next(changes.scaleData.currentValue);
    }
  }

  public ngAfterViewChecked(): void {
    if (isNil(this.starsContainer)) {
      return;
    }
    this.starsContainerClientWidth$.next(this.starsContainer.nativeElement.clientWidth);
  }

  public getIconPadding(clientWidth: number): string {
    // use it iconPadding$ instead of iconPadding$ because of ngx-stars bug
    const padding = Math.floor((clientWidth - ICON_WIDTH * (this.scaleData?.maxValue ?? 1)) / (this.scaleData?.maxValue ?? 1));
    return padding > 15 ? '1rem' : padding < 1 ? '1px' : padding + 'px';
  }

  public getInitialStars(entityId: string, startComponent: NgxStarsComponent): number {
    const rating = this.getRating(entityId);
    const ratingToSet = rating !== null ? rating - (this.scaleData?.minValue ?? 0) + 1 : 0;

    // ngx-stars bug
    if (ratingToSet === 0 && startComponent?.editableStars && startComponent?.rating !== 0) {
      startComponent.setRating(0);
    }

    return ratingToSet;
  }

  public getRating(entityId: string): number | null {
    let value: number | null = 0;
    this.formAnswer.getRawValue().formRows.forEach((item: { [key: string]: number | null }) => {
      const entry = Object.entries(item)[0];
      if (entry[0] === entityId) {
        value = entry[1];
      }
    });

    return value ?? null;
  }

  public onRatingSet(rating: number, entityId: string): void {
    this._onRatingSet.emit({ rating, entityId });
  }

  public getValue(value: number, locale?: string): string {
    return this.scaleService.value(this.scaleData, value, locale);
  }
}
