import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import {
  FabDictionaryItem,
  RankingResult,
  Task,
  TestItemResult,
  TestItemResultStatusEnum,
  TestItemResultTypeEnum,
} from 'src/api/testrunner/models';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { UtilsService } from '../../services/utils.service';
import { selectDictionaryItemsById } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-ranking',
  templateUrl: './ranking.component.html',
  styleUrls: ['./ranking.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RankingComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public index?: number;
  @Input() public testItemModel?: TestItemModel;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  public description?: SafeHtml;
  public largeDictionaries: Array<FabDictionaryItem> = []; //not used
  public ranking?: Task;
  private initialSourceItems: FabDictionaryItem[] = [];
  public sourceItems: FabDictionaryItem[] = [];
  public targetItems: FabDictionaryItem[] = [];

  public readonly isAnswerSkippedControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });
  public readonly isAnswerSkipped$: Observable<boolean> = this.isAnswerSkippedControl.valueChanges;

  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly sanitizer: DomSanitizer,
    private readonly pipe: MacrosPipe,
    private readonly store: Store,
  ) {
    this.subscription.add(this.processIsAnswerSkippedChanges());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public ngOnInit(): void {
    this.ranking = this.testItemModel?.data;

    const answer = this.persistenceService.get('answer_' + (this.ranking?.id ?? '')) as RankingResult;

    const isSkipped: boolean = Boolean(answer?.isAnswerSkipped && this.ranking?.isSkipButtonEnabled);
    this.isAnswerSkippedControl.setValue(isSkipped, { emitEvent: false });

    this.store
      .select(selectDictionaryItemsById(this.ranking?.rowFabDictionaryId ?? ''))
      .pipe(
        //take 1 для устранения дубликатов ранжирования. можно также сбрасывать сорс и таргет айтемс до заполнения
        take(1),
        tap((fabDictionaryItems) => {
          fabDictionaryItems?.forEach((item) => {
            if (item.entityId && answer?.elementRowItemIds?.includes(item.entityId)) {
              if (item.fabDictionaryItems && item.fabDictionaryItems.length > 0) {
                this.recursiveLargeDictionaries(item);
              }
              this.targetItems.push(item);
            } else {
              if (item.captionRu?.trim().length || item.imageUrl?.length) {
                this.sourceItems.push(item);
              }
            }
          });

          if (this.ranking?.shuffleRows) {
            UtilsService.shuffleItems(this.sourceItems, this.ranking?.lockOnShuffleDictionaryItems);
          }
        }),
      )
      .subscribe(() => (this.initialSourceItems = cloneDeep(this.sourceItems)));
  }

  public onMoveToTarget(items: { items: FabDictionaryItem[] }): void {
    const fabDictionaryItem = items.items[0];

    if (
      this.ranking?.answerMaxSelectedItems &&
      this.ranking?.answerMaxSelectedItemsEnabled &&
      this.targetItems.length > this.ranking?.answerMaxSelectedItems
    ) {
      this.returnToSource(fabDictionaryItem);
    } else {
      this.saveAnswer();
    }
  }

  public saveAnswer(isAnswerSkipped: boolean = false): void {
    const elementRowItemIds: string[] = [];
    this.targetItems.forEach((item) => {
      if (item.entityId) {
        elementRowItemIds.push(item.entityId);
      }
    });

    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      elementRowItemIds,
      iterationId: this.testItemModel?.iterationId,
      rowDictionaryId: this.testItemModel?.data.rowFabDictionaryId ?? '',
      testId: this.testItemModel?.data.testId,
      testItemId: this.testItemModel?.data.id,
      type: TestItemResultTypeEnum.Ranking,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as RankingResult);
  }

  private returnToSource(fabDictionaryItem: FabDictionaryItem): void {
    this.targetItems.splice(this.targetItems.indexOf(fabDictionaryItem), 1);
    this.sourceItems?.push(fabDictionaryItem);
  }

  private recursiveLargeDictionaries(item: FabDictionaryItem): void {
    if (item.fabDictionaryItems && item.fabDictionaryItems?.length > 0) {
      item.fabDictionaryItems.map((currentItem) => {
        this.largeDictionaries.push(currentItem);
        this.recursiveLargeDictionaries(currentItem);
      });
    } else {
      return;
    }
  }

  private processIsAnswerSkippedChanges(): Subscription {
    return this.isAnswerSkipped$.subscribe((isAnswerSkipped: boolean) => {
      if (isAnswerSkipped) {
        this.targetItems = [];
        this.sourceItems = cloneDeep(this.initialSourceItems);
      }
      this.saveAnswer(isAnswerSkipped);
    });
  }
}
