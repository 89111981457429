import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-preview',
  templateUrl: './image-preview.component.html',
  styleUrls: ['./image-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImagePreviewComponent {
  @Input() public alt?: string;
  @Input() public preview = false;
  @Input() public height?: string;
  @Input() public src?: string | SafeUrl;
  @Input() public styleClass?: string;
  @Input() public width?: string;

  @Output() public readonly imageLoaded: EventEmitter<void> = new EventEmitter<void>();

  public isPreviewVisible: boolean = false;
}
