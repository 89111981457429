import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { Observable, ReplaySubject, map, switchMap } from 'rxjs';

@Component({
  selector: 'app-test-unavailable',
  templateUrl: './test-unavailable.component.html',
  styleUrls: ['./test-unavailable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestUnavailableComponent implements OnChanges {
  @Input() error: HttpErrorResponse | null = null;

  private readonly error$: ReplaySubject<HttpErrorResponse> = new ReplaySubject<HttpErrorResponse>(1);

  public readonly errorMessage$: Observable<string> = this.translocoService.langChanges$.pipe(
    switchMap(() => this.error$),
    map((error: HttpErrorResponse) => {
      const errorStatusMessage: string = error.status ? this.translocoService.translate('testrunner.test.errors.' + error.status) : '';
      return `${this.translocoService.translate('testrunner.test.testUnavailable')}. ${errorStatusMessage}`;
    }),
  );

  constructor(private readonly translocoService: TranslocoService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('error') && !isNil(changes.error.currentValue)) {
      this.error$.next(changes.error.currentValue);
    }
  }
}
