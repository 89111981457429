import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CountryCallingCode,
  CountryCode,
  getCountries,
  getCountryCallingCode,
  getExampleNumber,
  isSupportedCountry,
  parsePhoneNumber,
} from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';
import { PersistenceService } from '../../services/persistence.service';

type CountryPhoneCode = { countryCode: CountryCode; phoneCode: CountryCallingCode };

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputComponent implements ControlValueAccessor {
  public selectedPhoneCode?: CountryPhoneCode;
  public phoneNumberWithoutCode: string = '';
  public placeholderPhoneNumber: string = '';
  public phoneNumberMask: string | null = null;
  public filteredPhoneCodes: CountryPhoneCode[] = [];

  private readonly phoneCodeList: CountryPhoneCode[];

  constructor(private readonly persistenceService: PersistenceService) {
    this.phoneCodeList = (getCountries() || []).map((countryCode) => ({ countryCode, phoneCode: getCountryCallingCode(countryCode) }));
  }

  public filterPhoneCode(query: string): void {
    this.filteredPhoneCodes = this.phoneCodeList.filter(
      (phoneCodeObj) =>
        phoneCodeObj.countryCode.toLowerCase().indexOf(query.toLowerCase()) === 0 ||
        phoneCodeObj.phoneCode.toLowerCase().indexOf(query.toLowerCase()) === 0,
    );
  }

  public onPhoneNumberChange(phoneNumber: string): void {
    this.phoneNumberWithoutCode = phoneNumber;
    try {
      const parsedNumber = parsePhoneNumber(this.phoneNumberWithoutCode, this.selectedPhoneCode?.countryCode);
      if (parsedNumber.number) {
        this.onChange(parsedNumber.number);
        this.onTouched();
      }
    } catch {}
  }

  public setPhoneMask(countryPhoneCode: CountryPhoneCode): void {
    this.phoneNumberWithoutCode = '';
    this.selectedPhoneCode = countryPhoneCode;
    const examplePhoneNumber = getExampleNumber(countryPhoneCode.countryCode, examples);
    this.placeholderPhoneNumber = examplePhoneNumber ? examplePhoneNumber.format('NATIONAL', { nationalPrefix: false }) : '';
    this.phoneNumberMask = examplePhoneNumber ? this.placeholderPhoneNumber.replace(/\d/g, '9') : null;
  }

  public registerOnChange(fn: (phoneNumber: string) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(phoneNumber: string): void {
    try {
      const parsedNumber = parsePhoneNumber(phoneNumber);
      if (parsedNumber) {
        this.setPhoneMask({ countryCode: parsedNumber.country as CountryCode, phoneCode: parsedNumber.countryCallingCode });
        this.phoneNumberWithoutCode = parsedNumber.format('NATIONAL', { nationalPrefix: false });
        return;
      }
    } catch {
      const locale = this.persistenceService.get('selectedTranslation').toUpperCase();
      if (isSupportedCountry(locale)) {
        this.setPhoneMask({ countryCode: locale as CountryCode, phoneCode: getCountryCallingCode(locale) });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onChange = (_: string): void => {};
  private onTouched = (): void => {};
}
