import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { Observable, Subscription, combineLatest, of, timer } from 'rxjs';
import { debounce, filter, map, take } from 'rxjs/operators';
import { TestItemResultStatusEnum } from 'src/api/testrunner/models';
import { FabDictionaryItem } from '../../../api/testrunner/models/fab-dictionary-item';
import { InteractionEnum } from '../../../api/testrunner/models/interaction-enum';
import { ScaleResult } from '../../../api/testrunner/models/scale-result';
import { ScaleResultItem } from '../../../api/testrunner/models/scale-result-item';
import { ScaleTypeEnum } from '../../../api/testrunner/models/scale-type-enum';
import { Task } from '../../../api/testrunner/models/task';
import { TestItemResult } from '../../../api/testrunner/models/test-item-result';
import { TestItemResultTypeEnum } from '../../../api/testrunner/models/test-item-result-type-enum';
import { PipingItem } from '../../models/piping-item';
import { TestItemModel } from '../../models/test-item-model';
import { DateTimeService } from '../../services/date-time.service';
import { MacrosPipe } from '../../services/macros.pipe';
import { PersistenceService } from '../../services/persistence.service';
import { ScaleService } from '../../services/scale.service';
import { lockButtonNext } from '../../store/actions/page.actions';
import { selectDictionaryItemsById } from '../../store/selectors/test-runner.selectors';

interface ScaleFormValue {
  formRows: ScaleResultItem[];
  isAnswerSkipped: boolean;
}

interface Emotion {
  title: string;
  icon: string;
}

const MAX_ROWS = 200;
const MAX_COLUMNS = 30;

@Component({
  selector: 'app-scale',
  templateUrl: './scale.component.html',
  styleUrls: ['./scale.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScaleComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public testItemModel?: TestItemModel;
  @Input() public index?: number;

  @Output() public readonly answerChanges: EventEmitter<TestItemResult> = new EventEmitter<TestItemResult>();

  columns: FabDictionaryItem[] = [];
  description: SafeHtml | undefined;
  defaultValue?: number;
  emotions: Emotion[] = [];
  formAnswer!: UntypedFormGroup;
  interactionEnum = InteractionEnum;
  rows: FabDictionaryItem[] = [];
  scaleData?: Task;
  scaleTypeEnum = ScaleTypeEnum;
  npsValue?: number | null;

  readonly maxRows = MAX_ROWS;
  readonly maxColumns = MAX_COLUMNS;

  private rows$: Observable<FabDictionaryItem[]> = of([]);
  private columns$: Observable<FabDictionaryItem[]> = of([]);

  private defaultFormValue?: ScaleResultItem[];

  private readonly subscription: Subscription = new Subscription();

  private descriptionString: string = '';

  constructor(
    private readonly persistenceService: PersistenceService,
    private readonly fb: UntypedFormBuilder,
    private readonly pipe: MacrosPipe,
    private readonly sanitizer: DomSanitizer,
    private readonly store: Store,
    private readonly translocoService: TranslocoService,
    private readonly scaleService: ScaleService,
  ) {}

  private get isNps(): boolean {
    return this.scaleData?.scaleType === ScaleTypeEnum.NpsRadioButton;
  }

  private get isSliders(): boolean {
    return this.scaleData?.scaleType === ScaleTypeEnum.Sliders;
  }

  private get isRating(): boolean {
    return this.scaleData?.scaleType === ScaleTypeEnum.Rating;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.testItemModel.previousValue?.lastUpdateTime !== changes.testItemModel.currentValue?.lastUpdateTime &&
      this.testItemModel?.data &&
      this.testItemModel?.data?.description &&
      this.descriptionString !== this.testItemModel.data.description // cannot be replaced with prev/curr value because of lastUpdateTime
    ) {
      const pipingItem = {
        ...this.testItemModel.data,
        iterationId: this.testItemModel?.iterationId ?? null,
      } as PipingItem;
      this.descriptionString = this.testItemModel.data.description;
      this.description = this.sanitizer.bypassSecurityTrustHtml(this.pipe.transform(this.testItemModel.data.description, pipingItem));
    }
  }

  public ngOnInit(): void {
    this.scaleData = this.testItemModel?.data;

    this.rows$ = this.store.select(selectDictionaryItemsById(this.scaleData?.rowFabDictionaryId ?? ''));
    this.columns$ = this.store.select(selectDictionaryItemsById(this.scaleData?.columnFabDictionaryId ?? ''));

    this.defaultValue = this.getDefaultValue();

    this.initializeForm();

    // сохраним промежуточное значение по умолчанию
    // const def: ScaleResultItem[] = this.formAnswer.value.formRows.map((row: ScaleResultItem) => {
    //   const entry = Object.entries(row)[0];
    //   return {
    //     answer: parseFloat(this.value(entry[1], 'en')),
    //     elementItemId: entry[0],
    //   };
    // });

    // this.save(def);

    this.subscription.add(this.processFormAnswerValueChanges());

    this.subscription.add(this.processSelectTranslationChanges());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onRatingSet(event: { rating: number; entityId: string }): void {
    let rating = event.rating;
    const entityId = event.entityId;

    if (this.isNps) {
      this.npsValue = rating;
    }

    if (!this.isNps) {
      rating += this.scaleData?.minValue ?? 0;
    }

    const formRows = this.formAnswer.value.formRows.map((row: { [key: string]: number }) => {
      const entry = Object.entries(row)[0];
      if (entry[0] === entityId) {
        const key = entry[0] as string;
        const object: { [key: string]: number | null } = {};
        if (!this.isNps && !this.isRating && this.scaleData?.minValue && rating < this.scaleData?.minValue) {
          object[key] = null;
        } else {
          object[key] = parseFloat(this.value(rating, 'en'));
        }

        return object;
      }

      return row;
    });

    this.formAnswer.patchValue({
      formRows,
    });
  }

  save(result: ScaleResultItem[], isAnswerSkipped: boolean): void {
    this.answerChanges.emit({
      answerTimeSpentMs: DateTimeService.getDuration(this.testItemModel?.showStartTime),
      clientStartTimeUtc: this.testItemModel?.showStartTime ?? DateTimeService.currentDateTimeUTC,
      itemsDictionaryId: this.testItemModel?.data?.rowFabDictionaryId,
      iterationId: this.testItemModel?.iterationId,
      resultItems: result,
      testId: this.testItemModel?.data?.testId,
      testItemId: this.testItemModel?.data?.id,
      type: TestItemResultTypeEnum.Scale,
      status: TestItemResultStatusEnum.Intermediate,
      isAnswerSkipped,
    } as ScaleResult);
  }

  value(value: number, locale?: string): string {
    return this.scaleData ? this.scaleService.value(this.scaleData, value, locale) : '';
  }

  private get(testItemId: string): ScaleResult | null {
    return this.persistenceService.get('answer_' + testItemId) || null;
  }

  private getDefaultValue(): number {
    let defaultValue = this.scaleData?.defaultValue ?? this.scaleData?.minValue ?? 0;

    if (this.scaleData?.gridEnabled && this.scaleData.valueGridAnchored) {
      const intervalLength = (this.scaleData?.maxValue ?? 1) - (this.scaleData?.minValue ?? 0);
      const intervalItemLength = intervalLength / (this.scaleData?.gridLinesCount ?? 1);
      const intervalPoints = [];

      let i = this.scaleData?.minValue ?? 0;

      while (i <= (this.scaleData?.maxValue ?? 1)) {
        intervalPoints.push(i);
        i += intervalItemLength;
      }

      defaultValue = intervalPoints.reduce((prev, curr) => (Math.abs(curr - defaultValue) < Math.abs(prev - defaultValue) ? curr : prev));
    }

    return defaultValue;
  }

  private initializeForm(): void {
    const answerResult = this.get(this.scaleData?.id as string);

    const isSkipped: boolean = Boolean(answerResult?.isAnswerSkipped && this.scaleData?.isSkipButtonEnabled);

    this.formAnswer = this.fb.group({
      formRows: this.fb.array([]),
      isAnswerSkipped: isSkipped,
    });

    if (isSkipped) {
      this.formAnswer.controls.formRows.disable();
    }

    const controls = this.formAnswer.controls.formRows as UntypedFormArray;

    combineLatest([this.rows$, this.columns$])
      .pipe(
        filter(([rows, columns]) => rows !== undefined && columns !== undefined),
        take(1),
      )
      .subscribe(([rows, columns]: [FabDictionaryItem[], FabDictionaryItem[]]) => {
        this.rows = rows;
        this.columns = columns;
        for (const row of this.rows || []) {
          const savedRow = (answerResult?.resultItems || []).find((item) => row.entityId === item.elementItemId);

          if (this.isNps && row.entityId === this.rows[0]?.entityId) {
            this.npsValue = savedRow?.answer ?? undefined;
          }

          controls.push(
            this.fb.group({
              [row.entityId as string]: [
                savedRow?.answer ?? (this.isSliders && this.scaleData?.defaultValueIsEnabled ? this.getDefaultValue() : undefined),
              ],
            }),
          );
        }

        this.defaultFormValue = this.formAnswer.controls.formRows.getRawValue();
      });
  }

  private getAnswerFromDefaultFormValue(): ScaleResultItem[] {
    return (
      this.defaultFormValue?.map((row) => {
        const entry = Object.entries(row)[0];
        return {
          answer: parseFloat(this.value(entry[1], 'en')),
          elementItemId: entry[0],
        };
      }) ?? []
    );
  }

  private processFormAnswerValueChanges(): Subscription {
    return this.formAnswer.valueChanges
      .pipe(
        debounce(() => {
          this.store.dispatch(lockButtonNext());
          return timer(0);
        }),
        map(() => this.formAnswer.getRawValue()),
      )
      .subscribe((data: ScaleFormValue) => {
        const answer: ScaleResultItem[] = data.formRows.map((row) => {
          const entry = Object.entries(row)[0];
          return {
            answer: parseFloat(this.value(entry[1], 'en')),
            elementItemId: entry[0],
          };
        });

        if (data.isAnswerSkipped) {
          this.formAnswer.controls.formRows.setValue(this.defaultFormValue, { emitEvent: false });
          this.npsValue = null;
          this.formAnswer.controls.formRows.disable({ emitEvent: false });
          this.save(this.getAnswerFromDefaultFormValue(), data.isAnswerSkipped);
          return;
        } else {
          this.formAnswer.controls.formRows.enable({ emitEvent: false });
        }

        this.save(answer, data.isAnswerSkipped);
      });
  }

  private processSelectTranslationChanges(): Subscription {
    return this.translocoService.selectTranslation().subscribe((translation) => {
      this.emotions = [
        { title: translation['testrunner.scale.emotions.terribly'], icon: 'fa-face-tired' },
        { title: translation['testrunner.scale.emotions.veryBad'], icon: 'fa-face-frown-open' },
        { title: translation['testrunner.scale.emotions.bad'], icon: 'fa-face-frown-slight' },
        { title: translation['testrunner.scale.emotions.normally'], icon: 'fa-face-meh' },
        { title: translation['testrunner.scale.emotions.well'], icon: 'fa-face-smile' },
        { title: translation['testrunner.scale.emotions.veryWell'], icon: 'fa-face-grin' },
        { title: translation['testrunner.scale.emotions.gloriously'], icon: 'fa-face-laugh-squint' },
      ];
    });
  }
}
