import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Test } from '../../../api/testrunner/models/test';
import { environment } from '../../../environments/environment';
import { selectTestStartData } from '../../store/selectors/test-runner.selectors';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  public readonly logo: string = environment.logo;
  public readonly test$: Observable<Test | null> = this.store.select(selectTestStartData);

  constructor(private readonly store: Store) {}
}
