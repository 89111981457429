import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ApiConfiguration } from '../api-configuration';
import { BaseService } from '../base-service';
import { TestItemResult } from '../models';
import { FinishSessionRequest } from '../models/finish-session-request';
import { StartSessionRequest } from '../models/start-session-request';
import { TestRunnerStartData } from '../models/test-runner-start-data';
import { RequestBuilder } from '../request-builder';
import { StrictHttpResponse } from '../strict-http-response';

@Injectable({
  providedIn: 'root',
})
export class SessionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation testsStartSession
   */
  static readonly TestsStartSessionPath = '/api/v1/Tests/{testId}/sessions/start';

  public getResults(testId: string): Observable<TestItemResult[]> {
    return this.http.get<TestItemResult[]>(`${this.rootUrl}/api/v1/Tests/${testId}/results`);
  }

  /**
   * Старт новой сессии для респондента.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testsStartSession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testsStartSession$Response(params: {
    testId: string;
    fabUniqueId?: string;
    preview?: boolean;
    body?: StartSessionRequest;
  }): Observable<StrictHttpResponse<TestRunnerStartData>> {
    const rb = new RequestBuilder(this.rootUrl, SessionService.TestsStartSessionPath, 'post');
    if (params) {
      rb.path('testId', params.testId, {});
      rb.query('fabUniqueId', params.fabUniqueId, {});
      rb.query('preview', params.preview, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<TestRunnerStartData>;
        }),
      );
  }

  /**
   * Старт новой сессии для респондента.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `testsStartSession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testsStartSession(params: {
    testId: string;
    fabUniqueId?: string;
    preview?: boolean;
    body?: StartSessionRequest;
  }): Observable<TestRunnerStartData> {
    return this.testsStartSession$Response(params).pipe(map((r: StrictHttpResponse<TestRunnerStartData>) => r.body as TestRunnerStartData));
  }

  /**
   * Path part for operation testsFinishSession
   */
  static readonly TestsFinishSessionPath = '/api/v1/Tests/{testId}/sessions/finish';

  /**
   * Завершение сессии пользователя.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `testsFinishSession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testsFinishSession$Response(params: {
    testId: string;
    preview?: boolean;
    body?: FinishSessionRequest;
  }): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, SessionService.TestsFinishSessionPath, 'post');
    if (params) {
      rb.path('testId', params.testId, {});
      rb.query('preview', params.preview, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'text',
          accept: '*/*',
        }),
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
        }),
      );
  }

  /**
   * Завершение сессии пользователя.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `testsFinishSession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  testsFinishSession(params: { testId: string; preview?: boolean; body?: FinishSessionRequest }): Observable<void> {
    return this.testsFinishSession$Response(params).pipe(map((r: StrictHttpResponse<void>) => r.body as void));
  }
}
