<div *transloco="let t" class="h-full flex flex-column align-items-center justify-content-center">
  <span class="mb-4 text-lg text-center line-height-3 font-normal">{{ t('testrunner.test.unknownError') }}</span>
  <button
    pButton
    pRipple
    type="button"
    class="p-button-success p-button-sm my-2 font-medium"
    (click)="reloadTest()"
    [label]="t('testrunner.test.testRestart')"
  ></button>
</div>
