import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayToColumns',
  pure: false,
})
export class ArrayToColumnsPipe implements PipeTransform {
  transform(value: Array<unknown>, columnsCount: number, columnIndex: number): Array<unknown> {
    if (!value || !columnsCount || !columnIndex) {
      return value;
    }
    return value.filter((_, index) => index % columnsCount === columnIndex - 1);
  }
}
