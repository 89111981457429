import { AnimationEvent } from '@angular/animations';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { fadeInLeftAnimation, fadeInRightAnimation, fadeOutLeftAnimation, fadeOutRightAnimation } from 'angular-animations';
import { Kano, KanoFeature, KanoFeatureAnswer, KanoResult, TestItemResultTypeEnum } from 'src/api/testrunner/models';
import { PipingItem } from 'src/app/models/piping-item';
import { MacrosPipe } from 'src/app/services/macros.pipe';
import { PersistenceService } from 'src/app/services/persistence.service';

@Component({
  selector: 'app-kano',
  templateUrl: './kano.component.html',
  styleUrls: ['./kano.component.scss'],
  animations: [
    fadeInLeftAnimation({ duration: 400 }),
    fadeInRightAnimation({ duration: 400 }),
    fadeOutLeftAnimation({ duration: 400 }),
    fadeOutRightAnimation({ duration: 400 }),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanoComponent implements OnInit {
  @Input() public kano?: Kano;
  @Output() public readonly answerChange: EventEmitter<KanoResult> = new EventEmitter<KanoResult>();
  @Output() public readonly finish: EventEmitter<void> = new EventEmitter<void>();

  public activeFeature: KanoFeature | null = null;
  public answerFeatures: { [featureId: string]: KanoFeatureAnswer } = {};
  public description?: SafeHtml;
  public fadeInLeftAnimationTrigger: boolean = false;
  public fadeInRightAnimationTrigger: boolean = false;
  public fadeOutLeftAnimationTrigger: boolean = false;
  public fadeOutRightAnimationTrigger: boolean = false;
  public kanoFeatures: KanoFeature[] = [];

  private prevFeature: KanoFeature | null = null;
  private answer: KanoResult | null = null;

  constructor(
    private readonly macrosPipe: MacrosPipe,
    private readonly persistenceService: PersistenceService,
    private readonly sanitizer: DomSanitizer,
  ) {}

  /*back(): void {
    if ((this.activeFeature?.order ?? 0) > 0) {
      this.activeFeature = this.kano?.functions?.[(this.activeFeature?.order ?? 0) - 1];
    } else {
      //@TODO переход на предыдущую страницу
    }
  }*/

  public ngOnInit(): void {
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.macrosPipe.transform(this.kano?.description ?? '', this.kano));

    this.answer =
      this.persistenceService.get(
        'answer_' + this.kano?.id + ((this.kano as PipingItem)?.iterationId ? '&' + (this.kano as PipingItem)?.iterationId : ''),
      ) ??
      ({
        iterationId: (this.kano as PipingItem).iterationId ?? null,
        testId: this.kano?.testId,
        testItemId: this.kano?.id,
        type: TestItemResultTypeEnum.Kano,
      } as KanoResult);
    (this.answer?.resultItems || []).forEach((answerFeature) => (this.answerFeatures[answerFeature.featureId ?? ''] = answerFeature));

    this.kanoFeatures = [...(this.kano?.features || [])]
      .sort(this.kano?.isFeaturesShuffled ? (): number => Math.random() - 0.5 : (a, b): number => (a.order ?? 0) - (b.order ?? 0))
      .map((feature, index) => ({ ...feature, order: index }));
    this.activeFeature = this.kanoFeatures?.[0];
  }

  public fadeOutLeftAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeOutLeftAnimationTrigger = false;
      this.fadeInRightAnimationTrigger = true;
      // Показать новую фичу после того как пройдет анимация скрытия предыдущей страницы
      const animationTimout = setTimeout(() => {
        this.activeFeature = this.kanoFeatures?.[(this.prevFeature?.order ?? 0) + 1];
        clearTimeout(animationTimout);
      }, 100);
    }
  }

  public fadeInLeftAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeInLeftAnimationTrigger = false;
    }
  }

  public fadeOutRightAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeOutRightAnimationTrigger = false;
      this.fadeInLeftAnimationTrigger = true;
    }
  }

  public fadeInRightAnimationDone(event: AnimationEvent): void {
    if (event.toState) {
      this.fadeInRightAnimationTrigger = false;
    }
  }

  public isNextButtonEnabled(): boolean {
    if (!this.kano?.answerRequired) {
      return true;
    }
    if (this.kano?.isSeparatePagesDisplayEnabled) {
      const activeFeatureAnswer = this.answer?.resultItems?.find((answerFeature) => answerFeature.featureId === this.activeFeature?.id);
      return (
        (activeFeatureAnswer?.functionalQuestionScore ?? 0) > 0 &&
        (activeFeatureAnswer?.dysfunctionalQuestionScore ?? 0) > 0 &&
        (!this.kano?.isImportanceQuestionEnabled || (activeFeatureAnswer?.valueQuestionScore ?? 0) > 0)
      );
    } else {
      return (
        this.answer?.resultItems?.filter(
          (answerFeature) =>
            (answerFeature?.functionalQuestionScore ?? 0) > 0 &&
            (answerFeature?.dysfunctionalQuestionScore ?? 0) > 0 &&
            (!this.kano?.isImportanceQuestionEnabled || (answerFeature?.valueQuestionScore ?? 0) > 0),
        ).length === this.kanoFeatures.length
      );
    }
  }

  public next(): void {
    if (this.kano?.isSeparatePagesDisplayEnabled && (this.activeFeature?.order ?? 0) + 1 < (this.kanoFeatures?.length ?? 0)) {
      //анимация и переключение фичи
      this.prevFeature = this.activeFeature;
      this.activeFeature = null;
      this.fadeOutLeftAnimationTrigger = true;
      // удалить текущую фичу до окончания анимации в 400мс
      const animationTimout = setTimeout(() => {
        this.activeFeature = this.kanoFeatures?.[(this.activeFeature?.order ?? 0) + 1];
        clearTimeout(animationTimout);
      }, 300);
    } else {
      this.finish.emit();
    }
  }

  public save(kanoFeatureAnswer: KanoFeatureAnswer): void {
    this.answer = {
      ...this.answer,
      resultItems: [
        ...(this.answer?.resultItems || []).filter((feature) => feature.featureId !== kanoFeatureAnswer.featureId),
        kanoFeatureAnswer,
      ],
    };

    this.answerChange.emit(this.answer);
  }
}
