import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { concatMap, map } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { backendErrorsAction, emptyAction, getPromoCodeErrorAction, scriptErrorsAction } from '../actions/test-runner.actions';
import { ClientLogsService } from 'src/api/client-logs/services/client-logs.service';
import { ErrorTypeEnum } from '../../models/error-type-enum';
import { LogLevel } from 'src/api/client-logs/models/log-level';
import { PersistenceService } from '../../services/persistence.service';
import { saveLogAction } from '../actions/client-logs.actions';
import { saveAnswersErrorAction } from '../actions/answers.actions';
import { selectTestInfo } from '../selectors/test-runner.selectors';

@Injectable()
export class ClientLogsEffects {
  backendErrorLogs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(backendErrorsAction, getPromoCodeErrorAction, saveAnswersErrorAction),
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      map(([{ error }, testInfo]) =>
        saveLogAction({
          saveLogRequest: {
            jsonLog: JSON.stringify({
              anonymousId: this.persistenceService.get('anonymousId'),
              error,
              errorType: ErrorTypeEnum.backendError,
              testInfo,
            }),
            logLevel: LogLevel.Error,
          },
        })
      )
    );
  });

  saveLog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(saveLogAction),
      concatMap(({ saveLogRequest }) => this.clientLogsService.clientLogsSaveLogs({ body: saveLogRequest }).pipe(map(() => emptyAction())))
    );
  });

  scriptsErrors$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(scriptErrorsAction),
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      map(([{ error, sourceItemId }, testInfo]) =>
        saveLogAction({
          saveLogRequest: {
            jsonLog: JSON.stringify({
              anonymousId: this.persistenceService.get('anonymousId'),
              error,
              errorType: ErrorTypeEnum.scriptError,
              sourceItemId,
              testInfo,
            }),
            logLevel: LogLevel.Error,
          },
        })
      )
    );
  });

  unhandledExceptions$ = createEffect(() => {
    return fromEvent<MessageEvent>(window, 'error').pipe(
      concatLatestFrom(() => this.store.select(selectTestInfo)),
      map(([errors, testInfo]) =>
        saveLogAction({
          saveLogRequest: {
            jsonLog: JSON.stringify({
              anonymousId: this.persistenceService.get('anonymousId'),
              error: errors,
              errorType: ErrorTypeEnum.unhandledError,
              testInfo,
            }),
            logLevel: LogLevel.Error,
          },
        })
      )
    );
  });

  constructor(
    private actions$: Actions,
    private clientLogsService: ClientLogsService,
    private persistenceService: PersistenceService,
    private store: Store
  ) {}
}
